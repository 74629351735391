import { isLuxPlusToursEnabled } from 'checkout/selectors/tourV2Selectors'
import { useAppSelector } from 'hooks/reduxHooks'
import { arrayToMap, min, nonNullable } from 'lib/array/arrayUtils'
import getOfferListKey from 'lib/offer/offerListKey'

function useSearchTourVariation(
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary,
  filters: App.OfferListFilters,
): App.Tours.TourV2OfferVariation | undefined {
  const filtersKey = getOfferListKey(filters)

  const luxPlusEnabled = useAppSelector(isLuxPlusToursEnabled)
  const metaData = useAppSelector(state => state.offer.tourSearchResultMetadata.tourMetadata[filtersKey]?.[offer.id])

  if (metaData?.tourOptionIds?.length) {
    // get the variations from what search told us
    const variationsByTourOptionId = arrayToMap(Object.values(offer.variations), variation => variation.fkTourOptionId)
    const variations = nonNullable(metaData.tourOptionIds.map(id => variationsByTourOptionId.get(id)))
    const allOptions = variations.flatMap(variation => variation.departures.filter(dep => dep.status === 'available').flatMap(dep => dep.options))
    const cheapestOption = min(allOptions, option => {
      if (luxPlusEnabled) {
        return option.memberPrice || option.price
      }
      return option.price
    })

    if (cheapestOption) {
      return offer.variations[cheapestOption.fkVariationId]
    }
  }
}

export default useSearchTourVariation
