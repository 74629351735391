import { checkoutAccommodationGroupingKey } from 'checkout/selectors/view/accommodation'
import { createSelector } from 'reselect'
import moment from 'moment'
import { groupBy, nonNullable } from 'lib/array/arrayUtils'
import { getInsuranceEnabledCheckoutItems } from '../view/accommodation'
import { areOccupantsEqual, countOccupants, countTravellersAsOccupants, getOccupanciesFromReservations, mergeOccupants } from 'lib/offer/occupancyUtils'
import { getAllInsuranceItems, getInsuranceItems, getInsuranceUpgradeSourceItem } from '../view/insurance'
import { getInsuranceDatesFromOrder } from 'lib/order/orderInsuranceUtils'
import getDestinationCountriesFromCart from '../view/getDestinationCountriesFromCart'
import getDestinationCountriesFromExistingOrder from '../view/getDestinationCountriesFromExistingOrder'
import getInsuranceDatesFromCart from './getInsuranceDatesFromCart'
import getMultiItemOccupants from './getInsuranceMultiItemOccupants'

const getInsuranceOccupantsByAccommodationGroup = createSelector(
  (state: App.State) => getInsuranceEnabledCheckoutItems(state),
  (items) => {
    if (items.length > 0) {
      const itemMap = groupBy(items, checkoutAccommodationGroupingKey)

      return Array.from(itemMap.values()).map((itemList) => {
        const occupants = nonNullable(itemList.map(item => item.occupancy))
        return mergeOccupants(occupants)
      })
    }
  },
)

/**
 * Whether or not all the travellers across multiple items in the
 * current cart are the same set of travellers or not
 */
export const areMultiItemTravellersTheSame = createSelector(
  (state: App.State) => getMultiItemOccupants(state),
  (occupants): boolean => {
    return occupants ? areOccupantsEqual(...occupants) : true // if no occupants, then they must be the same!
  },
)

export const getOccupantsFromCartItems = createSelector(
  (state: App.State) => getInsuranceOccupantsByAccommodationGroup(state),
  (state: App.State) => areMultiItemTravellersTheSame(state),
  (occupants, isConsistent) => {
    if (isConsistent && occupants) {
      return occupants[0]
    }
  },
)

export const getInsuranceDatesFromExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (order) => {
    if (order) {
      return getInsuranceDatesFromOrder(order)
    }
  },
)

export const getOccupantsFromExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (order): App.Occupants | undefined => {
    if (order) {
      if (order.items.length) {
        const reservations = nonNullable(order.items.map(item => item.reservation))
        return getOccupanciesFromReservations(...reservations)
      } else if (order.bedbankItems.length) {
        return countOccupants(order.bedbankItems.flatMap(item => item.rooms.map(room => room.occupancy)))
      } else if (order.tourItems.length) {
        const completedItems = order.tourItems.filter(item => item.status === 'completed')
        return countTravellersAsOccupants(completedItems.flatMap(items => items.tour.travellers))
      } else if (order.cruiseItems.length) {
        const completedItems = order.cruiseItems.filter(item => item.status === 'completed')
        return countTravellersAsOccupants(completedItems.flatMap(items => items.passengers))
      }
    }
  },
)

export const isPastHotelInsuranceCoverPeriod = createSelector(
  (state: App.State) => getInsuranceDatesFromCart(state),
  (state: App.State) => getInsuranceUpgradeSourceItem(state),
  (state: App.State) => state.checkout.cart.mode,
  (newDatesFromCart, insuranceUpgradeSourceItem, cartMode): boolean => {
    if (
      !insuranceUpgradeSourceItem ||
      !newDatesFromCart?.endDate ||
      !['change-dates', 'change-package'].includes(cartMode ?? '')
    ) {
      return false
    }
    return moment(insuranceUpgradeSourceItem?.createdAt).add(1, 'year').isBefore(newDatesFromCart?.endDate)
  },
)

interface InsuranceDates {
  startDate?: string,
  endDate?: string
}
const EmptyDates: InsuranceDates = { startDate: undefined, endDate: undefined }
export const getCheckoutInsuranceDates = createSelector(
  (state: App.State) => getInsuranceDatesFromCart(state),
  (state: App.State) => getInsuranceDatesFromExistingOrder(state),
  (state: App.State) => getAllInsuranceItems(state)[0],
  (cartDates, existingOrderDates, item): InsuranceDates => {
    if (item?.startDate && item?.endDate) {
      // user has customised dates, return them
      return {
        startDate: item.startDate,
        endDate: item.endDate,
      }
    }
    return existingOrderDates ?? cartDates ?? EmptyDates
  },
)

const EmptyOccupants: App.Occupants = { adults: 0 }
export const getCheckoutInsuranceTravellers = createSelector(
  (state: App.State) => getOccupantsFromExistingOrder(state),
  (state: App.State) => getOccupantsFromCartItems(state),
  (orderTravellers, cartTravellers): App.Occupants => {
    return orderTravellers ?? cartTravellers ?? EmptyOccupants
  },
)

export const getCheckoutInsuranceCountries = createSelector(
  (state: App.State) => getDestinationCountriesFromCart(state),
  (state: App.State) => getDestinationCountriesFromExistingOrder(state),
  (state: App.State) => getInsuranceItems(state)[0],
  (cartCountries, orderCountries, item): Array<string> => {
    if (item?.countries?.length) {
      return item.countries
    } else if (orderCountries.length) {
      return orderCountries
    } else if (cartCountries.length) {
      return cartCountries
    }
    return []
  },
)
