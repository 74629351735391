import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import BusinessTravellerOfferCreditsTextLink from 'businessTraveller/components/offer-credits/BusinessTravellerOfferCreditsTextLink'
import AdditionalGuestsPopup from 'components/Common/AdditionalGuestsPopup/AdditionalGuestsPopup'
import OfferPriceDetailsRow from 'components/Common/PriceDetails/OfferPriceDetailsRow'
import ReserveForZeroTag from 'components/Common/ReserveForZeroTagAndTooltip/ReserveForZeroTag'
import CruisesDiscountTsAndCsModal from 'components/Cruises/CruisesDiscountTsAndCsModal'
import TextButton from 'components/Luxkit/Button/TextButton'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import PriceRowValueLastMinuteDiscount from 'components/Luxkit/PricePoints/Value/PriceRowValueLastMinuteDiscount'
import OfferListEventsContext, { OfferListEvents } from 'components/OfferList/OfferListEventsContext'
import PriceRowMarginText from 'components/OfferPage/PriceRowMarginText'
import Group from 'components/utils/Group'
import { RouterLocation } from 'connected-react-router'
import config from 'constants/config'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import { OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_HOTEL, OFFER_TYPE_LAST_MINUTE } from 'constants/offer'
import ModalContext from 'contexts/ModalContext'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { useAppSelector } from 'hooks/reduxHooks'
import { useDirectSearchPrices, useSearchPrices } from 'hooks/Search/useSearchPrices'
import { EmptyArray, unique } from 'lib/array/arrayUtils'
import { hasIncludedGuestsExceeded } from 'lib/checkout/cartReservationUtils'
import { safeDivideAndCeil } from 'lib/maths/mathUtils'
import getLPCRegionLOS from 'lib/offer/getLPCRegionLOS'
import offerPageURL from 'lib/offer/offerPageURL'
import { isVillaOffer } from 'lib/offer/offerTypes'
import { divideAndCeilPricing } from 'lib/offer/pricing'
import { isPackageEligibleForZeroReservation } from 'lib/offer/reserveForZeroUtils'
import { buildSuggestedDatesString, formatDateRangesForAlternativeDates, isSearchStreamingSupported } from 'lib/search/searchUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import moment from 'moment'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'

interface Props {
  offer: App.Offer | App.OfferSummary;
  className?: string;
  pkg: App.Package;
  availableRate?: App.OfferAvailableRate;
  hasDates?: boolean;
  align?: 'start' | 'center' | 'end';
  desktopAlign?: 'start' | 'center' | 'end';
  /**
   * @default The offer page URL with no parameters
   */
  offerUrl?: string;
  checkInDate?: moment.Moment;
  filters?: App.OfferListFilters;
  showCompact?: boolean
  isPricePerNight?: boolean
  hideIncludesTaxesAndFees?: boolean
  duration?: number
}

interface MappedStateProps {
  isSpoofed: boolean;
  location: RouterLocation<unknown>;
}
// Export is only temporary for experiment
export const URGENCY_TAG_THRESHOLD = 5

function OfferTilePricing(props: Props & MappedStateProps) {
  const {
    offer,
    className,
    pkg,
    availableRate,
    hasDates,
    align,
    desktopAlign,
    offerUrl,
    checkInDate,
    isSpoofed,
    location,
    filters,
    showCompact = false,
    isPricePerNight,
    hideIncludesTaxesAndFees = false,
    duration,
  } = props

  const offerMetadata = useOfferMetaData(offer.id, filters)
  const isCruise = (offer.holidayTypes ?? []).map(i => i.toLowerCase()).includes('cruises')
  const isVillas = isVillaOffer(offer)
  const rooms = filters?.rooms ?? EmptyArray
  const showMarginInSpoofMode = useAppSelector(state => state.system.showMarginInSpoofMode)
  const displayMarginInfoFlash = showMarginInSpoofMode && !!offerMetadata?.marginInfo

  const isSearchStreamingEnabled = isSearchStreamingSupported(filters)

  const shouldShowDiscountPercent = offer.type === OFFER_TYPE_LAST_MINUTE

  const directSearchPrices = useDirectSearchPrices({ filters: filters ?? {}, offerId: offer.id })
  const {
    defaultPricing,
    memberPricing,
    hotelPrice,
    hotelMemberPrice,
    showMemberPrice,
    propertyFees,
    showPerNightPricing,
    suggestedDates,
  } = useSearchPrices({
    offer,
    pkg,
    rate: availableRate,
    duration,
    filters,
    useRealSearchPrices: isSearchStreamingEnabled,
  })

  const useLuxPlusPricing = useAppSelector(checkCanViewLuxPlusBenefits)
  const discountPercent = (showMemberPrice && memberPricing && useLuxPlusPricing) ? memberPricing.discountPercent : defaultPricing.discountPercent

  const hasSuggestedCheckInCheckOut = suggestedDates?.checkIn && suggestedDates?.checkOut
  const valueToDisplay = showPerNightPricing ? Math.ceil(defaultPricing.value / (duration || 1)) : defaultPricing.value
  const baseTaxesAndFees = (availableRate?.taxesAndFees ?? pkg.taxesAndFees) || 0
  const taxesAndFees = showPerNightPricing ? safeDivideAndCeil(baseTaxesAndFees, duration || 1) : baseTaxesAndFees
  const showPriceDetails = taxesAndFees > 0 || propertyFees > 0 || config.agentHub.isEnabled

  const shouldShowValue = pkg.value > 0 && !!discountPercent && pkg.shouldDisplayValue &&
    !shouldShowDiscountPercent && (offer.type == OFFER_TYPE_HOTEL || !pkg.roomRate?.inclusionsHideValue)

  const showIncludedGuestsExceeded = useMemo(() => {
    if (!pkg.roomType || (pkg.roomRate?.extraGuestSurcharges?.length || 0) > 0) {
      return false
    }

    return rooms?.some(room => hasIncludedGuestsExceeded(room, pkg, offer))
  }, [pkg, rooms, offer])

  const shouldShowRate = !offer.isDiscountPillHidden && discountPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  let staysFrom = offer.minDuration
  const LPCRegionLOS = getLPCRegionLOS(offer)
  if (LPCRegionLOS && offer.packages.some(p => p.duration === LPCRegionLOS)) {
    staysFrom = LPCRegionLOS
  }
  const showStaysFromLabel = !showCompact && !hasDates && offer.type === OFFER_TYPE_ALWAYS_ON && LPCRegionLOS && (staysFrom ?? 0) < duration

  const isReserveForZeroEnabled = config.RESERVE_FOR_ZERO_ENABLED
  const isReservableForZero = (isReserveForZeroEnabled || isSpoofed) && isPackageEligibleForZeroReservation(offer, pkg.roomRate?.isReservableForZero, checkInDate)
  const getDuration = useCallback((singular: string, count: number) => {
    if (isCruise && count) {
      // FOR FLASH CRUISES WE NEED TO
      // SUBTRACT 1 NIGHT FROM THE DURATION.
      // BECAUSE DURATION COMES IN DAYS RATHER THAN NIGHTS.
      // E.G. 7 DAYS CRUISE IS 6 NIGHTS
      return pluralizeToString(singular, count - 1)
    }
    return pluralizeToString(singular, count)
  }, [isCruise])

  const durationText = useMemo(() => {
    if (directSearchPrices && offerMetadata?.extendedAvailability) {
      return `${formatDateRangesForAlternativeDates(offerMetadata.extendedAvailability.checkIn, offerMetadata.extendedAvailability.checkOut)} (${getDuration('night', pkg.duration)}) from`
    }
    if (directSearchPrices?.duration) {
      return `${getDuration('night', directSearchPrices.duration)} from`
    }
    if (hasDates) {
      const text = getDuration('night', pkg.duration)
      return `${text} from`
    }

    const isFlexibleSearch = filters?.flexibleNights && suggestedDates?.checkIn && suggestedDates?.checkOut
    const isAnytimeSearch = (!filters?.checkIn && !filters?.checkOut) && !isFlexibleSearch

    if (!isAnytimeSearch && hasSuggestedCheckInCheckOut && suggestedDates) {
      return `${buildSuggestedDatesString(suggestedDates.checkIn, suggestedDates.checkOut)} from`
    }

    if (suggestedDates && duration) {
      return `${getDuration('night', duration)} from`
    }

    if (pkg.duration) {
      return `${getDuration('night', pkg.duration)} from`
    }

    if (offer.tileDurationLabel) {
      return `${offer.tileDurationLabel.toLocaleLowerCase()} from`
    }

    return 'From'
  }, [hasDates, filters?.flexibleNights, filters?.checkIn, filters?.checkOut, suggestedDates, hasSuggestedCheckInCheckOut, duration, pkg.duration, offer.tileDurationLabel, getDuration, directSearchPrices, offerMetadata?.extendedAvailability])

  const durationResolved = useMemo(() => {
    if (hasDates) return pkg.duration
    if (suggestedDates && duration) return duration
    if (pkg.duration) return pkg.duration
  }, [duration, hasDates, pkg.duration, suggestedDates])

  const saleUnit = useMemo(() => {
    if (isVillas && !showPerNightPricing) return 'total'
    if (rooms.length) return showPerNightPricing ? 'night' : 'total'
    if (isPricePerNight) return 'night'
    if (offer.saleUnit.toLowerCase() === 'cruise') return 'person'
    return showPerNightPricing ? 'night' : offer.saleUnit
  }, [isPricePerNight, isVillas, offer.saleUnit, rooms.length, showPerNightPricing])

  const showModal = useContext(ModalContext)
  const openCruiseDiscountTermsAndConditionsModal = useCallback(() => {
    showModal(<CruisesDiscountTsAndCsModal />)
  }, [showModal])

  const defaultPricingToDisplay = useMemo(() => {
    const pricingWithSaleUnitOverride = {
      ...defaultPricing,
      saleUnit,
    }

    if (showPerNightPricing) {
      return divideAndCeilPricing(pricingWithSaleUnitOverride, durationResolved || 1)
    }
    return pricingWithSaleUnitOverride
  }, [defaultPricing, showPerNightPricing, durationResolved, saleUnit])

  const memberPricingToDisplay = useMemo(() => {
    if (!memberPricing) return undefined

    const pricingWithSaleUnitOverride = {
      ...memberPricing,
      saleUnit,
    }

    if (showPerNightPricing) {
      return divideAndCeilPricing(pricingWithSaleUnitOverride, durationResolved || 1)
    }
    return pricingWithSaleUnitOverride
  }, [memberPricing, showPerNightPricing, durationResolved, saleUnit])

  const offerLocations = useMemo(() => unique(offer.locations.concat(offer.location)),
    [offer.locations, offer.location])
  const vendorName = offer.vendorName

  const isSearchPage = location.pathname.includes('search')

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const marginLPC = pkg.roomRate?.marginPercentage

  const marginValueLPC = marginLPC ? hotelPrice * marginLPC / 100 : 0
  const displayMarginLPC = Boolean(isSpoofed && showMarginInSpoofMode && marginLPC && marginValueLPC)

  useEffect(() => {
    const durationToDispatch = filters?.isStream ? directSearchPrices?.duration : durationResolved

    dispatchOfferListEvent({
      type: OfferListEvents.pricingCalculated,
      leadPrice: defaultPricingToDisplay.price,
      duration: durationToDispatch ?? 0,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Group
      className={className}
      direction="vertical"
      horizontalAlign={align}
      desktopHorizontalAlign={desktopAlign}
      gap={4}
    >
      <Group direction="vertical" horizontalAlign={align} desktopHorizontalAlign={desktopAlign} noDisplayWhenEmpty>
        {isReservableForZero && <ReserveForZeroTag />}
      </Group>
      <Group direction="vertical" horizontalAlign={align} desktopHorizontalAlign={desktopAlign}>
        <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>
        <LuxPlusPriceStack
          pricing={defaultPricingToDisplay}
          memberPricing={memberPricingToDisplay}
          size={showCompact ? 'S' : 'L'}
          testid="search-price"
          desktopHorizontalAlign={desktopAlign}
          offerConfig={offer.luxPlus}
        />
        {shouldShowValue && isCruise && !isPricePerNight && <PriceRowValueDiscountWithCaption
          data-testid="value-box"
          size="M"
          originalValue={Math.ceil(valueToDisplay)}
          discountPercentage={shouldShowRate ? discountPercent : undefined}
          onInfoIconClick={openCruiseDiscountTermsAndConditionsModal}
        />}
        {showStaysFromLabel && <PriceRowCaption>
          Stays from {staysFrom} available
        </PriceRowCaption>}
        {!!pkg.roomOccupancy && offer.type === 'tour' && <PriceRowCaption>{pkg.roomOccupancy}</PriceRowCaption>}
        {showIncludedGuestsExceeded && (
          <AdditionalGuestsPopup
            complex={false}
            description="+ Extra guest surcharge"
            modalContent={pkg.roomPolicyDescription}
          />
        )}
        {shouldShowValue && !isCruise && <PriceRowValueDiscountWithCaption
          data-testid="value-box"
          size="M"
          originalValue={Math.ceil(valueToDisplay)}
          discountPercentage={shouldShowRate ? discountPercent : undefined}
        />}
        {shouldShowDiscountPercent && discountPercent >= config.displayDiscountThreshold.lastMinute && <PriceRowValueLastMinuteDiscount
          discountPercentage={discountPercent}
        />}
        <Group direction="vertical" horizontalAlign={align} desktopHorizontalAlign={desktopAlign} gap={4}>
          {showPriceDetails && <OfferPriceDetailsRow
            trigger="price-row"
            triggerSize="M"
            offer={offer}
            duration={duration}
            propertyFees={propertyFees}
            hotelPrice={hotelPrice}
            taxesAndFees={baseTaxesAndFees}
            rooms={rooms}
            extraGuestMessage={showIncludedGuestsExceeded ? pkg.roomPolicyDescription : ''}
            dueAtPropertyMessage={offer.property?.taxesAndFeesContent ? offer.property?.taxesAndFeesContent : ''}
            hotelMemberPrice={hotelMemberPrice}
            showMemberPrice={showMemberPrice}
            context={isSearchPage ? 'search' : undefined}
            hideIncludesTaxesAndFees={hideIncludesTaxesAndFees}
            cta={
              <TextButton kind="primary" fit="flex" to={offerUrl ?? offerPageURL(offer)}>
                View Offer
              </TextButton>
            }
          />}
          {displayMarginInfoFlash && (
            <PriceRowMarginText marginValue={defaultPricing.price * offerMetadata.marginInfo!.percentage} margin={offerMetadata.marginInfo!.percentage} size="M" type="le_estimate" colour="critical" />
          )}
          {displayMarginLPC && (<PriceRowMarginText marginValue={marginValueLPC} margin={marginLPC! / 100} size="M" type="le_estimate" colour="critical" />)}
          {config.businessTraveller.currentAccountMode === 'business' && <BusinessTravellerOfferCreditsTextLink
            type="estimate"
            offer={offer}
            totalCost={defaultPricing.price}
            numberOfNights={duration}
          />}
          <PriceRowAgentHubCommission
            size="L"
            productType={offer.productType}
            offerId={offer.id}
            offerLocations={offerLocations}
            vendorName={vendorName}
          />
        </Group>
      </Group>
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((appState) => {
  return {
    isSpoofed: appState.auth.account.isSpoofed,
    location: appState.router.location,
  }
})(OfferTilePricing)
