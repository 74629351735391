import { addTotals } from 'checkout/lib/utils/payments/totals'
import { createSelector } from 'reselect'
import { selectEarnableFlightBusinessTravellerCreditsTotal } from '../view/flights'
import { getAccommodationTotals, selectEarnableAccommodationBusinessTravellerCreditsTotal } from './accommodation'
import { getBundleAndSaveTotals } from './bundleAndSave'
import { getCarHireTotals } from './carHire'
import { getExperienceTotals, getTransferTotals } from './experience'
import { getFlightTotals } from './flights'
import { getGiftCardTotals } from './giftCard'
import { getTourExperienceTotals } from './toursv2'
import { getVillaTotals } from './villas'

const getTotalsWithoutInsurance = createSelector(
  (state: App.State) => getAccommodationTotals(state),
  (state: App.State) => getBundleAndSaveTotals(state),
  (state: App.State) => getExperienceTotals(state),
  (state: App.State) => getTransferTotals(state),
  (state: App.State) => getFlightTotals(state),
  (state: App.State) => getCarHireTotals(state),
  (state: App.State) => getGiftCardTotals(state),
  (state: App.State) => getVillaTotals(state),
  (state: App.State) => getTourExperienceTotals(state),
  (state: App.State) => selectEarnableAccommodationBusinessTravellerCreditsTotal(state),
  (state: App.State) => selectEarnableFlightBusinessTravellerCreditsTotal(state),
  (...itemTotals: Array<App.WithDataStatus<App.Checkout.ItemViewTotals>>): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    return addTotals(itemTotals)
  },
)

export default getTotalsWithoutInsurance
