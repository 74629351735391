import { createSelector } from 'reselect'
import { getTourV2Items } from './toursv2'
import { excludeNullOrUndefined } from 'checkout/utils'
import { getDepositServiceFeeConfig } from '../featureConfig/deposit'
import { getFullOffers } from 'selectors/offerSelectors'
import getFlightBundledItemIds from './getFlightBundledItemIds'
import { groupBy, nonNullable } from 'lib/array/arrayUtils'
import { isTourV2Offer } from 'lib/offer/offerTypes'
import { getTourV2ItemView } from 'checkout/lib/utils/tours/view'
import { getLabels } from 'checkout/lib/utils/accommodation/label'
import { getLocationString } from 'checkout/lib/utils/accommodation/location'
import offerPageURL from 'lib/offer/offerPageURL'
import { isSalesforceTourV2 } from 'lib/offer/offerUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'

const getTourV2ItemViews = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => getDepositServiceFeeConfig(state),
  (state: App.State) => getFullOffers(state),
  (state: App.State) => getFlightBundledItemIds(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.checkout.cart.existingOrder,
  (
    cartItems,
    serviceFee,
    offers,
    bundledItemIds,
    postPurchase,
    existingOrder,
  ): App.WithDataStatus<Array<App.Checkout.TourV2AccommodationOfferView>> => {
    // Each item = 1 person, but we want to process the same departure/offer as a single view
    const itemsByBookingKey = groupBy(cartItems, item => `${item.offerId}-${item.purchasableOption.fkDepartureId}`)
    const itemGroups = itemsByBookingKey.values().map(items => ({
      departureId: items[0].purchasableOption.fkDepartureId,
      offerId: items[0].offerId,
      items,
    })).toArray()

    const views = itemGroups.map((itemGroup): App.WithDataStatus<App.Checkout.TourV2AccommodationOfferView | undefined> => {
      const { offerId, items } = itemGroup
      const offer = offers[offerId] as App.Tours.TourV2Offer
      if (!isTourV2Offer(offer)) {
        return {
          hasRequiredData: false,
          data: undefined,
        }
      }

      const firstItem = items[0]
      const { startDate, endDate, duration, image, purchasableOption } = firstItem

      const itemViewsWithStatuses = items.map(
        (item, index) => getTourV2ItemView(item, index, offer, postPurchase, existingOrder),
      )

      const variationId = offer?.variations[purchasableOption.fkVariationId] ?
        purchasableOption.fkVariationId :
        Object.values(offer?.variations ?? {}).find(variation => variation.fkTourOptionId === purchasableOption.fkVariationId)?.id

      const offerView: App.Checkout.TourV2AccommodationOfferView = {
        offerId,
        offerPageUrl: offerPageURL(offer),
        ...getLabels(offer, variationId),
        bedGroups: [],
        confidenceLabels: [],
        duration,
        startDate,
        endDate,
        image,
        location: getLocationString(offer, variationId),
        occupancy: items.map(item => item.occupancy).filter(excludeNullOrUndefined),
        offerType: 'tour_v2',
        reservationType: 'instant_booking',
        urgencyLabels: [],
        saleUnit: 'room',
        durationLabel: pluralizeToString('day', duration),
        offerLoaded: true,
        itemViews: itemViewsWithStatuses.map(i => i.data),
        propertyTimezone: 'GMT',
        operatorName: offer ? `Operated by: ${offer.operatedBy}` : '',
        offer,
        designation: 'Tour',
        depositType: offer?.depositType,
        depositAmount: offer?.depositAmount ?? undefined,
        serviceFee: isSalesforceTourV2(offer) ? serviceFee : undefined,
        isBundled: items.some(item => bundledItemIds.has(item.itemId)),
      }

      return {
        data: offerView,
        hasRequiredData: itemViewsWithStatuses.every(view => view.hasRequiredData),
      }
    })

    return {
      data: nonNullable(views.map(view => view.data)),
      hasRequiredData: views.every(view => view.hasRequiredData),
    }
  })

export default getTourV2ItemViews
