import React, { ComponentProps, PropsWithChildren, useCallback, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import TextButton from '../Button/TextButton'
import { LuxkitModalBehaviourDispatchContext, LuxkitModalBehaviourStateContext } from './context/LuxkitModalBehaviourContexts'
import { stopPropagation } from 'lib/web/eventHandlerUtils'
import { memoWithNestedProps } from 'lib/memoize/memoWithNestedProps'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import noop from 'lib/function/noop'
import { LuxkitModalBehaviourDispatchActions } from './context/LuxkitModalBehaviourState'

export const StyledModalFooter = styled.footer`
  background-color: ${props => props.theme.palette.neutral.default.eight};
  display: flex;
  gap: ${rem(8)};
  transition: box-shadow .2s ease;
  z-index: 1;
  padding: ${rem(20)};
  ${mediaQueryUp.tablet} {
    padding: ${rem(16)} ${rem(24)} ${rem(24)};
  }

  &.layout-auto {
    flex-direction: column;

    &.has-buttons {
      justify-content: flex-end;
    }

    ${mediaQueryUp.tablet} {
      &.has-buttons {
        align-items: center;
        flex-direction: row;
      }
    }
  }
  &.layout-stacked {
    flex-direction: column;
  }

  &.has-shadow {
    box-shadow: ${props => props.theme.shadow.top.small};
  }
`

const ActionsContainer = styled.div`
  display: inline-flex;
  gap: ${rem(8)};
  &.layout-auto {
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: end;

    > * {
      flex-grow: 1;
    }

    ${mediaQueryUp.tablet} {
      > * {
        flex-grow: unset;
      }
    }
  }

  &.layout-stacked {
    flex-direction: column;
  }
`

export type ModalFooterOnClickHandler = (e: React.MouseEvent<HTMLButtonElement>, resolve: (result?: any) => void) => void;

export interface ModalFooterActionProps extends Omit<ComponentProps<typeof TextButton>, 'onClick' | 'kind' | 'variant' | 'size' | 'fit'> {
  onClick?: ModalFooterOnClickHandler
}

interface Props extends PropsWithChildren {
  isPrimaryActionHidden?: boolean
  isSecondaryActionHidden?: boolean
  primaryActionProps?: ModalFooterActionProps
  secondaryActionProps?: ModalFooterActionProps
  /**
   * @default auto
   */
  actionsLayout?: 'auto' | 'stacked'
}

function ModalFooter({
  children,
  isPrimaryActionHidden,
  isSecondaryActionHidden,
  primaryActionProps,
  secondaryActionProps,
  actionsLayout = 'auto',
}: Props) {
  const modalContext = useModalElementContext()
  const modalBehaviourState = useContext(LuxkitModalBehaviourStateContext)
  const modalBehaviourDispatch = useContext(LuxkitModalBehaviourDispatchContext)
  const hasPrimaryButton = primaryActionProps?.children && !isPrimaryActionHidden
  const hasSecondaryButton = secondaryActionProps?.children && !isSecondaryActionHidden
  const hasButtons = hasPrimaryButton || hasSecondaryButton

  const onPrimaryButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (primaryActionProps?.onClick) {
      primaryActionProps.onClick(e, modalContext?.resolve ?? noop)
    } else if (e.currentTarget.type !== 'submit') {
      // if it's a submit button, assume 'default' behaviour is handled by some sort of form element
      modalContext?.resolve()
    }
  }, [modalContext, primaryActionProps])

  const onSecondaryButtonClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (secondaryActionProps?.onClick) {
      secondaryActionProps.onClick(e, modalContext?.resolve ?? noop)
    } else if (e.currentTarget.type !== 'submit') {
      // if it's a submit button, assume 'default' behaviour is handled by some sort of form element
      modalContext?.resolve()
    }
  }, [modalContext, secondaryActionProps])

  useEffect(() => {
    modalBehaviourDispatch({
      type: LuxkitModalBehaviourDispatchActions.TOGGLE_FOOTER,
      hasFooter: true,
    })

    return () => {
      modalBehaviourDispatch({
        type: LuxkitModalBehaviourDispatchActions.TOGGLE_FOOTER,
        hasFooter: false,
      })
    }
  }, [modalBehaviourDispatch])

  return <StyledModalFooter
    className={cn(
      `layout-${actionsLayout}`,
      {
        'has-shadow': !(modalBehaviourState?.bodyHasReachedEnd ?? true),
        'has-buttons': hasButtons,
      },
    )}
    onClick={stopPropagation}
  >
    {children}
    {hasButtons && <ActionsContainer className={`layout-${actionsLayout}`}>
      {hasPrimaryButton && <TextButton
        data-testid="modal-primary-action"
        {...primaryActionProps}
        kind="primary"
        size="large"
        onClick={onPrimaryButtonClick}
      />}
      {hasSecondaryButton && <TextButton
        data-testid="modal-secondary-action"
        {...secondaryActionProps}
        kind="tertiary"
        size="large"
        onClick={onSecondaryButtonClick}
      />}
    </ActionsContainer>}
  </StyledModalFooter>
}

export default memoWithNestedProps(ModalFooter, ['primaryActionProps', 'secondaryActionProps'])
