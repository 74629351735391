import { createSelector } from 'reselect'
import { getLuxPlusSubscriptionItems, isLuxPlusSubscriptionInCart } from 'checkout/selectors/view/luxPlusSubscription'
import { getTourV2Items } from '../view/toursv2'
import { isTourV2Offer } from 'lib/offer/offerTypes'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import { getTourItemKey } from 'lib/tours/tourUtils'
import { floatify } from 'lib/maths/mathUtils'
import getPayableTotal from './getPayableTotal'
import getDiscountTotal from './getDiscountTotal'
import getPayableAfterModifiers from './getPayableAfterModifiers'
import { filterItemDiscounts, PromoTypeLookup } from 'lib/promo/promoMappers'

export const checkoutPromotionView = createSelector(
  (state: App.State) => state.checkout.promo,
  (state: App.State) => getDiscountTotal(state),
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => !!state.checkout.isFetchingPromo,
  (promotion, discount, payableTotal, fetching): App.Checkout.PromotionView => {
    if (discount > payableTotal) {
      return {
        promotion,
        discount: payableTotal,
        fetching,
      }
    }
    return {
      promotion,
      discount,
      fetching,
    }
  },
)

export const checkoutCommissionView = createSelector(
  (state: App.State) => state.checkout.commission,
  (state: App.State) => getDiscountTotal(state),
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => !!state.checkout.isFetchingCommmission,
  (promotion, discount, payableTotal, fetching): App.Checkout.PromotionView => {
    if (discount > payableTotal) {
      return {
        promotion,
        discount: payableTotal,
        fetching,
      }
    }
    return {
      promotion,
      discount,
      fetching,
    }
  },
)

export const getApplyCreditDisclaimer = createSelector(
  (state: App.State) => state.auth.account.creditsByCurrency[state.checkout.cart.currencyCode],
  (state: App.State) => getPayableAfterModifiers(state),
  (state: App.State) => isLuxPlusSubscriptionInCart(state),
  (state: App.State) => getLuxPlusSubscriptionItems(state),
  (credits, payable, isLuxPlusSubscriptionInCart, luxPlusCartSubscriptionItems): string | undefined => {
    const creditBalance = credits?.balance ?? 0

    if (isLuxPlusSubscriptionInCart) {
      const subscriptionItem = luxPlusCartSubscriptionItems[0]
      const maxCreditTotalPayableForSubscription = floatify(payable - subscriptionItem.amount)

      if (creditBalance > maxCreditTotalPayableForSubscription) {
        return `Your ${LUXURY_PLUS.PROGRAM_NAME} annual subscription fee must be paid via card payment.`
      }
    }
  },
)

export const getSoldOutTourV2Offer = createSelector(
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.offer.tourV2Offers,
  (tourItems, offers, tourOffers): App.Tours.TourV2Offer | undefined => {
    let soldOutOffer
    tourItems.map(tourItem => {
      const tourItemKey = getTourItemKey(tourItem)
      const offer = (offers[tourItemKey] ?? tourOffers[tourItemKey]) as App.AnyOffer

      if (isTourV2Offer(offer)) {
        const departure = offer.departures[tourItem.purchasableOption.fkDepartureId]

        if (!departure) {
          soldOutOffer = offer
          return
        }

        const isUnavailable = departure.status === 'unavailable' || departure.status === 'unavailable-inactive-campaign'
        const datesNotMatching = departure.startDate !== tourItem.startDate || departure.endDate !== tourItem.endDate

        if (isUnavailable || datesNotMatching) {
          soldOutOffer = offers
        }
      }
    })

    return soldOutOffer
  },
)

export const isFetchingPromo = createSelector(
  (state: App.State) => state.checkout.isFetchingPromo ?? false,
  (isFetchingPromo) => isFetchingPromo,
)

export type GetPromoCodeItemDiscounts = {
  hasRequiredPromoCodeData: boolean
  codeName: string
  itemDiscounts: Array<App.Checkout.ItemDiscount>
}

/**
 * This is the preferred method for loading promo data as it is less error prone, extend this results of this selector with (carefully selected) promo information as required
 */
export const getPromoCodeItemDiscounts = createSelector(
  (state: App.State) => state.checkout.promo,
  (state: App.State) => state.checkout.isFetchingPromo,
  (state: App.State) => state.checkout.cart.isDevToolsEnabled,
  (_: App.State, lookup?: PromoTypeLookup) => lookup?.specificType,
  (_: App.State, lookup?: PromoTypeLookup) => lookup?._logFilterInfo,
  (
    promo,
    isFetchingPromo,
    isDevMode,
    lookupSpecificType = 'all',
    lookupLogFilterInfo = false,
  ):GetPromoCodeItemDiscounts => {
    // (No promo applied)
    if (!promo) {
      return {
        hasRequiredPromoCodeData: true,
        codeName: '',
        itemDiscounts: [],
      }
    }

    if (isFetchingPromo) {
      return {
        hasRequiredPromoCodeData: false,
        codeName: '',
        itemDiscounts: [],
      }
    }

    const items = filterItemDiscounts({
      itemDiscounts: promo?.items,
      lookup: {
        specificType: lookupSpecificType as any,
        _logFilterInfo: isDevMode ? true : lookupLogFilterInfo,
      },
    })

    return {
      hasRequiredPromoCodeData: true,
      codeName: promo.code,
      itemDiscounts: items,
    }
  },
)

export const getAllPromoCodeItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'all' }),
)

export const getCruiseItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'cruise' }),
)

export const getTourAndCruiseItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'tourAndCruiseItems' }),
)

export const getSubReoccuringItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'subscription_reoccurring-fee' }),
)

export const getSubJoiningFeeItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'subscription_joining-fee' }),
)

export const getTransferItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'transfer' }),
)

export const getExperienceItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'experience' }),
)

export const getAccommodationAndExperienceItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'accommodationAndExperiences' }),
)

export const getInsuranceItemDiscounts = createSelector(
  (state: App.State) => state,
  (state) => getPromoCodeItemDiscounts(state, { specificType: 'insurance' }),
)

type filterBreakdownItemDiscountsProps = {
  allItemDiscounts: Array<App.Checkout.ItemDiscount> | undefined,
  iIds: Array<{
    offerId?: string,
    itemId?: string,
  }>
}

export function filterBreakdownItemDiscounts({ allItemDiscounts, iIds }: filterBreakdownItemDiscountsProps): Array<App.Checkout.ItemDiscount> {
  if (allItemDiscounts == undefined || allItemDiscounts?.length == 0 || iIds.length === 0) {
    return []
  }

  const res = allItemDiscounts.filter(id =>
    iIds.some(({ offerId, itemId }) => {
      if (id.categoryBK == 'experiences') {
        return offerId == id.offerId
      }

      return (offerId === id.offerId && itemId === id.itemId) || (itemId == id.itemId && !offerId) || (offerId == id.offerId && !itemId)
    }),
  )

  return res
}
