import OfferPartnershipLabel from 'components/Common/Labels/OfferPartnershipLabel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import ProductTypeLabel from 'components/Luxkit/Label/ProductTypeLabel'
import ViewBundleOffer from 'components/OfferList/OfferListTiles/SearchOfferTiles/ViewBundleOffer'
import HotelSearchTileAlternativeDatesBanner from 'components/SearchKit/SearchTiles/accommodations/HotelSearchTileAlternativeDatesBanner'
import SearchTileCancellationPolicy from 'components/SearchV2/SearchTileCancellationPolicy/SearchTileCancellationPolicy'
import config from 'constants/config'
import { OFFER_TYPE_ALWAYS_ON } from 'constants/offer'
import useOffer from 'hooks/Offers/useOffer'
import useOfferMetaData from 'hooks/Offers/useOfferMetaData'
import { useAppSelector } from 'hooks/reduxHooks'
import useMapSearchOfferUrl from 'hooks/Search/useMapSearchOfferUrl'
import { nonNullable } from 'lib/array/arrayUtils'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { isOfferRatingDisplayable } from 'lib/order/reviewUtils'
import { buildSearchParamsKey, isSearchStreamingSupported } from 'lib/search/searchUtils'
import React, { useMemo } from 'react'
import { logNewRelic } from 'services/newRelic'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import SearchTile from '../SearchTile'
import SearchTileRowOverlineLocation from '../shared/SearchTileRowOverlineLocation'
import HotelSearchTileAction from './HotelSearchTileAction'
import HotelSearchTileFeatures from './HotelSearchTileFeatures'
import HotelSearchTileHighlightBanner from './HotelSearchTileHighlightBanner'
import HotelSearchTileLabels from './HotelSearchTileLabels'
import HotelSearchTilePriceStack from './HotelSearchTilePriceStack'
import HotelSearchTileUnavailabilityBanner from './HotelSearchTileUnavailabilityBanner'
import HotelSearchTileEarnablePoints from './HotelSearchTileEarnablePoints'

interface Props {
  offer: App.HotelOffer | App.HotelOfferSummary
  filters?: App.OfferListFilters
}

function HotelSearchTile({ offer, filters }: Props) {
  const metaData = useOfferMetaData(offer.id, filters)
  const checkIn = filters?.checkIn ?? metaData?.suggestedTravelDates?.checkIn
  const checkOut = filters?.checkOut ?? metaData?.suggestedTravelDates?.checkOut
  const isSpecificSearch = !!(checkIn && checkOut && filters?.rooms)

  const [, fetchingOffer] = useOffer<App.Offer>(offer.id, {
    requireSummaryOnly: !isSpecificSearch,
  })

  const searchKey = useMemo(() => buildSearchParamsKey(checkIn, checkOut, filters?.rooms), [checkIn, checkOut, filters?.rooms])

  const bestMaybeAvailableRate = useAppSelector<App.OfferMaybeAvailableRate | undefined>((state) => state.offer.offerBestPrices[offer.id]?.[searchKey])
  const bestPriceError = useAppSelector<boolean>((state) => !!state.offer.offerPricesErrors[offer.id]?.[searchKey])

  const searchStreamingEnabled = isSearchStreamingSupported(filters)
  const duration = metaData?.pricing?.duration ?? offer.lowestPricePackage?.duration

  const hasFiltersSet = !!(checkIn && checkOut && filters?.rooms)

  const bestPackage = useMemo<App.HotelPackage | undefined>(() => {
    if (!searchStreamingEnabled) {
      if (!hasFiltersSet || bestPriceError) {
        return offer.lowestPricePackage
      }
      if (bestMaybeAvailableRate?.rate && !fetchingOffer) {
        const bestPricePkg = offer.packages.find(pkg => pkg.uniqueKey === bestMaybeAvailableRate.rate.packageUniqueKey)
        if (!bestPricePkg) {
          logNewRelic('Missing best price package from offer', {
            offerId: offer.id,
            packages: offer.packages.map(pkg => pkg.uniqueKey),
            searchKey,
            bestPriceRate: bestMaybeAvailableRate.rate,
          })
        }
        return bestPricePkg ?? offer.lowestPricePackage
      }
    } else {
      // use search pricing package id by default
      const pkgId = metaData?.pricing?.lowestPricePackageId ?? offer.lowestPricePackage?.id
      if (!pkgId || !duration || !bestMaybeAvailableRate?.rate?.roomRateId) {
        return offer.lowestPricePackage
      }

      const pkg = offer.packages.find(pkg => pkg.uniqueKey === getPackageUniqueKey(pkgId, duration, bestMaybeAvailableRate.rate.roomRateId))
      return pkg ?? offer.lowestPricePackage
    }
  }, [bestPriceError, bestMaybeAvailableRate, fetchingOffer, hasFiltersSet, metaData, offer, searchStreamingEnabled, searchKey, duration])

  const cancellationPolicyType = bestPackage?.roomRate?.cancellationPolicy?.type

  const locations = useMemo<Array<string>>(() => nonNullable([offer.locationHeading, offer.locationSubheading]), [offer])
  const mapUrl = useMapSearchOfferUrl(offer)
  const isSpoofed = useAppSelector((state) => state.auth.account.isSpoofed)
  const showProductLabel = isSpoofed || offer.productType !== OFFER_TYPE_ALWAYS_ON

  const isSoldOut = searchStreamingEnabled ? !metaData?.available : hasFiltersSet && bestMaybeAvailableRate !== undefined && !bestMaybeAvailableRate.available

  const showHighlight = useMemo(() => {
    return !!(filters?.propertyId && !filters?.searchNearby && offer.property?.id === filters.propertyId.split(':')[1])
  }, [filters, offer])

  const bestAvailableRate = bestMaybeAvailableRate?.available ? bestMaybeAvailableRate.rate : undefined

  return <SearchTile
    className={HotelSearchTile.name}
    productType={offer.productType}
    offerType={offer.type}
    state={showHighlight ? 'highlighted' : 'default'}
    action={!isSoldOut && <HotelSearchTileAction offer={offer} offerMetaData={metaData} />}
    banner={<>
      {showHighlight && <HotelSearchTileHighlightBanner offer={offer} offerMetaData={metaData} filters={filters} />}
    </>}
    bookmarkAction={<BookmarkButton offer={offer} />}
    cancellationPolicyLabel={!!cancellationPolicyType && <SearchTileCancellationPolicy
      cancellationPolicyType={cancellationPolicyType}
      checkInDate={filters?.checkIn}
      timezoneOffset={offer.property.timezoneOffset}
      offerType={offer.type}
    />}
    extension={<>
      {config.ENABLE_BUNDLE_AND_SAVE && metaData?.bundleOfferId && <ViewBundleOffer
        offer={offer}
        filters={filters}
        offerMetaData={metaData}
        offerLinkIncludesFilters
      />}
      {metaData?.extendedAvailability && <HotelSearchTileAlternativeDatesBanner
        checkIn={metaData.extendedAvailability.checkIn}
        checkOut={metaData.extendedAvailability.checkOut}
      />}
      {isSoldOut && !metaData?.extendedAvailability && <HotelSearchTileUnavailabilityBanner offer={offer} filters={filters} />}
    </>}
    features={<HotelSearchTileFeatures offer={offer} offerMetaData={metaData} filters={filters} bestPackage={bestPackage} />}
    images={offer.images}
    labels={<HotelSearchTileLabels
      offer={offer}
      filters={filters}
      bestPackage={bestPackage}
      showUrgencyTags={!isSoldOut}
    />}
    overline={<SearchTileRowOverlineLocation locations={locations} mapUrl={mapUrl} />}
    pricePoint={!isSoldOut && <HotelSearchTilePriceStack
      offer={offer}
      filters={filters}
      bestPackage={bestPackage}
      duration={duration ?? 1}
      bestRate={bestAvailableRate}
    />}
    productLabel={<LabelGroup>
      {showProductLabel && <ProductTypeLabel productType={offer.productType} />}
      <OfferPartnershipLabel offer={offer} />
    </LabelGroup>}
    rating={isOfferRatingDisplayable(offer.property.rating) ? offer.property.rating : undefined}
    title={offer.property.name}
    meta={<HotelSearchTileEarnablePoints
      offer={offer}
      bestPackage={bestPackage}
      bestAvailableRate={bestAvailableRate}
      filters={filters}
    />}
  />
}

export default HotelSearchTile
