import { createReducer } from 'lib/redux/reducerUtils'
import { API_CALL_FAILURE, API_CALL_REQUEST, API_CALL_SUCCESS } from 'actions/actionConstants'
import { businessTravellerApiFailureActions, businessTravellerApiRequestActions, businessTravellerApiSuccessActions } from './businessTravellerApiActionReducers'
import { ValueOf } from 'type-fest'
import { USER_LOGOUT } from 'actions/apiActionConstants'

const BUSINESS_TRAVELLER_INITIAL_STATE: App.BusinessTravellerState = {
  currentBusinessAccount: undefined,
  offersCredits: {},
  businessBudgets: {},
  businessAccommodationPolicy: { status: 'pending', policy: undefined },
  businessFlightPolicy: { status: 'pending', policy: undefined },
  businessCarHirePolicy: { status: 'pending', policy: undefined },
  businessFlightBudgets: {},
  businessCarHireBudgets: {},
  businessApprovalPolicy: { status: 'pending', policy: undefined },
  budgets: {},
  flightSearchBudget: {},
  onboarding: undefined,
  employees: { status: 'pending', all: {} },
  approvers: { status: 'pending', all: {} },
  selectedTravellerEmployeeIds: [],
  selectedTravellerGuestCount: 0,
  approvalRequestId: undefined,
  businessMapBudget: undefined,
  employeeDetails: { status: 'pending', all: [] },
  businessCards: { status: 'pending', cards: [] },
}

export enum BUSINESS_TRAVELLER_ACTIONS {
  RESET = 'BUSINESS_TRAVELLER_RESET',
  CLEAR_APPROVAL_REQUESTS = 'BUSINESS_TRAVELLER_CLEAR_APPROVAL_REQUESTS',
  SET_BUSINESS_TRAVELLER = 'BUSINESS_TRAVELLER_SET_BUSINESS_TRAVELLER',
  SET_CART_RESTORE_REQUEST_ID = 'BUSINESS_TRAVELLER_SET_CART_RESTORE_REQUEST_ID',
  CLEAR_CART_RESTORE_REQUEST_ID = 'BUSINESS_TRAVELLER_CLEAR_CART_RESTORE_REQUEST_ID',
}

type Actions = Utils.FullActionMap<{
  [BUSINESS_TRAVELLER_ACTIONS.SET_BUSINESS_TRAVELLER]: { employeeIds?: Array<string>, guestCount?: number },
  [BUSINESS_TRAVELLER_ACTIONS.CLEAR_APPROVAL_REQUESTS]: { force?: boolean },
  [BUSINESS_TRAVELLER_ACTIONS.SET_CART_RESTORE_REQUEST_ID]: { approvalRequestId: string },
  [BUSINESS_TRAVELLER_ACTIONS.CLEAR_CART_RESTORE_REQUEST_ID]: { force?: boolean },
}>

const businessTravellerReducer = createReducer<App.BusinessTravellerState, ValueOf<Actions>>(
  BUSINESS_TRAVELLER_INITIAL_STATE,
  {
    [API_CALL_REQUEST]: (state, action) => businessTravellerApiRequestActions(action.api)(state, action),
    [API_CALL_SUCCESS]: (state, action) => businessTravellerApiSuccessActions(action.api)(state, action),
    [API_CALL_FAILURE]: (state, action) => businessTravellerApiFailureActions(action.api)(state, action),
    [BUSINESS_TRAVELLER_ACTIONS.SET_BUSINESS_TRAVELLER]: (state, action) => {
      return {
        selectedTravellerEmployeeIds: action.employeeIds ?? state.selectedTravellerEmployeeIds,
        selectedTravellerGuestCount: action.guestCount ?? state.selectedTravellerGuestCount,
      }
    },
    [USER_LOGOUT]: () => ({ ...BUSINESS_TRAVELLER_INITIAL_STATE }),
    [BUSINESS_TRAVELLER_ACTIONS.CLEAR_APPROVAL_REQUESTS]: (state, action) => {
      // Only clear if request type is hotel, unless force is passed in. Force was introduced because hotels clears
      // on the checkout page, but flights approval process requires this information on checkout page, and handles
      // clearing in different scenarios.
      if (action.force || state.approvalRequests?.request?.[0]?.type === 'hotel') {
        return { approvalRequests: undefined }
      } else {
        return { approvalRequests: state.approvalRequests }
      }
    },
    [BUSINESS_TRAVELLER_ACTIONS.SET_CART_RESTORE_REQUEST_ID]: (state, action) => {
      // Used to persist approvalRequestId from checkout-restore-cart
      return {
        approvalRequestId: action.approvalRequestId,
      }
    },
    [BUSINESS_TRAVELLER_ACTIONS.CLEAR_CART_RESTORE_REQUEST_ID]: (state, action) => {
      // Only clear if request type is hotel, unless force is passed in. Force was introduced because hotels clears
      // on the checkout page, but flights approval process requires this information on checkout page, and handles
      // clearing in different scenarios.
      if (action.force || state.approvalRequests?.request?.[0]?.type === 'hotel') {
        return { approvalRequestId: undefined }
      } else {
        return { approvalRequestId: state.approvalRequestId }
      }
    },
  },
)

export default businessTravellerReducer
