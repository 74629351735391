import React from 'react'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { connect } from 'react-redux'
import PriceRowLuxLoyaltyPoints from 'components/Luxkit/PricePoints/LuxLoyalty/PriceRowLuxLoyaltyPoints'
import { getLuxLoyaltyDefaultTier } from 'luxLoyalty/selectors/utils'

interface MappedProps {
  luxLoyaltyEnabled: boolean;
  loyaltyAccount?: App.LuxLoyaltyAccount;
  defaultTier: App.LuxLoyaltyTier;
}

interface Props extends MappedProps {
  pointsEarnCalculation: App.StatefulData<App.LuxLoyaltyPointsEarnCalculation, boolean>;
  /**
   * @default the base or starting program tier.
   * The user's LuxLoyalty account tier will be applied if it exits.
   * If needed, the tier can be overridden by passing a different value (e.g. for tier upgrades).
   */
  tier?: React.ComponentProps<typeof PriceRowLuxLoyaltyPoints>['tier'];
  /**
   * @default 'S'
   */
  size?: React.ComponentProps<typeof PriceRowLuxLoyaltyPoints>['size'];
  /**
   * @default false
   */
  hideStatusCredits?: boolean;
  calculationType: 'estimate' | 'exact';
}

function LuxLoyaltyPoints(props: Props) {
  const {
    luxLoyaltyEnabled,
    loyaltyAccount,
    pointsEarnCalculation,
    size = 'S',
    tier,
    defaultTier,
    hideStatusCredits = false,
    calculationType,
  } = props

  const fetching = !!pointsEarnCalculation?.fetching
  const err = pointsEarnCalculation?.error
  const points = pointsEarnCalculation?.data?.points ?? 0
  const statusCredits = pointsEarnCalculation?.data?.statusCredits ?? 0

  if (!luxLoyaltyEnabled || err || (!fetching && !points)) return null

  if (fetching) {
    return <TextLoadingBox
      width="20ch"
      lines={1}
      typography="body-large"
    />
  }

  return <PriceRowLuxLoyaltyPoints
    tier={tier ?? loyaltyAccount?.tier ?? defaultTier}
    earnablePoints={points}
    size={size}
    calculationType={calculationType}
    statusCredits={hideStatusCredits ? undefined : statusCredits}
  />
}

const mapStateToProps = (state: App.State): MappedProps => ({
  luxLoyaltyEnabled: getIsLuxLoyaltyEnabled(state),
  loyaltyAccount: state.luxLoyalty.account.data,
  defaultTier: getLuxLoyaltyDefaultTier(state),
})

export default connect(mapStateToProps)(LuxLoyaltyPoints)
