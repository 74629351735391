import { createSelector } from 'reselect'
import { ShareModalConfig } from 'lib/customer/getShareModalConfig'
import config from 'constants/config'

export const getCruiseShareModalConfig = createSelector(
  (appState: App.State) => appState.system.customerPortalURL,
  (appState: App.State) => appState.auth.account.memberId,
  (appState: App.State) => appState.router.location.pathname,
  (appState: App.State) => appState.geo.currentRegionCode,
  (appState: App.State, currentCruiseOffer: App.CruiseOffer) => currentCruiseOffer,
  (
    customerPortalURL,
    memberId,
    pathname,
    region,
    cruiseOffer,
  ): ShareModalConfig | undefined => cruiseOffer && memberId ?
      {
        customerPortalURL,
        memberId,
        offerId: cruiseOffer.ship.id,
        offerLocation: cruiseOffer.name,
        pathname,
        region,
        type: 'offer',
        utmCampaign: 'webshare',
      } :
    undefined,
)

export const isStandaloneCruiseEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (regionCode) => config.CRUISES_STANDLONE_ENABLED && config.CRUISES_REGIONS?.split(',')?.includes(regionCode),
)
