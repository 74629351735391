import config from 'constants/config'
import { PAYMENT_OPTIONS } from 'constants/payment'
import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from '../view/accommodation'
import { getCarHireItems } from '../view/carHire'
import getPartnershipModifierAmount from './getPartnershipModifierAmount'
import getCheckoutTotal from './getCheckoutTotal'
import getCheckoutTotals from './getCheckoutTotals'
import getCreditPayableAmount from './getCreditPayableAmount'
import getCreditPayAmount from './getCreditPayAmount'
import getDiscountTotal from './getDiscountTotal'
import getMerchantFeeAmount from './getMerchantFeeAmount'
import getPayableTotal from './getPayableTotal'
import getServiceFeeAmount from './getServiceFeeAmount'
import { getCruiseItems } from '../cruiseSelectors'
import getMobileAppDiscountTotalsView from './getMobileAppDiscountTotalsView'

const getCheckoutCruiseBrochure = createSelector(
  (state: App.State) => getCruiseItems(state),
  (cruiseItems) => {
    if (cruiseItems.length) {
      return cruiseItems.map((cruiseItem) => {
        const totalPassengers = Object.values(cruiseItem.occupancy).reduce((a, b) => a + b, 0)
        const brochurePrice = cruiseItem.brochureInfo?.price || 0
        return floatify(brochurePrice * totalPassengers)
      }).reduce((total, currentPrice) => floatify(total + currentPrice), 0)
    }
    return 0
  },
)

const getCheckoutTotalsView = createSelector(
  (state: App.State) => getCheckoutTotals(state),
  (state: App.State) => getCheckoutTotal(state),
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => getDiscountTotal(state),
  (state: App.State) => getCreditPayableAmount(state),
  (state: App.State) => getCreditPayAmount(state),
  (state: App.State) => state.checkout.payment.partnerships.velocity,
  (state: App.State) => getPartnershipModifierAmount(state),
  (state: App.State) => getMerchantFeeAmount(state, state.checkout.payment.paymentSelected),
  (state: App.State) => state.checkout.payment.paymentSelected,
  (state: App.State) => getServiceFeeAmount(state),
  (state: App.State) => getCheckoutCruiseBrochure(state),
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => state.checkout.commission,
  (state: App.State) => getCarHireItems(state),
  (state: App.State) => getMobileAppDiscountTotalsView(state),
  (
    checkoutTotals,
    total,
    payableTotal,
    discount,
    amountPayableByCredit,
    creditPaymentAmount,
    velocityState,
    partnershipModifierAmount,
    merchantFee,
    paymentSelected,
    serviceFee,
    cruiseBrochurePrice,
    accommodationViews,
    commission,
    carHireItems,
    mobileAppDiscountTotals,
  ): App.WithDataStatus<App.Checkout.TotalsView> => {
    const {
      price,
      value,
      surcharge,
      taxesAndFees,
      otherFees,
      propertiesFees,
      businessTravellerCredits,
      carHirePaidOnPickUpFees,
      carHirePayOnArrival,
      carHireAddonFees,
      paidPrice,
      newPrice,
      memberPrice,
    } = checkoutTotals.data

    // need to adjust values to account for value possibly being undefined
    const adjPrice = price ?? 0
    const adjSurcharge = surcharge ?? 0
    const adjValue = value ?? 0
    const adjCarHirePaidOnPickUpFees = carHirePaidOnPickUpFees ?? 0
    const adjCarHireAddonsFees = carHireAddonFees ?? 0
    const adjCarHirePayOnArrival = carHirePayOnArrival ?? 0

    let subTotal = adjPrice + adjSurcharge - taxesAndFees + adjCarHireAddonsFees + adjCarHirePayOnArrival
    // Need to round to avoid long decimals
    subTotal = floatify(subTotal)
    const totalValue = adjValue + adjSurcharge + cruiseBrochurePrice

    const hideDiscountPercentage = accommodationViews.data.some(view => view.offer &&
      'isDiscountPillHidden' in view.offer && view.offer.isDiscountPillHidden,
    )

    // Value always includes `taxesAndFees`
    const savedTotal = (totalValue - (subTotal + taxesAndFees + adjCarHirePaidOnPickUpFees))
    const savedPercentage = hideDiscountPercentage ? 0 : Math.floor((savedTotal / totalValue) * 100)

    const payableServiceFee = [PAYMENT_OPTIONS.DEPOSIT, PAYMENT_OPTIONS.PAYMENT_SCHEDULE].includes(paymentSelected as PAYMENT_OPTIONS) ? serviceFee : 0

    let grandTotal = floatify(
      total -
      creditPaymentAmount -
      partnershipModifierAmount +
      adjCarHirePaidOnPickUpFees +
      merchantFee +
      payableServiceFee +
      adjCarHirePayOnArrival,
    )

    if (discount > grandTotal) {
      grandTotal = 0
    } else {
      grandTotal -= discount
    }

    // Need to round to avoid long decimals
    grandTotal = floatify(grandTotal)

    let payableNow = payableTotal - creditPaymentAmount - partnershipModifierAmount + merchantFee + payableServiceFee

    if (discount > payableNow) {
      payableNow = 0
    } else {
      payableNow -= discount
    }

    payableNow = floatify(payableNow)

    const businessTravellerCredit = config.businessTraveller.currentAccountMode === 'business' && businessTravellerCredits ?
        { totalCreditValue: businessTravellerCredits } :
      undefined

    const velocityPointsApplied = (velocityState?.burn?.isApplied) ? velocityState.burn.pointsBurned : null

    return {
      hasRequiredData: checkoutTotals.hasRequiredData,
      data: {
        savedPercentage: Number.isFinite(savedPercentage) ? savedPercentage : 0,
        savedTotal,
        amountPayableByCredit,
        creditPaymentAmount,
        otherFees,
        propertiesFees,
        taxesAndFees,
        subTotal,
        commissionTotal: commission?.discountTotal ?? 0,
        commissionGrossTotal: grandTotal + (commission?.discountTotal ?? 0) + (creditPaymentAmount),
        grandTotal,
        payableNow,
        value: totalValue,
        businessTravellerCredit,
        carHirePaidOnPickUpFees: adjCarHirePaidOnPickUpFees,
        carHireAddonFees: adjCarHireAddonsFees,
        carHirePayOnArrival: adjCarHirePayOnArrival,
        carHirePickupDate: carHireItems[0]?.pickUpDate,
        ...(velocityPointsApplied && { velocityPointsApplied }),
        paidPrice,
        newPrice,
        merchantFee,
        serviceFee: payableServiceFee,
        isMemberPrice: memberPrice > 0,
        mobileAppDiscount: mobileAppDiscountTotals,
      },
    }
  },
)

export default getCheckoutTotalsView
