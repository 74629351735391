import React, { useCallback, useContext, useRef, useMemo } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import {
  FlightSearchWidgetActions,
  FlightSearchWidgetFlightItem,
  createEmptyFlightSearchWidgetFlightItem,
} from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchAirportInputs from './FlightSearchAirportInputs'
import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import useFlightDeals from 'hooks/Flights/useFlightDeals'
import { mediaQueryUp } from 'components/utils/breakpoint'
import FlightSearchDateSelect from './FlightSearchDateSelect'
import IconButton from 'components/Luxkit/Button/IconButton'
import LinePlusIcon from 'components/Luxkit/Icons/line/LinePlusIcon'
import FlightSearchDateSelectSheet from './FlightSearchDateSelectSheet'
import { StandaloneFlightMenu } from './FlightSearchWidget'
import SolidMultiplyIcon from 'components/Luxkit/Icons/solid/SolidMultiplyIcon'
import FlightSearchMaxFlightsModal from './FlightSearchMaxFlightsModal'
import ModalContext from 'contexts/ModalContext'
import config from 'constants/config'

const InputsWrapper = styled.form`
  display: none;

  ${mediaQueryUp.tablet} {
    display: flex;
    flex-direction: column;
    gap: ${rem(8)};
  }
`

const InputRow = styled.div`
  display: grid;

  ${mediaQueryUp.tablet} {
    position: relative;
    gap: ${rem(8)};
    grid-template-areas:
      "location-input"
      "date-input";
    grid-template-columns: 1fr;

    &.with-remove-btn {
      grid-template-areas:
        "location-input location-input location-input"
        "date-input date-input actions";
      grid-template-columns: 1fr 1fr ${rem(40)};
    }
  }

  ${mediaQueryUp.desktop} {
    grid-template-areas: "location-input date-input";
    grid-template-columns: 1fr minmax(${rem(230)}, ${rem(304)});
    align-items: center;

    &.with-remove-btn {
      grid-template-areas: "location-input date-input actions";
      grid-template-columns: 1fr minmax(${rem(230)}, ${rem(304)}) ${rem(40)};
    }
  }

  > .location-input-area {
    grid-area: location-input;
  }

  > .date-input-area {
    grid-area: date-input;
  }

  > .actions-area {
    grid-area: actions;
  }
`

const ActionsContainer = styled(Group)`
  ${mediaQueryUp.desktop} {
    &.with-remove-btn {
      margin-right: ${rem(48)};
    }
  }
`

interface Props {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  /**
   * Sets the airports to a 'read only' mode
   * Used when defaulting airport selections such as on flight deal pages
   */
  readOnlyAirports?: boolean;
}

function FlightSearchWidgetDesktopMultiCityForm(props: Props) {
  const { onSubmit, readOnlyAirports } = props
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)
  const arrivalRefs = useRef<Array<HTMLInputElement | null>>([])
  const departingRefs = useRef<Array<HTMLInputElement | null>>([])
  const departingDateRefs = useRef<Array<HTMLButtonElement | null>>([])
  const formRef = useRef<HTMLFormElement>(null)
  const rowRefs = useRef<Array<HTMLDivElement | null>>([])
  const showModal = useContext(ModalContext)

  const { flights } = state

  const [flightDeals] = useFlightDeals()
  const flightDealsMemoized = useMemo(() => (flightDeals || []), [flightDeals])

  const handleAddFlight = useCallback(() => {
    if (flights.length === config.FLIGHTS_MULTI_CITY_MAX_FLIGHTS) {
      showModal<boolean>(<FlightSearchMaxFlightsModal />)
      return
    }

    const item: FlightSearchWidgetFlightItem = createEmptyFlightSearchWidgetFlightItem()

    dispatch({
      type: FlightSearchWidgetActions.ADD_FLIGHT,
      payload: item,
    })
  }, [dispatch, flights.length, showModal])

  const handleRemoveFlight = useCallback(
    (id: string) => {
      dispatch({
        type: FlightSearchWidgetActions.REMOVE_FLIGHT,
        payload: id,
      })
    },
    [dispatch],
  )

  const handleDepartureChange = useCallback((index: number) => {
    arrivalRefs.current[index]?.focus()
  }, [])

  const handleArrivalChange = useCallback((index: number) => {
    departingDateRefs.current[index]?.click()
  }, [])

  const handleDepartingDateChange = useCallback(
    (flightId: string) => {
      const rowIndex = flights.findIndex((flight) => flight.id === flightId)
      departingRefs.current[rowIndex + 1]?.focus()
    },
    [flights],
  )

  const handleDateClose = useCallback(() => {
    dispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU })
  }, [dispatch])

  const showRemoveButton = flights.length > 1

  return (
    <InputsWrapper onSubmit={onSubmit} ref={formRef}>
      {flights.map((flight, index) => (
        <InputRow
          key={flight.id}
          className={showRemoveButton ? 'with-remove-btn' : ''}
          ref={node => rowRefs.current[index] = node}
        >
          <FlightSearchAirportInputs
            id={flight.id}
            className="location-input-area"
            readOnly={readOnlyAirports}
            arrivalFieldRef={(node) => (arrivalRefs.current[index] = node)}
            departingFieldRef={(node) => (departingRefs.current[index] = node)}
            onDepartureChange={() => handleDepartureChange(index)}
            onArrivalChange={() => handleArrivalChange(index)}
            departingRecentAirports={flights[index - 1]?.arrivalAirport?.cityDesc ? [flights[index - 1]?.arrivalAirport!] : undefined}
          />

          <FlightSearchDateSelect
            id={flight.id}
            className="date-input-area"
            departingFieldRef={(node) => departingDateRefs.current[index] = node}
          />

          {showRemoveButton && (
            <IconButton
              className="actions-area"
              kind="tertiary"
              size="medium"
              data-testid="airportSearchButton"
              onClick={() => handleRemoveFlight(flight.id)}
            >
              <SolidMultiplyIcon />
            </IconButton>
          )}

          {state.selectedFlightId === flight.id && (
            <FlightSearchDateSelectSheet
              id={state.selectedFlightId}
              anchorRef={{ current: rowRefs.current[index] }}
              isOpen={state.activeMenu === StandaloneFlightMenu.date}
              onClose={handleDateClose}
              onDateChange={() => handleDepartingDateChange(state.selectedFlightId!)}
              flightDeals={flightDealsMemoized}
            />
          )}
        </InputRow>
      ))}

      <ActionsContainer
        direction="horizontal"
        verticalAlign="center"
        horizontalAlign="space-between"
        className={`multi-city-actions-row ${
          showRemoveButton ? 'with-remove-btn' : ''
        }`}
      >
        <TextButton
          kind="secondary"
          variant="dark"
          startIcon={<LinePlusIcon />}
          size="medium"
          onClick={handleAddFlight}
        >
          Add flight
        </TextButton>

        <TextButton
          kind="primary"
          size="medium"
          data-testid="airportSearchButton"
          type="submit"
        >
          Search
        </TextButton>
      </ActionsContainer>
    </InputsWrapper>
  )
}

export default FlightSearchWidgetDesktopMultiCityForm
