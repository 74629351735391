import React, { useContext } from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { depositAmountStringBuilder } from 'lib/tours/tourUtils'
import VerticalSpacer from '../Spacing/VerticalSpacer'
import { LE_CONTRACTED_HOTEL_TYPES, OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_TOUR } from 'constants/offer'
import { getMinDepositAmount, getAlwaysOnCancellationDays, isOfferDefaultDepositAmountPercentage } from 'lib/payment/depositsUtils'
import FormatCurrency from '../FormatCurrency'
import GeoContext from 'contexts/geoContext'
import { INELEGIBLE_SERVICE_FEE_OFFER_TYPES } from 'constants/payment'

import { getDefaultDepositAmountPercentage, getDepositServiceFee } from 'checkout/selectors/featureConfig/deposit'
import { useAppSelector } from 'hooks/reduxHooks'
import { TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD } from 'constants/tours'
import { isTourV2OfferType } from 'lib/offer/offerTypes'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'

// HOW IS THIS DIFFERENT TO getPaymentDueDate IN LIB/DEPOSITUTILS?
function getPaymentDueDaysBeforeCheckin(
  offerType: App.OfferType,
  checkIn?: string,
  offerPackage?: App.Package,
  pkgAvailableRate?: App.OfferAvailableRate,
  depositThresholds?: App.DepositThreshold,
) {
  if (depositThresholds?.numberOfDays) {
    return depositThresholds.numberOfDays
  }

  if (offerType == OFFER_TYPE_ALWAYS_ON) {
    const freeCancellationDays = checkIn ? getAlwaysOnCancellationDays(checkIn, offerPackage, pkgAvailableRate?.cancellationPolicies) : 0
    return (freeCancellationDays) ? freeCancellationDays + 15 : 15
  }

  return (offerType == OFFER_TYPE_TOUR || isTourV2OfferType(offerType)) ? TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD : 60
}

function getOfferEligibilityDaysInAdvance(
  offerType: App.OfferType,
  checkIn?: string,
  offerPackage?: App.Package,
  pkgAvailableRate?: App.OfferAvailableRate,
  depositThresholds?: App.DepositThreshold,
) {
  if (depositThresholds?.numberOfDays) {
    return depositThresholds.numberOfDays + 1
  }

  if (offerType == OFFER_TYPE_ALWAYS_ON) {
    const days = checkIn ? getAlwaysOnCancellationDays(checkIn, offerPackage, pkgAvailableRate?.cancellationPolicies) ?? 0 : 0
    return days + 16
  }

  return (offerType === OFFER_TYPE_TOUR || isTourV2OfferType(offerType)) ? TOUR_V2_OFFER_DUE_DATE_DAY_THRESHOLD + 1 : 61
}

function getOfferTypeString(offerType: App.OfferType) {
  switch (offerType) {
    case OFFER_TYPE_TOUR:
    case 'direct_tour':
    case 'partner_tour':
    case 'connection_tour':
      return 'Tour'
    case OFFER_TYPE_ALWAYS_ON:
      return 'Lux Premium Collection'
    default:
      return 'Limited Time LUX Exclusive'
  }
}

export interface Props {
  offerType: App.OfferType;
  offerPackage?: App.Package;
  pkgAvailableRate?: App.OfferAvailableRate;
  checkIn?: moment.Moment;
  depositAmountPercentage?: number
  depositThresholds?: App.DepositThreshold
  depositType?: App.Tours.V2OfferDepositType
  isFlexibleCancellation?: boolean;
  depositAmount?: number;
}

function DepositInfoModalContent({
  offerType,
  offerPackage,
  pkgAvailableRate,
  checkIn,
  depositAmountPercentage,
  depositThresholds,
  depositType,
  isFlexibleCancellation,
  depositAmount,
}: Props) {
  const { currentCurrency } = useContext(GeoContext)
  const offerEligibilityDaysInAdvance = getOfferEligibilityDaysInAdvance(offerType, checkIn?.format(ISO_DATE_FORMAT), offerPackage, pkgAvailableRate, depositThresholds)
  const paymentDueDaysBeforeCheckin = getPaymentDueDaysBeforeCheckin(offerType, checkIn?.format(ISO_DATE_FORMAT), offerPackage, pkgAvailableRate, depositThresholds)
  const isHotel = LE_CONTRACTED_HOTEL_TYPES.includes(offerType)
  const minAmount = getMinDepositAmount(currentCurrency)

  const formattedMinimumAmount = <FormatCurrency value={ minAmount } format="roundedDollar" currency={ currentCurrency } />

  const offerTypeString = getOfferTypeString(offerType)

  const defaultDepositAmountPercentage = useAppSelector(getDefaultDepositAmountPercentage)
  const depositServiceFee = useAppSelector(getDepositServiceFee)
  // Enabled for a offer type as long as it is not a connection_tour v2 offer
  const shouldDisplayServiceFeeMessage: boolean = !INELEGIBLE_SERVICE_FEE_OFFER_TYPES.includes(offerType)

  const isOfferUsingDefaultDepositPercentage = isOfferDefaultDepositAmountPercentage(depositThresholds, depositAmount)

  const depositAmountString = isOfferUsingDefaultDepositPercentage ?
    'deposit' : !depositType ? `${depositAmountPercentage}% deposit` : `${depositAmountStringBuilder(defaultDepositAmountPercentage, depositType, depositAmountPercentage).short} deposit`

  return (
    <VerticalSpacer gap={16}>
      <BodyText variant="large" as="p">
        {`Just pay a ${depositAmountString} for this offer now, and the rest later `}
        { paymentDueDaysBeforeCheckin } days prior to your check-in date.
      </BodyText>
      <div>
        <Heading variant="heading6">Eligibility</Heading>
        <BodyText variant="large" as="p">
          {offerTypeString} bookings {isHotel && '(excluding flights, experiences, travel protection) '} with
          {minAmount > 0 && <> a value over {formattedMinimumAmount} and</>} travel dates at least{' '}
          { offerEligibilityDaysInAdvance } days in advance.
        </BodyText>
      </div>
      <div>
        <Heading variant="heading6">Cancellation policy</Heading>
        <BodyText variant="large" as="p">
          Deposit is non-refundable after free cancellation period. Failure to complete payment will result in the cancellation of your booking,
          {isFlexibleCancellation && ' your deposit less the service fee owed will be defaulted to short-dated credits, expiring 90 days after your booking has been cancelled.'}
          {!isFlexibleCancellation && ' and forfeit of the deposit.'}
        </BodyText>
      </div>
      {isHotel && <BodyText variant="large" as="p">
        Deposit refunds apply only to accommodation. Flights and experiences are subject{' '}
        to the refund policies of the respective operator
      </BodyText>}
      {shouldDisplayServiceFeeMessage && <div>
        <BodyText variant="large" as="p">
          The service fee paid as part of a deposit booking is non-refundable even if cancellation occurs within 7 days of purchase
        </BodyText>
      </div>}
      {shouldDisplayServiceFeeMessage && <div>
        <Heading variant="heading6">Service Fee</Heading>
        <BodyText variant="large" as="p">
          A service fee of {depositServiceFee}% of the balance of the purchase price payable will be charged at the time of purchase (at the same time the initial {depositAmountString} is paid).
        </BodyText>
      </div>}
    </VerticalSpacer>
  )
}

export default DepositInfoModalContent
