import {
  ADD_ORDERS,
  ADD_REFUND_REQUEST_TO_ORDER,
  API_CALL,
  TOUR_TRAVELLER_DETAILS_RESET_SUBMISSION,
  TOUR_TRAVELLER_DETAILS_UPDATE_SUBMITTED,
  UPDATE_ORDER,
} from './actionConstants'

import {
  CANCEL_ORDER_GIFTING,
  CREATE_DATES_REQUEST,
  FETCH_ORDER,
  FETCH_ORDERS_DATES_REQUESTS,
  FETCH_ORDERS_FOR_CURRENT_USER,
  FETCH_PAYMENT_METHOD_STRIPE,
  FETCH_TOUR_ORDER_TRAVELLER_DETAILS,
  SUBMIT_ORDER_TRAVELLERS,
  SAVE_DRAFT_ORDER_TRAVELLERS,
  MARK_EXPERIENCE_BOOKING_DETAILS_AS_REDEEMED,
  FETCH_ORDER_ITEM_FLIGHT_DETAILS,
  UPDATE_ORDER_PARTNERSHIP,
  FETCH_MERCHANT_FEE_DETAILS,
  FETCH_FLIGHT_ORDER_PNRS,
  FETCH_ORDER_BY_SUBSCRIPTION,
  FETCH_ROOM_REFUND_INFO,
  UPDATE_DATES_REQUEST,
} from './apiActionConstants'
import {
  cancelOrderGiftingRequest,
  createDatesRequestParams,
  getBrandOrders,
  getOrders,
  getOrderDatesRequest,
  getOrderWithPaymentsAndReservations,
  getPaymentMethodFromStripe,
  getReservationDetailsForItems,
  markExperienceItemAsRedeemed,
  postDatesRequest,
  getOrderItemFlightDetails,
  updateOrderPartnership,
  getOrderById,
  getOrderBySubscriptionId,
  getPayments,
  getPossibleRoomRefundTotal,
  updateDatesRequest,
} from 'api/order'
import {
  postOrderTravellerDetails,
  PostOrderTravellerDetailsParams,
  PostOrderTravellerDetailsPayload,
  reqGetTourOrderTravellerDetails,
} from 'api/traveller'
import { arrayToObject } from 'lib/array/arrayUtils'
import { getDepositDetails, getInstalmentDetails, getMerchantFeeDetails } from 'api/payment'
import { AppAction } from './ActionTypes'
import { TOUR_ORDER_TRAVELLER_DETAILS_TTL } from 'constants/tours'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { OrderItemType } from 'constants/order'
import { areOrdersBeingFetched } from 'lib/order/orderUtils'
import pollOrderService from 'lib/polling/pollOrderService'
import getObjectKey from 'lib/object/getObjectKey'

export function fetchRoomRefundInfo(orderId: string, itemId: string, roomId?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const key = getObjectKey({ orderId, itemId, roomId })
    if (state.orders.itemRefundInfo[key]) {
      // already fetched it/processing it
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_ROOM_REFUND_INFO,
      request: () => getPossibleRoomRefundTotal(orderId, itemId, roomId),
      key,
    })
  }
}

export function fetchOrderBySubscriptionId(subscriptionId: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const orderId = state.orders.ordersBySubscriptionId[subscriptionId]

    if (state.orders.orders[orderId] || state.orders.ordersFetching[subscriptionId]) {
      // already have the order or are fetching it, don't try again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_ORDER_BY_SUBSCRIPTION,
      request: () => getOrderBySubscriptionId(subscriptionId),
      subscriptionId,
    })
  }
}
interface FetchOrderOptions {
  force?: boolean
}

export function fetchOrder(orderId: string, options?: FetchOrderOptions): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (
      // don't refetch if:
      // we're already fetching it
      state.orders.ordersFetching[orderId] ||
      // we already have the full version
      (state.orders.orders[orderId]?.type === 'full' && !options?.force) ||
      // it errored
      !!state.orders.orderErrors[orderId]
    ) {
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_ORDER,
      request: () => getOrderWithPaymentsAndReservations(orderId),
      orderId,
    })
  }
}

export function updateOrder(updatedOrder: App.Order) {
  return {
    type: UPDATE_ORDER,
    order: updatedOrder,
    orderId: updatedOrder.id,
  }
}

interface TourOrderTravellerDetailsFetcherOptions {
  forceFetch?: boolean
}
export function fetchTourOrderTravellerDetails(tourOrderId: string, options?: TourOrderTravellerDetailsFetcherOptions): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const existingOrderTravellerDetails = state.orders.tourOrdersTravellerDetails[tourOrderId]
    const shouldFetch = !!options?.forceFetch || !existingOrderTravellerDetails
    const hasFetchExpired = existingOrderTravellerDetails?.status === 'fetched' &&
      existingOrderTravellerDetails.fetchedAtTimeStamp + TOUR_ORDER_TRAVELLER_DETAILS_TTL < new Date().getTime()

    if (shouldFetch || hasFetchExpired) {
      dispatch({
        type: API_CALL,
        api: FETCH_TOUR_ORDER_TRAVELLER_DETAILS,
        request: () => reqGetTourOrderTravellerDetails(tourOrderId),
        tourOrderId,
      })
    }
  }
}

export function updateTourTravellerDetailsSubmitted(travellerDetails: App.TourOrderTravellerDetails): AppAction {
  return {
    type: TOUR_TRAVELLER_DETAILS_UPDATE_SUBMITTED,
    travellerDetails,
  }
}

export function resetTourTravellerDetailsSubmission(): AppAction {
  return (dispatch) => {
    dispatch({
      type: TOUR_TRAVELLER_DETAILS_RESET_SUBMISSION,
    })
  }
}

export function submitTourTravellersDetails(
  payload: PostOrderTravellerDetailsPayload,
  params?: PostOrderTravellerDetailsParams,
): AppAction {
  return {
    type: API_CALL,
    api: params?.isDraft ? SAVE_DRAFT_ORDER_TRAVELLERS : SUBMIT_ORDER_TRAVELLERS,
    request: () => postOrderTravellerDetails(payload, params),
  }
}

interface FetchOrderParams {
  status?: 'upcoming';
  sort?: 'asc' | 'desc';
  itemType?: OrderItemType;
  limit?: number;
}

const StreamOrderPageSize = 20
export async function * fetchAndStreamOrders(
  memberId: string,
  currentRegionCode: string,
  params: FetchOrderParams = {},
) {
  let end = false
  let page = 1
  while (!end) {
    const results = await getOrders(memberId, currentRegionCode, {
      ...params,
      sort: params.sort ?? 'desc',
      page,
      pageSize: StreamOrderPageSize,
    })
    yield results.orders

    end = page * StreamOrderPageSize >= results.total
    page++
  }

  const brandOrders = await getBrandOrders(memberId)
  if (brandOrders.length > 0) {
    yield brandOrders
  }
}

export function fetchOrders(params: FetchOrderParams = {}): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const isAlreadyFetching = areOrdersBeingFetched(state, params.status)
    if (isAlreadyFetching) {
      // already happening, don't start it again
      return
    }

    dispatch({
      api: FETCH_ORDERS_FOR_CURRENT_USER,
      type: API_CALL,
      request: async() => {
        for await (const orders of fetchAndStreamOrders(state.auth.account.memberId!, state.geo.currentRegionCode, params)) {
          // pre-emptive dispatch of order data without their additioanl meta data
          // this improves preceived load times
          const missingOrders = orders.filter(order => !state.orders.orders[order.id] && !state.orders.ordersFetching[order.id])
          if (missingOrders.length) {
            dispatch({ type: ADD_ORDERS, orders: missingOrders })

            // pre-emptive orders dispatch, start fetching and filling in order meta-data
            const clonedOrders = missingOrders.map(order => ({ ...order }))
            const orderPromises = clonedOrders.flatMap(order => {
              return [
                getReservationDetailsForItems(
                  order.items.filter(item => item.reservationMade),
                  order.id,
                ).then(async reservations => {
                  const reservationsById = arrayToObject(reservations, res => res.itemId)
                  order.items = order.items.map(item => {
                    if (reservationsById[item.id]) {
                      return {
                        ...item,
                        reservation: reservationsById[item.id],
                      }
                    }
                    return item
                  })
                }),
                getDepositDetails(order.id).then(depositDetails => { order.depositDetails = depositDetails }),
                getInstalmentDetails(order.id).then(instalmentDetails => { order.instalmentDetails = instalmentDetails }),
                getPayments(order.id).then(payments => { order.payments = payments.filter(p => p.type !== 'manual') }),
              ]
            })
            // do this all non-blocking so we can continue streaming in orders
            // do not fail on a failure - it just won't update that portion
            Promise.allSettled(orderPromises).then(() => {
              dispatch({ type: ADD_ORDERS, orders: clonedOrders })
            })
          }
        }
      },
      key: params.status || 'all',
    })
  }
}

/**
 * Some upcoming orders are upsellable based on the item types that it contains.
 * This action will fetch up to 5 upcoming orders for EACH upsellable item types.
 * Right now, only LE hotel item is upsellable, so it will return up to 5 orders.
 */
export function fetchUpsellableOrders(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (!state.auth.account.memberId) {
      return
    }

    dispatch({
      api: FETCH_ORDERS_FOR_CURRENT_USER,
      type: API_CALL,
      request: () => getOrders(state.auth.account.memberId!, state.geo.currentRegionCode, {
        page: 1,
        pageSize: 5,
        itemType: OrderItemType.ACCOMMODATION,
        sort: 'asc',
      }).then(result => result.orders),
      key: 'upsellable',
    })
  }
}

export function createDatesRequest(body: createDatesRequestParams, onResolve: () => void): AppAction {
  return {
    type: API_CALL,
    api: CREATE_DATES_REQUEST,
    request: () => postDatesRequest(body)
      .then(() => {
        showSnackbar(
          'You will receive an email shortly confirming your request',
          'success',
          { heading: 'Your request has been successfully sent' },
        )
        onResolve()
      })
      .catch(e => {
        showSnackbar(
          e.message,
          'critical',
          { heading: 'Your date request has not been sent.' },
        )
        onResolve()
      }),
    body,
  }
}

export function fetchOrderDatesRequests(orderId: string): AppAction {
  return {
    type: API_CALL,
    api: FETCH_ORDERS_DATES_REQUESTS,
    request: () => getOrderDatesRequest(orderId),
    orderId,
  }
}

export function updateOrderDatesRequest(datesRequest: App.DatesRequest, status: string): AppAction {
  return {
    type: API_CALL,
    api: UPDATE_DATES_REQUEST,
    request: () => updateDatesRequest(datesRequest.id, status),
    datesRequest,
  }
}

export function cancelOrderGifting(orderId: string): AppAction {
  return (dispatch) => {
    dispatch({
      type: API_CALL,
      api: CANCEL_ORDER_GIFTING,
      request: () => cancelOrderGiftingRequest(orderId)
        .then(() => dispatch(fetchOrder(orderId))),
    })
  }
}

export function fetchOrderPaymentMethod(paymentMethodId: string, currency: string): AppAction {
  return {
    type: API_CALL,
    api: FETCH_PAYMENT_METHOD_STRIPE,
    request: () => getPaymentMethodFromStripe(paymentMethodId, currency),
  }
}

export function markExperienceOrderItemAsRedeemed(experienceItemId: string): AppAction {
  return {
    type: API_CALL,
    api: MARK_EXPERIENCE_BOOKING_DETAILS_AS_REDEEMED,
    request: () => {
      return new Promise<void>(resolve => {
        // easiest way to implement an 'undo' is to not do the action
        // until 'undo' is no longer available
        // so only actually mark the item as redeemed once the notification disappears
        const timeout = window.setTimeout(() => {
          markExperienceItemAsRedeemed(experienceItemId)
          resolve()
        }, 8000)

        showSnackbar('Your voucher has been marked as redeemed', 'success', {
          heading: 'Successfully redeemed',
          action: {
            label: 'Undo',
            onAction: () => {
              // prevent the timeout from firing
              window.clearTimeout(timeout)
              resolve()
            },
          },
        })
      })
    },
    experienceItemId,
  }
}

export function fetchOrderItemFlightDetails(orderId: string, itemId: string, skipCache: boolean = false): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.orders.flightDetails[itemId] && !skipCache) {
      // already fetched/are fetching, don't try again
      return
    }

    dispatch({
      type: API_CALL,
      api: FETCH_ORDER_ITEM_FLIGHT_DETAILS,
      request: () => getOrderItemFlightDetails({ orderId, itemId }),
      key: itemId,
    })
  }
}

interface UpdateOrderPartnershipDetails {
  orderId: string;
  type: string;
  accountId: string;
  lastName: string;
  firstName: string;
}
export function updateOrderPartnershipPostCheckout(updateOrderPartnershipDetails: UpdateOrderPartnershipDetails): AppAction {
  return {
    type: API_CALL,
    api: UPDATE_ORDER_PARTNERSHIP,
    request: () => updateOrderPartnership(updateOrderPartnershipDetails),
  }
}

// We need to fetch merchant fee details because refunds for some verticals (experiences, transfers) need to add merchant fee per item to total paid/refunded amount
export function fetchMerchantFeeDetails(orderId: string): AppAction {
  return {
    type: API_CALL,
    api: FETCH_MERCHANT_FEE_DETAILS,
    orderId,
    request: () => getMerchantFeeDetails(orderId),
  }
}

export function pollingOrderFlightsPnrId(order: App.Order): AppAction {
  return {
    type: API_CALL,
    api: FETCH_FLIGHT_ORDER_PNRS,
    request: () => pollOrderService({
      validateFunction: (order: App.Order) => {
        return order.flightItems.every((flightItem) => flightItem.pnrId) ? order : undefined
      },
      failedStatus: 'failed',
      apiCall: () => getOrderById(
        order.id,
      ),
      maxTime: 60000,
      waitTime: 2000,
    }),
  }
}

export function addRefundRequestToOrder(refundRequest: App.RefundRequest): AppAction {
  return {
    type: ADD_REFUND_REQUEST_TO_ORDER,
    orderId: refundRequest.orderId,
    orderItemId: refundRequest.itemId,
    refundRequest,
  }
}
