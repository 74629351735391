import { fetchBestPriceForOffer } from 'actions/OfferActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { isLEHotel, isVillaOffer } from 'lib/offer/offerTypes'
import { buildSearchParamsKey, buildSuggestedDatesParamsKey } from 'lib/search/searchUtils'
import { useEffect, useMemo } from 'react'
import { getSuggestedDates } from 'selectors/offerSelectors'
import { diffInDays } from 'lib/datetime/dateUtils'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'

interface Options {
  /**
   * For when the offer price is no longer needed, but still needs to be called to avoid violating the Rules of Hooks
   */
  disabled?: boolean
}

export function useOfferBestPrice(offer: App.AnyOffer, filters: App.OfferListFilters = {}, options: Options = {}) {
  const { disabled } = options
  const bestPrices = useAppSelector((state) => state.offer.offerBestPrices[offer.id])
  const dispatch = useAppDispatch()
  const searchKey = useMemo(() => buildSearchParamsKey(filters.checkIn, filters.checkOut, filters.rooms), [filters])
  const isHotelType = isLEHotel(offer) || isVillaOffer(offer)

  const flexibleSearchFilterKey = buildSuggestedDatesParamsKey(filters.flexibleMonths, filters.flexibleNights, filters.rooms)
  const suggestedDates = useAppSelector((state) => getSuggestedDates(state, flexibleSearchFilterKey, offer.id))

  const bestPrice = bestPrices ? bestPrices[searchKey] : undefined
  const available = bestPrice?.available
  const rate = available ? bestPrice.rate : undefined
  const occupanciesInvalid = (filters?.rooms ?? []).length === 0 || (filters?.rooms ?? []).some(item => item.childrenAge?.some(age => age < 0))

  useEffect(() => {
    if (disabled) {
      return
    }

    if (!!filters.checkIn && isHotelType && !!filters.checkOut && !occupanciesInvalid) {
      dispatch(fetchBestPriceForOffer(offer, {
        checkIn: filters.checkIn,
        checkOut: filters.checkOut,
        occupants: filters.rooms,
      }))
    }
  }, [dispatch, filters, isHotelType, offer, suggestedDates, occupanciesInvalid, disabled])

  const bestPricePackage = useMemo(() => {
    if (disabled || !isHotelType) {
      return undefined
    }

    if (available && rate) {
      const pkg = offer.packages.find(pkg => pkg.uniqueKey === rate?.packageUniqueKey)
      if (!pkg) {
        return undefined
      }
      return {
        ...pkg,
        price: rate.hotelPrice,
        value: rate.hotelValue,
        surcharge: rate.surcharge,
        availableRooms: rate.availableRooms,
        propertyFees: rate.propertyFees,
      }
    }
    if (suggestedDates?.roomRateId) {
      const uniqueKey = getPackageUniqueKey(suggestedDates.packageId, diffInDays(new Date(suggestedDates.checkIn), new Date(suggestedDates.checkOut)), suggestedDates.roomRateId)
      return offer.packages.find(pkg => pkg.uniqueKey === uniqueKey)
    }
    return offer.lowestPricePackage
  }, [isHotelType, suggestedDates, available, offer, rate, disabled])
  return bestPricePackage
}
