import { MessageSenders } from 'api/supportAssistant'

export const isSenderSystem = (sender: App.ChatMessageSender) => sender === 'system'

export const isSenderUser = (sender: App.ChatMessageSender) => sender === 'user'

export const isSenderLE = (sender: App.ChatMessageSender) => sender === 'agent' || sender === 'chatbot'

export interface BuildChatMessageParams {
  chatMessageMeta?: App.ChatMessageMeta;
  currentChatId: string | undefined;
  account?: App.AuthAccount,
  customerId?: string;
  hasUpcomingBooking: boolean;
  canViewLuxPlusBenefits: boolean;
  luxPlusContact: {
    number: string | undefined;
    numberHumanReadable: string | undefined;
  }
  message: string;
  isCurrentOrder?: boolean;
  orderBookingNumbers?: Array<App.OrderBookingNumber>,
}

// TODO: WRITE UNIT TESTS
export function buildChatMessagePayload(params: BuildChatMessageParams): Partial<App.ChatMessage> {
  const {
    chatMessageMeta,
    currentChatId,
    account,
    customerId,
    hasUpcomingBooking,
    canViewLuxPlusBenefits,
    luxPlusContact,
    message,
    isCurrentOrder,
    orderBookingNumbers,
  } = params

  const trimmedMessage = message.trim()

  const messageMeta = chatMessageMeta ? {
    action: chatMessageMeta.action,
    payload: {
      ...chatMessageMeta.payload,
      chatId: currentChatId,
      customerId,
      email: chatMessageMeta.payload?.email ?? account?.email,
      phone: chatMessageMeta.payload?.phone ?? account?.phone,
      firstName: chatMessageMeta.payload?.firstName ?? account?.givenName,
      lastName: chatMessageMeta.payload?.lastName ?? account?.surname,
      hasUpcomingBooking,
      isLuxPlusMember: canViewLuxPlusBenefits,
      luxPlusContact,
      isCurrentOrder: isCurrentOrder || chatMessageMeta.payload?.isCurrentOrder,
      orderBookingNumbers,
    },
  } : {
    payload: { customerId },
  }

  return {
    text: trimmedMessage,
    chatId: currentChatId,
    customer: {
      countryCode: account?.phonePrefix,
      email: account?.email,
      phone: account?.phone,
      firstName: account?.givenName,
      lastName: account?.surname,
    },
    meta: messageMeta,
    sender: MessageSenders.USER,
  }
}
