import React, { ComponentProps, useContext, useMemo } from 'react'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import OfferUrgencyCountdownLabel from './OfferUrgencyCountdownLabel'
import LuxPlusLabel from 'luxPlus/components/LuxPlusLabel'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'
import { take } from 'lib/array/arrayUtils'
import LabelCountContext from 'contexts/LabelCountContext'

interface Props extends ComponentProps<typeof LabelGroup> {
  offer?: App.Offer | App.OfferSummary;
  urgencyLabels?: Array<App.OfferUrgencyLabel>;
  countDownShowHourOnly?: boolean,
  saveAmount?: number;
  showMemberInclusionLabels?: boolean;
  luxPlusInclusions?: Array<App.PackageInclusion>;
}

const OfferLabels = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    offer,
    urgencyLabels,
    countDownShowHourOnly = false,
    saveAmount,
    showMemberInclusionLabels,
    luxPlusInclusions,
    ...rest
  } = props

  const maxLabels = useContext(LabelCountContext)

  const labels = useMemo(() => {
    const offerLabels = urgencyLabels ?? offer?.urgencyTags ?? []
    // we unshift to add to the start...create a copy so we're not mutating the original
    const baseLabels = [...offerLabels]

    if (offer?.luxPlus?.access === 'earlyAccess') {
      baseLabels.unshift({ type: 'lux_plus_early_access' })
    } else if (offer?.luxPlus?.access === 'memberOnly') {
      baseLabels.unshift({ type: 'lux_plus_member_only' })
    } else if (saveAmount) {
      baseLabels.unshift({ type: 'lux_plus_member_price' })
    }

    if (offer?.luxPlus?.hasMemberInclusions && showMemberInclusionLabels) {
      baseLabels.unshift({ type: 'lux_plus_member_inclusions' })
    }

    if (offer?.isAgentHubExclusive) {
      baseLabels.unshift({ type: 'agenthub_exclusive' })
    }

    return maxLabels ? take(baseLabels, maxLabels) : baseLabels
  }, [urgencyLabels, offer, saveAmount, showMemberInclusionLabels, maxLabels])

  if (!labels.length) return null

  return <LabelGroup {...rest} ref={ref}>
    {labels.map(label => <React.Fragment key={label.type}>
      {/*
          The general release tag is only used for LuxPlus+ early access offers
          however the logic is slightly different to lux_plus_early_access so we need to handle it separately
      */}
      {label.type === 'lux_plus_general_release' && label.end && <OfferUrgencyCountdownLabel
        endDate={label.end}
        showHourOnly={countDownShowHourOnly}
        isLuxPlusEarlyAccess
      />}
      {label.type === 'lux_plus_early_access' && offer && <LuxPlusLabelWithModal
        type="early-access-offer"
        saveAmount={saveAmount}
        offer={offer}
      />}
      {label.type === 'lux_plus_member_only' && <LuxPlusLabel type="member-only-offer" />}
      {label.type === 'lux_plus_member_price' && offer && saveAmount && <LuxPlusLabelWithModal
        type="member-price-offer"
        saveAmount={saveAmount}
        offer={offer}
        tileInclusions={offer.luxPlusTileInclusions}
      />}
      {label.type === 'lux_plus_member_inclusions' && offer && <LuxPlusLabelWithModal
        type="bonus-inclusions"
        offer={offer}
        inclusions={luxPlusInclusions}
      />}
      {label.type === 'agenthub_exclusive' && <PromotionLabel variant="premium">Agent Exclusive</PromotionLabel>}
      {label.type === 'left' && label.end && <OfferUrgencyCountdownLabel
        endDate={label.end}
        showHourOnly={countDownShowHourOnly}
      />}
    </React.Fragment>)}
  </LabelGroup>
})

OfferLabels.displayName = 'OfferLabels'

export default OfferLabels
