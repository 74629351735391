import { groupBy, last, max, min, sum } from 'lib/array/arrayUtils'
import {
  isAccommodationItem,
  isBNBLLEHotelItem,
  isBedbankItem,
  isCruiseItem,
  isInstantBookingHotelItem,
  isInstantBookingLEHotelItem,
  isLEHotelItem,
  isTourV1Item,
  isTourV2Item,
  isCruiseV1,
  isInstantLEHotelPostPurchaseItem,
  isBNBLHotelSetDatesItem,
  findPostPurchaseCheckout,
} from 'lib/checkout/checkoutUtils'
import { createSelector } from 'reselect'
import { excludeNullOrUndefined } from 'checkout/utils'
import { getFlightTotals } from 'checkout/selectors/payment/flights'
import { isMemberOrHasSubscriptionInTheCart } from 'checkout/selectors/view/luxPlusSubscription'
import {
  LE_CONTRACTED_HOTEL_TYPES,
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_HOTEL,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_TOUR,
  OFFER_TYPE_TOUR_V2,
} from 'constants/offer'
import { getCruiseBreakdownView } from 'checkout/lib/utils/cruises/view'
import { getCheckoutCruiseOfferView } from './cruise'
import { getTourItems, getTourV2BreakdownView, getTourV2ExperienceItems, getTourV2Items } from './toursv2'
import { getHotelBreakdownView } from './hotels'

import { getFlightItems } from './flights'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import config from 'constants/config'
import {
  CHECKOUT_ITEM_TYPE_CRUISE,
  CHECKOUT_ITEM_TYPE_TOUR_V1,
  CHECKOUT_ITEM_TYPE_TOUR_V2,
} from 'constants/checkout'
import { CheckoutPageId } from 'checkout/constants/pages'
import { countTravellersAsOccupants } from 'lib/offer/occupancyUtils'
import { getExperienceItems, getTransferItemsView } from './experience'
import { getVillaItems } from './villa'
import { getCarHireItems } from './carHire'
import { diffInDays } from 'lib/datetime/dateUtils'
import { getDefaultAirport } from 'selectors/flightsSelectors'
import { isLEOffer } from 'lib/offer/offerTypes'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import getBedbankItemViews from './getBedbankItemViews'
import getTourV2ItemViews from './getTourV2ItemViews'
import getHotelItemViews from './getHotelItemViews'
import getTourV1ItemViews from './getTourV1ItemViews'

const isPostPurchaseAncillaryPayment = createSelector(
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (postPurchase) => postPurchase === 'deposit-balance' ||
  postPurchase === 'instalment-balance' ||
  postPurchase === 'instalment' ||
  postPurchase === 'reserve-balance' ||
  postPurchase === 'tour-optional-experiences',
)

const hidePrices = createSelector(
  (state: App.State) => isPostPurchaseAncillaryPayment(state),
  (state: App.State) => state.checkout.cart.mode === 'tour-change-dates',
  (state: App.State) => state.router.location.pathname,
  (isAncillaryPayment, isPostPurchaseTourDateChange, pathname) => isAncillaryPayment || isPostPurchaseTourDateChange || last(pathname.split('/')) === CheckoutPageId.LuxPlus,
)

export function checkoutAccommodationGroupingKey(item: App.Checkout.AccommodationItem): string {
  if (isBNBLLEHotelItem(item)) {
    return `${item.offerId}-bnbl-${item.duration}`
  }

  else if (isTourV2Item(item)) {
    return `${item.offerId}-${item.purchasableOption.fkDepartureId}`
  }

  else if (isCruiseItem(item)) {
    return `${item.offerId}-${item.departureId}`
  }

  else if (item.itemType === 'tourV1') {
    return `${item.offerId}-${item.startDate}-${item.endDate}`
  }

  else {
    return `${item.offerId}-${item.checkIn}-${item.checkOut}`
  }
}

const getCartAccommodationItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.AccommodationItem> => items.filter(isAccommodationItem),
)

const getOrderAccommodationItems = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.checkout.cart.mode,
  (existingOrder, cartMode): Array<App.Checkout.AccommodationItem> => {
    if (existingOrder) {
      return [
        ...existingOrder.items.flatMap<App.Checkout.AccommodationItem>((item) => {
          if ('roomRateId' in item.package && item.reservationType === 'instant_booking' && item.reservation) {
            return [{
              itemId: item.id,
              transactionKey: item.transactionKey,
              offerId: item.offer.id,
              duration: item.duration || 0,
              itemType: 'hotel',
              packageId: item.package.id,
              roomRateId: item.package.roomRateId,
              reservationType: item.reservationType,
              checkIn: item.reservation.startDate,
              checkOut: item.reservation.endDate,
              occupancy: {
                adults: item.reservation.adults,
                children: item.reservation.children,
                infants: item.reservation.infants,
                childrenAge: item.reservation.childrenAge,
              },
            }]
          } else {
            return []
          }
        }),
        ...existingOrder.bedbankItems.flatMap<App.Checkout.BedbankHotelItem>((item) => {
          return item.rooms.map(room => ({
            itemId: item.id,
            sessionId: '',
            roomId: item.roomTypeId,
            checkIn: moment(item.checkIn).format(ISO_DATE_FORMAT),
            checkOut: moment(item.checkIn).format(ISO_DATE_FORMAT),
            roomRateId: item.roomRateId,
            isFlightBundle: room.isFlightBundle,
            bedGroupId: '',
            transactionKey: item.transactionKey,
            offerId: item.offer.id,
            duration: item.duration,
            newPrice: item.total,
            itemType: 'bedbankHotel',
            occupancy: room.occupancy,
          }))
        }),
        ...existingOrder.tourItems.flatMap<App.Checkout.TourV2Item>(item => {
          const isPostPurchaseTourOptionalExperience = cartMode === 'tour-optional-experiences'
          const travellersByRooms = Array.from(groupBy(
            item.tour.travellers,
            (traveller) => traveller.roomNumber,
          ).values())
          return travellersByRooms.map((travellers: Array<App.Tours.TourV2OrderItemTravellerDetails>, index: number) => {
            // as tour item is the total of all travellers, we only want to show the total price on the first item
            const total = index === 0 ? item.total : 0
            return {
              startDate: item.tour.startDate,
              endDate: item.tour.endDate,
              occupancy: countTravellersAsOccupants(travellers),
              duration: item.tour.duration,
              image: item.tour.images?.[0] ?? {},
              itemId: item.itemId,
              itemType: CHECKOUT_ITEM_TYPE_TOUR_V2,
              offerId: item.tourId,
              transactionKey: item.transactionKey,
              purchasableOption: {
                fkDepartureId: item.departureId,
                fkSeasonId: item.tour.seasonId,
                fkTourId: item.tourId,
                roomType: travellers[0].roomType as App.Tours.V2OfferRoomType,
                fkVariationId: item.tourOptionId ?? '',
                inventoryLeft: 0, // not stored on the order item
                fullPrice: isPostPurchaseTourOptionalExperience ? 0 : total,
                price: isPostPurchaseTourOptionalExperience ? 0 : total,
                memberPrice: isPostPurchaseTourOptionalExperience ? 0 : item.memberPrice,
                packageType: item.packageOption?.packageOption,
              },
            } as App.Checkout.TourV2Item
          })
        }),
      ]
    }

    return []
  },
)
export const getAccommodationItems = createSelector(
  (state: App.State) => getCartAccommodationItems(state),
  (state: App.State) => getOrderAccommodationItems(state),
  (state: App.State) => isPostPurchaseAncillaryPayment(state),
  (accommodationItemsFromCart, accommodationItemsFromOrder, isAncillaryPayment): Array<App.Checkout.AccommodationItem> => {
    return isAncillaryPayment ? accommodationItemsFromOrder : accommodationItemsFromCart
  },
)

export const getTourV2CheckoutItems = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (items): Array<App.Checkout.TourV2Item> => items.filter(isTourV2Item),
)

export const getInsuranceEnabledCheckoutItems = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (state: App.State) => state.offer.offers,
  (items, leOffers): Array<App.Checkout.InstantBookingItem> => {
    return items
      .filter(isInstantBookingHotelItem)
      .filter(item => isEnabledForFeature(config.UNIVERSAL_CHECKOUT_INSURANCE_ENABLED_LIST, item.itemType))
      .filter(item => {
        if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1 || item.itemType === CHECKOUT_ITEM_TYPE_CRUISE) {
          return isCruiseV1(item, leOffers)
        }
        return true
      })
  },
)

export const getLeAccommodationItems = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (items): Array<App.Checkout.LEHotelItem> => items.filter(isLEHotelItem),
)

export const getLeInstantPostPurchaseItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InstantBookingLEHotelPostPurchaseItem> => items.filter(isInstantLEHotelPostPurchaseItem),
)

export const getBNBLHotelPostPurchaseItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.BNBLLEHotelSetDatesItem> => items.filter(isBNBLHotelSetDatesItem),
)

export const getLeInstanceBookingItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (items): Array<App.Checkout.InstantBookingLEHotelItem> => items.filter(isInstantBookingLEHotelItem),
)

export const checkoutAccommodationOfferView = createSelector(
  (state: App.State) => getCheckoutCruiseOfferView(state),
  (state: App.State) => getBedbankItemViews(state),
  (state: App.State) => getTourV2ItemViews(state),
  (state: App.State) => getHotelItemViews(state),
  (state: App.State) => getTourV1ItemViews(state),
  (
    cruiseView,
    bedbankItemViews,
    tourV2ItemViews,
    hotelItemViews,
    tourV1ItemViews,
  ): App.WithDataStatus<Array<App.Checkout.AccommodationOfferView>> => {
    return {
      hasRequiredData: tourV1ItemViews.hasRequiredData &&
        cruiseView.hasRequiredData &&
        bedbankItemViews.hasRequiredData &&
        tourV2ItemViews.hasRequiredData &&
        hotelItemViews.hasRequiredData,
      data: [
        ...hotelItemViews.data,
        ...tourV1ItemViews.data,
        ...bedbankItemViews.data,
        ...cruiseView.data,
        ...tourV2ItemViews.data,
      ].filter(excludeNullOrUndefined),
    }
  },
)

export const getAccommodationBreakdownView = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getFlightTotals(state),
  (state: App.State) => hidePrices(state),
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => isMemberOrHasSubscriptionInTheCart(state),
  (state: App.State) => state.checkout.commissionData,
  (offerViewsWithStatus, flightsTotals, hidePrice, postPurchase, isMemberOrHasSubscriptionInTheCart, commissionData): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (!offerViewsWithStatus.hasRequiredData) { return { hasRequiredData: false, data: [] } }

    const breakdownViews = offerViewsWithStatus.data.map<App.Checkout.PriceBreakdownView | null>(offerView => {
      switch (offerView.offerType) {
        case OFFER_TYPE_CRUISE:
          return getCruiseBreakdownView(offerView, hidePrice)
        case OFFER_TYPE_TOUR_V2:
          return getTourV2BreakdownView(offerView, hidePrice, postPurchase, commissionData)
        case OFFER_TYPE_ALWAYS_ON:
        case OFFER_TYPE_LAST_MINUTE:
        case OFFER_TYPE_TOUR: // Not enabled yet
        case OFFER_TYPE_BED_BANK:
        case OFFER_TYPE_HOTEL:
          return getHotelBreakdownView(offerView, flightsTotals, hidePrice, isMemberOrHasSubscriptionInTheCart, commissionData)
        default:
          return null
      }
    }).filter(excludeNullOrUndefined)

    return {
      hasRequiredData: true,
      data: breakdownViews,
    }
  },
)

export const getHotelTotalPrice = createSelector(
  (state: App.State) => getAccommodationBreakdownView(state),
  (state: App.State) => getFlightTotals(state),
  (views, flightsTotal) => {
    return sum(views.data.filter(view => view.productType && LE_CONTRACTED_HOTEL_TYPES.includes(view.productType)), view => view.price) - flightsTotal.data.price
  })

export const getAccommodationOccupantsFromCart = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (items): App.Occupants | undefined => {
    if (items.length) {
      return items.reduce((acc, item) => {
        if ('occupancy' in item && item.occupancy) {
          return {
            adults: acc.adults + item.occupancy.adults,
            children: acc.children + (item.occupancy.children || 0),
            infants: acc.infants + (item.occupancy.infants || 0),
          }
        }
        return acc
      }, { adults: 0, children: 0, infants: 0 })
    }
  },
)

export const getAccommodationStartDate = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (items): string | undefined => {
    const startDates = items
      .map(item => {
        if (isInstantBookingLEHotelItem(item) || isBedbankItem(item)) {
          return item.checkIn
        }
        if (isTourV1Item(item) || isTourV2Item(item)) {
          return item.startDate
        }
      }).filter(excludeNullOrUndefined)
    return min(startDates, (date) => new Date(date))
  },
)

export const getAccommodationEndDate = createSelector(
  (state: App.State) => getAccommodationItems(state),
  (items): string | undefined => {
    const startDates = items
      .map(item => {
        if (isInstantBookingLEHotelItem(item) || isBedbankItem(item)) {
          return item.checkOut
        }
        if (isTourV1Item(item) || isTourV2Item(item)) {
          return item.endDate
        }
      }).filter(excludeNullOrUndefined)

    return max(startDates, (date) => new Date(date))
  },
)

export const isCheckoutAccommodationItemsOnly = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getExperienceItems(state),
  (state: App.State) => getFlightItems(state),
  (state: App.State) => getTransferItemsView(state),
  (state: App.State) => getVillaItems(state),
  (state: App.State) => getCarHireItems(state),
  (state: App.State) => getTourV2Items(state),
  (state: App.State) => getTourItems(state),
  (state: App.State) => getTourV2ExperienceItems(state),
  (accommodationViews, experienceItems, flightItems, transferItems, villaItems, carHireItems, tourV2Items, tourItems, tourV2ExperienceItems): boolean => {
    return !!accommodationViews.data.length &&
      !experienceItems.length &&
      !flightItems.length &&
      !transferItems.data.length &&
      !villaItems.length &&
      !carHireItems.length &&
      !tourV2Items.length &&
      !tourItems.length &&
      !tourV2ExperienceItems.length &&
      !flightItems.length
  },
)

export const canShowAddFlightsForHotelItems = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getDefaultAirport(state),
  (accommodationViews, defaultUserAirport): boolean => {
    if (accommodationViews.hasRequiredData && !accommodationViews.data.length) return false

    return accommodationViews.data.every(view => view.offerType === OFFER_TYPE_HOTEL) &&
      accommodationViews.data.every(view => view.itemViews.every(itemView => isInstantBookingLEHotelItem(itemView.item))) &&
      accommodationViews.data.every(view => view.startDate && diffInDays(new Date(), new Date(view.startDate)) <= 330) &&
      accommodationViews.data.every(view => isLEOffer(view.offer) && view.offer.offerFlightsEnabled && view.offer.flightDestinationPort !== defaultUserAirport?.code)
  },
)
