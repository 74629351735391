import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import { replace } from 'connected-react-router'
import { fetchAvailableRatesForOffer } from 'actions/OfferActions'
import SearchDesktopInputs from './SearchDesktopInputs'
import { SearchMenuStates } from '../type'
import {
  clearLastDates,
  loadRecentRooms,
  saveLastDates,
  saveLastFlexibleSelection,
  saveRecentRooms,
  searchPaneInteractEventName,
} from 'components/Search/utils'
import {
  flexibleSearchFormSubmit,
  searchDestinationFormSubmit,
  searchFormDisplay,
  searchFormSubmit,
} from 'analytics/eventDefinitions'
import { addGTMEvent } from 'api/googleTagManager'
import responsiveHelper from 'components/Common/Breakpoint/responsiveHelper'

import HideLiveChat from 'components/Common/HideLiveChat'
import { KEYCODE_ENTER } from 'constants/keyCode'
import {
  addFlexibleMonthsIfNoneSelected,
  convertDatesToString,
  decodeSearchParams,
  encodeSearchParams,
  generateSearchString,
  getSearchKey,
  isRoomsInvalid, mapSearchContext,
} from 'lib/search/searchUtils'
import { pushWithRegion, replaceWithRegion } from 'actions/NavigationActions'

import noop from 'lib/function/noop'
import {
  queryKeyAdults,
  queryKeyBounds,
  queryKeyCheckIn,
  queryKeyCheckOut,
  queryKeyChildren,
  queryKeyDestinationId,
  queryKeyDestinationName,
  queryKeyPropertyId,
  queryKeyPropertyName,
} from 'constants/url'
import { fetchPlaceByName } from 'actions/PlaceActions'

import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import AnalyticsPageContext from 'contexts/Analytics/analyticsPageContext'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import {
  ANYWHERE_SEARCH_ITEM,
  DATE_SEARCH_OPTION_IDS,
  DEFAULT_FLEXIBLE_DURATION_RANGE,
  FLEXIBLE_DURATION_RANGE,
} from 'constants/search'
import { OFFER_TYPE_HOTEL } from 'constants/offer'
import offerPageURL from 'lib/offer/offerPageURL'
import { deleteSearchParams, getQueryParam, parseSearchString } from 'lib/url/searchUrlUtils'
import GlobalSearchTrackingContext from 'contexts/GlobalSearch/GlobalSearchTracking'
import * as Analytics from 'analytics/analytics'
import config from 'constants/config'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import useOffer from 'hooks/Offers/useOffer'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'
import { setSearchId } from 'actions/SearchActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import uuidV4 from 'lib/string/uuidV4Utils'
import useRecentSearches from 'hooks/Search/useRecentSearches'

const Container = styled.form`
  display: block;
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
`

interface Props {
  className?: string;
  filters?: App.OfferListFilters
  initialLocation?: string
  openLocationInputOnLoad?: boolean
  replaceUrl?: boolean
  saleUnit?: string
  showDateFieldOnMobile?: boolean
  submitOnApply?: boolean
  typeaheadTypes?: Array<App.SearchPlaceType>
  switchView?: {
    label: string,
    view: 'map' | 'list'
    URL: string
  }
  // onSubmit handles any extra stuff the parent should do upon form submission
  onSubmit?: (location: App.SearchItem, rooms: Array<App.Occupants>, checkIn?: string, checkOut?: string, flexibleNights?: FLEXIBLE_DURATION_RANGE, flexibleMonths?: string) => void
  baseSearchPath?: string
  baseSearchMapPath?: string
  locationSearchPlaceholder?: string
  initialLocationPlaceId?: string
  pathname: string
  region: string
  search: string
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
}

function getDateString() {
  const today = new Date()
  return '' + today.getFullYear() + today.getMonth() + today.getDate()
}

export type SearchFormRef = {
  focusLocationInput: () => void,
  openDatePickerMenu: () => void,
  form: React.MutableRefObject<HTMLFormElement>,
}

const searchQueryParams = [
  queryKeyAdults,
  queryKeyChildren,
  queryKeyDestinationId,
  queryKeyDestinationName,
  queryKeyPropertyId,
  queryKeyPropertyName,
  queryKeyBounds,
  queryKeyCheckIn,
  queryKeyCheckOut,
]

const SearchForm = React.forwardRef<SearchFormRef, Props & MappedProps>(({
  className,
  filters,
  initialLocation,
  initialLocationPlaceId,
  onSubmit = noop,
  openLocationInputOnLoad,
  pathname,
  region,
  replaceUrl,
  saleUnit,
  search,
  showDateFieldOnMobile = true,
  submitOnApply,
  typeaheadTypes,
  switchView,
  travellerEmployees,
  baseSearchPath = '/search',
  baseSearchMapPath = '/search/map',
  locationSearchPlaceholder,
}, ref) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchParams = useMemo(() => decodeSearchParams(search), [search, getDateString()])
  const {
    checkinDate,
    checkoutDate,
    flexibleNights,
    flexibleMonths,
    searchItem,
    areRoomErrorsShown,
    occupancies,
    recentOccupancies,
    activeMenu,
    isAnytimeDateSelected,
    dateSearchOptionId,
    userSelectedFlexibleMonths,
    urlOfferId,
    disableRecentSearches,
    searchVerticals,
    eventAnalytics,
    suggestedSearchItems,
  } = useContext(GlobalSearchStateContext)
  const dispatch = useAppDispatch()

  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)
  const recentSearches = useRecentSearches()
  const [hasLocationChanged, setHasLocationChanged] = useState(false)

  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant' as any,
    })
  }, [])

  const isBusinessTravellerSelectEnabled = config.businessTraveller.currentAccountMode === 'business'

  const setSearchItem = useCallback((searchItem: App.SearchItem) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_SEARCH_ITEM, searchItem })
  }, [globalSearchDispatch])

  const setUrlOfferId = useCallback((offerId: string) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_URL_OFFER_ID, urlOfferId: offerId })
  }, [globalSearchDispatch])

  const setUrlOfferType = useCallback((offerType: App.OfferType) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_URL_OFFER_TYPE, urlOfferType: offerType })
  }, [globalSearchDispatch])

  const setActiveMenu = useCallback((menu: SearchMenuStates) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu })
  }, [globalSearchDispatch])

  const toggleErrors = useCallback((shown?: boolean) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.TOGGLE_ERRORS, shown })
  }, [globalSearchDispatch])

  const toggleRoomErrors = useCallback((shown?: boolean) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.TOGGLE_ROOM_ERRORS, shown })
  }, [globalSearchDispatch])

  const setOccupancies = useCallback((occupancies: Array<App.Occupants>) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_OCCUPANCIES, occupancies })
  }, [globalSearchDispatch])

  const setRecentOccupancies = useCallback((occupancies: Array<Array<App.Occupants>>) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_RECENT_OCCUPANCIES, occupancies })
  }, [globalSearchDispatch])

  const setFlexibleDuration = useCallback((flexibleNights: FLEXIBLE_DURATION_RANGE) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION, flexibleNights })
  }, [globalSearchDispatch])

  const toggleAnytimeDateSelected = useCallback((selected?: boolean) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.TOGGLE_ANYTIME_DATES_SELECTED, selected })
  }, [globalSearchDispatch])

  const setDateSearchOption = useCallback((optionId: DATE_SEARCH_OPTION_IDS) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_DATE_SEARCH_OPTION, optionId })
  }, [globalSearchDispatch])

  const unsetDates = useCallback(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKIN_DATE })
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_CHECKOUT_DATE })
  }, [globalSearchDispatch])

  const unsetFlexibleDates = useCallback(() => {
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_DURATION })
    globalSearchDispatch({ type: GlobalSearchStateActions.UNSET_FLEXIBLE_MONTH_RANGE })
  }, [globalSearchDispatch])

  useEffect(() => {
    if (initialLocation) {
      if (!initialLocationPlaceId) {
        dispatch(fetchPlaceByName(initialLocation))
      } else {
        setSearchItem({
          searchType: 'destination',
          value: initialLocationPlaceId,
          format: {
            mainText: initialLocation,
          },
        })
      }
    }
  }, [initialLocation, initialLocationPlaceId, setSearchItem, dispatch])

  useEffect(() => {
    const offerId = getQueryParam(search, 'offerId')
    const offerType = getQueryParam(search, 'offerType')
    if (offerId) {
      setUrlOfferId(offerId)
    }
    if (offerType) {
      setUrlOfferType(offerType as App.OfferType)
    }
  }, [search, setUrlOfferId, setUrlOfferType])

  useEffect(() => {
    setRecentOccupancies(loadRecentRooms())
    addGTMEvent(searchFormDisplay())
  }, [setRecentOccupancies])

  // sync filter options from search params to global state

  useEffect(() => {
    if (searchParams.dates?.checkIn) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKIN_DATE, date: moment(searchParams.dates.checkIn) })
    }
    if (searchParams.dates?.checkOut) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_CHECKOUT_DATE, date: moment(searchParams.dates.checkOut) })
    }
    if (searchParams.rooms?.length) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_OCCUPANCIES, occupancies: searchParams.rooms })
    }
    if (searchParams.flexibleSearch?.flexibleMonths) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_MONTH_RANGE, flexibleMonths: searchParams.flexibleSearch.flexibleMonths })
    }
    if (searchParams.flexibleSearch?.userSelectedFlexibleMonths) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_USER_SELECTED_FLEXIBLE_MONTHS, userSelectedFlexibleMonths: true })
    }
    if (searchParams.flexibleSearch?.flexibleNights) {
      globalSearchDispatch({ type: GlobalSearchStateActions.SET_FLEXIBLE_DURATION, flexibleNights: searchParams.flexibleSearch.flexibleNights as FLEXIBLE_DURATION_RANGE })
    }
  }, [searchParams, globalSearchDispatch])

  const closeMenu = useCallback(() => {
    setActiveMenu(SearchMenuStates.Closed)

    // Determine if rooms error needed to be displayed
    if (occupancies && isRoomsInvalid(occupancies)) {
      toggleRoomErrors(true)
    }
  }, [setActiveMenu, occupancies, toggleRoomErrors])

  const onToggleMenu = useCallback((nextFilter?: SearchMenuStates) => {
    if (!nextFilter || activeMenu === nextFilter) {
      closeMenu()
    } else {
      toggleErrors(false)
      setActiveMenu(nextFilter)
    }

    // Determine if rooms error needed to be displayed
    if (occupancies && isRoomsInvalid(occupancies)) {
      toggleRoomErrors(true)
    }
  }, [activeMenu, occupancies, closeMenu, toggleErrors, setActiveMenu, toggleRoomErrors])

  const openDatePickerMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onToggleMenu(SearchMenuStates.Dates)
  }, [onToggleMenu])

  const openTravellerMenu = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onToggleMenu(SearchMenuStates.Rooms)
  }, [onToggleMenu])

  const openLocationMenu = useCallback((e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation()
    onToggleMenu(SearchMenuStates.Locations)
  }, [onToggleMenu])

  useEffect(() => {
    if (activeMenu !== SearchMenuStates.Closed) {
      // let other components know our picker is open
      document.dispatchEvent(new CustomEvent(searchPaneInteractEventName))
    }
  }, [activeMenu])

  const locationInputRef = useRef<HTMLInputElement>(null)

  const formRef = useRef<HTMLFormElement>(null)

  function saveToLocalStorage<A>(prevRecentData: Array<A>, data: A, setStateFn: (items: Array<A>) => void, saveLocalStorageFn: (items: Array<A>) => void) {
    // Filter out item if it's already been saved
    const recentData = prevRecentData.filter(nextItem => JSON.stringify(nextItem) !== JSON.stringify(data))

    if (recentData.length < 3) {
      recentData.unshift(data)
    } else {
      recentData.pop()
      recentData.unshift(data)
    }

    setStateFn(recentData)
    saveLocalStorageFn(recentData)
  }

  const shouldNavigateToMapResults = pathname.includes('/map')

  const resultsRoute = shouldNavigateToMapResults ? baseSearchMapPath : baseSearchPath
  const enableDateField = responsiveHelper.matchesMin('tablet') || showDateFieldOnMobile

  const navigateToOfferPage = useCallback((
    offer: App.AnyOffer,
    searchParams: URLSearchParams,
  ) => {
    const url = offerPageURL(offer, searchParams)
    dispatch(replace({ search: deleteSearchParams(search, 'offerId') }))
    if (url) {
      dispatch(pushWithRegion(url))
    }
  }, [dispatch, search])

  const navigateToResults = useCallback((
    searchItem: App.SearchItem,
    rooms: Array<App.Occupants>,
    checkIn?: string,
    checkOut?: string,
    flexibleNights?: FLEXIBLE_DURATION_RANGE,
    flexibleMonths?: string,
    userSelectedFlexibleMonths?: boolean,
  ) => {
    const dates = (checkIn && checkOut) ? { checkIn, checkOut } : undefined
    const flexibleMonthsToSearch = addFlexibleMonthsIfNoneSelected(flexibleNights, flexibleMonths)

    const filtersToApply = hasLocationChanged && searchItem.searchType !== 'bounds' ? undefined : filters
    setHasLocationChanged(false)
    const searchStr = encodeSearchParams({ urlSearch: search, searchItem, dates, rooms, filters: filtersToApply, flexibleNights, flexibleMonths: flexibleMonthsToSearch, userSelectedFlexibleMonths }).toString()
    if (replaceUrl) {
      dispatch(replaceWithRegion(resultsRoute, searchStr))
    } else {
      dispatch(pushWithRegion(resultsRoute, searchStr))
    }
  }, [hasLocationChanged, filters, search, replaceUrl, dispatch, resultsRoute])

  const occupanciesInvalid = occupancies?.some(item => item.childrenAge?.some(age => age < 0))

  const offerId = useMemo(() => {
    if (urlOfferId) {
      return urlOfferId
    }

    if (searchItem?.searchType === 'property' && searchItem?.offerId) {
      return searchItem.offerId
    }

    return undefined
  }, [urlOfferId, searchItem])

  const [offer] = useOffer<App.Offer>(offerId)

  useEffect(() => {
    if ((searchItem?.searchType === 'property' && offerId)) {
      if (offer && checkinDate && checkoutDate && !occupanciesInvalid) {
        dispatch(fetchAvailableRatesForOffer(offer, {
          checkIn: checkinDate.format(ISO_DATE_FORMAT),
          checkOut: checkoutDate.format(ISO_DATE_FORMAT),
          occupants: occupancies,
        }))
      }
    }
  }, [checkinDate, checkoutDate, occupanciesInvalid, occupancies, offer, offerId, searchItem?.searchType, dispatch])

  const onSearchEvent = useContext(GlobalSearchTrackingContext)
  const analyticsPage = useContext(AnalyticsPageContext)

  const searchKey = useMemo(() => {
    const dates = convertDatesToString(ISO_DATE_FORMAT, checkinDate, !checkoutDate && checkinDate ? checkinDate.clone().add(1, 'day') : checkoutDate)
    const searchStr = generateSearchString({
      windowSearch: search,
      searchItem,
      checkIn: enableDateField ? dates.checkIn : undefined,
      checkOut: enableDateField ? dates.checkOut : undefined,
      rooms: occupancies,
      filters,
      hasLocationChanged,
      flexibleNights,
      flexibleMonths,
      userSelectedFlexibleMonths,
    })
    return getSearchKey(parseSearchString(searchStr))
  }, [checkinDate, checkoutDate, search, searchItem, enableDateField, occupancies, filters, hasLocationChanged, flexibleNights, flexibleMonths, userSelectedFlexibleMonths])

  const searchId = useAppSelector(state => state.search.searches[searchKey]?.searchId)

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    // user might have the date picker displayed when click Search button, close it if needed
    if (activeMenu !== SearchMenuStates.Closed && activeMenu !== SearchMenuStates.Main) {
      closeMenu()
    }

    // Validate
    if (!occupancies || occupancies.length === 0 || isRoomsInvalid(occupancies)) {
      toggleErrors(true)
      return
    }
    toggleErrors(false)

    const dates = convertDatesToString(ISO_DATE_FORMAT, checkinDate, !checkoutDate && checkinDate ? checkinDate.clone().add(1, 'day') : checkoutDate)

    const checkIn = enableDateField ? dates.checkIn : null
    const checkOut = enableDateField ? dates.checkOut : null

    // assuming the customer feels good about the first suggested item
    // do the search with the first suggested item if no search item selected
    const selectedItem = searchItem ?? suggestedSearchItems[0] ?? ANYWHERE_SEARCH_ITEM

    // set search item to update search box
    if (!searchItem) {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_SEARCH_ITEM,
        searchItem: selectedItem,
      })
    }

    if (onSubmit) {
      onSubmit(selectedItem, occupancies, checkIn ?? undefined, checkOut ?? undefined, flexibleNights, flexibleMonths)
    }
    if (urlOfferId && offer && searchItem) {
      navigateToOfferPage(offer, encodeSearchParams({ urlSearch: search, searchItem, dates, rooms: occupancies, filters, isFlexibleWithDate: isAnytimeDateSelected, flexibleNights, flexibleMonths, userSelectedFlexibleMonths }))
    } else {
      navigateToResults(selectedItem, occupancies, checkIn ?? undefined, checkOut ?? undefined, flexibleNights, flexibleMonths, userSelectedFlexibleMonths)
    }

    Analytics.trackClientEvent({
      subject: 'search_button',
      action: 'clicked',
      category: analyticsPage,
      type: 'interaction',
    })

    addGTMEvent(searchFormSubmit())
    if (searchItem?.searchType === 'destination') {
      addGTMEvent(searchDestinationFormSubmit())
    }
    if (flexibleNights) {
      addGTMEvent(flexibleSearchFormSubmit())
    }
    scrollToTop()
  }, [activeMenu, occupancies, toggleErrors, checkinDate, checkoutDate, enableDateField, searchItem, suggestedSearchItems, onSubmit, urlOfferId, offer, analyticsPage, flexibleNights, scrollToTop, closeMenu, isAnytimeDateSelected, globalSearchDispatch, flexibleMonths, navigateToOfferPage, search, filters, userSelectedFlexibleMonths, navigateToResults])

  const apply = useCallback((checkin?: moment.Moment, checkout?: moment.Moment, withFlexibleDate?: boolean, flexibleNights?: FLEXIBLE_DURATION_RANGE, flexibleMonths?: string, userSelectedFlexibleMonths?: boolean) => {
    if (!submitOnApply) {
      return
    }

    if (!occupancies || occupancies.length === 0 || isRoomsInvalid(occupancies)) {
      return
    }

    const dates = convertDatesToString(ISO_DATE_FORMAT, checkin, !checkoutDate && checkinDate ? checkinDate.clone().add(1, 'day') : checkoutDate)

    const checkIn = enableDateField ? dates.checkIn : null
    const checkOut = enableDateField ? dates.checkOut : null

    if (onSubmit) {
      onSubmit(searchItem ?? ANYWHERE_SEARCH_ITEM, occupancies, checkIn ?? undefined, checkOut ?? undefined, flexibleNights, flexibleMonths)
    }
    if (urlOfferId && offer && searchItem) {
      navigateToOfferPage(offer, encodeSearchParams({ urlSearch: search, searchItem, dates, rooms: occupancies, filters, isFlexibleWithDate: isAnytimeDateSelected, flexibleNights, flexibleMonths, userSelectedFlexibleMonths }))
    } else {
      navigateToResults(searchItem ?? ANYWHERE_SEARCH_ITEM, occupancies, checkIn ?? undefined, checkOut ?? undefined, flexibleNights, flexibleMonths, userSelectedFlexibleMonths)
    }

    if ([...searchVerticals][0] === 'hotels' && eventAnalytics.contextLocation === 'search-list' || eventAnalytics.contextLocation === 'common-search') {
      const newSearchId = uuidV4()
      const searchContext = mapGlobalSearchContextToSnowplowSearchEvent({
        searchItem,
        occupancies,
        isAnytimeDateSelected,
        searchVerticals,
        flexibleNights,
        checkinDate,
        checkoutDate,
        eventAnalytics: { searchId: searchId ?? newSearchId },
      })

      if (!searchId) {
        dispatch(setSearchId(searchKey, { newSearchId, searchContext: mapSearchContext(searchContext) }))
      }
    }

    addGTMEvent(searchFormSubmit())
    if (searchItem?.searchType === 'destination') {
      addGTMEvent(searchDestinationFormSubmit())
    }
    if (flexibleNights) {
      addGTMEvent(flexibleSearchFormSubmit())
    }

    if (onSearchEvent) {
      const params = {
        searchItem,
        filters,
        checkIn,
        checkOut,
        vertical: OFFER_TYPE_HOTEL,
        isFlexibleDates: isAnytimeDateSelected,
        occupancies,
      }
      onSearchEvent('search-apply-button', params)
    }

    scrollToTop()
  }, [submitOnApply, occupancies, checkoutDate, checkinDate, enableDateField, onSubmit, urlOfferId, offer, searchItem, searchVerticals, onSearchEvent, scrollToTop, navigateToOfferPage, search, filters, isAnytimeDateSelected, navigateToResults, searchId, dispatch, searchKey, eventAnalytics])

  const applyAndClose = useCallback(() => {
    apply(checkinDate, checkoutDate, isAnytimeDateSelected, flexibleNights, flexibleMonths, userSelectedFlexibleMonths)
    closeMenu()
  }, [closeMenu, apply, checkinDate, checkoutDate, isAnytimeDateSelected, flexibleNights, flexibleMonths, userSelectedFlexibleMonths])

  const onRoomsApply = useCallback(() => {
    if (recentOccupancies) {
      saveToLocalStorage(recentOccupancies, occupancies, setRecentOccupancies, saveRecentRooms)
    }
    if (formRef.current?.checkValidity()) {
      applyAndClose()
    }
  }, [recentOccupancies, occupancies, setRecentOccupancies, applyAndClose])

  const onMenuRoomsApply = useCallback(() => {
    if (recentOccupancies) {
      saveToLocalStorage(recentOccupancies, occupancies, setRecentOccupancies, saveRecentRooms)
    }
    applyAndClose()
  }, [recentOccupancies, occupancies, setRecentOccupancies, applyAndClose])

  const onDatesApply = useCallback((shouldPerformSearch?: boolean) => {
    // If enabled, enforce selecting business traveller before submitting search.
    if (isBusinessTravellerSelectEnabled && travellerEmployees.length === 0) {
      onToggleMenu(SearchMenuStates.Rooms)
      return
    }

    const flexibleParams = {
      durationSelection: flexibleNights,
      monthsSelection: flexibleMonths,
    }

    if (flexibleNights) {
      saveLastFlexibleSelection(flexibleParams)
      if (shouldPerformSearch) {
        apply(undefined, undefined, isAnytimeDateSelected, flexibleNights, flexibleMonths, userSelectedFlexibleMonths)
      }
    } else if (checkinDate && checkoutDate) {
      const currentDate = {
        checkinDate,
        checkoutDate,
      }
      saveLastDates(currentDate)
      if (shouldPerformSearch) {
        apply(checkinDate, checkoutDate, isAnytimeDateSelected, flexibleNights, flexibleMonths, userSelectedFlexibleMonths)
      }
    } else if (isAnytimeDateSelected) {
      // on mobile view, click search button when I'm flexible selected, will perform a search
      if (shouldPerformSearch) {
        apply(undefined, undefined, true)
      }
    }
    closeMenu()
    scrollToTop()
  }, [isBusinessTravellerSelectEnabled, travellerEmployees, flexibleNights, flexibleMonths, checkinDate, checkoutDate, isAnytimeDateSelected, closeMenu, onToggleMenu, apply, userSelectedFlexibleMonths, scrollToTop])

  const onDatesDecline = useCallback(() => {
    clearLastDates()
    unsetDates()
    toggleAnytimeDateSelected(false)
    setDateSearchOption(DATE_SEARCH_OPTION_IDS.SPECIFIC)
    closeMenu()
    scrollToTop()
  }, [closeMenu, toggleAnytimeDateSelected, unsetDates, setDateSearchOption, scrollToTop])

  const onLocationChange = useCallback((searchItem?: App.SearchItem) => {
    if (searchItem && enableDateField) {
      onToggleMenu(SearchMenuStates.Dates)
    }
    if (searchItem) {
      setHasLocationChanged(true)
    }
  }, [enableDateField, onToggleMenu])

  const focusLocationInput = useCallback(() => {
    locationInputRef.current?.focus()
    setActiveMenu(SearchMenuStates.Locations)
  }, [setActiveMenu])

  useEffect(() => {
    if (openLocationInputOnLoad) {
      setTimeout(() => focusLocationInput(), 300)
    }

    // Only want this to run on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useImperativeHandle(ref, () => ({
    focusLocationInput,
    openDatePickerMenu: () => onToggleMenu(SearchMenuStates.Dates),
    form: formRef,
  }), [focusLocationInput, onToggleMenu])

  const applyWithEnter = useCallback((e: KeyboardEvent) => {
    if (activeMenu === SearchMenuStates.Closed) {
      return
    }

    if (e.key === 'Enter' || e.keyCode === KEYCODE_ENTER) {
      if (activeMenu === SearchMenuStates.Dates && ((checkinDate && checkoutDate) || (flexibleMonths && flexibleNights))) {
        onDatesApply(true)
      }

      if (activeMenu === SearchMenuStates.Rooms && occupancies?.length) {
        onMenuRoomsApply()
      }
    }
  }, [activeMenu, checkinDate, checkoutDate, occupancies, onDatesApply, onMenuRoomsApply, flexibleMonths, flexibleNights])

  useEffect(() => {
    if (activeMenu !== SearchMenuStates.Closed) {
      document.addEventListener('keydown', applyWithEnter)
    }

    return () => {
      document.removeEventListener('keydown', applyWithEnter)
    }
  }, [activeMenu, applyWithEnter])

  const handleRoomChange = useCallback((nextRooms: Array<App.Occupants>) => {
    setOccupancies(nextRooms)

    if (!isRoomsInvalid(nextRooms)) {
      toggleRoomErrors(false)
    }
  }, [setOccupancies, toggleRoomErrors])

  /**
   * Handler when user select I'm flexible
   * On mobile view, will apply search
   * On desktop view, will not apply search
   * Fire UA event when I'm flexible clicked
   */
  const selectAnytimeDate = useCallback((shouldApply: boolean) => {
    unsetDates()
    unsetFlexibleDates()
    toggleAnytimeDateSelected(true)
    setDateSearchOption(DATE_SEARCH_OPTION_IDS.ANYTIME)
    closeMenu()

    if (shouldApply) {
      apply(undefined, undefined, true)
    }
  }, [unsetDates, unsetFlexibleDates, toggleAnytimeDateSelected, closeMenu, apply, setDateSearchOption])

  /**
   * Reset the anytimeDateSelected when user select i've got dates or when user select dates in calendar
   */
  const selectSpecificDates = useCallback(() => {
    unsetFlexibleDates()
    toggleAnytimeDateSelected(false)
    setDateSearchOption(DATE_SEARCH_OPTION_IDS.SPECIFIC)
  }, [toggleAnytimeDateSelected, setDateSearchOption, unsetFlexibleDates])

  const selectFlexibleDates = useCallback(() => {
    unsetDates()
    toggleAnytimeDateSelected(false)
    setDateSearchOption(DATE_SEARCH_OPTION_IDS.FLEXIBLE)
    if (flexibleNights === DEFAULT_FLEXIBLE_DURATION_RANGE.EMPTY) {
      setFlexibleDuration(DEFAULT_FLEXIBLE_DURATION_RANGE.ANY_DURATION)
    }
  }, [setDateSearchOption, toggleAnytimeDateSelected, unsetDates, setFlexibleDuration, flexibleNights])

  const initialSearchParams: URLSearchParams = encodeSearchParams({ urlSearch: search, searchItem, dates: convertDatesToString(ISO_DATE_FORMAT, checkinDate, checkoutDate), rooms: occupancies })

  return <Container
    method="GET"
    action={searchItem ? `/${region.toLowerCase()}${resultsRoute}/` : undefined}
    className={className}
    onSubmit={handleSubmit}
    ref={formRef}
  >
    <SearchWrapper>
      <InputContainer>
        {searchItem && searchQueryParams.map((key) => <React.Fragment key={key}>
          {initialSearchParams.has(key) && <input
            type="hidden"
            name={key}
            value={initialSearchParams.get(key)!}
          />}
        </React.Fragment>,
        )}
        <CSSBreakpoint min="tablet">
          <SearchDesktopInputs
            searchItem={searchItem}
            rooms={occupancies}
            switchView={switchView}
            openDatePickerMenu={openDatePickerMenu}
            openTravellerMenu={openTravellerMenu}
            openLocationMenu={openLocationMenu}
            closeMenu={closeMenu}
            activeMenu={activeMenu}
            onRoomChange={handleRoomChange}
            onRoomsApply={onRoomsApply}
            onDatesApply={onDatesApply}
            onDatesDecline={onDatesDecline}
            ref={locationInputRef}
            showRoomErrors={areRoomErrorsShown}
            anytimeDateSelected={isAnytimeDateSelected}
            dateSearchOptionId={dateSearchOptionId}
            selectAnytimeDate={selectAnytimeDate}
            selectSpecificDates={selectSpecificDates}
            selectFlexibleDates={selectFlexibleDates}
            onLocationChange={onLocationChange}
            saleUnit={saleUnit}
            typeaheadTypes={typeaheadTypes}
            recentSearches={disableRecentSearches ? undefined : recentSearches}
            locationSearchPlaceholder={locationSearchPlaceholder}
          />
        </CSSBreakpoint>
      </InputContainer>

      {activeMenu !== SearchMenuStates.Closed && <HideLiveChat />}
    </SearchWrapper>
  </Container>
})

SearchForm.displayName = 'SearchForm'

type MappedProps = Pick<Props, 'initialLocationPlaceId' | 'pathname' | 'region' | 'search' | 'travellerEmployees'>

export default connect((state: App.State, ownProps: Partial<Props>): MappedProps => ({
  initialLocationPlaceId: state.destination.locationToPlace[ownProps.initialLocation!]?.id,
  pathname: state.router.location.pathname,
  region: state.geo.currentRegionCode,
  search: state.router.location.search,
  travellerEmployees: selectSelectedTravellerEmployees(state),
}), null, null, { forwardRef: true })(SearchForm)
