import { getExperienceProductIdFromProvider, getExperienceProviderFromOffer } from 'analytics/snowplow/helpers/itemCategorisation'
import { DiscountRequestItem, DiscountRequestOrder } from 'api/mappers/promoMap'
import { getCarHireSelectedRateOption } from 'checkout/lib/utils/carHire/view'
import { getTourV2ItemViewPrice } from 'checkout/lib/utils/tours/priceView'
import { getFormattedExperienceItems } from 'checkout/selectors/payment/orderCreationSelectors'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import getAllBreakdownViews from 'checkout/selectors/view/getAllBreakdownViews'
import getAllItemViewsByType from 'checkout/selectors/view/getAllItemViewsByType'
import { isMemberOrHasSubscriptionInTheCart } from 'checkout/selectors/view/luxPlusSubscription'
import { CHECKOUT_ITEM_TYPE_TOUR_V1 } from 'constants/checkout'
import config from 'constants/config'
import { OFFER_TYPE_HOTEL, OFFER_TYPE_BED_BANK, OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2, OFFER_TYPE_ALWAYS_ON, OFFER_TYPE_CRUISE } from 'constants/offer'
import { partitionBy, sum } from 'lib/array/arrayUtils'
import { objectKeys } from 'lib/object/objectUtils'
import { isAndroidAppUserAgent, isIOSAppUserAgent } from 'lib/web/deviceUtils'
import { getWhiteLabelAppConfig } from 'lib/whitelabels/whitelabels'
import { reportClientError } from 'services/errorReportingService'

export type PromoItemDiscountsWithStatus = {
  hasRequiredPromoCodeData: boolean
  itemDiscounts: Array<App.Checkout.ItemDiscount>
}

export type BaseSpecificPromoTypeLookup = {
  specificType: 'subscription'
} | {
  specificType: 'subscription_joining-fee'
} | {
  specificType: 'subscription_reoccurring-fee'
}| {
  specificType: 'insurance'
} | {
  specificType: 'booking_protection'
} | {
  specificType: 'all'
} | {
  specificType: 'other'
} | {
  specificType: 'tour_optional_experience'
} | {
  specificType: 'bundleAndSave'
} | {
  specificType: 'accommodationAndExperiences'
  itemIds?: Array<string>
} | {
  specificType: 'tourCruiseAndOptionalExperiences'
} | {
  specificType: 'cruise'
} | {
  specificType: 'flight'
} | {
  specificType: 'car_hire'
} | {
  specificType: 'gift_card'
} | {
  specificType: 'transfer'
} | {
  specificType: 'accommodation',
} | {
  specificType: 'tour',
} | {
  specificType: 'experience',
}| {
  specificType: 'tourAndCruiseItems'
}

export type SpecificIdPromoTypeLookups = {
  specificType: 'cart-id-based',
  cartItemIds: Array<string>
} | {
  specificType: 'transactionKey-based',
  transactionKeys: Array<string>
} | {
  specificType: 'breakdownTitle-based',
  breakdownTitle: string
}
| {
  specificType: 'tour',
  cartItemId?: string
} | {
  specificType: 'accommodation',
  cartItemIds?: Array<string>
} | {
  specificType: 'experience',
  transactionKeys?: Array<string>
}

export type PromoLookup = BaseSpecificPromoTypeLookup | SpecificIdPromoTypeLookups

export type PromoTypeLookup = PromoLookup & {
  _logFilterInfo?: boolean
}

export type FilterDiscountItemProps = {
  itemDiscounts: Array<App.Checkout.ItemDiscount>
  lookup: PromoTypeLookup
}

const _filterItemDiscounts = ({ itemDiscounts, lookup }: FilterDiscountItemProps): Array<App.Checkout.ItemDiscount> => {
  switch (lookup.specificType) {
    case 'subscription':
      return itemDiscounts.filter((item) => item.categoryBK === 'subscription')
    case 'subscription_joining-fee':
      return itemDiscounts.filter((item) => item.categoryBK === 'subscription' && item.subCategoryBK === 'subscription-joining_fee')
    case 'subscription_reoccurring-fee':
      return itemDiscounts.filter((item) => item.categoryBK === 'subscription' && item.subCategoryBK === 'subscription-recurring_fee')
    case 'tour':
      if ('cartItemId' in lookup) {
        return itemDiscounts.filter((item) => item.categoryBK === 'tour' && item.itemId === lookup.cartItemId) ?? []
      } else {
        return itemDiscounts.filter((item) => item.categoryBK === 'tour')
      }
    case 'tourAndCruiseItems':
      return itemDiscounts.filter((item) => item.categoryBK == 'tour' || item.categoryBK == 'cruise')
    case 'tourCruiseAndOptionalExperiences':
      return itemDiscounts.filter((item) => item.categoryBK == 'tour' || item.categoryBK == 'experience' || item.categoryBK == 'cruise')
    case 'cruise':
      return itemDiscounts.filter((item) => item.categoryBK == 'cruise')
    case 'booking_protection':
    case 'insurance':
      return itemDiscounts.filter((item) => item.categoryBK == 'insurance')
    case 'experience':
      if ('transactionKeys' in lookup && lookup.transactionKeys && lookup.transactionKeys?.length > 0) {
        return itemDiscounts.filter((item) => item.categoryBK == 'experience' && lookup.transactionKeys?.includes(item?.itemId ?? ''))
      }
      return itemDiscounts.filter((item) => item.categoryBK == 'experience')
    case 'flight':
      return itemDiscounts.filter((item) => item.categoryBK == 'flight')
    case 'accommodation':
      if ('cartItemIds' in lookup) {
        return itemDiscounts.filter((item) => item.categoryBK == 'hotel' && lookup.cartItemIds?.includes(item?.itemId ?? ''))
      } else {
        return itemDiscounts.filter((item) => item.categoryBK == 'hotel')
      }
    case 'accommodationAndExperiences':
      if ('itemIds' in lookup) {
        return itemDiscounts.filter((item) => (item.categoryBK == 'hotel' || item.categoryBK == 'experience') && lookup.itemIds?.includes(item?.itemId ?? ''))
      } else {
        return itemDiscounts.filter((item) => item.categoryBK == 'hotel' || item.categoryBK == 'experience')
      }

    case 'all':
      return itemDiscounts
    case 'car_hire':
      return itemDiscounts.filter((item) => item.categoryBK == 'car_hire')
    case 'transfer':
      return itemDiscounts.filter((item) => item.categoryBK == 'transfer')
    case 'bundleAndSave':
      // Confirm if bundleAndSave can be bought with/including other products?
      return itemDiscounts
    case 'gift_card':
      return []
    case 'tour_optional_experience':
      return itemDiscounts.filter((item) => item.categoryBK == 'experience')
    case 'cart-id-based':
      return itemDiscounts.filter((item) => {
        if ('itemId' in item && item.itemId) {
          return lookup.cartItemIds.includes(item.itemId)
        } else {
          console.warn('cart-id-based lookup requires the itemId (cart_item_id) to be set on the promo item')
          return []
        }
      })
    case 'transactionKey-based':
      return itemDiscounts.filter((item) => {
        if ('itemId' in item && item.itemId) {
          return lookup.transactionKeys.includes(item.itemId)
        } else {
          console.warn('cart-id-based lookup requires the itemId (cart_item_id) to be set on the promo item')
          return []
        }
      })
      /**
       * This is used to match the grouping of the discounts in the breakdown view
       * While mapping against a display title isn't ideal, it does solve the problem effectively and we can move to a more granular mapping in the future
       */
    case 'breakdownTitle-based':
      switch (lookup.breakdownTitle) {
        case 'Accommodation':
          return itemDiscounts.filter((item) => item.categoryBK == 'hotel')
        case 'Tour':
          return itemDiscounts.filter((item) => item.categoryBK == 'tour')
        case 'Transfer':
          return itemDiscounts.filter((item) => item.categoryBK == 'transfer')
        case 'Experiences':
        case 'Optional experiences':
          return itemDiscounts.filter((item) => item.categoryBK == 'experience')
        case 'Cruise':
          return itemDiscounts.filter((item) => item.categoryBK == 'cruise')
        case 'Insurance':
        case 'Cancellation Protection':
          return itemDiscounts.filter((item) => item.categoryBK == 'insurance')
        default:
          console.warn(`Promo Discount Mapping for breakdownTitle: '${lookup.breakdownTitle}' did not map breakdownTitle-based lookup to item type, this will impact this display of the item level discount in the breakdown summary view`)
          return []
      }
    case 'other':
    default:
      const msg = `Could not map item filter type ${lookup.specificType} - add this mapping to display in breakdownView`
      if (lookup._logFilterInfo) {
        throw new Error(msg)
      } else {
        console.warn(msg)
      }
      return []
  }
}

export function getTourV1PromoLookupType(item: App.CheckoutCartState['items']['0']): PromoTypeLookup {
  if (item.itemType == 'cruise') {
    return {
      specificType: 'cruise',
    }
  } else if (item.itemType == 'tourV1') {
    return {
      specificType: 'tour',
    }
  } else {
    console.warn('getTourV1PromoLookupType-fall through')
    return {
      specificType: 'tour',
    }
  }
}

/* TODO - move to checkout libs */
export function filterItemDiscounts({ itemDiscounts, lookup }: FilterDiscountItemProps): Array<App.Checkout.ItemDiscount> {
  const discounts = _filterItemDiscounts({ itemDiscounts, lookup })

  if (lookup._logFilterInfo) {
    const { _logFilterInfo, ...toLog } = lookup
    // eslint-disable-next-line no-console
    console.log(`filterItemDiscounts - for '${toLog.specificType}' filtering to ${discounts.length > 0 ? `${discounts.length} out of ${itemDiscounts.length}` : 'none'}`)
    // eslint-disable-next-line no-console
    console.log('filterItemDiscounts', {
      itemDiscounts,
      matched: discounts,
      lookup,
    })
  }

  return discounts
}

/**
 * @deprecated - see getPromoItems()
 * (when PROMO_CHECKOUT_V2_ITEM_TOTALS_COUNTRY_CODES include ALL or the current country, we will attempt the new order (and if no products found fallback to the old method - we'll use stateToDiscountOrder directly after this move see getPromoItems)
 *
 *
 * The goal of checkoutStateToDiscountOrder is to provide item level information
 * for promo calculators for all item types in a way that is consistent with the placed order
 * Next steps: we'd like to move away from the getPromoCodeProductType() mapping
 * towards making this determination, in svc-promo via the offerId (avoiding this mapping)
 *
 *
 * @param state
 * @param breakdownView
 * @returns
 */
export function checkoutStateToDiscountOrder(
  state: App.State,
  reportErrors: boolean,
): DiscountRequestOrder {
  const bv = getAllBreakdownViews(state).data

  const items = bv.flatMap((bv) => {
    // Prefer the more specific price or ids on the item
    return getItemsSpecificDetails(bv, state, reportErrors)
  }).filter((item) => {
    // if car-hire items are populated in the breakdownView, exclude them as we are patching them in below
    return item.categoryBK !== 'car_hire'
  })

  // CruiseV1 doesn't populate the breakdown view 'items' array, so we patch from the offer view
  const accommodationItems = checkoutAccommodationOfferView(state)
  const cruiseV1Items: Array<DiscountRequestItem> =
  accommodationItems.data?.filter((item) =>
    item.offer?.type === OFFER_TYPE_TOUR &&
    item.offer?.holidayTypes?.includes('Cruises'))
    .map((item) => {
      const cruiseV1Items = item.itemViews.filter((view) => view.item && view.item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1)
      return {
        categoryBK: 'hotel',
        discountableTotal: sum(cruiseV1Items, (i) => i.totals.price),
        offerId: item.offerId,
      }
    })

  // Car hire doesn't populate the breakdown view 'items' array, so we patch from the checkout state items
  // (As a safeguard for if/when they are 'items' are added to the car-hire breakdown view, we filter them out above)
  const carHireItems = state.checkout.cart.items.filter((item) => item.itemType === 'car-hire').map((item:App.Checkout.CarHireItem):DiscountRequestItem => {
    const selectedRateOption = getCarHireSelectedRateOption(item)
    return {
      itemId: item.itemId,
      categoryBK: 'car_hire',
      discountableTotal: selectedRateOption.payNowAmount,
      offerId: item.offerId,
    }
  })

  const results: DiscountRequestOrder = {
    region: state.geo.currentRegionCode as DiscountRequestOrder['region'],
    brand: config.BRAND as DiscountRequestOrder['brand'],
    isGiftOrder: state.checkout.cart.isGift ?? false,
    items: items.concat(carHireItems, cruiseV1Items),
    hasBedbankPromotion: false,
    deviceType: getPromoDeviceType(state),
    clientOrderVersion: 1,
  }

  return results
}

type PromoCategory = {
  categoryBK: DiscountRequestItem['categoryBK']
  subCategoryBK?: DiscountRequestItem['subCategoryBK']
}

const getPromoItemCategoriesFromItemType = (itemType: string):PromoCategory => {
  switch (itemType) {
    case 'hotel':
      return { categoryBK: 'hotel', subCategoryBK: 'hotel-hotel' }
    case 'villa':
      return { categoryBK: 'hotel', subCategoryBK: 'hotel-rental' }
    case 'tourV1':
      return { categoryBK: 'tour' }
    case 'bedbankHotel':
      return { categoryBK: 'hotel', subCategoryBK: 'hotel-bedbank_hotel' }
    case 'tourV2experience':
    case 'tourV2Experience':
      return { categoryBK: 'experience' }
    case 'tourV2':
      // QQQ: add cruise "HolidayType" check here?
      return { categoryBK: 'tour' }
    case 'cruise':
      return { categoryBK: 'cruise' }
    default:
      reportClientError(new Error(`promoMap:getPromoItemCategoriesFromItemType - Failed to map item via ${itemType}`))
      return { categoryBK: 'hotel' }
  }
}

const getExperienceItemSubCategory = (offerIdWithPrefix: string):DiscountRequestItem['subCategoryBK'] => {
  const provider = getExperienceProviderFromOffer(offerIdWithPrefix)
  const productId = getExperienceProductIdFromProvider(provider)

  switch (productId) {
    case 'led_exp':{
      return 'experience-led_exp'
    }
    case 'mus_exp': {
      return 'experience-mus_exp'
    }
    case 'rez_exp': {
      return 'experience-rez_exp'
    }
  }
}

export const getPromoDeviceType = (state: App.State):DiscountRequestOrder['deviceType'] => {
  const wlApp = getWhiteLabelAppConfig()
  const browserName = state.system.rawUserAgentString

  if (wlApp.isIOS || isIOSAppUserAgent(browserName)) {
    return 'ios'
  }

  if (wlApp.isAndroid || isAndroidAppUserAgent(browserName)) {
    return 'android'
  }

  return 'web'
}

/**
 *
 * @deprecated - see getPromoItems()
 *
 * We're currently sourcing most of these totals from the PriceBreakdownView, but order item totals from getAllItemViews are the preferred source of total data as it is not inter-related with logic used to display the Summary Breakdown in checkout (i.e. let breakdownView just focus on presenting the totals, allow independent item total calculations for the promo)
 * We are still testing the totals will apply the in the same way as the checkout summary breakdown (via preCheckoutOrder, see marketing/promo-request page in admin (?dev=true mode) for more details)
 */
export function getItemsSpecificDetails(bv: App.Checkout.PriceBreakdownView, state: App.State, reportErrors: boolean): DiscountRequestOrder['items'] {
  /** The preferred source of total data: */
  const {
    subscriptionItemView,
    luxPlusSubscriptionItemView,
  } = getAllItemViewsByType(state).data

  return bv.items.map((item):DiscountRequestOrder['items']['0'] => {
    const { memberPrice = 0, price = 0, taxesAndFees = 0 } = item
    const itemPrice = price + taxesAndFees
    const canApplyMembership = isMemberOrHasSubscriptionInTheCart(state)
    const itemMemberPrice = (canApplyMembership && memberPrice > 0) ? memberPrice + taxesAndFees : undefined

    switch (item.itemType) {
      case 'accommodation':
      case 'villa':
        return {
          itemId: item.itemId,
          categoryBK: 'hotel',
          discountableTotal: itemPrice,
          luxPlusPrice: itemMemberPrice,
          offerId: item.offerId,
          reservationType: item.reservationType,
          travellers: state.checkout.form.travellerForms.map((traveller) => ({
            firstName: traveller.firstName,
            lastName: traveller.lastName,
          })),
        }
      case 'flight':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'flight',
          discountableTotal: itemPrice,
          travellers: [],
        }
      case 'experience':
      case 'transfer':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'experience',
          discountableTotal: itemPrice,
        }
      // QQQ: could consider addons as something here?
      case 'tour':
      case 'tourV2':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'tour',
          discountableTotal: itemPrice,
        }
      case 'insurance':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'insurance',
          discountableTotal: itemPrice,
        }
        /* NB: Car Hire (unlike others) does not populate breakdownView.items,
       This matching logic remains as it is the preferred method, but (outside of being filtered out), it is currently unused
        */
      case 'car_hire':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'car_hire',
          discountableTotal: itemPrice,
        }
      case 'cruise':
        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'cruise',
          discountableTotal: itemPrice,
        }
      case 'lux-plus-subscription':

        const subscriptionItemSum = sum(subscriptionItemView.data, (i) => i.totals.price) + sum(luxPlusSubscriptionItemView.data, (i) => i.totals.price)

        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'subscription',
          discountableTotal: subscriptionItemSum,
        }
      default:
        if (reportErrors) {
          reportClientError(new Error(`promoMap:getItemsSpecificDetails (BV) could not map promo item type - ${item.itemType}`))
        }

        return {
          offerId: item.offerId,
          itemId: item.itemId,
          categoryBK: 'hotel',
          discountableTotal: itemPrice,
        }
    }
  })
}

type CountryCodeValidationSupportedType = App.Checkout.LEAccommodationOfferView | App.Checkout.TourV2AccommodationOfferView;

export const getItemCountryCode = (item: CountryCodeValidationSupportedType): string | undefined => {
  // Some limited support for single country tour_v2 item destination validation
  if (item.offerType === 'tour_v2') {
    const itinerary = item.itemViews?.[0]?.variation?.itinerary
    if (itinerary && itinerary.length > 0 && 'locationsVisitedDetails' in itinerary[0]) {
      return itinerary[0].locationsVisitedDetails?.[0]?.countryCode
    }
    return undefined
  }

  return item.offer?.property?.geoData?.countryCode ?? undefined
}

export const getNumberOfOccupants = (item: App.Checkout.AccommodationItemView | App.Checkout.TourV2AccommodationItemView | App.Checkout.BedbankAccommodationItemView | App.Checkout.VillaItemView): {
  numberOfAdults: number | undefined
  numberOfChildren: number | undefined
} => {
  const itemToCheck = item.kind == 'villa' ? item.item : item

  return {
    numberOfAdults: itemToCheck.occupancy?.adults,
    numberOfChildren: (itemToCheck.occupancy?.children ?? 0) + (itemToCheck.occupancy?.infants ?? 0),
  }
}

/**
 *
 * @param prefix a prefix added to the info string
 * @param item the item to build the info string from
 * @returns a string of item identifiers and values
 * Please don't use this directly for business logic, (it only supported on client request and designed to inform changes to the categoryBK/subCategoryBK mapping)
*/
export function buildItemInfoString(prefix: string, item: object, fullDetails = true): string {
  const includedIds = ['price', 'total', 'memberTotal', 'memberPrice', 'surcharge', 'taxesAndFees', 'offerType', 'designation', 'itemType', 'parentType', 'reservationType', 'price', 'memberTotal', 'total', 'provider', 'kind']
  if (fullDetails) {
    includedIds.concat(['itemId', 'offerId'])
  }
  const includedKeys = objectKeys(item).filter((key) => includedIds.includes(key))
  const log = includedKeys.map((key) => `${key}:${item[key]}`).join(' ')
  return `${prefix} ${log}`
}

export const getDefaultTravellers = (state: App.State): DiscountRequestItem['travellers'] => state.checkout.form.travellerForms.map((traveller) => ({
  firstName: traveller?.firstName?.length > 0 ? traveller.firstName : 'Unknown',
  lastName: traveller?.lastName?.length > 0 ? traveller.lastName : 'Unknown',
}))

/**
 * An Array of DiscountIIDs is used to ensure we can correctly identify items and their associated discounts, in three ways:
 * - Which cart items the discounts are associated with
 * - Where/What total is rendered in the breakdown view (i.e. the "Promotion 'testcode'  -A$472" line in the summary display)
 * - Which order items the discounts are associated with on the order AFTER it has been placed (i.e. mapping the relevant discounts for each item is passed when we submit the order)
 */
export type DiscountIIDs = {
  offerId?: string,
  itemId?: string,
} | {
  offerId: string
} | {
  itemId: string
}

function sumViewItemsTotal(items: Array<App.Checkout.AnyItemView>): number {
  return sum(items, (itemView) => sumViewItemTotal(itemView))
}

function sumViewItemTotal(item: App.Checkout.AnyItemView): number {
  return item.totals.price + item.totals.surcharge + (item.totals.otherFees?.extraGuestSurcharge ?? 0)
}

function sumViewItemsMemberTotal(items: Array<App.Checkout.AnyItemView>): number {
  return sum(items, (itemView) => sumViewItemMemberTotal(itemView))
}

function sumViewItemMemberTotal(item: App.Checkout.AnyItemView): number {
  return item.totals.memberPrice + item.totals.surcharge + (item.totals.otherFees?.extraGuestSurcharge ?? 0)
}

const getNumberOfNights = (itemView: App.Checkout.LEAccommodationItemView | App.Checkout.BedbankAccommodationItemView | App.Checkout.TourV2AccommodationItemView | App.Checkout.VillaItemView): number | undefined => {
  if (itemView.kind == 'villa' || itemView.kind == 'bedbank' || itemView.kind == 'tourV2') {
    return itemView.item.duration
  }

  if (itemView.pkg?.duration) {
    return itemView.pkg.duration
  }

  if ('duration' in itemView) {
    return itemView.duration as number
  }
  return undefined
}

/**
 * (A simplified replacement for getPromoItems - generates 'Discount Request Item' (as opposed to 'Discount Request Item V2'))
 *
 * Please bump 'clientOrderVersion' when making changes to the logic or structure of the Discount Request Order/Items
 *
 * If adjusting the itemId or offerId below,  * ensure the relevant call to filterItemDiscounts is updated to match or the promo total will not be displayed in the breakdown view.
 * NB: (offer/itemId changes are encouraged if making it more relevant/useful for the vertical!)
 */
export const getPromoItems = (state: App.State, clientOrderVersion: number): Array<DiscountRequestItem> => {
  const resultItems: Array<DiscountRequestItem> = []
  try {
    const {
      accommodationItemsView,
      tourV2ExperienceItemsView,
      carHireItemsView,
      villaItemsView,
      transferItemsView,
      insuranceItemsView,
      subscriptionItemView,
      luxPlusSubscriptionItemView,
      flightItemsView,
    } = getAllItemViewsByType(state).data
    const isMemberOfHasSubItem = isMemberOrHasSubscriptionInTheCart(state)

    const checkoutAccOfferView = checkoutAccommodationOfferView(state)

    const [cruiseItems, accommodationItems] = partitionBy(accommodationItemsView.data || [], (item) => !!(item.offerType == OFFER_TYPE_TOUR && item.offer && 'holidayTypes' in item.offer && item.offer?.holidayTypes?.includes('Cruises')))

    for (const _ of cruiseItems || []) {
      const categoryBK: DiscountRequestItem['categoryBK'] = 'cruise'
      resultItems.push(...cruiseItems.map((item) => ({
        categoryBK,
        discountableTotal: sumViewItemsTotal(item.itemViews),
        luxPlusPrice: isMemberOfHasSubItem ? sumViewItemsMemberTotal(item.itemViews) : 0,
        numberOfNights: item.duration,
        numberOfAdults: item.occupancy.map((occupancy) => occupancy.adults).reduce((a, b) => a + b, 0),
        numberOfChildren: item.occupancy.map((occupancy) => (occupancy.children ?? 0) + (occupancy.infants ?? 0)).reduce((a, b) => a + b, 0),
        reservationType: item.reservationType,
        itemInfoString: buildItemInfoString('cruise-items-v1', item.itemViews),
        offerId: item.offerId,
        travellers: [],
      })))
    }

    for (const item of accommodationItems || []) {
      if (item.offerType == OFFER_TYPE_BED_BANK) {
        const categoryBK: DiscountRequestItem['categoryBK'] = 'hotel'
        const subCategoryBK: DiscountRequestItem['subCategoryBK'] = 'hotel-bedbank_hotel'
        resultItems.push(...item.itemViews.map((iv) => ({
          categoryBK,
          subCategoryBK,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          itemCountryCode: item.locationCountryCode,
          numberOfNights: getNumberOfNights(iv),
          reservationType: item.reservationType,
          offerId: item.offerId,
          itemInfoString: buildItemInfoString('bedbank-items-v1', item),
          travellers: getDefaultTravellers(state),
          ...getNumberOfOccupants(iv),
        })))
      } else if (item.offerType == OFFER_TYPE_HOTEL) {
        const categoryBK: DiscountRequestItem['categoryBK'] = 'hotel'
        const subCategoryBK: DiscountRequestItem['subCategoryBK'] = 'hotel-hotel'
        resultItems.push(...item.itemViews.map((iv) => ({
          categoryBK,
          subCategoryBK,
          itemId: iv.item.itemId,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          numberOfNights: getNumberOfNights(iv),
          itemCountryCode: getItemCountryCode(item),
          reservationType: item.reservationType,
          offerId: item.offerId,
          itemInfoString: buildItemInfoString('hotel-v1', item),
          travellers: getDefaultTravellers(state),
          ...getNumberOfOccupants(iv),
        })))
      } else if (item.offerType == OFFER_TYPE_ALWAYS_ON) {
        const categoryBK: DiscountRequestItem['categoryBK'] = 'hotel'
        const subCategoryBK: DiscountRequestItem['subCategoryBK'] = 'hotel-tactical_ao_hotel'
        resultItems.push(...item.itemViews.map((iv) => ({
          categoryBK,
          subCategoryBK,
          itemId: iv.item.itemId,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          numberOfNights: getNumberOfNights(iv),
          itemCountryCode: getItemCountryCode(item),
          reservationType: item.reservationType,
          offerId: item.offerId,
          itemInfoString: buildItemInfoString('always-on-v1', item),
          travellers: getDefaultTravellers(state),
          ...getNumberOfOccupants(iv),
        })))
      } else if (item.offerType == OFFER_TYPE_TOUR_V2) {
        const categoryBK: DiscountRequestItem['categoryBK'] = 'tour'
        item.itemViews.forEach((iv) => {
          const tourV2Offer = checkoutAccOfferView.data[0].offer as App.Tours.TourV2Offer
          const { totalPrice, totalMemberPrice } = getTourV2ItemViewPrice(tourV2Offer, iv.item.purchasableOption, iv.occupancy)

          resultItems.push({
            categoryBK,
            discountableTotal: totalPrice,
            luxPlusPrice: isMemberOfHasSubItem ? totalMemberPrice : 0,
            itemCountryCode: getItemCountryCode(item),
            numberOfNights: getNumberOfNights(iv),
            reservationType: item.reservationType,
            itemInfoString: buildItemInfoString('offerType-tourv2-v4', item),
            offerId: iv.item.purchasableOption.fkTourId,
            itemId: ('roomId' in iv.item.occupancy ? iv.item.occupancy.roomId as string : iv.item.itemId),
            ...getNumberOfOccupants(iv),
          })
        })
      } else if (item.offerType == OFFER_TYPE_CRUISE) {
        const categoryBK: DiscountRequestItem['categoryBK'] = 'cruise'
        resultItems.push(...item.itemViews.map((iv) => ({
          categoryBK,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          numberOfNights: item.duration,
          reservationType: item.reservationType,
          itemInfoString: buildItemInfoString('itemType-cruise', item),
          offerId: item.offerId,
          ...getNumberOfOccupants(iv),
        })))
      } else if ('itemType' in item && typeof item.itemType == 'string') {
        const category = getPromoItemCategoriesFromItemType(item.itemType)
        switch (item.itemType) {
          case 'hotel': {
            resultItems.push(...item.itemViews.map((iv:App.Checkout.AccommodationItemView) => ({
              ...category,
              discountableTotal: sumViewItemTotal(iv),
              luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
              numberOfNights: item.duration,
              itemCountryCode: getItemCountryCode(item),
              reservationType: item.reservationType,
              itemInfoString: buildItemInfoString('itemType-hotel-v1', item),
              offerId: item.offerId,
              itemId: iv.item.itemId,
              ...getNumberOfOccupants(iv),
            })))
            break
          }
          case 'tourV2':
          case 'addons':
          case 'tourV2Experience': {
            resultItems.push(...item.itemViews.map((iv) => ({
              ...category,
              discountableTotal: sumViewItemTotal(iv),
              luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
              numberOfNights: getNumberOfNights(iv),
              itemCountryCode: getItemCountryCode(item),
              reservationType: item.reservationType,
              itemInfoString: buildItemInfoString('itemType-tourV2-addons-tourv2-experiences-v1', item),
              offerId: item.offerId,
              itemId: iv.item.itemId,
              ...getNumberOfOccupants(iv),
            })))
            break
          }
          case 'cruise':{
            resultItems.push(...item.itemViews.map((iv) => ({
              ...category,
              discountableTotal: sumViewItemTotal(iv),
              luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
              numberOfNights: getNumberOfNights(iv),
              itemCountryCode: getItemCountryCode(item),
              reservationType: item.reservationType,
              itemInfoString: buildItemInfoString('itemType-cruise', item),
              offerId: item.offerId,
              itemId: iv.item.itemId,
              ...getNumberOfOccupants(iv),
            })))
            break
          }
          default:
            reportClientError(new Error(`promoMap:getPromoItems: Could not map itemType or offerType:${item.itemType} ${item.offerType} ${clientOrderVersion} ${buildItemInfoString('fallthrough-1', item, false)}`))
        }
      } else {
        reportClientError(new Error(`promoMap:getPromoItems: Could not map itemType or offerType ${clientOrderVersion} ${item.offerType} ${buildItemInfoString('fallthrough-2', item, false)}`))
      }
    }

    if (villaItemsView.data.length > 0) {
      const category = getPromoItemCategoriesFromItemType('villa')
      resultItems.push(...villaItemsView.data.map((iv) => ({
        ...category,
        discountableTotal: sumViewItemTotal(iv),
        luxPlusPrice: isMemberOfHasSubItem ? sum(villaItemsView.data, (i) => i.totals.memberPrice) : 0,
        numberOfNights: getNumberOfNights(iv),
        itemId: villaItemsView.data[0].item.itemId,
        offerId: villaItemsView.data[0].offer.id,
        travellers: getDefaultTravellers(state),
        itemInfoString: buildItemInfoString('itemArray-villa-items', iv),
        ...getNumberOfOccupants(iv),
      })))
    }

    const experienceCartFormattedItems = getFormattedExperienceItems(state)

    if (experienceCartFormattedItems.length > 0) {
      const categoryBK: DiscountRequestItem['categoryBK'] = 'experience'

      resultItems.push(...experienceCartFormattedItems.map((expCartItem) => {
        return {
          categoryBK,
          subCategory: getExperienceItemSubCategory(expCartItem.id_experience_items ?? expCartItem.transaction_key),
          discountableTotal: expCartItem.total,
          luxPlusPrice: 0,
          offerId: expCartItem.provider_offer_id,
          itemId: expCartItem.transaction_key,
          itemInfoString: buildItemInfoString('itemArray-experience-items', expCartItem),
        }
      }))
    }

    if (tourV2ExperienceItemsView.data.length > 0) {
      resultItems.push(...tourV2ExperienceItemsView.data.map((iv) => {
        const category = getPromoItemCategoriesFromItemType(iv.item.itemType)
        return {
          ...category,
          discountableTotal: sumViewItemTotal(iv),
          luxPlusPrice: isMemberOfHasSubItem ? sumViewItemMemberTotal(iv) : 0,
          itemInfoString: buildItemInfoString('itemArray-tourv2-v2', iv),
          itemId: iv.item.itemId,
        }
      }))
    }

    if (carHireItemsView.data.length > 0) {
      const categoryBK: DiscountRequestItem['categoryBK'] = 'car_hire'
      resultItems.push(...carHireItemsView.data.map((vi) => ({
        categoryBK,
        discountableTotal: getCarHireSelectedRateOption(vi.item).payNowAmount,
        luxPlusPrice: 0,
        offerId: vi.item.offerId,
        itemInfoString: buildItemInfoString('itemArray-car-hire-v1', vi.item),
        itemId: vi.item.itemId,
      })))
    }

    if (subscriptionItemView.data.length > 0) {
      const categoryBK: DiscountRequestItem['categoryBK'] = 'subscription'
      const joiningSubCategoryBK: DiscountRequestItem['subCategoryBK'] = 'subscription-joining_fee'
      resultItems.push(...subscriptionItemView.data.map((siv) => ({
        categoryBK,
        subCategoryBK: joiningSubCategoryBK,
        discountableTotal: sumViewItemTotal(siv),
        luxPlusPrice: 0,
        itemId: siv.item.itemId,
        itemInfoString: buildItemInfoString('itemArray-subscriptionItemView-v1', siv),
        travellers: getDefaultTravellers(state),
      })))
    }

    if (luxPlusSubscriptionItemView.data.length > 0) {
      const categoryBK: DiscountRequestItem['categoryBK'] = 'subscription'
      const reoccurringFeeSubCategoryBK: DiscountRequestItem['subCategoryBK'] = 'subscription-recurring_fee'
      resultItems.push(...luxPlusSubscriptionItemView.data.map((siv) => ({
        categoryBK,
        subCategoryBK: reoccurringFeeSubCategoryBK,
        discountableTotal: sumViewItemTotal(siv),
        luxPlusPrice: 0,
        itemId: siv.item.itemId,
        itemInfoString: buildItemInfoString('itemArray-luxPlusSubscriptionItemView-v1', siv),
        travellers: getDefaultTravellers(state),
      })))
    }

    if (flightItemsView.data.length > 0) {
      const categoryBK: DiscountRequestItem['categoryBK'] = 'flight'
      resultItems.push(...flightItemsView.data.map((view) => ({
        categoryBK,
        discountableTotal: view.totals.price,
        luxPlusPrice: isMemberOfHasSubItem ? view.item.memberTotal : 0,
        offerId: view.item.flights[0].journeyId,
        itemId: view.item.itemId,
        itemInfoString: buildItemInfoString('itemArray-flights-v1', view),
        travellers: getDefaultTravellers(state),
      })))
    }

    if (transferItemsView.data.length > 0) {
      resultItems.push({
        categoryBK: 'transfer',
        discountableTotal: sum(transferItemsView.data, (i) => i.item.transfer.option?.price ?? 0),
        luxPlusPrice: isMemberOfHasSubItem ? sum(transferItemsView.data, (i) => i.item.transfer.option?.memberPrice ?? 0) : 0,
        itemId: transferItemsView.data[0].item.itemId,
        itemInfoString: buildItemInfoString('itemArray-transfer-v1', transferItemsView.data),
        travellers: getDefaultTravellers(state),
      })
    }

    if (insuranceItemsView.data.length > 0) {
      resultItems.push({
        categoryBK: 'insurance',
        discountableTotal: sum(insuranceItemsView.data, (i) => i.totals.price),
        itemId: insuranceItemsView.data[0].item.itemId,
        itemInfoString: buildItemInfoString('itemArray-insurance-v1', transferItemsView.data),
        travellers: getDefaultTravellers(state),
      })
    }
  } catch (err) {
    reportClientError(err)
  }

  return resultItems
}

/**
 * @deprecated - This is the (very soon) becoming the default method of determining the composition of the cart (i.e. via getAllItemsView)
 * It is just here if we need to fallback to the old priceBreakdownView based method
 */
export const applyPromoWithV2ItemTotals = (state: App.State):boolean => {
  return config.PROMO_CHECKOUT_V2_ITEM_TOTALS_COUNTRY_CODES.includes('ALL') ||
      config.PROMO_CHECKOUT_V2_ITEM_TOTALS_COUNTRY_CODES.includes(state.geo.currentRegionCode) ||
      false
}

/**
 *
 * @param state The current state of the application (in the cart/checkout)
 * @returns The <see cerf="DiscountRequestOrder"/> object that represents the current state of the cart from the perspective of the promo code system.
 * This information will be used to determine if the user qualifies for and calculate the amount of discount each item has (via the App.Promotion and App.Checkout.ItemDiscount Array)
 */
export const stateToDiscountOrder = (state: App.State): DiscountRequestOrder => {
  // Please bump the clientOrderVersion when making changes to the structure of the Discount Request Order
  const clientOrderVersion = 20
  return {
    brand: config.BRAND as DiscountRequestOrder['brand'],
    region: state.geo.currentRegionCode as DiscountRequestOrder['region'],
    deviceType: getPromoDeviceType(state),
    hasBedbankPromotion: false,
    isGiftOrder: state.checkout.cart.isGift ?? false,
    items: getPromoItems(state, clientOrderVersion),
    clientOrderVersion,
  }
}

/**
 * @@deprecated - see filterItemDiscounts/getPromoCodeItemDiscounts (the 'breakdownTitle-based')
 */
export const mapItemTypeToLabel = (type: string) => {
  switch (type) {
    case 'accommodation':
      return 'Accommodation'
    case 'tour':
    case 'tour_v2':
      return 'Tour'
    case 'tour_optional_experience':
      return 'Optional experiences'
    case 'cruise':
      return 'Cruise'
    case 'experience':
      return 'Experience'
    case 'flight':
      return 'Flight'
    case 'insurance':
      return 'Insurance'
    case 'subscription':
      return 'LuxPlus+ Membership'
    case 'booking_protection':
      return 'Cancellation Protection'
      /**
 * @@deprecated - see filterItemDiscounts/getPromoCodeItemDiscounts (the 'breakdownTitle-based')
 */
    case 'car_hire':
      return 'Car Hire'
    case 'gift_card':
      return 'Gift Card'
    default:
      return type
  }
}

/**
 * @@deprecated - see filterItemDiscounts/getPromoCodeItemDiscounts (the 'breakdownTitle-based')
 */
export const mapLabelToItemType = (label: string): BaseSpecificPromoTypeLookup['specificType'] => {
  switch (label) {
    case 'Accommodation':
      return 'accommodation'
    case 'Tour':
      return 'tour'
    case 'Optional experiences':
      return 'tour_optional_experience'
    case 'Cruise':
      return 'cruise'
    case 'Experiences':
    case 'Experience':
      return 'experience'
    case 'Flight':
      return 'flight'
    case 'Insurance':
      return 'insurance'
    case 'LuxPlus+ Membership':
      return 'subscription'
    case 'Cancellation Protection':
    case 'Travel Protection':
      return 'booking_protection'
    case 'Car Hire':
      return 'car_hire'
    case 'Gift Card':
      return 'gift_card'
    case 'Transfers':
      return 'transfer'
    default:
      console.warn(`Could not mapLabelToItemType "${label}"`)
      return 'all'
  }
}
