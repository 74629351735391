import { useContext, useMemo } from 'react'
import { useAppSelector } from 'hooks/reduxHooks'
import { GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { nonNullable, unique } from 'lib/array/arrayUtils'
import { pluralizeToString } from 'lib/string/pluralize'
import {
  CruiseOfferPriceVariation,
  getCruiseCountriesByItinerary,
  getCruiseOfferPriceVariation,
  getCruisePortsByItinerary,
} from 'lib/cruises/cruiseUtils'
import { checkHasOnlySuitesAvailable, getHighlightedDepartureDate } from 'lib/cruises/cruiseOfferUtils'
import { isVelocityEnabled as getVelocityEnabled } from 'selectors/featuresSelectors'
import { useCruiseOfferInclusions } from 'hooks/Cruise/useCruiseOfferInclusions'
import moment from 'moment'
import useCruisePriceByNight from 'hooks/useCruisePriceByNight'
import { isCruiseOffer, isCruiseV1Offer } from 'lib/offer/offerTypes'
import uuidV4 from 'lib/string/uuidV4Utils'
import { DMY_CASUAL_FORMAT, ISO_DATE_FORMAT } from 'constants/dateFormats'
import { CABIN_CATEGORIES } from 'constants/cruise'

interface CommonCruiseOfferView<Offer extends App.CruiseOffer | App.TourOffer | App.TourOfferSummary> {
  offer: Offer;
  offerTitle: string;
  offerDescription?: string;
  departureCity: string;
  destinationCity: string;
  cities: Array<string>;
  countries: Array<string>;
  duration: number;
  offerImages: Array<App.Image>;
  lowestPrice: CruiseOfferPriceVariation;
  hasOnlySuitesAvailable: boolean;
  isVelocityEnabled: boolean;
  highlightedDepartureId?: string;
  earliestDepartureDate?: string;
  laterDates: Array<string>;
  departureDates: Array<string>;
  shipName: string;
  cruiseLineName: string;
  cruiseLineImage: string | undefined;
  isSpecialOffer: boolean;
  shipDetailsLabel: string;
  itineraryDetailsLabel: string;
  productType: App.ProductType;
  promotionDetails?: App.CruisePromotionDetails;
  evergreenInclusions: Array<App.EvergreenInclusion>;
  disableDeposits: boolean;
  isFlashOffer: boolean;
  cabinCategory: string;
  discountPills?: App.CruiseDiscountPillDetails;
  itinerary?: Array<App.CruiseItineraryItem>;
  itineraryFlash?: string;
  tileInclusions?: Array<App.OfferInclusion>;
  inclusions: Array<App.OfferInclusion>;
  inclusionsTitle?: string;
  urgencyLabels: Array<App.OfferUrgencyLabel>;
  isNew?: boolean;
  isEndingIn?: number;
  standardInclusions: Array<App.PackageInclusion>
  luxPlusInclusions: Array<App.PackageInclusion>
}

interface CurrentCruiseOfferView extends CommonCruiseOfferView<App.CruiseOffer> {
  availableCabinCategoriesPriceVariations: Map<string, CruiseOfferPriceVariation>
}

interface LegacyCruiseOfferView extends CommonCruiseOfferView<App.TourOffer | App.TourOfferSummary> {
  availableCabinCategoriesPriceVariations?: undefined
}

export type CruiseOfferView = CurrentCruiseOfferView | LegacyCruiseOfferView

interface Props {
  offer: App.CruiseOffer | App.TourOffer | App.TourOfferSummary;
  cabinTypes?: string;
}

function useCruiseOfferView({ offer, cabinTypes }: Props): CruiseOfferView {
  const globalFilters = useContext(GlobalSearchStateContext)
  const cruisePriceByNight = useCruisePriceByNight()
  const isVelocityEnabled = useAppSelector(getVelocityEnabled)
  const mainDepartureDetails = 'mainDepartureDetails' in offer ? offer.mainDepartureDetails : undefined
  const { standardInclusions, luxPlusInclusions, inclusionDetails } = useCruiseOfferInclusions({
    departure: mainDepartureDetails!,
  })

  const lowestPrice = useMemo((): CruiseOfferPriceVariation => {
    if (isCruiseOffer(offer)) {
      let lowestOffer = offer.mainDepartureDetails.lowestOverallPriceDetails
      if (cabinTypes) {
        lowestOffer = offer.mainDepartureDetails.lowestPriceDetailsByCategory[cabinTypes]
      }

      return getCruiseOfferPriceVariation(offer, lowestOffer, cruisePriceByNight)
    }

    const saleUnit = cruisePriceByNight ? 'night' : offer.saleUnit.toLowerCase() === 'cruise' ? 'person' : offer.saleUnit
    const lowestPrice = offer.lowestPricePackage?.price || 0
    const total = cruisePriceByNight ? Math.round(lowestPrice / offer.minDuration! || 1) : lowestPrice
    return {
      primaryPrice: {
        total,
        type: saleUnit,
      },
    }
  }, [cruisePriceByNight, offer, cabinTypes])

  const departureDates = useMemo(() => {
    if (isCruiseOffer(offer)) {
      const { mainDepartureDetails } = offer
      const allDepartureDates = (offer.departureDates ?? [])
        .map((date) => moment(date))
        .sort((a, b) => a.unix() - b.unix())
        .map((date) => date.format(DMY_CASUAL_FORMAT))

      if (mainDepartureDetails.departureDate) {
        const mainDepartureDate = moment(mainDepartureDetails.departureDate).format(DMY_CASUAL_FORMAT)
        return [
          ...new Set([mainDepartureDate, ...allDepartureDates]),
        ]
      }

      return allDepartureDates
    }
    const departureDates: Array<string> = []

    if (offer.travelFromDate) {
      departureDates.push(offer.travelFromDate)
    }

    if (offer.travelFromDate && (offer.travelToDate !== offer.travelFromDate)) {
      departureDates.push(offer.travelToDate)
    }

    return departureDates
      .filter(Boolean)
      .map((date) => moment(date).format(DMY_CASUAL_FORMAT))
  }, [offer])

  const [earliestDepartureDate, ...laterDates] = departureDates

  const offerDescription = useMemo(() => {
    if (isCruiseOffer(offer)) {
      const shipInfoDescription = offer.ship.shipInfo?.find(info => info.dataTypeName === 'Summary')?.description
      const cruiseDescription = offer.description || shipInfoDescription || offer.destinationDescription
      const parsedDescription = cruiseDescription
        ?.replace(/(<([^>]+)>)/gi, '')
        ?.split(' ')
        ?.slice(0, 35)
        ?.join(' ') + '...'

      return parsedDescription
    }
  }, [offer])

  const promotion = useMemo((): App.CruisePromotionDetails | undefined => {
    if (isCruiseOffer(offer)) {
      return offer.mainDepartureDetails.lowestOverallPriceDetails.promotion ?? offer.fallbackPromotion ?? undefined
    }

    return {
      startDate: moment().format(ISO_DATE_FORMAT),
      endDate: offer.bookByDate,
      isNew: false,
      isEndingIn: offer.bookByDate ? moment(offer.bookByDate).diff(new Date(), 'days') : 0,
      leExclusive: false,
      sellingPoints: [],
      deposit: null,
      onBoardCredit: null,
    }
  }, [offer])

  const isSpecialOffer = isCruiseOffer(offer) ? !!(promotion || offer.evergreenInclusions.length || standardInclusions.length || luxPlusInclusions.length) : true

  const urgencyLabels = useMemo<Array<App.OfferUrgencyLabel>>(() => {
    const tags: Array<App.OfferUrgencyLabel> = []

    if (promotion?.isEndingIn) {
      tags.push({ type: 'left', end: promotion.endDate })
    }

    if (isCruiseV1Offer(offer)) {
      if (offer.lowestPricePackage?.memberPrice) {
        tags.push({
          type: 'lux_plus_member_price',
          saveAmount: offer.lowestPricePackage.price - offer.lowestPricePackage.memberPrice,
        })
      }
      if (offer.isAgentHubExclusive) {
        tags.push({ type: 'agenthub_exclusive' })
      }
    }

    if (isSpecialOffer) {
      tags.push({ type: 'sale', message: 'Special offer' })
    }

    return tags
  }, [offer, promotion, isSpecialOffer])

  const inclusions = useMemo<Array<App.OfferInclusion>>(() => {
    if (isCruiseOffer(offer)) {
      const evergreenInclusions = offer.evergreenInclusions.map<App.OfferInclusion>(incl => ({
        id: incl.id,
        description: incl.description,
        isHighlighted: true,
        symbol: 'line_key_skeleton',
      }))

      const promotionInclusions: Array<App.OfferInclusion> = []
      if (promotion) {
        if (promotion.onBoardCredit) {
          promotionInclusions.push({
            description: `Up to ${promotion.onBoardCredit.formattedValue}`,
            id: uuidV4(),
            symbol: 'line_price_tag',
            isHighlighted: evergreenInclusions.length === 0,
          })
        } else if (promotion?.sellingPoints) {
          promotionInclusions.push(...promotion.sellingPoints.map<App.OfferInclusion>((point, index) => {
            const willBeTopInclusion = index === 0 && !promotion.onBoardCredit && evergreenInclusions.length === 0
            return {
              description: point,
              id: uuidV4(),
              symbol: willBeTopInclusion ? 'line_price_tag' : 'check',
              isHighlighted: willBeTopInclusion,
            }
          }))
        }
      }

      return [...evergreenInclusions, ...promotionInclusions]
    } else {
      return offer.tileInclusions ?? []
    }
  }, [offer, promotion])

  const inclusionsTitle = useMemo<string | undefined>(() => {
    if (
      promotion?.sellingPoints ||
      (isCruiseOffer(offer) && offer.evergreenInclusions.length)
    ) {
      return 'Inclusions (select packages only)'
    }
  }, [offer, promotion?.sellingPoints])

  const view = useMemo<CruiseOfferView>(() => {
    if (isCruiseOffer(offer)) {
      const departureCity = offer.departurePort.split(',')[0]
      const destinationCity = offer.returnPort.split(',')[0]
      const cities = getCruisePortsByItinerary(offer.itinerary)
      const countries = getCruiseCountriesByItinerary(offer.itinerary)
      const detailItems = [offer.cruiseLine.name, offer.ship.name].filter(Boolean)
      const itineraryDetailItems: Array<string> = []
      const duration = offer.duration

      if (duration) itineraryDetailItems.push(pluralizeToString('night', duration))
      if (cities.length) itineraryDetailItems.push(pluralizeToString('port', cities.length))
      if (countries.length) itineraryDetailItems.push(pluralizeToString('country', countries.length))

      const { highlightedDepartureId, highlightedDepartureDate, otherDepartureDates } = getHighlightedDepartureDate(
        offer.mainDepartureDetails.departureDate ?? undefined,
        offer.departuresSummary,
        globalFilters.checkinDate,
        globalFilters.checkoutDate,
        globalFilters.flexibleMonths,
      )
      const hasOnlySuitesAvailable = checkHasOnlySuitesAvailable(highlightedDepartureId, offer)

      const { lowestDeparturePricesByCategory, mainDepartureDetails } = offer
      const { lowestPriceDetailsByCategory } = mainDepartureDetails
      const lowestDeparturePriceByCategory = highlightedDepartureId ? lowestDeparturePricesByCategory[highlightedDepartureId] : undefined

      const availableCabinCategoriesPriceVariations = new Map<string, CruiseOfferPriceVariation>(
        CABIN_CATEGORIES
          .map(cabinCategory => {
            const price = (lowestDeparturePriceByCategory ?? lowestPriceDetailsByCategory)[cabinCategory.category]
            return [
              cabinCategory.category,
              getCruiseOfferPriceVariation(offer, price, cruisePriceByNight),
            ] as const
          })
          .filter(([, price]) => !!price.primaryPrice.total),
      )

      const view: CruiseOfferView = {
        offerTitle: offer.name,
        offerDescription,
        departureCity,
        destinationCity,
        cities,
        countries,
        duration,
        offerImages: offer.images,
        lowestPrice,
        hasOnlySuitesAvailable,
        isVelocityEnabled,
        highlightedDepartureId,
        earliestDepartureDate: highlightedDepartureDate,
        laterDates: otherDepartureDates,
        departureDates,
        shipName: offer.ship.name,
        cruiseLineName: offer.cruiseLine.name,
        cruiseLineImage: offer.cruiseLine.imageId,
        isSpecialOffer,
        shipDetailsLabel: detailItems.join(' · '),
        itineraryDetailsLabel: itineraryDetailItems.join(' · '),
        productType: offer.productType,
        promotionDetails: promotion,
        evergreenInclusions: offer.evergreenInclusions,
        disableDeposits: offer.disableDeposits,
        isFlashOffer: false,
        cabinCategory: cabinTypes || offer.mainDepartureDetails.lowestOverallPriceDetails.cabinCategory,
        discountPills: offer.mainDepartureDetails.lowestOverallPriceDetails.discountPills ?? undefined,
        itinerary: offer.itinerary,
        offer,
        urgencyLabels,
        inclusions,
        inclusionsTitle,
        availableCabinCategoriesPriceVariations,
        isNew: promotion?.isNew || inclusionDetails?.hasNewInclusion || false,
        isEndingIn: promotion?.isEndingIn || inclusionDetails?.endingInInclusion,
        standardInclusions,
        luxPlusInclusions,
      }
      return view
    }

    const shipName = offer.vendorVehicle ?? ''
    const cruiseLineName = offer.vendorName ?? ''
    const cruiseLineImage = offer.lowestPricePackage?.tour?.logoImageId || ''
    const duration = offer.minDuration ?? 0
    const departureCity = offer.startLocation?.split(',')?.[0] ?? ''
    const destinationCity = offer.endLocation?.split(',')?.[0] ?? ''
    const locations = offer.locationsVisited ?? []
    const countries = unique(nonNullable(locations.map(({ country }) => country)))
    const cities = unique(nonNullable(locations.map(({ cityOrPlaceName }) => cityOrPlaceName)))

    const detailItems: Array<string> = []

    if (duration) {
      // FOR FLASH CRUISES WE NEED TO
      // SUBTRACT 1 NIGHT FROM THE DURATION.
      // BECAUSE DURATION COMES IN DAYS RATHER THATN NIGHTS.
      // E.G. 7 DAYS CRUISE IS 6 NIGHTS
      const nights = duration - 1
      detailItems.push(pluralizeToString('night', nights))
    }

    if (cities.length) {
      detailItems.push(pluralizeToString('port', cities.length))
    }

    if (countries.length) {
      detailItems.push(pluralizeToString('country', countries.length))
    }

    const itineraryDetailsLabel = detailItems.join(' · ')
    const shipDetailsItems = [cruiseLineName, shipName].filter(Boolean)
    const shipDetailsLabel = shipDetailsItems.join(' · ')

    const itineraryFlash = offer.lowestPricePackage?.tour?.itinerary || ''

    const view: CruiseOfferView = {
      offerTitle: offer.name,
      shipName,
      cruiseLineName,
      cruiseLineImage,
      departureCity,
      destinationCity,
      duration,
      offerImages: offer.images,
      lowestPrice,
      hasOnlySuitesAvailable: false,
      shipDetailsLabel,
      itineraryDetailsLabel,
      isVelocityEnabled,
      earliestDepartureDate,
      laterDates,
      cities,
      countries,
      itineraryFlash,
      departureDates,
      isSpecialOffer,
      productType: 'cruise-flash',
      offerDescription: offer.preHeader,
      disableDeposits: true,
      isFlashOffer: true,
      tileInclusions: offer.tileInclusions,
      inclusions,
      inclusionsTitle,
      cabinCategory: '',
      offer,
      urgencyLabels,
      promotionDetails: promotion,
      evergreenInclusions: [],
      isNew: false,
      isEndingIn: promotion?.isEndingIn,
      luxPlusInclusions,
      standardInclusions,
    }
    return view
  }, [cabinTypes, cruisePriceByNight, departureDates, earliestDepartureDate, globalFilters.checkinDate, globalFilters.checkoutDate, globalFilters.flexibleMonths, inclusionDetails?.endingInInclusion, inclusionDetails?.hasNewInclusion, inclusions, inclusionsTitle, isSpecialOffer, isVelocityEnabled, laterDates, lowestPrice, luxPlusInclusions, offer, offerDescription, promotion, standardInclusions, urgencyLabels])
  return view
}

export default useCruiseOfferView
