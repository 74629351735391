import { Dispatch, useCallback, useEffect, useMemo } from 'react'
import { ANYWHERE_SEARCH_ITEM } from 'constants/search'
import GlobalSearchState, { GlobalSearchAction, GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import {
  addFlexibleMonthsIfNoneSelected,
  convertDatesToString,
  encodeSearchParams,
  getSearchKey,
  isRoomsInvalid, mapSearchContext,
} from 'lib/search/searchUtils'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { loadRecentRooms, saveLastDates, saveRecentRooms } from 'components/Search/utils'
import { addGTMEvent } from 'api/googleTagManager'
import { flexibleSearchFormSubmit, searchFormSubmit } from 'analytics/eventDefinitions'
import { useLocation } from 'react-router'
import { pushWithRegion } from 'actions/NavigationActions'
import { getHotelOfferUrl } from 'lib/offer/offerPageURL'
import { getQueryParam, parseSearchString } from 'lib/url/searchUrlUtils'
import { queryKeyUrlOfferId, queryKeyUrlOfferType } from 'constants/url'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import usePrevious from 'hooks/usePrevious'
import { getUrlOfferListFilters } from 'selectors/routerSelectors'
import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapGlobalSearchContextToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'
import { setSearchId } from 'actions/SearchActions'
import uuidV4 from 'lib/string/uuidV4Utils'

interface Props {
  globalSearchDispatch: Dispatch<GlobalSearchAction>
  globalSearchState: GlobalSearchState;
  baseSearchPath?: string
  baseSearchMapPath?: string
}

export interface GlobalSearchVerticalTabBookingHook {
  onSearch: () => void;
  context: App.GlobalSearchVerticalTabV2['context']
}

// Hook to be used as base for any booking hook (e.g Hotels & Resorts and Villas & Unique Stays)
function useGlobalSearchVerticalTabBooking(props: Props): GlobalSearchVerticalTabBookingHook {
  const { globalSearchState, globalSearchDispatch, baseSearchPath = '/search', baseSearchMapPath = '/search/map' } = props

  const location = useLocation()
  const dispatch = useAppDispatch()

  const saveOccupancies = useCallback(() => {
    const {
      recentOccupancies,
      occupancies,
    } = globalSearchState

    // Filter out item if it's already been saved
    const recentData = recentOccupancies.filter(nextItem => JSON.stringify(nextItem) !== JSON.stringify(occupancies))

    if (recentData.length > 2) recentData.pop()

    recentData.unshift(occupancies)

    globalSearchDispatch({ type: GlobalSearchStateActions.SET_RECENT_OCCUPANCIES, occupancies: recentData })
    saveRecentRooms(recentData)
  }, [globalSearchState, globalSearchDispatch])

  const toggleOccupancyErrors = useCallback((shown: boolean) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.TOGGLE_ROOM_ERRORS, shown })
  }, [globalSearchDispatch])

  const previousSearchPlaceId = usePrevious(globalSearchState.searchItem?.value)

  const filters = useAppSelector(state => getUrlOfferListFilters(state))

  const generateSearchString = useCallback((globalSearchState: GlobalSearchState): string => {
    const {
      checkinDate,
      checkoutDate,
      flexibleMonths,
      flexibleNights,
      searchItem,
      occupancies,
      isFlexibleDateSelected,
      userSelectedFlexibleMonths,
    } = globalSearchState

    let dates
    if (checkinDate && checkoutDate) {
      dates = convertDatesToString(ISO_DATE_FORMAT, checkinDate, checkoutDate)
    }

    const flexibleMonthsToSearch = addFlexibleMonthsIfNoneSelected(flexibleNights, flexibleMonths)

    return encodeSearchParams({
      urlSearch: location.search,
      searchItem: searchItem ?? ANYWHERE_SEARCH_ITEM,
      dates,
      rooms: occupancies,
      filters: previousSearchPlaceId === searchItem?.value && searchItem?.searchType !== 'bounds' ? filters : undefined,
      isFlexibleWithDate: isFlexibleDateSelected,
      flexibleNights,
      flexibleMonths: flexibleMonthsToSearch,
      userSelectedFlexibleMonths,
    }).toString()
  }, [location.search, previousSearchPlaceId, filters])

  const searchKey = useMemo(() => {
    const searchStr = generateSearchString(globalSearchState)
    return getSearchKey(parseSearchString(searchStr))
  }, [globalSearchState, generateSearchString])

  const searchId = useAppSelector(state => state.search.searches[searchKey]?.searchId)

  const onSearch = useCallback(() => {
    const {
      checkinDate,
      checkoutDate,
      flexibleNights,
      occupancies,
      searchItem,
      isAnytimeDateSelected,
      searchVerticals,
      eventAnalytics,
    } = globalSearchState

    if (occupancies.length === 0 || isRoomsInvalid(occupancies)) {
      toggleOccupancyErrors(true)
      return
    }
    saveOccupancies()

    if (checkinDate && checkoutDate) {
      saveLastDates({ checkinDate, checkoutDate })
    }

    const shouldNavigateToMapResults = location.pathname.includes('/map')

    const urlOfferId = getQueryParam(location.search, queryKeyUrlOfferId)
    const urlOfferType = getQueryParam(location.search, queryKeyUrlOfferType)

    const searchStr = generateSearchString(globalSearchState)

    const newSearchId = uuidV4()
    const searchContext = mapGlobalSearchContextToSnowplowSearchEvent({
      searchItem,
      occupancies,
      isAnytimeDateSelected,
      searchVerticals,
      flexibleNights,
      checkinDate,
      checkoutDate,
      eventAnalytics: { searchId: searchId ?? newSearchId },
    })

    if (!searchId) {
      dispatch(setSearchId(searchKey, { newSearchId, searchContext: mapSearchContext(searchContext) }))
    }

    if (eventAnalytics.contextLocation) {
      Analytics.trackEvent(searchEventWithContext(
        eventAnalytics.contextLocation,
        eventAnalytics.searchItemCategory,
        mapGlobalSearchContextToSnowplowSearchEvent({
          searchItem,
          occupancies,
          isAnytimeDateSelected,
          searchVerticals,
          flexibleNights,
          checkinDate,
          checkoutDate,
          eventAnalytics: { searchId: searchId ?? newSearchId },
        }),
      ))
    }

    let url = shouldNavigateToMapResults ? baseSearchMapPath : baseSearchPath
    if (urlOfferId && urlOfferType) {
      url = getHotelOfferUrl(urlOfferId, urlOfferType as App.OfferType)
    }
    dispatch(pushWithRegion(url, searchStr))
    addGTMEvent(searchFormSubmit())
    if (flexibleNights) {
      addGTMEvent(flexibleSearchFormSubmit())
    }
  }, [globalSearchState, saveOccupancies, location.pathname, location.search, generateSearchString, dispatch, searchKey, baseSearchMapPath, baseSearchPath, toggleOccupancyErrors, searchId])

  const setRecentOccupancies = useCallback((occupancies: Array<Array<App.Occupants>>) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_RECENT_OCCUPANCIES, occupancies })
  }, [globalSearchDispatch])

  useEffect(() => {
    setRecentOccupancies(loadRecentRooms())
  }, [setRecentOccupancies])

  return useMemo(() => ({
    context: { dispatch: globalSearchDispatch, state: globalSearchState },
    onSearch,
  }), [globalSearchDispatch, globalSearchState, onSearch])
}

export default useGlobalSearchVerticalTabBooking
