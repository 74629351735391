import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import getPartnershipModifierAmount from './getPartnershipModifierAmount'
import getDiscountTotal from './getDiscountTotal'
import getPayableTotal from './getPayableTotal'

const getPayableAfterModifiers = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => getDiscountTotal(state),
  (state: App.State) => getPartnershipModifierAmount(state),
  (payableTotal, promoDiscountAmount, partnershipModifierAmount): number => {
    if (promoDiscountAmount > (payableTotal + partnershipModifierAmount)) {
      return 0
    }
    return floatify(payableTotal - promoDiscountAmount - partnershipModifierAmount)
  },
)

export default getPayableAfterModifiers
