import React, { useCallback, useContext } from 'react'

import { MAX_ROOM_LIMIT } from 'constants/offer'
import { connect } from 'react-redux'
import TextButton from 'components/Luxkit/Button/TextButton'
import TextLink from 'components/Luxkit/TextLink'
import SolidPlusIcon from 'components/Luxkit/Icons/solid/SolidPlusIcon'
import Caption from 'components/Luxkit/Typography/Caption'
import { pluralizeToString } from 'lib/string/pluralize'
import ModalContext from 'contexts/ModalContext'
import CruiseModalFormRequestPrivate from 'checkout/Components/Cruises/CheckoutV2/CabinTravelerSelection/CruiseRequestCallbackFormModal'
import { getSupportContact } from 'selectors/supportContactSelector'

interface Props {
  className?: string;
  contactNumber?: App.GeoContact;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  roomsCount: number;
  saleUnit?: string;
  maxRooms?: number;
  noServiceContact?: boolean;
  contactBoxStyle?: boolean;
  offerId?: string;
  departureDate?: string;
}

function AddRoomButton(props: Props) {
  const showModal = useContext(ModalContext)
  const {
    className,
    contactNumber,
    onClick,
    roomsCount,
    saleUnit,
    maxRooms = MAX_ROOM_LIMIT,
    noServiceContact,
    contactBoxStyle,
    offerId,
    departureDate,
  } = props
  const unit = saleUnit || 'room'
  const pluralizeSaleUnit = pluralizeToString(unit, maxRooms)

  const openFormRequestPrivateModal = useCallback(async(e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()
    await showModal<boolean>(<CruiseModalFormRequestPrivate offerId={offerId} departureDate={departureDate}/>)
  }, [departureDate, offerId, showModal])

  return (
    <div className={className} data-testid="add-room-button">
      {roomsCount < maxRooms &&
        <TextButton kind="secondary" variant="dark" startIcon={<SolidPlusIcon />} onClick={onClick}>
          Add another {unit}
        </TextButton>
      }
      {(roomsCount >= maxRooms && !noServiceContact && contactNumber) && <>
        {!contactBoxStyle && <Caption variant="medium" weight="bold">
          <>
            For bookings of more than {pluralizeSaleUnit} please contact our customer service team on{' '}
            <TextLink href={`tel:${contactNumber.number}`} rel="nofollow">
              {contactNumber.humanReadable}
            </TextLink>.
          </>
        </Caption>}

        {contactBoxStyle && <TextButton
          onClick={openFormRequestPrivateModal}
          kind="secondary"
          size="medium"
          startIcon={<SolidPlusIcon/>}
          variant="dark">
          Add another cabin
        </TextButton>}
      </>}
    </div>
  )
}

function mapStateToProps(state: App.State) {
  return {
    contactNumber: getSupportContact(state).defaultContact,
  }
}

export default connect(mapStateToProps)(AddRoomButton)
