import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import BookmarkButton from 'tripPlanner/components/Bookmark/BookmarkButton'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import React, { useCallback, useContext, useMemo } from 'react'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import TextButton from 'components/Luxkit/Button/TextButton'
import TextLink from 'components/Luxkit/TextLink'
import useCruiseOfferView from 'hooks/Cruise/useCruiseOfferView'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import { HeroCruiseLocation } from './components/HeroCruiseLocation'
import CruiseTilePrices from 'components/Cruises/CruiseTiles/Common/CruiseTilePrices'
import OfferTilePricing from 'components/OfferList/OfferListTiles/Support/OfferTilePricing'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import SolidFireIcon from 'components/Luxkit/Icons/solid/SolidFireIcon'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import ModalContext from 'contexts/ModalContext'
import CruiseModalPrices from 'components/Cruises/CruiseTiles/Common/CruiseModalPrices/CruiseModalPrices'
import CruiseModalItineraryDetails from 'components/Cruises/CruiseTiles/Common/CruiseModalItineraryDetails'
import { isCruiseOffer, isCruiseV1Offer } from 'lib/offer/offerTypes'
import { useCruiseOfferInclusions } from 'hooks/Cruise/useCruiseOfferInclusions'
import CruiseLuxPlusOnboardCreditLabel from 'components/Cruises/CruiseInclusion/CruiseLuxPlusOnboardCreditLabel'

const DetailContainer = styled(Group)`
  height: 100%;
  padding-top: ${rem(16)};
  background-color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaQueryUp.desktop} {
    flex: 1;
    padding: ${rem(16)} ${rem(32)} ${rem(32)} ${rem(32)};
  }
`
const MinHeightGroup = styled(Group)`
  min-height: ${rem(300)};
  flex-grow: 1;
`

interface Props {
  offer: App.CruiseOffer | App.TourOffer | App.TourOfferSummary;
}

function HeroCruiseOfferDetails(props: Props) {
  const { offer } = props
  const view = useCruiseOfferView({ offer })
  const {
    departureCity,
    destinationCity,
    promotionDetails,
    shipDetailsLabel,
    itineraryDetailsLabel,
    earliestDepartureDate,
    laterDates,
    isFlashOffer,
    isSpecialOffer,
    itinerary,
    itineraryFlash,
    offerTitle,
  } = view
  const showModal = useContext(ModalContext)

  const openItineraryModal = useCallback(async(e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    showModal(<CruiseModalItineraryDetails
      itinerary={itinerary}
      itineraryFlash={itineraryFlash}
      offerTitle={offerTitle}
      offer={offer}
    />)
  }, [itinerary, showModal, itineraryFlash, offerTitle, offer])

  const openPricesModal = useCallback(async(e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    e.stopPropagation()

    if (!isFlashOffer) {
      const departures = 'departuresSummary' in offer ? offer.departuresSummary : undefined
      await showModal<boolean>(<CruiseModalPrices
        offerTitle={offerTitle}
        offer={offer}
        departures={departures}/>)
    }
  }, [isFlashOffer, offer, offerTitle, showModal])

  const mainDeparture = useMemo(() => {
    if (!('mainDepartureDetails' in offer)) {
      return
    }

    return offer.mainDepartureDetails
  }, [offer])
  const { luxPlusInclusions } = useCruiseOfferInclusions({ departure: mainDeparture })

  return (
    <DetailContainer direction="vertical" gap={16}>
      <TripGuard>
        <CSSBreakpoint min="desktop">
          <Group direction="horizontal-reverse">
            <BookmarkButton offer={offer} />
          </Group>
        </CSSBreakpoint>
      </TripGuard>
      <MinHeightGroup direction="vertical" verticalAlign="space-between" fullHeight gap={24}>
        <VerticalSpacer gap={4}>
          <LabelGroup>
            {promotionDetails?.isNew && (
              <UrgencyLabel variant="positive">NEW</UrgencyLabel>
            )}
            {isSpecialOffer && (
              <PromotionLabel icon={<SolidFireIcon />} variant="premium">
                Special offer
              </PromotionLabel>
            )}
            {isCruiseV1Offer(offer) && <OfferLabels offer={offer} />}
          </LabelGroup>
          <CruiseLuxPlusOnboardCreditLabel luxPlusInclusions={luxPlusInclusions}/>

          <HeroCruiseLocation departureCity={departureCity} destinationCity={destinationCity} />
          <Group direction="vertical" gap={12}>
            <Heading variant="heading6" lineClamp={3} colour="neutral-one">{offer.name}</Heading>

            <Group direction="vertical" gap={2}>
              <BodyText variant="medium" colour="neutral-two">
                {shipDetailsLabel}
              </BodyText>

              <Group gap={8} direction="horizontal" verticalAlign="center" wrap="wrap">
                <BodyText variant="medium" colour="neutral-two">
                  {itineraryDetailsLabel}
                </BodyText>

                {(itinerary || isFlashOffer) && (
                  <TextLink weight="regular" size="caption" onClick={openItineraryModal}>
                    <CSSBreakpoint min="desktop">View itinerary</CSSBreakpoint>
                    <CSSBreakpoint max="tablet"><SolidInfoCircleIcon size="XS" /></CSSBreakpoint>
                  </TextLink>
                )}
              </Group>
            </Group>
          </Group>
          <BodyText variant="medium" colour="neutral-two" weight="bold">
            {earliestDepartureDate && `Departing on ${earliestDepartureDate}`}{' '}
            {isFlashOffer && !!laterDates.length && <TextLink>
              and more
            </TextLink>}
            {!isFlashOffer && !!laterDates.length && <TextLink onClick={openPricesModal}>
              and more
            </TextLink>}
          </BodyText>
        </VerticalSpacer>
        <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
          {isCruiseOffer(offer) && <CruiseTilePrices view={view} isStandalone />}

          {isCruiseV1Offer(offer) && !offer.bundledWithFlightsOnly && offer.lowestPricePackage && (
            <OfferTilePricing
              offer={offer}
              pkg={offer.lowestPricePackage}
              offerUrl=""
            />
          )}

          <CSSBreakpoint min="tablet">
            <TextButton fit="desktop-full-width" kind="primary" nonInteractable>View offer</TextButton>
          </CSSBreakpoint>
        </Group>
      </MinHeightGroup>
    </DetailContainer>
  )
}

export default HeroCruiseOfferDetails
