import { useAppSelector } from 'hooks/reduxHooks'
import { getIsEnabledLuxPlusCruiseInclusions, isInsuranceAndRefundProtectionEnabled } from 'luxPlus/selectors/featureToggle'

function useEnabledLuxPlusBenefits(benefits?: Array<App.OfferSubscriptionBenefitItem | App.MemberSubscriptionBenefit>) {
  const areInsuranceAndBookingProtectEnabled = useAppSelector(isInsuranceAndRefundProtectionEnabled)
  const areCruiseInclusionsEnabled = useAppSelector(getIsEnabledLuxPlusCruiseInclusions)

  if (!benefits) return []

  const excludedBenefitTypes: Array<App.LuxPlusBenefitType> = []
  !areCruiseInclusionsEnabled && excludedBenefitTypes.push('cruise_inclusions')
  !areInsuranceAndBookingProtectEnabled && excludedBenefitTypes.push('discounted_insurance')

  return excludedBenefitTypes.length ? benefits.filter(benefit => !excludedBenefitTypes.includes(benefit.type)) : benefits
}

export default useEnabledLuxPlusBenefits
