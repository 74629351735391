import { objectEntries } from 'lib/object/objectUtils'

interface BreakpointSize {
  min: number
  max: number
}

export const breakpointEntries: Array<[bp: App.ScreenSize, sizes: BreakpointSize]> = [
  ['mobile', { min: 1, max: 575.98 }], // was xxs and xs
  ['tablet', { min: 576, max: 991.98 }], // was sm
  ['desktop', { min: 992, max: 1199.98 }], // was md
  ['largeDesktop', { min: 1200, max: Number.MAX_SAFE_INTEGER }], // was lg and xl
]

export const breakpointSizes = Object.fromEntries(breakpointEntries) as Record<App.ScreenSize, BreakpointSize>

type BreakpointConfig = Partial<Record<App.ScreenSize, Partial<BreakpointSize>>>
type MediaQueriesUp = Record<Exclude<App.ScreenSize, 'mobile'>, string>

export function buildMediaQueryUp(sizes: BreakpointConfig) {
  return objectEntries(sizes).reduce<MediaQueriesUp>((acc, [bp, value]) => {
    if (bp !== 'mobile' && value?.min) {
      acc[bp] = `@media (min-width: ${value.min}px)`
    }
    return acc
  }, { tablet: '', desktop: '', largeDesktop: '' })
}

type MediaQueriesDown = Record<Exclude<App.ScreenSize, 'largeDesktop'>, string>
export function buildMediaQueryDown(sizes: BreakpointConfig) {
  return objectEntries(sizes).reduce<MediaQueriesDown>((acc, [bp, value]) => {
    if (bp !== 'largeDesktop' && value?.max) {
      acc[bp] = `@media (max-width: ${value.max}px)`
    }
    return acc
  }, { mobile: '', tablet: '', desktop: '' })
}

type MediaQueriesOnly = Record<App.ScreenSize, string>
export function buildMediaQueryOnly(sizes: BreakpointConfig) {
  return objectEntries(sizes).reduce<MediaQueriesOnly>((acc, [bp, value]) => {
    if (value?.min && value.max) {
      acc[bp] = `@media (min-width: ${value.min}px) and (max-width: ${value.max}px)`
    }
    return acc
  }, { mobile: '', tablet: '', desktop: '', largeDesktop: '' })
}

export const mediaQueryUp = buildMediaQueryUp(breakpointSizes)
export const mediaQueryDown = buildMediaQueryDown(breakpointSizes)
export const mediaQueryOnly = buildMediaQueryOnly(breakpointSizes)
