import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import { getExperienceItemsView, getTransferItemsView } from 'checkout/selectors/view/experience'
import { getExperienceLocations } from './itemContextHelpers'
import {
  getCategoryAndProductId,
  getCruiseTourV2OrExperienceOffer,
  getExperienceProductIdFromProvider,
  getExperienceProviderFromOffer,
} from 'analytics/snowplow/helpers/itemCategorisation'

function getExperienceCheckoutItem(state: App.State, item: App.Checkout.TransferItem | App.Checkout.ExperienceItem) {
  const offerId = item.experienceId

  const offer = getCruiseTourV2OrExperienceOffer(state, offerId) as App.ExperienceOffer

  const { categoryId } = getCategoryAndProductId('experience', offer)
  const productId = getExperienceProductIdFromProvider(offer.provider)
  const finalProductId = productId || getExperienceProductIdFromProvider(getExperienceProviderFromOffer(offerId))
  const locations = getExperienceLocations(offer?.location?.name)
  const destinationCity = locations.destinationCity
  const originCity = locations.originCity
  const destinationCountry = locations.destinationCountry
  const originCountry = locations.originCountry

  const experienceDate = item.itemType === 'experience' ? item.date : item.transfer.date

  const experienceItems = getExperienceItemsView(state)
  const transferItems = getTransferItemsView(state)

  const baseProps = {
    offerId,
    categoryId,
    productId: finalProductId,
    duration: 1,
    travelStart: experienceDate,
    travelEnd: experienceDate,
    offerLeadPrice: offer?.price,
    offerLeadDuration: '1',
    offerLeadUnit: offer?.ticketUnitLabel,
    currency: state.geo.currentCurrency,
    destinationCity,
    destinationCountry,
    originCity,
    originCountry,
    itemId: item.itemId,
  }

  const experienceItemsMapped = experienceItems.data.map((experienceItem) => {
    return comluxgroup.createItem_1_1_0(
      {
        ...baseProps,
        price: experienceItem.totals.price,
        value: experienceItem.totals.value,
      },
    )
  })
  const transferItemsMapped = transferItems.data.map((transferItemView) => {
    return comluxgroup.createItem_1_1_0(
      {
        ...baseProps,
        price: transferItemView.item.transfer.option?.price,
      },
    )
  })
  if (experienceItemsMapped.length === 0 && transferItemsMapped.length === 0) return [comluxgroup.createItem_1_1_0(baseProps)]
  return [...experienceItemsMapped, ...transferItemsMapped]
}

export default getExperienceCheckoutItem
