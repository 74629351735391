import React, { FormEventHandler, useCallback, useContext, useEffect, useRef, useState } from 'react'
import TextInput from 'components/Common/Form/Input/TextInput'
import MessageBanner from 'components/Luxkit/Banners/MessageBanner'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { formToObject } from 'lib/forms/formToObject'
import { checkUserExists, register } from 'actions/AuthActions'
import TextButton from 'components/Luxkit/Button/TextButton'
import CheckboxInput from 'components/Luxkit/Checkbox/CheckboxInput'
import BodyText from 'components/Luxkit/Typography/BodyText'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'
import PhoneInput from 'components/Common/Form/Input/PhoneInput'
import LegalText from './AccountAccessLegalText'
import config from 'constants/config'
import Group from 'components/utils/Group'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import noop from 'lib/function/noop'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { AccountAccessModalResult } from './AccountAccessModal'
import * as Analytics from 'analytics/analytics'
import GeoContext from 'contexts/geoContext'
import ReCAPTCHA from 'react-google-recaptcha'
import { formatPhoneNumber, sanitisePhoneNumber } from 'lib/format/formatPhoneNumber'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { mockPointsAndCreditsEarned } from 'luxLoyalty/lib/utils'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import LuxLoyaltyCopyListItem from 'luxLoyalty/components/LuxLoyaltyCopyListItem'

interface JoinForm {
  email: string;
  password: string;
  givenName: string;
  firstName: string;
  phoneNumber?: App.PhoneNumber;
  postcode: string;
}

interface Props {
  user: App.User;
  onModeChange?: (mode: App.UiAccountModalMode, userId?: string) => void;
  dismissable?: boolean;
  hotelOfferDetails?: App.AccountModalOfferDetails;
}

function AccountAccessJoin(props: Props) {
  const {
    user,
    onModeChange = noop,
    dismissable,
    hotelOfferDetails,
  } = props

  const modalContext = useModalElementContext<AccountAccessModalResult>()
  const firstInput = useRef<HTMLInputElement>(null)
  const loggedIn = useAppSelector(selectLoggedIn)
  const { currentRegionCode } = useContext(GeoContext)
  const error = useAppSelector(state => state.auth.error)
  const reRef = useRef<ReCAPTCHA>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const [emailChecked, setEmailChecked] = useState<string>('')
  const dispatch = useAppDispatch()
  const checkedUser = useAppSelector(state => state.auth.users[emailChecked])
  const luxLoyaltyEnabled = useAppSelector(getIsLuxLoyaltyEnabled)
  const { orderPointsEarned, statusCreditsEarned } = mockPointsAndCreditsEarned()

  // it's possible to sign up from an email OR a phone number
  const isEmailSignUp = !!user.email

  const registerUser = useCallback(() => {
    if (formRef.current) {
      const joinData = formToObject<JoinForm>(formRef.current)
      const phoneNumber = joinData.phoneNumber
      delete joinData.phoneNumber

      // email sign up has already validated the user exists, we can go straight to registering
      dispatch(register({
        ...joinData,
        ...(phoneNumber?.phone && {
          phone: phoneNumber.phone,
          phonePrefix: phoneNumber.prefix,
        }),
      }, isEmailSignUp ? 'email' : 'phone'))
    }
  }, [dispatch, isEmailSignUp])

  useEffect(() => {
    if (loggedIn) {
      modalContext?.resolve({ loggedIn: true })
    } else if (checkedUser) {
      if (checkedUser.exists) {
        onModeChange('joinExistingAccount', emailChecked)
      } else {
        registerUser()
      }
    } else {
      setEmailChecked('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, checkedUser])

  useEffect(() => {
    Analytics.trackClientEvent({
      subject: 'sign_up_details',
      action: 'impression',
      category: isEmailSignUp ? 'email' : 'phone',
      type: 'operational',
    })
    firstInput.current?.focus()
  }, [isEmailSignUp])

  const onJoin = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault()

    if (isEmailSignUp) {
      registerUser()
    } else {
      // registering with a phone number, first we need to validate the email
      const joinData = formToObject<JoinForm>(event.currentTarget)
      setEmailChecked(joinData.email)
      dispatch(checkUserExists({ email: joinData.email, phoneNumber: user.phoneNumber }, reRef.current))
    }
  }, [dispatch, isEmailSignUp, registerUser, user])

  const onBack = useCallback(() => onModeChange('login'), [onModeChange])

  return (
    <>
      <ModalHeader
        title="Welcome"
        onBackButtonClick={onBack}
        dismissible={dismissable}
      />
      <ModalBody>
        <ModalContent>
          <form name="joinForm" onSubmit={onJoin} ref={formRef}>
            <Group direction="vertical" gap={12}>
              <Group direction="vertical" gap={16}>
                {error?.message && <MessageBanner kind="critical" description={error.message} />}
                <BodyText variant="medium">
                  Let’s create an account for <b>{isEmailSignUp ? user.email : formatPhoneNumber(sanitisePhoneNumber(user.phoneNumber?.phone!), user.phoneNumber?.prefix)}</b>
                </BodyText>
                {luxLoyaltyEnabled && <VerticalSpacer gap={8}>
                  {hotelOfferDetails && <>
                    <LuxLoyaltyCopyListItem
                      tier="silver"
                      size="M"
                      type="points-and-status-credits"
                      earnablePoints={orderPointsEarned}
                      statusCreditsEarned={statusCreditsEarned}
                    />
                    <LuxLoyaltyCopyListItem tier="silver" size="M" type="unlock-status" />
                  </>}
                  {!hotelOfferDetails && <LuxLoyaltyCopyListItem tier="silver" size="M" type="welcome-gift" />}
                </VerticalSpacer>}
                <Group
                  direction="vertical"
                  tabletDirection="horizontal"
                  tabletHorizontalAlign="stretch"
                  gap={12}
                >
                  <TextInput
                    id="givenName"
                    required
                    type="text"
                    name="givenName"
                    placeholder="First Name"
                    autoComplete="given-name"
                    requiredErrorMessage="First name is required"
                    ref={firstInput}
                    label="First name"
                    pattern="^(?=.*[^\s])[^\.\$\%\&\(\)\*!,@#\d]*$"
                    invalidErrorMessage="Please enter a valid first name"
                  />
                  <TextInput
                    id="surname"
                    required
                    type="text"
                    name="surname"
                    placeholder="Last Name"
                    autoComplete="family-name"
                    requiredErrorMessage="Last name is required"
                    label="Last name"
                    pattern="^(?=.*[^\s])[^\.\$\%\&\(\)\*!,@#\d]*$"
                    invalidErrorMessage="Please enter a valid last name"
                  />
                </Group>
              </Group>
              {isEmailSignUp && <>
                <HiddenInput
                  id="email"
                  name="email"
                  value={user.email}
                />
                <PhoneInput
                  name="phoneNumber"
                  label="Phone number"
                  prefixCountry={currentRegionCode}
                />
              </>}
              {!isEmailSignUp && <>
                <TextInput
                  label="Email address"
                  id="email"
                  required
                  type="email"
                  name="email"
                  autoComplete="email"
                  placeholder="Email"
                  requiredErrorMessage="Email is required"
                  defaultValue={user.email}
                />
                <HiddenInput name="phoneNumber.phone" value={user.phoneNumber?.phone} />
                <HiddenInput name="phoneNumber.prefix" value={user.phoneNumber?.prefix} />
              </>}
              {config.signUp.showPostcode && (
                <TextInput
                  id="postcode"
                  required
                  type="text"
                  name="postcode"
                  placeholder="Postcode"
                  autoComplete="off"
                  requiredErrorMessage="Postcode is required"
                  label="Postcode"
                />
              )}
              {/* Chrome was autofilling the field above password with a username.
              This HiddenInput is a hacky way to prevent that.
              https://stackoverflow.com/questions/23156578/google-chrome-autofilling-all-password-inputs */}
              <HiddenInput type="text" />
              <TextInput
                id="password"
                required
                type="password"
                name="password"
                placeholder="Password"
                minLength={8}
                autoComplete="new-password"
                requiredErrorMessage="Password is required"
                label="Create password"
              />
              <CheckboxInput
                name="agreeTandC"
                data-testid="terms-and-privacy-agree-checkbox"
                required
              >
                <LegalText />
              </CheckboxInput>
              <TextButton
                kind="primary"
                fit="flex"
                type="submit"
                size="large"
              >
                Create account
              </TextButton>
            </Group>
          </form>
          {config.RECAPTCHA_KEY && <ReCAPTCHA size="invisible" sitekey={config.RECAPTCHA_KEY} ref={reRef} />}
        </ModalContent>
      </ModalBody>
    </>
  )
}
export default AccountAccessJoin
