import TextLink from 'components/Luxkit/TextLink'
import React, { Fragment, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import SmallToggleButton from 'components/Luxkit/Button/SmallToggleButton'
import { rem } from 'polished'
import offerPageURL from 'lib/offer/offerPageURL'
import MarkdownRender from 'components/Luxkit/MarkdownRender'
import FormatCurrency from 'components/Common/FormatCurrency'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { isBedbankOffer } from 'lib/offer/offerTypes'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import Group from 'components/utils/Group'
import { EmptyArray, sum, unique } from 'lib/array/arrayUtils'
import VerticalSpacer from '../Spacing/VerticalSpacer'
import Divider from 'components/Luxkit/Divider'
import SlideDown from 'components/utils/SlideDown'
import config from 'constants/config'
import { useAppSelector } from 'hooks/reduxHooks'
import { LUXURY_PLUS } from 'luxPlus/constants/base'
import Heading from 'components/Luxkit/Typography/Heading'
import useAgentHubCommission from 'agentHub/hooks/useAgentHubCommission'
import useToggle from 'hooks/useToggle'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import PriceSummaryRowItemDescription from 'components/Luxkit/PriceSummaries/PriceSummaryRowItemDescription'
import Plural from 'components/utils/Formatters/Plural'
import PriceSummaryRowItemSubtitle from 'components/Luxkit/PriceSummaries/PriceSummaryRowItemSubtitle'
import FormatPercent from 'components/utils/Formatters/FormatPercent'
import PriceSummaryRowItemDescriptionDiscount from 'components/Luxkit/PriceSummaries/PriceSummaryRowItemDescriptionDiscount'

const PriceDetailsModalExtraDesc = styled.div`
  width: 100%;
  background-color: ${props => props.theme.palette.brand.primary.lightest};
  padding: ${rem(12)};
  margin-top: ${rem(24)};
`

const PaymentSchedule = styled(Group)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(16)};
`

interface Props {
  offerPage?: boolean;
  offer: App.Offer | App.BedbankOffer | App.OfferSummary | App.BedbankOfferSummary;
  duration: number;
  taxesAndFees?: number;
  propertyFees?: number;
  /** The price **without property fees** */
  hotelPrice: number;
  /** The member price **without property fees** */
  hotelMemberPrice?: number;
  showMemberPrice?: boolean;
  flightPrice?: number;
  otherFees?: {
    propertyFees: number;
    extraGuestsSurcharge: number;
  }
  rooms?: Array<App.Occupants>;
  extraGuestMessage?: string;
  dueAtPropertyMessage?: string;
  propertiesFees?: Array<App.PropertyFeesDetails>;
  cta?: React.ReactNode;
  onCtaAfterClose?: () => void;
  hasMobilePromotion?: boolean
}

function PriceDetailsModal({
  offerPage,
  offer,
  duration,
  taxesAndFees = 0,
  propertyFees = 0,
  propertiesFees = EmptyArray,
  hotelPrice,
  flightPrice = 0,
  rooms,
  extraGuestMessage,
  dueAtPropertyMessage,
  cta,
  hotelMemberPrice = 0,
  showMemberPrice,
  otherFees,
  hasMobilePromotion,
}: Props) {
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const enabledShowMemberPrice = showMemberPrice && luxPlusEnabled

  const [expanded, expand] = useToggle(false)

  const { resolve } = useModalElementContext<'fine-print' | undefined>()

  const dueAtProperty = (otherFees?.propertyFees || 0) + (otherFees?.extraGuestsSurcharge || 0)
  const priceBeforeTax = hotelPrice - taxesAndFees - dueAtProperty + flightPrice
  const priceAfterTax = hotelPrice + flightPrice
  const memberPriceAfterTax = hotelMemberPrice + flightPrice

  const adultsNumber = useMemo(() => sum(rooms ?? [], room => room.adults) || 2, [rooms])

  const offerLocations = useMemo(() => {
    if (isBedbankOffer(offer)) {
      const locations = [
        offer.property?.address.city,
        offer.property?.address.stateProvinceName || '',
        offer.property?.address.countryName]
        .filter(Boolean)
      return locations
    }

    const locations = unique(offer.locations.concat(offer.location))

    return locations
  }, [offer])

  const vendorName = useMemo(() => {
    if (!isBedbankOffer(offer)) {
      return offer.vendorName
    }

    return ''
  }, [offer])

  const { commissionAmount } = useAgentHubCommission({
    productType: offer.productType,
    offerId: offer.id,
    offerLocations,
    vendorName,
  })
  const customerView = useAppSelector(state => state.agentHub.customerView)
  const showAgentView = config.agentHub.isEnabled && !customerView && !!commissionAmount

  const total = priceAfterTax + propertyFees
  const memberTotal = memberPriceAfterTax + propertyFees
  const commissionTotal = total * (commissionAmount / 100)

  const onFinePrint = useCallback(() => resolve('fine-print'), [resolve])

  const shouldDisplayPricePerPerson = isBedbankOffer(offer) && offer.displayPricingAsPerPerson

  return <ModalBase size="L">
    <ModalHeader title="Price details" />
    <ModalBody>
      <ModalContent>
        <Group direction="vertical" gap={12}>
          <div>
            <PriceSummaryRowItemSubtitle
              label={<>
                <Plural singular="night" count={duration} withCount />
                {' '}
                {shouldDisplayPricePerPerson && <Plural singular="person" count={adultsNumber} withCount />}
                {!shouldDisplayPricePerPerson && <Plural singular={offer.saleUnit} count={rooms?.length || 1} withCount />}
              </>}
              value={priceBeforeTax}
              valueFormat="roundedDollar"
            />
            {duration > 1 && <PriceSummaryRowItemDescription
              label="Average per night"
              value={priceBeforeTax / duration}
              valueFormat="roundedDollar"
            />}
          </div>
          {hasMobilePromotion && <>
            <Divider direction="horizontal" kind="secondary" />
            <VerticalSpacer gap={4}>
              <PriceSummaryRowItemDescriptionDiscount label="Mobile only price" />
              <PriceSummaryRowItemDescription label="You're getting a discounted price only available on mobile and tablet devices" />
            </VerticalSpacer>
          </>}
          {taxesAndFees > 0 && <>
            <Divider direction="horizontal" kind="secondary" />
            <VerticalSpacer gap={4}>
              <PriceSummaryRowItemDescription
                label="Taxes and fees"
                value={taxesAndFees}
                valueFormat="roundedDollar"
              />
              <SlideDown show={expanded} animateOpacity>
                <BodyText variant="medium">
                  This charge includes estimated amounts the travel service provider pays for their taxes, and/or taxes that we pay, to taxing
                  authorities on your booking (including but not limited to sales, occupancy, and value-added tax). This amount may also include
                  any amounts charged to us for resort fees, cleaning fees, and other fees and/or a fee we, the website you booked on, retain as
                  part of the compensation for our and/or their services, which varies based on factors such as location, the amount, and how
                  you booked.
                  {isBedbankOffer(offer) && <>
                    For more details, please see the <TextLink weight="regular" size="large" to={`${offerPageURL(offer)}#fine-print`} target="_blank">Terms and Conditions</TextLink>.
                  </>}
                  {!isBedbankOffer(offer) &&
                    <>
                      Please refer to the {offerPage ? <TextLink weight="regular" onClick={onFinePrint}>‘Fine Print’</TextLink> : <TextLink weight="regular" to={`${offerPageURL(offer)}#fine-print`}>‘Fine Print’ </TextLink>} for any additional costs payable directly to the property.
                    </> }
                </BodyText>
              </SlideDown>
              <SmallToggleButton onClick={expand} on={expanded} size="small">
                {expanded ? 'Read Less' : 'Learn More'}
              </SmallToggleButton>
            </VerticalSpacer>
          </>}
          {!otherFees && propertyFees > 0 && propertiesFees.length === 0 && <>
            <Divider direction="horizontal" kind="secondary" />
            <PriceSummaryRowItemSubtitle
              label="Due at property"
              value={propertyFees}
              valueFormat="roundedDollar"
            />
          </>}
          {!otherFees && propertiesFees.length > 0 && <>
            <Divider direction="horizontal" kind="secondary" />
            <div>
              {propertiesFees.map((fee) => <Fragment key={fee.propertyName}>
                <PriceSummaryRowItemSubtitle
                  label="Local tax"
                  value={fee.propertyFees}
                  valueFormat="roundedDollar"
                />
                <PriceSummaryRowItemDescription label={`(Payable at ${fee.propertyName})`} />
              </Fragment>)}
            </div>
          </>}
          <Divider direction="horizontal" kind="secondary" />
          {showAgentView && <>
            <PriceSummaryRowItemSubtitle
              label={<>Total commission (<FormatPercent value={commissionAmount / 100} />)</>}
              value={commissionTotal * -1}
              valueFormat="roundedDollar"
            />
            <PriceSummaryRowItemSubtitle
              label="Gross total"
              value={total}
              valueFormat="roundedDollar"
            />
            <PriceSummaryRowItemSubtitle
              label="Net total"
              value={total - commissionTotal}
              valueFormat="roundedDollar"
            />
          </>}
          {!showAgentView && <>
            <PriceSummaryRowItemSubtitle
              label={enabledShowMemberPrice ? 'Total for non-members' : 'Total'}
              value={total}
              valueFormat="roundedDollar"
            />
            {enabledShowMemberPrice && <>
              <Divider direction="horizontal" kind="secondary" />
              <PriceSummaryRowItemDescriptionDiscount
                label={`${LUXURY_PLUS.PROGRAM_NAME} member discount`}
                discountValue={memberPriceAfterTax - priceAfterTax}
                discountValueFormat="roundedDollar"
              />
              <Divider direction="horizontal" kind="secondary" />
              <PriceSummaryRowItemSubtitle
                label={`Total for ${LUXURY_PLUS.PROGRAM_NAME} members`}
                value={memberTotal}
                valueFormat="roundedDollar"
              />
            </>}
          </>}
          <Divider direction="horizontal" kind="secondary" />
          {dueAtProperty > 0 &&
            <PaymentSchedule direction="vertical" gap={8}>
              <Group direction="horizontal" horizontalAlign="space-between">
                <Heading variant="heading6" colour="highlight-secondary" >Due on checkout</Heading>
                <Heading variant="heading6" colour="highlight-secondary" align="end">
                  <FormatCurrency value={total - dueAtProperty} format="preciseDollar" />
                </Heading>
              </Group>
              <Group direction="horizontal" horizontalAlign="space-between" className="dense">
                <BodyText variant="medium" weight="normal">Due at property on arrival</BodyText>
                <BodyText variant="medium" weight="normal" align="end">
                  <FormatCurrency value={dueAtProperty} format="preciseDollar" />
                </BodyText>
              </Group>
            </PaymentSchedule>
            }
          {extraGuestMessage && <PriceDetailsModalExtraDesc>
            <BodyText variant="large" weight="bold">Extra guest surcharges</BodyText>
            <MarkdownRender content={extraGuestMessage}/>
          </PriceDetailsModalExtraDesc>}
          {dueAtPropertyMessage && <PriceDetailsModalExtraDesc>
            <BodyText variant="large" weight="bold">Due at property</BodyText>
            <MarkdownRender content={dueAtPropertyMessage}/>
          </PriceDetailsModalExtraDesc>}
        </Group>
      </ModalContent>
    </ModalBody>
    {!!cta && <ModalFooter>
      <div onClick={() => resolve(undefined)}>
        {cta}
      </div>
    </ModalFooter>}
  </ModalBase>
}

export default PriceDetailsModal
