import PromotionLabel from 'components/Luxkit/Label/PromotionLabel'
import React from 'react'
import TourDiscountLabel from './TourDiscountLabel'
import TourV2LuxPlusUrgencyTag from './TourV2LuxPlusUrgencyTag'
import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import OfferUrgencyCountdownLabel from 'components/Common/Labels/OfferUrgencyCountdownLabel'
import { useAppSelector } from 'hooks/reduxHooks'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'
import config from 'constants/config'
import Typography from 'components/Luxkit/Typography/Typography'

type UrgencyLabelVariant = React.ComponentProps<typeof UrgencyLabel>['variant']
const urgencyLabelTypeToVariant: Utils.PartialRecord<App.UrgencyLabelType, UrgencyLabelVariant> = {
  left: 'critical2',
}

interface Props {
  label: App.OfferUrgencyLabel;
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary;
}

function TourV2UrgencyLabel(props: Props) {
  const { label, offer } = props

  const showPromoTag = useAppSelector(state => config.TOUR_PROMO_CODE_ENABLED && isEnabledForFeature(config.TOUR_PROMO_CODE_REGIONS, state.geo.currentRegionCode))

  switch (label.type) {
    case 'agenthub_exclusive':
      return <PromotionLabel variant="engagement">Agent exclusive</PromotionLabel>
    case 'sale':
      return <TourDiscountLabel key="sale" />
    case 'departuresale':
      return <TourDiscountLabel saleOnDepartures />
    case 'lux_plus_member_price':
      return <TourV2LuxPlusUrgencyTag offer={offer} variant="memberPrice" />
    case 'lux_plus_early_access':
      return <TourV2LuxPlusUrgencyTag offer={offer} variant="earlyAccess" />
    case 'lux_plus_general_release':
      if (!label.end) {
        return null
      }

      return <OfferUrgencyCountdownLabel
        endDate={label.end}
        isLuxPlusEarlyAccess
      />
    case 'promo_code':
      return showPromoTag ? <UrgencyLabel variant="critical2">
        <Typography format="propercase">
          {label.message}
        </Typography>
      </UrgencyLabel> : null
    default:
      const variant = urgencyLabelTypeToVariant[label.type] ?? 'positive'
      return <UrgencyLabel variant={variant}>
        {label.message}
      </UrgencyLabel>
  }
}

export default TourV2UrgencyLabel
