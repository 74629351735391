import OfferLabels from 'components/Common/Labels/OfferLabels'
import OfferBadgeLabel from 'components/Common/OfferBadgeLabel/OfferBadgeLabel'
import LabelGroup from 'components/Luxkit/Label/LabelGroup'
import OfferTilePricingUrgencyTags from 'components/OfferList/OfferListTiles/Support/OfferTilePricingUrgencyTags'
import { useSearchPrices } from 'hooks/Search/useSearchPrices'
import { useOfferInclusions } from 'hooks/useOfferInclusions'
import React, { useMemo } from 'react'

interface Props {
  bestPackage?: App.HotelPackage
  filters?: App.OfferListFilters
  offer: App.HotelOffer | App.HotelOfferSummary,
  showUrgencyTags?: boolean
}

function HotelSearchTileLabels({
  bestPackage,
  filters,
  offer,
  showUrgencyTags = true,
}: Props) {
  const { totalPrice, totalMemberPrice, showMemberPrice } = useSearchPrices({
    offer,
    pkg: bestPackage,
    filters,
    useRealSearchPrices: true,
  })

  const saveAmount = totalPrice - (totalMemberPrice || 0)

  const { luxPlusInclusions } = useOfferInclusions(
    bestPackage,
    {
      checkIn: filters?.checkIn,
      checkOut: filters?.checkOut,
    },
  )

  const searchTileLuxPlusInclusions = useMemo<Array<App.PackageInclusion>>(() => {
    if (offer.luxPlusTileInclusions?.length) {
      return offer.luxPlusTileInclusions
    }
    return luxPlusInclusions
  }, [luxPlusInclusions, offer.luxPlusTileInclusions])
  const showLuxPlusInclusions = !!searchTileLuxPlusInclusions.length

  const showMemberInclusionLabel = !!offer.luxPlus.hasMemberInclusions && !showLuxPlusInclusions

  return <LabelGroup className={HotelSearchTileLabels.name}>
    <OfferLabels
      offer={offer}
      saveAmount={showMemberPrice ? saveAmount : undefined}
      showMemberInclusionLabels={showMemberInclusionLabel}
      luxPlusInclusions={luxPlusInclusions}
    />
    {showUrgencyTags && <OfferTilePricingUrgencyTags offer={offer} filters={filters} />}
    {offer.badge && <OfferBadgeLabel badge={offer.badge} />}
  </LabelGroup>
}

export default HotelSearchTileLabels
