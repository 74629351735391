import React, { useCallback, useMemo, MouseEventHandler } from 'react'
import AvatarCircle from 'components/Common/AvatarCircle/AvatarCircle'
import ListItem from 'components/Luxkit/List/ListItem'
import Group from 'components/utils/Group'
import { connect } from 'react-redux'
import ListSubheader from 'components/Luxkit/List/ListSubheader'
import { useAppDispatch } from 'hooks/reduxHooks'
import useBusinessTravellerEmployeeQuery from 'businessTraveller/hooks/useEmployeeQuery'
import { EmptyArray, sortBy } from 'lib/array/arrayUtils'
import setSelectedBusinessTravellersAndGuests from 'actions/businessTraveller/setSelectedBusinessTravellerEmployeesAndGuests'
import Divider from 'components/Luxkit/Divider'
import { getBusinessTravellerSelectedTravellerState, saveBusinessTravellerSelectedTravellerState } from 'businessTraveller/storage/businessTravellerStorage'
import { resetCheckoutForm } from 'actions/CheckoutActions'
import { clearFormStateSnapshot } from 'storage/checkout'

interface EmployeeOption {
  id: string
  customerId: string | undefined
  firstName: string
  lastName: string
  email: string
  isSelected: boolean
}

interface MappedStateProps {
  businessAccount?: App.BusinessTraveller.CurrentBusinessAccount
  selectedTravellerEmployeeIds?: Array<string>
}

interface Props {
  searchTerm?: string
  onTravellerSelect: (selectedTravellerEmployeeIds: Array<string>) => void
}

function BusinessTravellerSelectContent(props: Props & MappedStateProps) {
  const { businessAccount, selectedTravellerEmployeeIds, searchTerm = '', onTravellerSelect } = props
  const { employees } = useBusinessTravellerEmployeeQuery()

  const employeeOptions = useMemo<Array<EmployeeOption>>(() => {
    if (!employees) {
      return []
    }

    const selectedIds = new Set(selectedTravellerEmployeeIds?.slice(0, 1) ?? [])
    const options: Array<EmployeeOption> = Object.values(employees).map<EmployeeOption>((employee) => {
      return {
        id: employee.id,
        customerId: employee.customerId,
        firstName: employee.firstName,
        lastName: employee.lastName,
        email: employee.email,
        isSelected: selectedIds.has(employee.id),
      }
    })

    return sortBy(options, (employee) => employee.firstName + employee.lastName, 'asc')
  }, [employees, selectedTravellerEmployeeIds])

  const dispatch = useAppDispatch()

  const searchActive = !!searchTerm

  const filteredEmployeeOptions = useMemo<Array<EmployeeOption>>(() => {
    if (employeeOptions.length && searchTerm) {
      return employeeOptions.filter((employee) => {
        return employee.firstName.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase()) ||
        employee.lastName.toLocaleLowerCase().startsWith(searchTerm.toLocaleLowerCase())
      })
    }
    return EmptyArray
  }, [employeeOptions, searchTerm])

  const handleEmployeeClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    const employeeId = event.currentTarget.dataset.employeeid
    const selectedEmployee = employeeOptions.find((employee) => employee.id === employeeId)

    // Because we are now saving the traveller form, we need to make sure its cleared when a new traveller is selected.
    dispatch(resetCheckoutForm())
    clearFormStateSnapshot()

    if (selectedEmployee) {
      const selected = [selectedEmployee.id]
      dispatch(setSelectedBusinessTravellersAndGuests(selected))
      // save to localstorage so persist on page refresh
      if (selectedEmployee.customerId) {
        saveBusinessTravellerSelectedTravellerState(selected)
      }
      onTravellerSelect(selected)
    }
  }, [dispatch, employeeOptions, onTravellerSelect])

  const isBusinessAccountSelected = useMemo<boolean>(() => {
    return !!selectedTravellerEmployeeIds?.find(id => id === businessAccount?.employee?.id)
  }, [businessAccount?.employee?.id, selectedTravellerEmployeeIds])

  const showFilteredEmployees = filteredEmployeeOptions.length > 0
  const showNoResults = searchActive && !showFilteredEmployees

  return (
    <Group direction="vertical" gap={12}>
      {!showFilteredEmployees && !showNoResults && (
        <>
          {!!businessAccount?.employee?.id &&
            <ListItem
              startIcon={
                <AvatarCircle
                  firstName={businessAccount?.employee?.firstName}
                  lastName={businessAccount?.employee?.lastName}
                  nonInteractable
                />}
              title={`${businessAccount?.employee?.firstName} ${businessAccount?.employee?.lastName}`}
              subtitle={businessAccount?.employee?.email}
              data-employeeid={businessAccount.employee.id}
              selected={isBusinessAccountSelected}
              onClick={handleEmployeeClick}
            />
          }
          <Divider kind="primary" />
          <ListSubheader>
            {businessAccount?.business?.name}'s team members {employeeOptions?.length}
          </ListSubheader>
          {employeeOptions
            ?.filter((employeeOption) => employeeOption.id !== businessAccount?.employee?.id)
            .map((employeeOption) => <ListItem
              key={employeeOption.id}
              startIcon={
                <AvatarCircle
                  firstName={employeeOption.firstName}
                  lastName={employeeOption.lastName}
                  nonInteractable
                />}
              title={`${employeeOption.firstName} ${employeeOption.lastName}`}
              subtitle={employeeOption.email}
              data-employeeid={employeeOption.id}
              selected={employeeOption.isSelected}
              onClick={handleEmployeeClick}
            />)}
        </>
      )}
      {showFilteredEmployees && (
        <div>
          {filteredEmployeeOptions.map((employeeOption) => <ListItem
            key={employeeOption.id}
            startIcon={<AvatarCircle
              firstName={employeeOption.firstName}
              lastName={employeeOption.lastName}
              nonInteractable
            />}
            title={`${employeeOption.firstName} ${employeeOption.lastName}`}
            subtitle={employeeOption.email}
            data-employeeid={employeeOption.id}
            selected={employeeOption.isSelected}
            onClick={handleEmployeeClick}
          />)}
        </div>
      )}
      {showNoResults && <ListSubheader>No results</ListSubheader>}
    </Group>
  )
}

export default connect<MappedStateProps, undefined, Props, App.State>((state) => {
  return {
    businessAccount: state.businessTraveller.currentBusinessAccount,
    selectedTravellerEmployeeIds: state.businessTraveller.selectedTravellerEmployeeIds.length > 0 ? state.businessTraveller.selectedTravellerEmployeeIds : getBusinessTravellerSelectedTravellerState()?.employeeIds,
  }
})(BusinessTravellerSelectContent)
