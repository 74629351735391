import { useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchOfferList, streamOfferList } from 'actions/OfferActions'
import arrayShuffle from 'lib/array/arrayShuffle'
import memoize from 'lib/memoize/memoize'
import getOfferListKey from 'lib/offer/offerListKey'
import { without } from 'lib/array/arrayUtils'
import { SetOptional } from 'type-fest'
import { disconnectSearchSocket } from 'search/initialiseSearchSocket'
import { SearchAnalyticsLocation, SearchAnalyticsItemType } from 'analytics/mapSnowplowSearchTracking'
import { useSearchAnalytics } from 'hooks/Analytics/useSearchAnalytics'
import { SearchContext_1_0_1 } from '@luxuryescapes/contract-data-event-schemas/lib/com.luxgroup'
import { omitKeys } from '../../lib/object/objectUtils'

export interface Options {
  /**
   * For when the offer list is no longer needed, but still needs to be called to avoid violating the Rules of Hooks
   * */
  disabled?: boolean;
  /**
   * Randomize the offer ids in the list
   * Will be stable for that set of offer ids
   */
  randomize?: boolean;
  /**
   * An array of offer ids to explicitly exclude from the results
   */
  excludedIds?: Array<string>;
  /**
   * Whether explicitly filter out sold-out offer ids from the list
   * This will use the meta-data to determine if they are sold out
   */
  filterSoldOut?: boolean;
  /**
   * Whether check the availability of the offer on the client side
   */
  clientCheckAvailability?: boolean;

  /**
   * Whether to fetch the offer list or just return existing offer list
   * It stops the fetches when the filters are changed unexpectedly
   * so that the issue could be exposed and fixed
   */
  noFetch?: boolean;
  streamResults?: boolean;
  offset?: number;
  searchId?: string;
  includeAllBedbankWithSales?: boolean;
  leaveSearchSocketOpen?: boolean;
}

export interface TrackingOptions {
  /**
   * Whether to disable tracking for this search
   */
  disable?: boolean;
  /**
   * The search state that were used to generate the this search
   */
  searchContext: SearchContext_1_0_1;
  contextLocation: SearchAnalyticsLocation;
  searchItemCategory?: SearchAnalyticsItemType;
  ignoreChangedAttributes?: Array<keyof App.OfferListFilters>;
}

/**
 * Memoize is used here as it provides a stable set of offer ids for that exact version of the array
 * This ensures that we always return the same randomised set throughout the life of the page
 */
const getRandomizedOfferIds = memoize((offerIds: Array<string>) => arrayShuffle(offerIds))

const emptyOfferList: SetOptional<App.OfferList, 'key'> = {
  offerIds: [],
  fetching: true,
  error: null,
}

const emptyDisabledOfferList: SetOptional<App.OfferList, 'key'> = {
  offerIds: [],
  fetching: false,
  error: null,
}

function useOfferList(
  filters: App.OfferListFilters | undefined,
  options: Options = {},
  trackingOptions?: TrackingOptions,
): App.OfferList {
  const {
    randomize,
    excludedIds,
    filterSoldOut,
    clientCheckAvailability,
    noFetch,
    streamResults,
    offset,
    searchId,
    leaveSearchSocketOpen,
  } = options

  const searchDisabled = options.disabled || !filters
  const dispatch = useAppDispatch()
  const { trackSearchEvent } = useSearchAnalytics(trackingOptions?.searchContext, {
    contextLocation: trackingOptions?.contextLocation ?? 'search-list',
    searchItemCategory: trackingOptions?.searchItemCategory,
  })

  const { listKey, trackedListKey } = useMemo(() => {
    if (filters) {
      return {
        listKey: getOfferListKey(filters),
        trackedListKey: getOfferListKey(omitKeys(trackingOptions?.ignoreChangedAttributes ?? [], filters)),
      }
    }
    return {
      listKey: '',
      trackedListKey: '',
    }
  }, [filters, trackingOptions?.ignoreChangedAttributes])

  const [tracked, setTracked] = useState<Set<string>>(new Set())

  useEffect(() => {
    if (searchDisabled || noFetch) {
      // Do nothing
    } else if (streamResults) {
      if (!trackingOptions?.disable && trackingOptions?.searchContext && !tracked.has(trackedListKey)) {
        trackSearchEvent()
        setTracked(prev => new Set([...prev, trackedListKey]))
      }
      dispatch(streamOfferList(filters, offset, searchId, { leaveSearchSocketOpen }))
    } else if (!streamResults) {
      dispatch(fetchOfferList(filters, {
        clientCheckAvailability,
      }))
    }
    // eslint-disable-next-line
  }, [listKey, searchDisabled])

  useEffect(() => {
    return () => {
      if (streamResults) {
        // On unmount of the useOfferList we disconnect the socket
        disconnectSearchSocket()
      }
    }
  }, [streamResults])

  const offerList = useAppSelector((state) => {
    if (searchDisabled) {
      return emptyDisabledOfferList
    }
    return state.offer.offerLists[listKey] ?? emptyOfferList
  })

  const metaData = useAppSelector(state => state.offer.searchResultMetadata.offerMetaData[listKey])

  const finalOfferList = useMemo(() => {
    let postExclusionIds = excludedIds ? without(offerList.offerIds, ...excludedIds) : offerList.offerIds

    if (filterSoldOut && metaData) {
      postExclusionIds = postExclusionIds.filter(id => {
        const offerData = metaData[id]
        // if we don't have any meta data on it, assume it's available
        return !offerData || offerData.available
      })
    }

    if (randomize) {
      return {
        ...offerList,
        key: offerList.key ?? listKey,
        offerIds: getRandomizedOfferIds(postExclusionIds),
      }
    }
    return {
      ...offerList,
      key: offerList.key ?? listKey,
      offerIds: postExclusionIds,
    }
  }, [excludedIds, offerList, filterSoldOut, metaData, randomize, listKey])

  return finalOfferList
}

export default useOfferList
