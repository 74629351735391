import request from 'api/requestUtils'
import qs from 'qs'
import { sortBy } from 'lib/array/arrayUtils'
import { definitions, paths } from '@luxuryescapes/contract-search'
import { tourV2PopularDestinationMap } from './mappers/TourV2/tourV2SearchMapper'
import switchFunc from 'lib/function/switchFunc'
import { mapSpecificPlace } from './mappers/Search/placeMap'
import { TourSearchResultResponse } from './offer'

type TypeaheadSearchResult = {
  format: {
    mainText: string;
    secondaryText?: string;
    imageId?: string;
  }
  destinationType?: string;
  id: string;
  name: string;
  searchType: App.SearchItem['searchType'];
  offerType?: App.OfferType;
  offerDiscount?: number;
  offerId?: string;
  hasTactical?: boolean;
  bedbankStatus?: string;
  bedbankHasPromotions?: boolean;
  fk_location?: string;
  country_code?: string | null;
}

export interface Destination {
  name: string;
  place_id: string;
}

// The airport code are the 3 characters after the open paren
function getAirportCode(str: string) {
  const openParenIndex = str.indexOf('(')
  return openParenIndex === -1 ? '' : str.substring(openParenIndex + 1, openParenIndex + 4)
}

function mapTypeaheadSuggestion(suggestion: TypeaheadSearchResult): App.SearchItem {
  return {
    searchType: suggestion.searchType,
    destinationType: suggestion.destinationType,
    value: suggestion.id,
    format: {
      mainText: suggestion.format.mainText,
      secondaryText: suggestion.format.secondaryText,
      imageId: suggestion.format?.imageId,
    },
    offerType: suggestion.offerType,
    offerDiscount: suggestion.offerDiscount,
    offerId: suggestion.offerId,
    hasTactical: suggestion.hasTactical,
    airportCode: suggestion.searchType === 'airport' ? getAirportCode(suggestion.format.mainText) : undefined,
    bedbankHasPromotions: suggestion.bedbankHasPromotions,
    ...(suggestion.fk_location && {
      locationId: suggestion.fk_location,
    }),
    countryCode: suggestion.country_code,
  }
}

export interface TypeaheadSuggestionsGetOptions {
  types?: Array<App.SearchPlaceType>,
  searchTypes?: Array<App.SearchItem['searchType']>,
  vertical?: App.SearchPlaceVertical,
  priority?: App.SearchPlacePriority,
  limit?: number,
  canViewLuxPlusBenefits?: boolean,
}
export async function getTypeaheadSuggestions(
  input: string,
  region: string,
  options: TypeaheadSuggestionsGetOptions,
): Promise<Array<App.SearchItem>> {
  const params = {
    region,
    type: options.types,
    vertical: options.vertical,
    ...(options.priority && { priority: options.priority }),
    limit: options.limit,
    isLuxPlusMember: options.canViewLuxPlusBenefits,
  }
  const queryString = qs.stringify(params)

  const typeaheadSuggestions = await request.get<App.ApiResponse<Array<TypeaheadSearchResult>>>(
    `/api/search/typeahead/${encodeURIComponent(input)}?${queryString}`,
  ).then(response => response.result.map(mapTypeaheadSuggestion))

  return options.searchTypes?.length ? typeaheadSuggestions.filter(s => options.searchTypes!.includes(s.searchType)) : typeaheadSuggestions
}

type GetPlaceBySlugResult = paths['/api/search/place/v1/slug-lookup/{slug}']['get']['responses']['200']['content']['application/json']['result']
export function getPlaceBySlug(input: string): Promise<App.Place> {
  return request.get<App.ApiResponse<GetPlaceBySlugResult>>(`/api/search/place/v1/slug-lookup/${input}`).then(response => ({
    id: response.result.placeId,
    name: response.result.name,
    canonicalName: response.result.nameFull,
    type: response.result.type as App.PlaceType,
  }))
}

type GetSpecificPlacesResult = paths['/api/search/place/v1/specific-places/{id}']['get']['responses']['200']['content']['application/json']['result']
export function getSpecificPlaces(placeId: string): Promise<Array<App.SpecificPlace>> {
  return request.get<App.ApiResponse<GetSpecificPlacesResult>>(`/api/search/place/v1/specific-places/${placeId}`).then(response => response.result.map(mapSpecificPlace))
}

type GetDestinationImageIdResult = paths['/api/search/place/v1/places/{placeId}/image']['get']['responses']['200']['content']['application/json']['result']
export function getDestinationImageId(placeId: string): Promise<GetDestinationImageIdResult> {
  return request.get<App.ApiResponse<GetDestinationImageIdResult>>(`/api/search/place/v1/places/${placeId}/image`).then(response => response.result)
}

interface ServerPlaceTwo {
  lePlaceId: string;
  lePlaceName: string;
}

export function getPlaceByLatLong(latitude: number, longitude: number, level: App.PlaceType = 'city'): Promise<App.Place> {
  return request.get<App.ApiResponse<ServerPlaceTwo>>(`/api/search/place/v1/type/${level}/lat/${latitude}/lng/${longitude}`).then(response => {
    return {
      id: response.result.lePlaceId,
      name: response.result.lePlaceName.split(',')[0],
      canonicalName: response.result.lePlaceName,
    }
  })
}

type GetPlaceByidResult = paths['/api/search/place/v1/places/{id}']['get']['responses']['200']['content']['application/json']['result']
export function getPlaceById(lePlaceId: string): Promise<App.Place> {
  return request.get<App.ApiResponse<GetPlaceByidResult>>(`/api/search/place/v1/places/${lePlaceId}`).then(response => {
    return {
      id: response.result.placeId,
      name: response.result.name,
      canonicalName: response.result.nameFull,
      type: response.result.type as App.PlaceType,
      countryCode: response.result.countryCode,
    }
  })
}

type BatchLePlaceDetails =
  paths['/api/search/place/v1/places']['get']['responses']['200']['content']['application/json']['result'];
export async function getBatchPlaceById(lePlaceIds: Array<string>): Promise<Array<App.Place>> {
  const response = await request.get<App.ApiResponse<BatchLePlaceDetails>>(`/api/search/place/v1/places?ids=${lePlaceIds}`)
  return response.result.map((place) => ({
    id: place.placeId,
    name: place.name,
    canonicalName: place.nameFull,
    type: place.type as App.PlaceType,
    countryCode: place.countryCode,
  }))
}

export async function getPlaceBoundingRadius(lePlaceId: string): Promise<App.Place> {
  const response =
    await request.get<App.ApiResponse<GetPlaceByidResult>>(`/api/search/place/v1/places?ids=${lePlaceId}&include=boundingRadius`)
  return {
    id: response.result[0].placeId,
    name: response.result[0].name,
    canonicalName: response.result[0].nameFull,
    countryCode: response.result.countryCode,
    type: response.result[0].type as App.PlaceType,
    ...(response.result[0].boundingRadius && {
      boundingRadius: {
        centre: {
          type: response.result[0].boundingRadius.centre.type,
          longitude: response.result[0].boundingRadius.centre.coordinates[0],
          latitude: response.result[0].boundingRadius.centre.coordinates[1],
        },
        radius: response.result[0].boundingRadius.radius,
      },
    }),
  }
}

const sortPlaceTypeValue = switchFunc<number, App.PlaceType>({
  city: 1,
  multi_city_vicinity: 2,
  province_state: 3,
  country: 4,
  continent: 5,
  high_level_region: 6,
},
Number.MAX_SAFE_INTEGER)

type GetAncestorsByPlaceByIdResult = paths['/api/search/place/v1/places/{id}/ancestors']['get']['responses']['200']['content']['application/json']['result']
export function getPlaceAncestorsById(placeId: string): Promise<Array<App.Place>> {
  return request.get<App.ApiResponse<GetAncestorsByPlaceByIdResult>>(`/api/search/place/v1/places/${placeId}/ancestors`).then(response => {
    const places = response.result.map(place => ({
      id: place.placeId,
      name: place.name,
      canonicalName: place.nameFull,
      type: place.type as App.PlaceType,
    }))

    return sortBy(places, place => sortPlaceTypeValue(place.type), 'asc')
  })
}

export function getPopularDestinations(type: 'domestic' | 'international', regionCode: string): Promise<Array<App.PopularDestination>> {
  return request.get<App.ApiResponse<Array<App.PopularDestination>>>(`/api/search/popular-destinations/${type}?region=${regionCode}`)
    // Show destinations with images first
    .then(response => sortBy(response.result, destination => !!destination.imageId, 'desc'))
}

export function getBreadcrumbs(propertyId: string, type: 'le' | 'bedbank', showAllLocations: boolean): Promise<Array<Destination>> {
  return request.get<App.ApiResponse<Array<Destination>>>(`/api/search/locations/breadcrumbs/${propertyId}?withPlaceId=true&type=${type}&showAllLocations=${showAllLocations}`).then(result => result.result ?? [])
}

export function getAlternativeOffers(propertyId: string, type: 'le' | 'bedbank', region: string): Promise<App.AlternativeOffers> {
  return request.get<App.ApiResponse<App.AlternativeOffers>>(`/api/search/search-alternative-offers/${propertyId}?region=${region}&type=${type}`).then(result => result.result)
}

export function reqToursPopularDestinations(regionCode: string) {
  return request.get<App.ApiResponse<Array<definitions['toursSearchTrendingDestinations']>>>(`/api/search/tour/v1/trending-destinations?region=${regionCode}`).then(result => result.result.map(tourV2PopularDestinationMap))
}

interface DestinationMaxSavingsResult {
  placeId: string;
  saveUpTo: number;
}

export function getDestinationMaxSavings(
  region: string,
  placeIds: Array<string>,
  filter: App.DestinationSelectedFilters,
  isLuxPlusMember: boolean,
) {
  const query = qs.stringify({
    region,
    placeIds: placeIds.join(','),
    ...(isLuxPlusMember && { isLuxPlusMember }),
  }, { indices: false })
  return request.get<App.ApiResponse<Array<DestinationMaxSavingsResult>>>(
    `/api/search/${filter}/v1/places/max-savings?${query}`,
  ).then(res => res.result)
}

export async function getHomesAndVillasDestinationMaxSavings(
  region: string,
  placeIds: Array<string>,
) {
  const offerTypes = 'rental,hotel,tactical_ao_hotel'
  const holidayTypesScoped = 'Homes & Villas'
  const query = qs.stringify({
    region,
    placeIds: placeIds.join(','),
    offerTypes,
    holidayTypesScoped,
  }, { indices: false })
  return request.get<App.ApiResponse<Array<DestinationMaxSavingsResult>>>(
    `/api/search/hotel/v1/places/max-savings?${query}`).then(res => res.result)
}

type GetTourFacetsParams = {
  placeId: string;
  region: string;
  category?: string;
  excludeIds?: string;
  luxPlusExclusive?: Array<string>;
  priceGte?: number;
  priceLte?: number;
  onSale?: boolean;
  tourLengthGte?: number;
  tourLengthLte?: number;
}
type GetTourFacetsResponse = paths['/api/search/tour/v1/facet/list']['get']['responses']['200']['content']['application/json']['result']
export async function getTourFacets(params: GetTourFacetsParams): Promise<App.Tours.TourSearchFacets> {
  const response = await request.get<App.ApiResponse<GetTourFacetsResponse>>(`/api/search/tour/v1/facet/list?${qs.stringify(params)}`)
  return {
    operators: response.result.operators.map((external) => ({ label: external.label, value: external.value, count: external.count })),
    locations: response.result.locations.map((external) => ({ label: external.label, value: external.value, count: external.count })),
    groupTypes: response.result.groupTypes.map((external) => ({ label: external.label, value: external.value, count: external.count })),
    offerTypes: response.result.offerTypes.map((external) => ({ label: external.label, value: external.value, count: external.count })),
    categories: response.result.categories.map((external) => ({ label: external.label, value: external.value, count: external.count })),
    offerTags: response.result.offerTags.map((external) => ({ label: external.label, value: external.value, count: external.count })),
    tourStyles: response.result.tourStyles.map((external) => ({ label: external.label, value: external.value, count: external.count })),
    luxPlusExclusive: response.result.luxPlusExclusive.map((external) => ({ label: external.label, value: external.value, count: external.count })),
    tourLength: {
      min: response.result.tourLength.min,
      max: response.result.tourLength.max,
    },
    numberOfPlacesVisited: {
      min: response.result.numberOfPlacesVisited.min,
      max: response.result.numberOfPlacesVisited.max,
    },
    price: {
      min: response.result.price.min,
      max: response.result.price.max,
    },
  }
}

export function hasValidLength(data?: Array<any> | string): boolean {
  return !!data && data.length > 0
}

export function isNotDefaultSort(sortBy?: string): boolean {
  return !!sortBy && sortBy !== 'recommended' && sortBy !== 'homepage' && sortBy !== 'vertical-tour'
}

export function getTourSearchListByFacet(params: App.OfferListFilters, region: string) {
  const queryParamsObj = {
    region,
    placeIds: hasValidLength(params.destinations) ? [...params.destinations!] : [params.destinationId],
    priceGte: params.priceGte,
    priceLte: params.priceLte,
    operators: params.operators,
    tourLengthGte: params.tourLengthGte,
    tourLengthLte: params.tourLengthLte,
    numberOfPlacesVisitedGte: params.numberOfPlacesVisitedGte,
    numberOfPlacesVisitedLte: params.numberOfPlacesVisitedLte,
    isFacet: 'true',
    offerType: 'tour_v2',
    sortBy: isNotDefaultSort(params.sortBy) ? params.sortBy : 'vertical',
    flexibleMonths: hasValidLength(params.flexibleMonths) ? params.flexibleMonths : undefined,
    flexibleNights: params.flexibleNights,
    isSmallGroup: params.isSmallGroup,
    isLargeGroup: params.isLargeGroup,
    isFamilyFriendly: params.isFamilyFriendly,
    isPrivateRequest: params.isPrivateRequest,
    productTypes: params.productTypes,
    categories: params.categories,
    onSale: params.onSale,
    luxPlusExclusive: params.luxPlusFeatures,
    isLuxPlusMember: params.isLuxPlusMember,
    showEnrichedResult: params.showEnrichedResult,
    tourStyles: params.tourStyles,
    agentHubExclusiveOffersOnly: params.agentHubExclusiveOffersOnly,
  }

  const filteredQueryParams = Object.fromEntries(Object.entries(queryParamsObj).filter(([, value]) => value !== undefined))
  const queryString = qs.stringify(filteredQueryParams, { arrayFormat: 'indices' })

  return request.get<TourSearchResultResponse>(`/api/search/tour/v1/list?${queryString}`)
}

export async function getPopularHotelFilters(region: string) {
  const query = {
    region,
    limit: 40,
  }
  return request.get<App.ApiResponse<definitions['popularFilterType']>>(`/api/search/hotel/v1/popular/filters?${qs.stringify(query)}`).then(res => res.result)
}

export function logSearchEvent<T extends {event: string}>(params: T) {
  return request.post<App.ApiResponse<null>, T>('/api/search/hotel/v1/log', params)
}

type GetGoogleAutocompleteTypes = paths['/api/search/place/v1/google/autocomplete/{input}']['get']
export type GoogleAutocompletePlaceItem = GetGoogleAutocompleteTypes['responses']['200']['content']['application/json']['result'][number]
export async function getAutocompleteSuggestions(input: string, query: Omit<GetGoogleAutocompleteTypes['parameters']['query'], 'brand'>): Promise<Array<GoogleAutocompletePlaceItem>> {
  const queryString = qs.stringify(query)

  return request.get<App.ApiResponse<Array<GoogleAutocompletePlaceItem>>>(`/api/search/place/v1/google/autocomplete/${input}?${queryString}`)
    .then(response => response.result)
}
