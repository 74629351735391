import { useAppSelector } from 'hooks/reduxHooks'
import { objectKeys } from 'lib/object/objectUtils'
import { useMemo } from 'react'

interface Props {
  productType: App.ProductType;
  offerId?: string;
  offerLocations?: Array<string>;
  vendorName?: string;
}

interface OfferCommissionParams {
  productType: App.ProductType;
  agent: string;
  agency: string;
  offer: string;
  location: Array<string>;
  vendor: string;
  affiliation: string;
  tier: string;
}

function checkIfOfferMatchCommissionConditions(commissionRule: App.AgentHubCommissionRule, offerData: OfferCommissionParams) {
  return objectKeys(commissionRule.conditions)
    .every((condition) => {
      if (condition === 'location') {
        return offerData.location?.some(location => commissionRule.conditions[condition]?.includes(location))
      } else if (condition === 'productType') {
        return commissionRule.conditions.productType?.includes(offerData.productType)
      }

      return commissionRule.conditions[condition]?.includes(offerData[condition])
    })
}

function useAgentHubCommission(props: Props): { commissionAmount: number } {
  const { productType, offerId, offerLocations, vendorName } = props
  const baseRules = useAppSelector(state => state.agentHub.agentCommissions.baseCommissions)
  const additionalRules = useAppSelector(state => state.agentHub.agentCommissions.additionalCommissions)
  const agentAccount = useAppSelector(state => state.agentHub.agentHubAccount.account)

  let leahProductType = productType

  const newTourProductTypes = ['classic_tour', 'deluxe_tour', 'ultra_lux_tour', 'signature_series_tour']
  if (newTourProductTypes.includes(productType)) {
    leahProductType = 'direct_tour'
  }
  const customerId = useAppSelector(state => state.auth.account.memberId)

  const offerCommissionParams: OfferCommissionParams = useMemo(() => {
    if (!agentAccount) {
      return {
        productType: leahProductType,
        agent: '',
        agency: '',
        offer: '',
        location: [''],
        vendor: '',
        affiliation: '',
        tier: '',
      }
    }

    return {
      productType: leahProductType,
      agent: customerId || '',
      agency: agentAccount.agencyId || '',
      offer: offerId || '',
      location: offerLocations?.length ? offerLocations : [''],
      vendor: vendorName || '',
      affiliation: agentAccount.affiliation || '',
      tier: agentAccount.agentTier,
    } }, [agentAccount, leahProductType, offerId, offerLocations, vendorName, customerId])

  const commissionAmount = useMemo(() => {
    let baseCommission = 0
    let additionalCommission = 0

    // Find the highest base commission that matches all conditions
    baseRules.forEach(commissionRule => {
      if (commissionRule.commissionPercentage < baseCommission) {
        return
      }

      const offerMatchAllConditions = checkIfOfferMatchCommissionConditions(commissionRule, offerCommissionParams)

      if (offerMatchAllConditions) {
        baseCommission = commissionRule.commissionPercentage
      }
    })

    // If base commission is 0, we don't need to check for additional commission
    // because the offer is not eligible for any commission
    if (baseCommission === 0) {
      return 0
    }

    // then add any additional commission that matches all conditions
    additionalRules.forEach(commissionRule => {
      const matchAllConditions = checkIfOfferMatchCommissionConditions(commissionRule, offerCommissionParams)

      if (matchAllConditions) {
        additionalCommission += commissionRule.commissionPercentage
      }
    })

    return baseCommission + additionalCommission
  }, [additionalRules, baseRules, offerCommissionParams])

  return { commissionAmount }
}

export default useAgentHubCommission
