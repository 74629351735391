import { checkoutAccommodationGroupingKey } from 'checkout/selectors/view/accommodation'
import { createSelector } from 'reselect'
import { groupBy, nonNullable } from 'lib/array/arrayUtils'
import { getInsuranceEnabledCheckoutItems } from '../view/accommodation'
import { mergeOccupants } from 'lib/offer/occupancyUtils'
import { getFlightItems } from '../view/flights'

const getOccupantsFromItems = <T extends { occupancy?: App.Occupants, occupants?: App.Occupants }>(
  items: Array<T>,
  groupingKey: (item: T) => string,
  getOccupants: (item: T) => App.Occupants | undefined,
) => {
  const itemsMap = groupBy(items, groupingKey)
  return Array.from(itemsMap.values()).map(itemList => {
    const occupants = nonNullable(itemList.map(getOccupants))
    return mergeOccupants(occupants)
  })
}

const getMultiItemOccupants = createSelector(
  (state: App.State) => getInsuranceEnabledCheckoutItems(state),
  (state: App.State) => getFlightItems(state),
  (items, flights) => {
    if (items.length === 0) return

    const accommodationOccupants = getOccupantsFromItems(
      items,
      checkoutAccommodationGroupingKey,
      item => item.occupancy,
    )

    const flightOccupants = getOccupantsFromItems(
      flights,
      item => item.itemId,
      item => item.occupants,
    )

    return [...accommodationOccupants, ...flightOccupants]
  },
)

export default getMultiItemOccupants
