import { isFlashHotelType, isLpcHotelType } from 'lib/offer/offerTypes'

/**
 * @returns mx i.e. encrypted margin
 */
function getHotelEncryptedMargin(
  offerType: App.OfferType,
  pkg: App.Package | undefined,
): string | undefined {
  if (isFlashHotelType(offerType)) return pkg?.mx
  if (isLpcHotelType(offerType)) return pkg?.roomRate?.mx
}

export default getHotelEncryptedMargin
