import * as Analytics from 'analytics/analytics'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { SearchAnalyticsLocation, SearchAnalyticsItemType } from 'analytics/mapSnowplowSearchTracking'
import { useCallback } from 'react'
import { SearchContext_1_0_1 } from '@luxuryescapes/contract-data-event-schemas/lib/com.luxgroup'

interface SearchAnalyticsOptions {
  contextLocation: SearchAnalyticsLocation
  searchItemCategory?: SearchAnalyticsItemType
}

export function useSearchAnalytics(
  searchContext?: SearchContext_1_0_1,
  options: SearchAnalyticsOptions = { contextLocation: 'search-list', searchItemCategory: 'standard' },
) {
  const trackSearchEvent = useCallback(() => {
    if (!searchContext) return
    const { contextLocation, searchItemCategory } = options
    Analytics.trackEvent(
      searchEventWithContext(
        contextLocation,
        searchItemCategory,
        searchContext,
      ),
    )
  }, [options, searchContext])

  return {
    trackSearchEvent,
  }
}

export default useSearchAnalytics
