import React from 'react'
import LuxPlusLabelWithModal from 'luxPlus/components/LuxPlusLabelWithModal'

interface Props {
  luxPlusInclusions: Array<App.PackageInclusion>;
  offer?: App.CruiseOffer;
  isModalPrimaryActionHidden?: boolean;
}

function CruiseLuxPlusOnboardCreditLabel(props: Props) {
  const { luxPlusInclusions, offer, isModalPrimaryActionHidden } = props
  const luxPlusOnboardCreditInclusion = luxPlusInclusions.find((incl) => incl.parentCategory === 'ONBOARD_CREDIT')
  return <>
    {!!luxPlusOnboardCreditInclusion && <LuxPlusLabelWithModal
      type="bonus-inclusions"
      description={luxPlusOnboardCreditInclusion.description}
      inclusions={luxPlusInclusions}
      offer={offer}
      isModalPrimaryActionHidden={isModalPrimaryActionHidden}
    />}
  </>
}

export default CruiseLuxPlusOnboardCreditLabel
