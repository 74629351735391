import getObjectKey from 'lib/object/getObjectKey'

/**
 * @returns formatted points key e.g. "price:100;productType:hotel;offerId:123;packageId:456"
 */
function buildPointsKey(options: App.LuxLoyaltyPointsCalculatorOptions, memberId?: string): string {
  return getObjectKey({ ...options, memberId })
}

export default buildPointsKey
