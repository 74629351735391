import { capitalise } from 'lib/string/stringUtils'
import { createSelector } from 'reselect'
import getInsuranceItemsView from './getInsuranceItemsView'
import moment from 'moment'
import { DMY_CASUAL_FORMAT } from 'constants/dateFormats'

function getInsuranceBreakdownItem(item: App.Checkout.InsuranceItemView): App.Checkout.InsuranceItemBreakdownView {
  return {
    title: item.policyNames.length ? '' : item.name,
    price: item.totals.price,
    memberPrice: item.totals.memberPrice,
    itemType: 'insurance',
    additionalInfoText: [
      ...item.policyNames,
      `${moment(item.startDate).format(DMY_CASUAL_FORMAT)} - ${moment(item.endDate).format(DMY_CASUAL_FORMAT)}`,
      `Covered in: ${item.destinationCountries.join(', ')}`,
    ],
    additionalElements: [],
    taxesAndFees: item.totals.taxesAndFees,
    mobileAppPrice: item.mobileAppPrice,
  }
}

const getInsuranceBreakdownView = createSelector(
  (state: App.State) => getInsuranceItemsView(state),
  (state: App.State) => state.geo.insuranceProductName,
  (viewWithStatus, insuranceName): App.WithDataStatus<Array<App.Checkout.PriceBreakdownView>> => {
    if (viewWithStatus.data.length === 0) {
      return {
        hasRequiredData: viewWithStatus.hasRequiredData,
        data: [],
      }
    }

    const breakdownViews = viewWithStatus.data.map((view): App.Checkout.PriceBreakdownView => {
      const breakdownItem = getInsuranceBreakdownItem(view)

      if (view.item.insuranceType === 'cfmr') {
        return {
          title: 'Cancellation ' + capitalise(insuranceName),
          price: view.totals.price + (view.totals.mobileAppDiscount?.amount ?? 0),
          memberPrice: view.totals.memberPrice,
          additionalInfoText: [],
          items: [],
          breakdownItemType: 'bookingProtection',
          alwaysShowItemPrice: !!view.totals.price,
          mobileAppDiscount: view.totals.mobileAppDiscount,
        }
      } else {
        return {
          title: 'Travel ' + capitalise(insuranceName),
          price: view.totals.price + (view.totals.mobileAppDiscount?.amount ?? 0),
          memberPrice: view.totals.memberPrice,
          additionalInfoText: [],
          items: [breakdownItem],
          breakdownItemType: 'insurance',
          mobileAppDiscount: view.totals.mobileAppDiscount,
        }
      }
    })
    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: breakdownViews,
    }
  },
)

export default getInsuranceBreakdownView
