import {
  API_CALL_SUCCESS,
  CLEAR_AUTH_ERROR, RESET_CUSTOMER_TRAVELLER_SUBMISSION, SET_CUSTOMER_VALUE,
  SET_RECENTLY_USED_AIRPORT_CODE,
  SYNC_USER_DETAILS,
  USER_CART_ADD_CONNECTED_ROOMS,
  USER_CART_ADD_ITEM,
  USER_CART_CLEAR_PURCHASED,
  USER_CART_SYNC,
} from 'actions/actionConstants'

import { GSI_SCRIPT_LOADED, API_CALL, SAVE_CUSTOMER_SIGNATURE, DELETE_CUSTOMER_SIGNATURE } from './actionConstants'
import {
  FETCH_AVAILABLE_CREDIT,
  FETCH_PAYMENT_CARDS,
  USER_LOGIN,
  USER_REGISTRATION,
  UPDATE_USER_DETAILS,
  USER_LOGOUT,
  UPDATE_ACCOUNT_PARTNERSHIP,
  USER_FORGOT_PASSWORD,
  LINK_PARTNERSHIP_ACCOUNT,
  FETCH_STRIPE_PAYMENT_CARDS,
  CHECK_LEGACY_ORDERS_FOR_CURRENT_USER,
  CHECK_USER_EXISTS,
  USER_RESET_PASSWORD,
  FETCH_CUSTOMER_DETAILS,
  DELETE_CUSTOMER_DETAILS,
  UPDATE_CUSTOMER_DETAILS, SEND_VERIFICATION_CODE,
  UPDATE_ADD_PHONE_PROMPTED,
  FETCH_CUSTOMER_TRAVELLERS,
  DELETE_CUSTOMER_TRAVELLER,
  SUBMIT_CUSTOMER_TRAVELLER,
} from './apiActionConstants'
import { getAvailableCredit, getSavedCardsFromStripe, getLedLegacyOrders, getSavedCardsOnStripe } from 'api/order'
import { getSavedStripeCards } from 'api/payment'
import * as authService from 'api/auth'
import { sendResetPasswordEmail, updatePartnershipDetails, resetPassword } from 'api/auth'
import { setTimeUserLastSeenSnackbar } from 'storage/creditsSnackbar'
import { getCreditSnackbar, shouldShowCreditSnackbar } from 'lib/customer/creditSnackbarUtils'
import config from 'constants/config'
import { replaceWithRegion } from './NavigationActions'
import { deleteCustomerDetails, getCustomerDetails, getCustomerTravellers, updateCustomerDetails, deleteCustomerTraveller, updateCustomerTraveller, createCustomerTraveller } from 'api/traveller'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import ReCAPTCHA from 'react-google-recaptcha'
import { isStripePaymentElementCardAvailable, isStripe3dsV2Available } from 'checkout/selectors/payment/paymentType'
import { replace } from 'connected-react-router'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { AppAction } from './ActionTypes'
import { enrolLuxLoyaltyAccount } from './LuxLoyaltyActions'

const HIGH_VALUE_OFFER_AMOUNT = 4999
const MID_VALUE_OFFER_AMOUNT = 2999

export function getUserKeyFromPhone(phoneNumber: App.PhoneNumber): string {
  return `+${phoneNumber.prefix}${phoneNumber.phone}`
}

export function checkUserExists(
  user: { email?: string, phoneNumber?: App.PhoneNumber},
  recaptchaApi?: ReCAPTCHA | null,
): AppAction {
  return (dispatch, getState) => {
    const key = user.email ?? (user.phoneNumber ? getUserKeyFromPhone(user.phoneNumber) : undefined)
    if (!key) {
      return
    }
    const state = getState()
    // no need to check again, we've already done so
    if (state.auth.users[key]) {
      return
    }

    dispatch({
      type: API_CALL,
      api: CHECK_USER_EXISTS,
      request: async() => {
        let recaptchaData: string | null | undefined
        if (recaptchaApi) {
          recaptchaData = await recaptchaApi.executeAsync()
        }
        return authService.checkUserExists(user, recaptchaData)
      },
      email: user.email,
      phoneNumber: user.phoneNumber,
      key,
    })
  }
}

export function loginNativeApp(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.webViewAccountAccess(state.geo.currentRegionCode, isLuxPlusEnabled(state)),
    })
  } }

export function login(
  user: App.User,
  password: string,
  recaptchaData: string,
): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusEnabled = isLuxPlusEnabled(state)
    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.login(user, password, recaptchaData, state.geo.currentRegionCode, luxPlusEnabled),
      source: user.email ? 'email' : 'phone',
    })
  }
}

export interface GoogleLoginData {
  id_google: string;
  email: string;
  token_id: string,
  givenName: string;
  surname: string;
}

export function loginGoogle(data: GoogleLoginData): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusEnabled = isLuxPlusEnabled(state)
    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.loginGoogle(data, state.geo.currentRegionCode, luxPlusEnabled),
      source: 'google',
    })
  }
}

export interface FacebookLoginData {
  app_id: string;
  email: string;
  access_token: string;
  givenName: string;
  surname: string;
}

export function loginFacebook(data: FacebookLoginData): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusEnabled = isLuxPlusEnabled(state)

    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.loginFacebook(data, state.geo.currentRegionCode, luxPlusEnabled),
      source: 'facebook',
    })
  }
}

export interface AppleLoginData {
  id_token: string;
  givenName?: string;
  firstName?: string;
}

export function loginApple(data: AppleLoginData): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusEnabled = isLuxPlusEnabled(state)

    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.loginApple(data, state.geo.currentRegionCode, luxPlusEnabled),
      source: 'apple',
    })
  }
}

const registerMethods = {
  phone: authService.register,
  email: authService.register,
  google: authService.loginGoogle,
  facebook: authService.loginFacebook,
  apple: authService.loginApple,
}

export function register(userData: Partial<App.AuthAccount> & { password: string }, source: App.SignUpAuthSource): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const registerData = {
      ...userData,
      utmAdgroup: state.utm.adgroup,
      utmCampaign: state.utm.campaign,
      utmContent: state.utm.content,
      utmMedium: state.utm.medium,
      utmSource: state.utm.source,
      utmTerm: state.utm.term,
    }

    dispatch({
      type: API_CALL,
      api: USER_REGISTRATION,
      source,
      request: () => {
        return registerMethods[source]((registerData as any), state.geo.currentRegionCode).then(res => {
          if (res.accessToken && res.account.givenName) {
            // WL travel brands do not need Email Verification

            // Temporarily remove instructions for email verification while enx work on a fix
            // const verificationEmailMessage = config.BRAND === 'luxuryescapes' ?
            //   'Check your email inbox to verify your account' :
            //   ''
            showSnackbar('', 'success', { heading: `Welcome to ${config.title}` })
          }

          dispatch(enrolLuxLoyaltyAccount({
            accountMemberId: res.account.memberId,
            token: res.accessToken,
          }))

          return res
        })
      },
    })
  }
}

export function resetUserPassword(token: string, password: string, callbackPath?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusEnabled = isLuxPlusEnabled(state)
    dispatch({
      type: API_CALL,
      api: USER_RESET_PASSWORD,
      request: () => resetPassword({ token, password, luxPlusEnabled }).then(data => {
        showSnackbar('Your password has been successfully reset', 'success')
        dispatch(callbackPath ? replace(callbackPath) : replaceWithRegion('/'))

        dispatch({
          type: API_CALL_SUCCESS,
          api: USER_LOGIN,
          data,
        })

        return data
      }),
    })
  }
}

export function forgotPassword(email: string, callbackPath?: string): AppAction {
  return {
    type: API_CALL,
    api: USER_FORGOT_PASSWORD,
    request: () => sendResetPasswordEmail(email, callbackPath),
  }
}

export function linkPartnershipAccount(partnershipPrefix: string, details: any): AppAction {
  return {
    type: API_CALL,
    api: LINK_PARTNERSHIP_ACCOUNT,
    request: () => updatePartnershipDetails({ [partnershipPrefix]: details }),
    prefix: partnershipPrefix,
    details,
  }
}

export function authLogout(): AppAction {
  return {
    type: API_CALL,
    api: USER_LOGOUT,
    request: () => authService.logout(),
  }
}

export function fetchAvailableCreditAndOpenSnackbar(currencyCode?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const memberId = state.auth.account.memberId
    if (!memberId) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_AVAILABLE_CREDIT,
      request: () => getAvailableCredit(
        memberId,
        currencyCode ?? state.geo.currentCurrency,
      ).then(resp => {
        if (!resp.balance) return resp
        const {
          geo: { currentCurrency, currentRegionCode },
          router: { location: { pathname } },
          system: { headlessMode },
        } = state

        if (shouldShowCreditSnackbar(resp.balance, currentCurrency, pathname, memberId, headlessMode)) {
          const creditSnackbar = getCreditSnackbar(resp.balance, currentCurrency, currentRegionCode)
          showSnackbar(creditSnackbar.message, 'success', { heading: creditSnackbar.heading })
          setTimeUserLastSeenSnackbar(memberId)
        }
        return resp
      }),
      currencyCode: currencyCode ?? state.geo.currentCurrency,
    })
  }
}

export function fetchAvailableCredit(currencyCode?: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const memberId = state.auth.account.memberId
    if (!memberId) { return }

    dispatch({
      type: API_CALL,
      api: FETCH_AVAILABLE_CREDIT,
      request: () => getAvailableCredit(
        memberId,
        currencyCode ?? state.geo.currentCurrency,
      ),
      currencyCode: currencyCode ?? state.geo.currentCurrency,
    })
  }
}

export function openCreditSnackbar(): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    const {
      geo: { currentCurrency, currentRegionCode },
      router: { location: { pathname } },
      auth: { account: { balance, memberId } },
      system: { headlessMode },
    } = state

    if (memberId && shouldShowCreditSnackbar(balance, currentCurrency, pathname, memberId, headlessMode)) {
      const creditSnackbar = getCreditSnackbar(balance, currentCurrency, currentRegionCode)
      showSnackbar(creditSnackbar.message, 'success', { heading: creditSnackbar.heading })
      setTimeUserLastSeenSnackbar(memberId)
    }
  }
}

export function setRecentlyUsedAirportCode(airportCode: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.auth.account.memberId) {
      dispatch({
        type: API_CALL,
        api: SET_RECENTLY_USED_AIRPORT_CODE,
        request: () => authService.updateUserDetails({ recentlyUsedAirportCode: airportCode }),
        airportCode,
      })
    }
  }
}

export function updateAddPhonePromptedAt(addPhonePromptedAt: Date): AppAction {
  return (dispatch, getState) => {
    const state = getState()

    if (state.auth.account.memberId) {
      dispatch({
        type: API_CALL,
        api: UPDATE_ADD_PHONE_PROMPTED,
        request: () => authService.updateAddPhonePromptedAt(addPhonePromptedAt),
        addPhonePromptedAt: addPhonePromptedAt.toISOString(),
      })
    }
  }
}

export function fetchPaymentCards(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    if (!isStripePaymentElementCardAvailable(state)) {
      dispatch({
        type: API_CALL,
        api: FETCH_PAYMENT_CARDS,
        request: () =>
          getSavedCardsFromStripe(state.auth.account.memberId),
      })
    }
  }
}

export function fetchStripePaymentCards(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: API_CALL,
      api: FETCH_STRIPE_PAYMENT_CARDS,
      request: () => (isStripePaymentElementCardAvailable(state) || isStripe3dsV2Available(state)) ?
        getSavedStripeCards(state.geo.currentCurrency) :
        getSavedCardsOnStripe(state.auth.account.memberId),
    })
  }
}

/**
 * Saves the details given for the current user in the API and updates state
 * with the newest details
 * @param userDetails The user details to save to the API
 * @returns
 */
export function updateUserDetails(userDetails: Partial<App.AuthAccount>, supportPhoneNumber: string | null = null): AppAction {
  return {
    type: API_CALL,
    api: UPDATE_USER_DETAILS,
    request: () => authService.updateUserDetails(userDetails, supportPhoneNumber),
  }
}

/**
 * Manually sync a set of user details into redux
 * Used for when you absolutely have to call the update api method yourself
 * @param userDetails The user details to sync to redux
 */
export function syncUserDetails(userDetails: Partial<App.AuthAccount>): AppAction {
  return {
    type: SYNC_USER_DETAILS,
    userDetails,
  }
}

export function updateAccountPartnership(partnershipPrefix: string, details: App.PartnershipsMap): AppAction {
  return {
    type: API_CALL,
    api: UPDATE_ACCOUNT_PARTNERSHIP,
    request: () => authService.updatePartnershipDetails(details).then(() => details),
    partnershipPrefix,
  }
}

export function hasLegacyOrdersForCurrentUser(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const { memberId } = state.auth.account
    if (memberId && !state.auth.ledLegacyOrders.hasFetched) {
      dispatch({
        type: API_CALL,
        api: CHECK_LEGACY_ORDERS_FOR_CURRENT_USER,
        request: () => getLedLegacyOrders(memberId),
      })
    }
  }
}

export function fetchTravellerDetails(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const { memberId } = state.auth.account
    if (!memberId) { return }
    dispatch({
      type: API_CALL,
      api: FETCH_CUSTOMER_DETAILS,
      request: () => getCustomerDetails(memberId),
    })
  }
}

export function deleteTravellerDetails(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const { memberId } = state.auth.account
    if (!memberId) { return }
    dispatch({
      type: API_CALL,
      api: DELETE_CUSTOMER_DETAILS,
      request: () => deleteCustomerDetails(memberId),
    })
  }
}

export function updateTravellerDetails(travellerDetails: App.CustomerDetails): AppAction {
  return {
    type: API_CALL,
    api: UPDATE_CUSTOMER_DETAILS,
    request: () => updateCustomerDetails({
      ...travellerDetails,
    }),
  }
}

export function saveCustomerSignature(signature: string): AppAction {
  return {
    type: SAVE_CUSTOMER_SIGNATURE,
    signature,
  }
}

export function deleteCustomerSignature(): AppAction {
  return {
    type: DELETE_CUSTOMER_SIGNATURE,
  }
}

export function GSILoadComplete(): AppAction {
  return {
    type: GSI_SCRIPT_LOADED,
  }
}

export function sendOTPVerificationCode(user: App.User): AppAction {
  return {
    type: API_CALL,
    api: SEND_VERIFICATION_CODE,
    request: () => authService.sendOTPVerificationCode(user),
  }
}

export function validateOTPVerificationCode(user: App.User, code: string): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    const luxPlusEnabled = isLuxPlusEnabled(state)
    dispatch({
      type: API_CALL,
      api: USER_LOGIN,
      request: () => authService.validateOTPVerificationCode(user, code, state.geo.currentRegionCode, luxPlusEnabled),
      source: 'otp',
    })
  } }

export function setCustomerValue(price: number): AppAction {
  const value = price >= HIGH_VALUE_OFFER_AMOUNT ? 'highValue' : price >= MID_VALUE_OFFER_AMOUNT ? 'midValue' : undefined
  return {
    type: SET_CUSTOMER_VALUE,
    value,
  }
}

/**
 * Call to clear any existing auth errors
 */
export function clearAuthError(): AppAction {
  return {
    type: CLEAR_AUTH_ERROR,
  }
}

export function fetchCustomerTravellersDetails(): AppAction {
  return (dispatch) => {
    dispatch({
      type: API_CALL,
      api: FETCH_CUSTOMER_TRAVELLERS,
      request: () => getCustomerTravellers(),
    })
  }
}

export function destroyCustomerTravellerDetails(id: string): AppAction {
  return (dispatch) => {
    if (!id) { return }
    dispatch({
      type: API_CALL,
      api: DELETE_CUSTOMER_TRAVELLER,
      id,
      request: () => deleteCustomerTraveller(id),
    })
  }
}

export function createCustomerTravellerDetails(customerTraveller: Omit<App.CustomerTraveller, 'id'>) {
  return {
    type: API_CALL,
    api: SUBMIT_CUSTOMER_TRAVELLER,
    request: () => createCustomerTraveller(customerTraveller),
  }
}

export function updateCustomerTravellerDetails(customerTraveller: App.CustomerTraveller) {
  return {
    type: API_CALL,
    api: SUBMIT_CUSTOMER_TRAVELLER,
    request: () => updateCustomerTraveller(
      customerTraveller,
    ),
  }
}

export function resetCustomerTravellerSubmission() {
  return {
    type: RESET_CUSTOMER_TRAVELLER_SUBMISSION,
  }
}

export function addToUserCart(items: Array<App.Checkout.AnyItem>) : AppAction {
  return (dispatch, _) => {
    items.forEach(item => {
      dispatch({
        type: USER_CART_ADD_ITEM,
        item,
      })
    })
  }
}

export function addUserCartConnectedRooms(connectedRooms: Array<Array<string>> = []) {
  return {
    type: USER_CART_ADD_CONNECTED_ROOMS,
    connectedRooms,
  }
}

export function clearUserCartPurchased(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    dispatch({
      type: USER_CART_CLEAR_PURCHASED,
      purchasedItemIds: new Set(state.checkout.cart.items.map(item => item.itemId)),
    })
  }
}

export function moveCheckoutItemsToUserCart(): AppAction {
  return (dispatch, getState) => {
    const state = getState()
    dispatch(addToUserCart(state.checkout.cart.items))
    if (state.checkout.cart.roomsToBeConnected) {
      dispatch(addUserCartConnectedRooms(state.checkout.cart.roomsToBeConnected))
    }
  }
}

export function syncUserCart(userCartState: App.UserCartState) {
  return {
    type: USER_CART_SYNC,
    userCartState,
  }
}
