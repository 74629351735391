import { useCallback, useEffect, useMemo, useReducer } from 'react'
import qs from 'qs'
import moment from 'moment'
import { SEARCH_VERTICALS } from 'constants/search'
import { FlightsFareTypes, fareCabins, fareTypes } from 'constants/flight'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { pushWithRegion } from 'actions/NavigationActions'
import {
  getFlightSearchQueryparams,
  getFlightsRecentSearch,
  setFlightSessionRecentSearch,
  validateFlightOccupants,
} from 'lib/flights/flightUtils'
import { countOccupantsForFlights } from 'lib/offer/occupancyUtils'
import {
  getFlightMultiCitySearchValues,
  getFlightSearchValues,
  isFlightsCredit,
  isStandaloneFlightsEnabled,
} from 'selectors/flightsSelectors'
import FlightsTabContent from '../Components/Mobile/TabContents/FlightsTabContent'
import flightSearchWidgetStateReducer, {
  FlightSearchWidgetActions,
  createEmptyFlightSearchWidgetFlightItem,
  initialState,
} from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import FlightSearchWidgetStateProvider from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateProvider'
import LinePlaneDepartureIcon from 'components/Luxkit/Icons/line/LinePlaneDepartureIcon'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import * as Analytics from 'analytics/analytics'
import { FlightSearchWidgetSearchData, getFlightSearchWidgetRecentStateData } from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import { flightsFlightSearchClick } from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import { searchEventWithContext } from 'analytics/snowplow/events'
import { mapFlightSearchDataToSnowplowSearchEvent } from 'analytics/mapSnowplowSearchTracking'

function useGlobalSearchVerticalTabFlights(
  searchVerticalItem?: App.SearchVerticalItem,
): App.GlobalSearchVerticalTabV2 {
  const dispatch = useAppDispatch()

  const searchValues = useAppSelector((state) => getFlightSearchValues(state))
  const multiCitySearchValues = useAppSelector((state) => getFlightMultiCitySearchValues(state))
  const isEnabled = useAppSelector((state) => isStandaloneFlightsEnabled(state) || isFlightsCredit(state))
  const pathname = useAppSelector((state) => state.router.location.pathname)
  const withRecentSearch = !pathname.includes('flights-search-results')

  const flightSearchData = useMemo((): Partial<FlightSearchWidgetSearchData> => {
    if (!searchValues.departDate || !searchValues.fareType || !searchValues.fareClass) {
      return {}
    }

    return {
      flights: [
        createEmptyFlightSearchWidgetFlightItem({
          departureAirport: searchValues.departureLocation,
          arrivalAirport: searchValues.arrivalLocation,
          checkinDate: moment(searchValues.departDate),
          checkoutDate: moment(searchValues.returnDate),
        }),
      ],
      occupants: searchValues.occupants,
      fareType: fareTypes.find((option) => option.value === searchValues.fareType) || fareTypes[0],
      fareCabin: fareCabins.find((option) => option.value === searchValues.fareClass) || fareCabins[0],
    }
  }, [searchValues])

  const flightMultiCitySearchData = useMemo((): Partial<FlightSearchWidgetSearchData> => {
    if (!multiCitySearchValues.fareType || !multiCitySearchValues.flights?.length || !multiCitySearchValues.fareClass) {
      return {}
    }

    return {
      flights: multiCitySearchValues.flights.map(flight => createEmptyFlightSearchWidgetFlightItem({
        departureAirport: {
          airportCode: flight.originAirport?.code!,
          airportName: flight.originAirport?.name!,
        },
        arrivalAirport: {
          airportCode: flight.destinationAirport?.code!,
          airportName: flight.destinationAirport?.name!,
        },
        checkinDate: moment(flight.departDate),
      })),
      occupants: multiCitySearchValues.occupants,
      fareType: fareTypes.find((option) => option.value === multiCitySearchValues.fareType)!,
      fareCabin: fareCabins.find((option) => option.value === multiCitySearchValues.fareClass)!,
    }
  }, [multiCitySearchValues])

  const [state, stateDispatch] = useReducer(flightSearchWidgetStateReducer, {
    ...initialState,
    ...(flightSearchData.fareType ? flightSearchData : flightMultiCitySearchData),
    ...(withRecentSearch ? getFlightSearchWidgetRecentStateData(getFlightsRecentSearch()) : {}),
    eventAnalytics: {
      contextLocation: 'common-search',
    },
  })

  useEffect(() => {
    stateDispatch({
      type: FlightSearchWidgetActions.UPDATE_FLIGHT_SEARCH_WIDGET_DATA,
      payload: {
        ...initialState,
        ...(flightSearchData.fareType ? flightSearchData : flightMultiCitySearchData),
        ...(withRecentSearch ? getFlightSearchWidgetRecentStateData(getFlightsRecentSearch()) : {}),
      },
    })
  }, [flightSearchData, flightMultiCitySearchData, withRecentSearch])

  const { occupants, fareType, fareCabin, flights, eventAnalytics } = state

  const invalidOccupantsSelection = useMemo(
    () => validateFlightOccupants(occupants),
    [occupants],
  )

  const checkFlightsErrors = useCallback(() => {
    let isValid = true

    flights.forEach((flight) => {
      if (!flight.departureAirport?.airportCode) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_DEPARTURE_ERROR,
          id: flight.id,
          error: true,
        })

        isValid = false
      }

      if (!flight.departureAirport?.airportCode) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_DEPARTURE_ERROR,
          id: flight.id,
          error: true,
        })

        isValid = false
      }

      if (!flight.arrivalAirport?.airportCode) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_ARRIVAL_ERROR,
          id: flight.id,
          error: true,
        })

        isValid = false
      }

      const isInvalidDate = fareType.value === 'return' ? !flight.checkinDate || !flight.checkoutDate : !flight.checkinDate
      if (isInvalidDate) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_TRAVEL_DATES_ERROR,
          id: flight.id,
          error: true,
        })

        isValid = false
      }

      const isSameAirport = flight.departureAirport?.airportCode === flight.arrivalAirport?.airportCode
      if (isSameAirport) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_SAME_AIRPORT_ERROR,
          id: flight.id,
          error: true,
        })

        isValid = false
      }
    })

    return isValid
  }, [fareType.value, flights])

  const onSearch = useCallback(() => {
    const isInvalidOccupants = occupants.childrenAge?.some((age) => age === -1)
    const isValid = checkFlightsErrors()

    if (!isValid || isInvalidOccupants || invalidOccupantsSelection) {
      return false
    }

    const transformedOccupants = countOccupantsForFlights([occupants])
    const isReturnFare = fareType.value === FlightsFareTypes.RETURN
    let nextFareType = fareType?.value as FlightsFareTypes

    // If there is only one multi city flight it's one way
    if (nextFareType === FlightsFareTypes.MULTI_CITY && flights.length === 1) {
      nextFareType = FlightsFareTypes.ONE_WAY
    }

    fireInteractionEvent(flightsFlightSearchClick())

    if (withRecentSearch) {
      setFlightSessionRecentSearch({
        fareType: fareType?.value,
        fareClass: fareCabin?.value,
        occupants,
        flights: flights.map((flight) => {
          const departDate = moment(flight.checkinDate).format(ISO_DATE_FORMAT)
          const returnDate = isReturnFare ?
            moment(flight.checkoutDate).format(ISO_DATE_FORMAT) :
            null

          return {
            departingDate: departDate,
            returningDate: returnDate,
            departureAirport: flight.departureAirport,
            arrivalAirport: flight.arrivalAirport,
          }
        }),
      })
    }

    const contextLocation = eventAnalytics?.contextLocation
    const searchItemCategory = eventAnalytics?.searchItemCategory

    if (contextLocation) {
      flights.forEach((flight) => {
        const departDate = moment(flight.checkinDate).format(ISO_DATE_FORMAT)
        const returnDate = isReturnFare ? moment(flight.checkoutDate).format(ISO_DATE_FORMAT) : undefined

        Analytics.trackEvent(
          searchEventWithContext(
            contextLocation,
            searchItemCategory,
            mapFlightSearchDataToSnowplowSearchEvent({
              searchVerticals: new Set([SEARCH_VERTICALS.FLIGHTS]),
              originAirportCode: flight.departureAirport?.airportCode,
              originAirportName: flight.departureAirport?.airportName,
              destinationAirportCode: flight.arrivalAirport?.airportCode,
              destinationAirportName: flight.arrivalAirport?.airportName,
              departDate,
              returnDate,
              occupancies: transformedOccupants,
              fareClass: fareCabin?.value,
              fareType: fareType?.value as FlightsFareTypes,
            }),
          ),
        )
      })
    }

    const params = {
      adults: transformedOccupants.adults,
      children: transformedOccupants.children,
      infants: transformedOccupants.infants,
      childrenAge: transformedOccupants.childrenAge.join(','),
      fareClass: fareCabin?.value,
      fareType: nextFareType,
      searchId: moment().unix(),
    }

    const flightParams = getFlightSearchQueryparams(flights, nextFareType)

    if (nextFareType === FlightsFareTypes.MULTI_CITY) {
      dispatch(pushWithRegion('/flights-search-results-multi-city', qs.stringify({
        ...params,
        ...flightParams,
      })))

      return
    }

    dispatch(pushWithRegion('/flights-search-results', qs.stringify({
      ...params,
      ...flightParams,
    })))
  }, [
    flights,
    occupants,
    fareType.value,
    fareCabin.value,
    withRecentSearch,
    eventAnalytics?.contextLocation,
    eventAnalytics?.searchItemCategory,
    dispatch,
    invalidOccupantsSelection,
    checkFlightsErrors,
  ])

  useEffect(() => {
    flights.forEach(flight => {
      if (flight.departureAirport?.airportCode && flight.showDepartureError) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_DEPARTURE_ERROR,
          id: flight.id,
          error: false,
        })
      }

      if (flight.arrivalAirport?.airportCode && flight.showDestinationError) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_ARRIVAL_ERROR,
          id: flight.id,
          error: false,
        })
      }

      const isInvalidDate = fareType.value === 'return' ? !flight.checkinDate || !flight.checkoutDate : !flight.checkinDate
      if (!isInvalidDate && flight.showTravelDatesError) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_TRAVEL_DATES_ERROR,
          id: flight.id,
          error: false,
        })
      }
      if (
        flight.departureAirport?.airportCode !== flight.arrivalAirport?.airportCode &&
        flight.showSameAirportError
      ) {
        stateDispatch({
          type: FlightSearchWidgetActions.SHOW_SAME_AIRPORT_ERROR,
          id: flight.id,
          error: false,
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flights])

  return useMemo((): App.GlobalSearchVerticalTabV2 => ({
    isEnabled: isEnabled && !!searchVerticalItem,
    label: searchVerticalItem?.label ?? 'flights',
    key: SEARCH_VERTICALS.FLIGHTS,
    context: { dispatch: stateDispatch, state },
    contextProvider: FlightSearchWidgetStateProvider,
    onSearch,
    ContentComponent: FlightsTabContent,
    Icon: LinePlaneDepartureIcon,
  }), [isEnabled, searchVerticalItem, state, onSearch])
}

export default useGlobalSearchVerticalTabFlights
