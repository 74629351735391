import { floatify } from 'lib/maths/mathUtils'
import { createSelector } from 'reselect'
import { getAccommodationPayable } from './accommodation'
import getPayableTotal from './getPayableTotal'
import getAccommodationDepositAmount from './getAccommodationDepositAmount'

const getDepositAmountBeforeCreditApplied = createSelector(
  (state: App.State) => getPayableTotal(state),
  (state: App.State) => getAccommodationPayable(state),
  (state: App.State) => getAccommodationDepositAmount(state),
  (payableTotal, accommodationPayable, depositAmount): number => {
    return floatify(payableTotal - accommodationPayable + depositAmount)
  },
)

export default getDepositAmountBeforeCreditApplied
