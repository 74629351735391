import moment, { Moment } from 'moment'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'

import {
  DatesInputTrigger,
  GuestsInputTrigger,
  PlaceInputTrigger,
} from './Common/SearchFields'
import SearchDatePicker from './SearchDatePicker'
import SearchOccupancyPicker from './SearchOccupancyPicker'
import SearchPlace from './SearchPlace'
import useDefaultDates from '../Common/Hooks/useDefaultDates'

import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextButton from 'components/Luxkit/Button/TextButton'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { ANYWHERE_SEARCH_ITEM } from 'constants/search'
import {
  GlobalSearchDispatchContext,
  GlobalSearchStateContext,
} from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import { encodeSearchParams } from 'lib/search/searchUtils'
import { useIsMobileScreen } from 'hooks/useScreenSize'
import { useTripMetadataPrefetch, useTrip } from 'tripPlanner/hooks/api/trip'
import useImmersiveModeHandlers from 'tripPlanner/hooks/useImmersiveModeHandlers'
import useTripClientSource from 'tripPlanner/hooks/useTripClientSource'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import useOpenModal from 'tripPlanner/hooks/View/useOpenModal'

interface Props {
  tripId: string
  defaultDate?: string
  sectionId?: string
}

const MIN_SEARCH_DATE = moment().subtract(1, 'day')

function SearchAccommodation({ tripId, sectionId, defaultDate }: Props) {
  useTripMetadataPrefetch({ tripId })
  const { setImmersiveMode } = useImmersiveModeHandlers()
  const openModal = useOpenModal()
  const { closeModal, goBack } = useModalCallbacks()

  const isMobile = useIsMobileScreen()

  const {
    occupancies,
    searchItem,
    checkinDate,
    checkoutDate,
    isFlexibleDateSelected,
  } = useContext(GlobalSearchStateContext)
  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)

  const { data: trip } = useTrip({
    tripId,
  })

  const defaultDates = useDefaultDates({
    activeDate: defaultDate,
    tripStartDate: trip?.startDate,
    tripEndDate: trip?.endDate,
  })

  useEffect(() => {
    if (
      checkinDate !== undefined ||
      checkoutDate !== undefined ||
      !defaultDates.start ||
      !defaultDates.end
    ) {
      return
    }

    globalSearchDispatch({
      type: GlobalSearchStateActions.SET_CHECKIN_DATE,
      date: defaultDates.start,
    })
    const defaultEndDate = defaultDates.end.isSame(defaultDates.start) ?
      defaultDates.end.clone().add(1, 'day') :
      defaultDates.end
    globalSearchDispatch({
      type: GlobalSearchStateActions.SET_CHECKOUT_DATE,
      date: defaultEndDate,
    })
  }, [checkinDate, checkoutDate, globalSearchDispatch, defaultDates])

  const onSetDates = useCallback(
    (startDate?: Moment, endDate?: Moment) => {
      if (startDate) {
        globalSearchDispatch({
          type: GlobalSearchStateActions.SET_CHECKIN_DATE,
          date: startDate,
        })
      }

      if (endDate) {
        globalSearchDispatch({
          type: GlobalSearchStateActions.SET_CHECKOUT_DATE,
          date: endDate,
        })
      }
    },
    [globalSearchDispatch],
  )

  const selectFlexibleDate = useCallback(
    (isFlexibleDate: boolean) => {
      if (!isFlexibleDate) {
        globalSearchDispatch({
          type: GlobalSearchStateActions.UNSET_CHECKIN_DATE,
        })
        globalSearchDispatch({
          type: GlobalSearchStateActions.UNSET_CHECKOUT_DATE,
        })
      }
      globalSearchDispatch({
        type: GlobalSearchStateActions.TOGGLE_ANYTIME_DATES_SELECTED,
        selected: isFlexibleDate,
      })
    },
    [globalSearchDispatch],
  )

  const onSetOccupancies = useCallback(
    (occupancies: Array<App.Occupants>) => {
      globalSearchDispatch({
        type: GlobalSearchStateActions.SET_OCCUPANCIES,
        occupancies,
      })
    },
    [globalSearchDispatch],
  )

  const onSearch = useCallback(() => {
    setImmersiveMode({ tripId, sectionId })
  }, [setImmersiveMode, tripId, sectionId])

  const source = useTripClientSource()
  const query = useMemo(
    () =>
      encodeSearchParams({
        urlSearch: '',
        searchItem: searchItem || ANYWHERE_SEARCH_ITEM,
        dates: {
          checkIn: checkinDate ?
            checkinDate.format(ISO_DATE_FORMAT) :
            undefined,
          checkOut: checkoutDate ?
            checkoutDate.format(ISO_DATE_FORMAT) :
            undefined,
        },
        rooms: occupancies,
        isFlexibleWithDate: isFlexibleDateSelected,
      }).toString() + `&source=${source}`,
    [
      searchItem,
      checkinDate,
      checkoutDate,
      occupancies,
      isFlexibleDateSelected,
      source,
    ],
  )

  return (
    <>
      <ModalHeader
        title="Search accommodation offers"
        onCloseButtonClick={closeModal}
        onBackButtonClick={goBack}
      />
      <ModalBody>
        <ModalContent>
          <VerticalSpacer gap={16}>
            <BodyText variant="medium" colour="neutral-two">
              Save up to 70% off on exclusive accommodation offers
            </BodyText>
            <VerticalSpacer gap={12}>
              <PlaceInputTrigger
                subTitle={searchItem?.format.mainText}
                onClick={() =>
                  openModal(
                    SearchPlace,
                    {
                      searchView: 'ACCOMMODATION' as const,
                      tripId,
                    },
                    {
                      height: 'full',
                    },
                  )
                }
              />
              <DatesInputTrigger
                checkinDate={checkinDate}
                checkoutDate={checkoutDate}
                onClick={() =>
                  openModal(SearchDatePicker, {
                    initStartDate: checkinDate,
                    initEndDate: checkoutDate,
                    initVisibleDate:
                      !checkinDate && !checkoutDate ?
                        trip?.startDate :
                        undefined,
                    initIsFlexibleDateSelected: isFlexibleDateSelected,
                    minDate: MIN_SEARCH_DATE,
                    onSetDates,
                    onSetFlexibleDates: selectFlexibleDate,
                    startLabel: 'Check-in',
                    endLabel: 'Check out',
                  })
                }
              />
              <GuestsInputTrigger
                occupancies={occupancies}
                onClick={() =>
                  openModal(SearchOccupancyPicker, {
                    tripId,
                    onSetOccupancies,
                    initOccupants: occupancies,
                  })
                }
              />
            </VerticalSpacer>
            <Group direction="horizontal-reverse">
              <TextButton
                kind="primary"
                fit={isMobile ? 'full-width' : undefined}
                to={'/search?' + query}
                onClick={onSearch}
                size="large"
              >
                Search
              </TextButton>
            </Group>
          </VerticalSpacer>
        </ModalContent>
      </ModalBody>
    </>
  )
}

export default SearchAccommodation
