import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import FlightDealDuration from 'components/Flights/FlightMerchandising/FlightDealDuration/FlightDealDuration'
import DropdownSheet from 'components/Luxkit/Dropdown/Sheet/DropdownSheet'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { FlightsFareTypes } from 'constants/flight'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import useFlightDeal from 'hooks/Flights/useFlightDeal'
import { addDays } from 'lib/datetime/dateUtils'
import React, {
  ComponentProps,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import FormattedFlightSearchDate from './FlightSearchMenu/FormattedFlightSearchDate'
import FlightsDatePicker from './FlightsDatePicker/FlightsDatePicker'

interface Props
  extends Pick<ComponentProps<typeof DropdownSheet>, 'anchorRef'> {
  id: string;
  flightDeals?: Array<App.FlightDeal>;
  onDepartingClick?: () => void;
  onArrivalClick?: () => void;
  onClose?: () => void;
  isOpen: boolean;
  onDateChange?: (date: Date) => void
}

const earliestBookingDate = addDays(new Date(), 1)
const finalBookingDate = addDays(new Date(), 330)

function FlightSearchDateSelectSheet(props: Props) {
  const { anchorRef, id, onClose, isOpen, onDateChange, flightDeals } = props
  const state = useContext(FlightSearchWidgetStateContext)
  const dispatch = useContext(FlightSearchWidgetDispatchContext)

  const currentFlight = useMemo(() => {
    return state.flights.find((flight) => flight.id === id)
  }, [state.flights, id])

  const { checkinDate, checkoutDate } = currentFlight!
  const { fareType } = state
  const [flightDeal] = useFlightDeal(state.flightDealId)

  useEffect(() => {
    if (fareType.value === FlightsFareTypes.ONE_WAY) {
      dispatch({ type: FlightSearchWidgetActions.SET_CHECKOUT_DATE, date: null })
    }
  }, [dispatch, fareType.value])

  const featuredRanges = useMemo(() => {
    return flightDeal?.travelPeriods.map(period => ({
      start: new Date(period.departureDate),
      end: new Date(period.arrivalDate),
    }))
  }, [flightDeal])

  const departingFormField = useRef<HTMLButtonElement | null>(null)

  return (
    <DropdownSheet
      size="fill-anchor"
      open={isOpen}
      anchorRef={anchorRef}
      triggerRef={departingFormField}
      onClose={onClose}
      primaryActionProps={{
        'data-testid': 'apply-flight-search-changes',
        type: 'submit',
        children: 'Apply',
        onClick: onClose,
      }}
      secondaryActionProps={{
        kind: 'tertiary',
        children: 'Cancel',
        onClick: onClose,
      }}
      footerStart={
        <Group
          direction="horizontal"
          horizontalAlign="space-between"
          verticalAlign="center"
        >
          <BodyText variant="small" colour="neutral-two">
            Based on recent return prices (per adult) found
          </BodyText>

          {!!(checkinDate && checkoutDate) && (
            <FormattedFlightSearchDate
              checkInDate={checkinDate}
              checkOutDate={checkoutDate}
            />
          )}
        </Group>
      }
    >
      <VerticalSpacer gap={24} key={id}>
        {flightDeal && <FlightDealDuration flightDeal={flightDeal} />}

        <FlightsDatePicker
          onDateChange={onDateChange}
          minDate={earliestBookingDate}
          maxDate={finalBookingDate}
          type="dual"
          id={id}
          featuredRanges={featuredRanges}
          flightDeals={flightDeals}
        />
      </VerticalSpacer>
    </DropdownSheet>
  )
}

export default FlightSearchDateSelectSheet
