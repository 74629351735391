import useCurrentSiteTakeover, { SiteTakeoverBackground } from 'hooks/useCurrentSiteTakeover'
import React from 'react'

interface Props {
  background: SiteTakeoverBackground;
  className?: string;
}

function SiteTakeoverBackgroundImage(props: Props) {
  const { className, background } = props

  const currentTakeover = useCurrentSiteTakeover()

  if (!currentTakeover) {
    return null
  }

  const Background = currentTakeover.backgroundElement

  return <Background
    className={className}
    background={background}
  />
}

export default SiteTakeoverBackgroundImage
