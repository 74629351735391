import { createSelector } from 'reselect'
import { OptimizelyFeatureFlags } from 'constants/optimizely'
import config from 'constants/config'
import { matchPath } from 'react-router'
import { CHECKOUT_HEADER_PATHS } from 'components/App/Routes/constants'
import { LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'

export const isLuxPlusEnabled = (state: App.State): boolean => !!(config.LUXURY_PLUS_ENABLED && state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusFeatureToggle])
export const getIsLuxPlusLPPEnabled = (state: App.State): boolean => !!(isLuxPlusEnabled(state) && state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusLppPricingEnabled])
export const isEligibleLuxPlusFreePreview = (state: App.State): boolean => !!(isLuxPlusEnabled(state) && state.luxPlus.products.freePreview?.offer?.isEligible)
export const isEnabledLuxPlusDiscountedInsurance = (state: App.State): boolean => isLuxPlusEnabled(state) && state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusDiscountedInsuranceBenefit]
export const isEnabledLuxPlusDiscountedBookingProtection = (state: App.State): boolean => isLuxPlusEnabled(state) && state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusDiscountedBookingProtectionBenefit]
export const getIsEnabledLuxPlusCruiseInclusions = (state: App.State): boolean => isLuxPlusEnabled(state) && state.optimizely.optimizelyFeatureFlags[OptimizelyFeatureFlags.luxPlusCruiseInclusionsEnabled]

/**
 * Check if the user has LuxPlus+ membership in the current region.
 * Members are only members in the region they bought the subscription in
 */
const isEnabledInCurrentRegionLuxPlusMember = createSelector(
  (state: App.State) => isLuxPlusEnabled(state),
  (state: App.State) => state.auth.account.luxPlus.member.subscription.item,
  (state: App.State) => state.geo.currentRegionCode,
  (isLuxPlusEnabled, memberSubscriptionItem, currentRegion): boolean => {
    return isLuxPlusEnabled && currentRegion === memberSubscriptionItem?.region
  },
)

/**
 * if you think you need this selector, you probably don't. Use checkCanRedeemLuxPlusBenefits instead
 */
export const _isActiveMemberInCurrentRegion = createSelector(
  (state: App.State) => isEnabledInCurrentRegionLuxPlusMember(state),
  (state: App.State) => state.auth.account.luxPlus.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && memberSubscriptionItem?.status === 'ACTIVE'
  },
)

export const isPendingRenewalLuxPlusMember = createSelector(
  (state: App.State) => isEnabledInCurrentRegionLuxPlusMember(state),
  (state: App.State) => state.auth.account.luxPlus.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && memberSubscriptionItem?.status === 'AWAITING_RENEWAL'
  },
)

/**
 * Check if the user is a LuxPlus+ member either subscribed e.g. 'base' tier. awaiting renewal or in free preview.
 * This is used to determine if the user can view but not redeem the benefits of LuxPlus+.
 */
export const checkCanViewLuxPlusBenefits = createSelector(
  (state: App.State) => _isActiveMemberInCurrentRegion(state),
  (state: App.State) => isPendingRenewalLuxPlusMember(state),
  (state: App.State) => state.auth.account.luxPlus.member.disableMembership,
  (isActiveMemberInCurrentRegion, isAwaitingRenewalInCurrentRegion, disableMembership): boolean => {
    return (isActiveMemberInCurrentRegion || isAwaitingRenewalInCurrentRegion) && !disableMembership
  },
)

export const isFreePreviewLuxPlusMember = createSelector(
  (state: App.State) => isEnabledInCurrentRegionLuxPlusMember(state),
  (state: App.State) => state.auth.account.luxPlus.member.subscription.item,
  (isEnabledInCurrentRegionLuxPlusMember, memberSubscriptionItem): boolean => {
    return isEnabledInCurrentRegionLuxPlusMember && memberSubscriptionItem?.tier === 'free_preview'
  },
)

/** Requirement to make any LuxPlus+ purchases */
export const checkCanRedeemLuxPlusBenefits = createSelector(
  (state: App.State) => checkCanViewLuxPlusBenefits(state),
  (state: App.State) => isFreePreviewLuxPlusMember(state),
  (state: App.State) => isPendingRenewalLuxPlusMember(state),
  (checkCanViewLuxPlusBenefits, isFreePreviewLuxPlusMember, isPendingRenewalLuxPlusMember): boolean => {
    return checkCanViewLuxPlusBenefits && !isFreePreviewLuxPlusMember && !isPendingRenewalLuxPlusMember
  },
)

export const isInsuranceAndRefundProtectionEnabled = createSelector(
  (state: App.State) => isEnabledLuxPlusDiscountedInsurance(state),
  (state: App.State) => isEnabledLuxPlusDiscountedBookingProtection(state),
  (isEnabledLuxPlusDiscountedInsurance, isEnabledLuxPlusDiscountedBookingProtection): boolean => {
    return isEnabledLuxPlusDiscountedInsurance && isEnabledLuxPlusDiscountedBookingProtection
  },
)

const mappedCheckoutHeaderPaths = CHECKOUT_HEADER_PATHS.map((path) => '/:region' + path)
const pathsBlacklist = [
  ...mappedCheckoutHeaderPaths,
  '/:region/booking/confirmation',
  `/:region/${LUXURY_PLUS_PATHS.ACCOUNT_MANAGEMENT_PAGE}`,
  '/:regionCode/campaign/early-bird-2025',
  '/:region/search/map',
]

export const selectHasLuxPlusFreePreviewBanner = createSelector(
  (state: App.State) => isLuxPlusEnabled(state),
  (state: App.State) => checkCanViewLuxPlusBenefits(state),
  (state: App.State) => state.system.luxPlusFreePreviewBannerDismissed,
  (state: App.State) => state.router.location.pathname,
  (
    isLuxPlusEnabled,
    canViewLuxPlusBenefits,
    luxPlusFreePreviewBannerDismissed,
    path,
  ): boolean => {
    const isBlacklistedPath = !!matchPath(path, {
      path: pathsBlacklist,
      exact: true,
    })

    return isLuxPlusEnabled && !canViewLuxPlusBenefits && !luxPlusFreePreviewBannerDismissed && !isBlacklistedPath
  },
)
