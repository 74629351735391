import React, { useCallback, useContext, useRef } from 'react'

import {
  getOfferImageId,
  getSavedDetailsForOffer,
  IS_SAVED_COPY,
  mapExperienceTicketSelections,
  NOT_SAVED_COPY,
  SaveItemsCallbackResult,
} from '../utils'
import BaseBookmarkButton from '../../Common/BaseBookmarkButton'
import SaveModal, { TripPlannerSaveModalResult } from '../../Common/SaveModal'

import {
  saveToTripEvent,
  tripLoginModalSignUpDismiss,
  tripLoginModalSignUpView,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import AnalyticsPageContext from 'contexts/Analytics/analyticsPageContext'
import ExperienceOfferPageStateContext from 'contexts/Experience/ExperienceOfferPage/experienceOfferPageStateContext'
import ModalContext from 'contexts/ModalContext'
import { RecommendationSaveClickTrackerContext } from 'contexts/RecommendationSaveClickTrackerContext'
import useTrackExperienceOfferEvent from 'contexts/support/useTrackExperienceOfferEvent'
import { useCurrentExperiencePageTicketOptions } from 'hooks/ExperiencePage/useCurrentExperiencePageTicketOptions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import usePendingLoginHandler from 'hooks/usePendingLoginHandler'
import { EmptyArray } from 'lib/array/arrayUtils'
import { ExperienceOfferPageState } from 'src/client/contexts/Experience/ExperienceOfferPage/experienceOfferPageStateReducer'
import {
  clearRecentlySavedTripId,
  getRecentlySavedTripId,
  setRecentlySavedTripId,
} from 'storage/recentSavedTrip'
import { ExperienceBookmarkPayload } from 'tripPlanner/api/bookmark/types'
import { useTripId } from 'tripPlanner/contexts/TripContext'
import { useDeleteTripItem } from 'tripPlanner/hooks/api/tripItem'
import { useTrip, useEditableTrips } from 'tripPlanner/hooks/api/trip'
import { useProcessBookmarks } from 'tripPlanner/hooks/api/bookmark'
import useBookmarkSnackbarHandlers from 'tripPlanner/hooks/bookmarks/useSavedItemSnackbarHandlers'
import {
  setCurrentSelectionId,
  setTripItemHasJustBeenAdded,
} from 'tripPlanner/reducers/actions'
import {
  getImmersiveTripId,
  selectTripPlannerTemplateId,
  selectTripPlannerTemplateItemId,
} from 'tripPlanner/selectors'
import { BasicTrip, FullTrip } from 'tripPlanner/types/common'
import { tripItemSelectionId } from 'tripPlanner/utils/itemSelection'
import useNewTripNameFromOffer from 'tripPlanner/hooks/useNewTripNameFromOffer'

export interface Props {
  offer: App.ExperienceOffer
  iconOnly?: boolean
  size?: React.ComponentProps<typeof BaseBookmarkButton>['size']
}

const trackTripLoginModalSignUpView = () => {
  fireInteractionEvent(tripLoginModalSignUpView('save'))
}
const trackTripLoginModalSignUpDismiss = () => {
  fireInteractionEvent(tripLoginModalSignUpDismiss('save'))
}

function ExperienceBookmarkButton({ offer, iconOnly, size }: Props) {
  const inTripId = useTripId()
  const currentTripId = useAppSelector(getImmersiveTripId) ?? inTripId
  const { data: currentTrip } = useTrip({ tripId: currentTripId })
  const { data } = useEditableTrips()
  const trips = data ?? EmptyArray
  const analyticsPage = useContext(AnalyticsPageContext)

  const dispatch = useAppDispatch()
  const details = getSavedDetailsForOffer(trips, offer.id)
  const isSaved = details.length > 0

  const didAutoSelectTrip = useRef(false)

  // A promise that can be awaited to make sure we only show snackbar messages once the modal is closed
  const modalPromise = useRef<Promise<any>>(Promise.resolve())

  const recommendationSaveClickTracker = useContext(
    RecommendationSaveClickTrackerContext,
  )

  const {
    showSaveSuccessSnackbar,
    showSaveErrorSnackbar,
    showRemoveSuccessSnackbar,
    showRemoveErrorSnackbar,
  } = useBookmarkSnackbarHandlers()

  const trackEvent = useTrackExperienceOfferEvent()

  const { mutate: deleteBookmark, isLoading: isDeleting } = useDeleteTripItem({
    onSuccess: (_res, vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveSuccessSnackbar(trip.id, trip.name)
    },
    onError: (_res, _vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveErrorSnackbar(trip.name)
    },
  })

  const experienceOfferPageState = useContext(
    ExperienceOfferPageStateContext,
  ) as ExperienceOfferPageState | undefined

  const allTicketOptions = useCurrentExperiencePageTicketOptions(
    offer.id,
    experienceOfferPageState,
  )

  const showModal = useContext(ModalContext)

  const newTripName = useNewTripNameFromOffer(offer)

  const { mutateAsync: processBookmarks, isLoading: isCreating } =
    useProcessBookmarks({
      onError: (e, variables) => {
        console.error(e)
        showSaveErrorSnackbar(
          trips.find((t) => t.id === variables.tripIdsAdded[0])?.name,
        )

        // Clear the recently saved trip ID in case it errored because the trip was deleted
        clearRecentlySavedTripId()
      },
    })

  const templateId = useAppSelector(selectTripPlannerTemplateId)
  const templateItemId = useAppSelector(selectTripPlannerTemplateItemId)

  const createTripItems = useCallback(
    async(tripId: string): Promise<SaveItemsCallbackResult> => {
      const payload: ExperienceBookmarkPayload = {
        type: 'experience',
        code: offer.id,
        languageId: experienceOfferPageState?.language?.id,
        pickupPointId: experienceOfferPageState?.pickupPoint?.id,
        redemptionLocationId: experienceOfferPageState?.redemptionLocation?.id,
        startDate: experienceOfferPageState?.date,
        startTime: experienceOfferPageState?.time,
        tickets: mapExperienceTicketSelections(
          experienceOfferPageState,
          allTicketOptions,
        ),
        templateId,
        templateItemId,
      }

      const bookmarkResult = await processBookmarks({
        items: [payload],
        tripIdsAdded: [tripId],
        tripIdsRemoved: [],
      })

      return {
        savedItemIds: bookmarkResult.created.map((item) => item.id),
      }
    },
    [
      experienceOfferPageState,
      offer,
      allTicketOptions,
      processBookmarks,
      templateId,
      templateItemId,
    ],
  )

  const createTripItemsImmediate = useCallback(
    async(trip: FullTrip | BasicTrip) => {
      try {
        const res = await createTripItems(trip.id)
        const itemId = res.savedItemIds[0]
        dispatch(setCurrentSelectionId(tripItemSelectionId(itemId)))
        dispatch(setTripItemHasJustBeenAdded())
        modalPromise.current.then(() =>
          showSaveSuccessSnackbar(
            trip.id,
            trip.name,
            itemId,
            'EXPERIENCE',
            true,
          ),
        )
        setRecentlySavedTripId(trip.id)
      } catch {
        // Do nothing - failure case is handled by the hook's onError handler
      }
    },
    [createTripItems, dispatch, showSaveSuccessSnackbar],
  )

  const openSaveModal = useCallback(() => {
    return showModal<TripPlannerSaveModalResult>(
      <SaveModal
        createTripItems={createTripItems}
        isCreatingItem={isCreating}
        itemTypeLabel="offer"
        defaultTripName={newTripName}
        offerImageId={getOfferImageId(offer)}
      />,
    )
  }, [createTripItems, isCreating, newTripName, offer, showModal])

  const onSave = useCallback(async() => {
    didAutoSelectTrip.current = false
    const recentlySavedTripId = getRecentlySavedTripId()
    trackEvent('SAVE_TO_TRIP_BUTTON_CLICKED')
    if (isSaved) {
      Promise.all(
        details.map((detail) =>
          deleteBookmark({
            tripId: detail.trip.id,
            tripItemId: detail.itemId,
          }),
        ),
      )
    } else if (currentTrip) {
      fireInteractionEvent(
        saveToTripEvent('button', 'immersive', 'click', analyticsPage),
      )
      recommendationSaveClickTracker()
      createTripItemsImmediate(currentTrip)
    } else {
      fireInteractionEvent(
        saveToTripEvent('button', 'global', 'click', analyticsPage),
      )
      const recentlySavedTrip = trips.find((t) => t.id === recentlySavedTripId)
      if (recentlySavedTrip) {
        didAutoSelectTrip.current = true
        createTripItemsImmediate(recentlySavedTrip)
      } else {
        modalPromise.current = openSaveModal()
        await modalPromise.current
        modalPromise.current = Promise.resolve()
      }
    }
  }, [
    trackEvent,
    isSaved,
    currentTrip,
    details,
    deleteBookmark,
    createTripItemsImmediate,
    openSaveModal,
    recommendationSaveClickTracker,
    analyticsPage,
    trips,
  ])

  const onButtonClick = usePendingLoginHandler(
    onSave,
    'tripPlannerLogin',
    trackTripLoginModalSignUpView,
    trackTripLoginModalSignUpDismiss,
  )

  return (
    <BaseBookmarkButton
      label={isSaved ? IS_SAVED_COPY : NOT_SAVED_COPY}
      isSaved={isSaved}
      onClick={onButtonClick}
      isProcessing={isDeleting || isCreating}
      iconOnly={iconOnly}
      size={size}
    />
  )
}

export default ExperienceBookmarkButton
