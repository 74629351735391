import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import { searchSteps } from 'components/SearchV2/Components/Mobile/TabContents/HotelsTabContent'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import { GlobalSearchDispatchContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'
import ModalContext from 'contexts/ModalContext'
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import BusinessTravellerSelectModal from './BusinessTravellerSelectModal'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
}

interface Props {
  activeStep?: searchSteps
  onBusinessTravellerSelectClick: () => void
  gotoNextStep: () => void
}

function BusinessTravellerSelectHotelsMobile(props: Props & MappedStateProps) {
  const {
    activeStep,
    travellerEmployees,
    onBusinessTravellerSelectClick,
    gotoNextStep,
  } = props

  const showModal = useContext(ModalContext)

  const globalSearchDispatch = useContext(GlobalSearchDispatchContext)

  const setOccupancies = useCallback((occupancies: Array<App.Occupants>) => {
    globalSearchDispatch({ type: GlobalSearchStateActions.SET_OCCUPANCIES, occupancies })
  }, [globalSearchDispatch])

  const handleTravellerSelect = useCallback((selectedTravellerEmployeeIds: Array<string>) => {
    if (selectedTravellerEmployeeIds.length > 0) {
      const occupants: Array<App.Occupants> = [{
        adults: selectedTravellerEmployeeIds.length ?? 1,
        children: 0,
        infants: 0,
        childrenAge: [],
      }]

      setOccupancies(occupants)
    }
    gotoNextStep()
  }, [gotoNextStep, setOccupancies])

  const showBusinessTravellerSelectModal = useCallback(async() => {
    await showModal(<BusinessTravellerSelectModal onTravellerSelect={handleTravellerSelect} />)

    gotoNextStep()
  }, [gotoNextStep, handleTravellerSelect, showModal])

  const handleSelectTravellerClick = useCallback(() => {
    if (activeStep !== searchSteps.GUESTS) {
      onBusinessTravellerSelectClick()
    } else {
      gotoNextStep()
    }
  }, [activeStep, gotoNextStep, onBusinessTravellerSelectClick])

  const fieldValue = useMemo(() => {
    if (travellerEmployees.length) {
      const firstTraveller = travellerEmployees[0]
      return `${firstTraveller.firstName} ${firstTraveller.lastName}`
    }

    return undefined
  }, [travellerEmployees])

  const localInputRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (activeStep === searchSteps.GUESTS) {
      showBusinessTravellerSelectModal()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep])

  return (
    <SearchFormField
        label="Travellers"
        required
        requiredErrorMessage="Please select traveller"
        value={fieldValue}
        displayValue={fieldValue}
        placeholder="Select Traveller"
        onClick={handleSelectTravellerClick}
        ref={localInputRef}
      />
  )
}

export default connect<MappedStateProps, {}, Props, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectHotelsMobile)
