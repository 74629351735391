import React, { useState, useRef, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import TourV2ItineraryBreakdown from './TourV2ItineraryBreakdown'
import Heading from 'components/Luxkit/Typography/Heading'
import TextButton from 'components/Luxkit/Button/TextButton'
import { arrayToObject } from 'lib/array/arrayUtils'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { printModal } from 'components/utils/printModal'
import LinePrintIcon from 'components/Luxkit/Icons/line/LinePrintIcon'
import Group from 'components/utils/Group'
import LineExcursionIcon from 'components/Luxkit/Icons/line/LineExcursionIcon'
import { rem } from 'polished'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import noop from 'lib/function/noop'

const AlternativeRouteCTA = styled(Group)`
  padding: ${rem(20)};
  background-color: ${props => props.theme.palette.highlight.primary.lightBackground};
`

interface Props {
  offerType: App.Tours.V2OfferType
  itinerary: Array<App.Tours.TourV2OfferItineraryItem>
  showPrint?: boolean
  title?: string
  subtitle?: string
  showExperiences?: boolean
  alternativeVariation?: App.Tours.TourV2OfferVariation
  changeAlternativeVariation: () => void
}

function TourV2Itinerary(props: Props) {
  const { itinerary, title, offerType, subtitle, alternativeVariation, showPrint = false, showExperiences = true, changeAlternativeVariation } = props
  const [expandedAccordions, setExpandedAccordions] = useState<Record<string, boolean>>(arrayToObject(itinerary, item => `${item.startDay}`, (item, index) => index === 0))

  const expandAll = useMemo(() => {
    return Object.values(expandedAccordions).some(item => !item)
  }, [expandedAccordions])

  const toggleExpandAll = useCallback(() => {
    setExpandedAccordions(itinerary.reduce((obj, item) => Object.assign(obj, { [item.startDay]: expandAll }), {}))
  }, [expandAll, itinerary])

  const toggleAccordion = useCallback((key: string) => {
    setExpandedAccordions({ ...expandedAccordions, [key]: !expandedAccordions[key] })
  }, [expandedAccordions])

  const itineraryPdfRef = useRef<HTMLDivElement|null>(null)

  const handleDownloadPdf = useCallback(async() => {
    // Wait expanded all accordions before generate/download the pdf
    await (new Promise<void>(resolve => {
      setExpandedAccordions(() => {
        resolve()
        return itinerary.reduce((obj, item) => Object.assign(obj, { [item.startDay]: true }), {})
      })
    }))
    printModal(itineraryPdfRef.current as HTMLDivElement)
  }, [itinerary])

  const {
    hasTheSameStartAndEndLocation,
    startLocation,
    endLocation,
  } = useTourV2VariationDetails(alternativeVariation)

  return <Group direction="vertical" gap={32} ref={itineraryPdfRef}>
    {!!subtitle && <div className="subtitle-area">
      <BodyText
        variant="medium"
        colour="neutral-two"
        as="p"
        id="LUXKIT-MODAL-HEADER-SUBTITLE"
      >
        {subtitle}
      </BodyText>
    </div>}
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
      {!!title && <Heading variant="heading3" align="center">{title}</Heading>}
      {showPrint && <TextButton className="hidden-print" kind="tertiary" onClick={handleDownloadPdf} startIcon={<LinePrintIcon />} horizontalOutdent="start">
        Print
      </TextButton>}
      <TextButton className="hidden-print" kind="tertiary" horizontalOutdent="end" onClick={toggleExpandAll} >
        {expandAll ? 'Expand all' : 'Hide all'}
      </TextButton>
    </Group>
    <Group direction="vertical" gap={0} tabletGap={16}>
      {itinerary.map((item, index) => (
        <TourV2ItineraryBreakdown
        key={item.startDay}
        last={index === itinerary.length - 1}
        itineraryItem={item}
        open={expandedAccordions[item.startDay]}
        toggleAccordion={toggleAccordion}
        shouldDisplayLocationsVisited={offerType !== 'direct_tour'}
        showExperiences={showExperiences}
      />
      ))}
    </Group>
    {alternativeVariation && <AlternativeRouteCTA direction="vertical" desktopDirection="horizontal" gap={12} horizontalAlign="space-between" >
      <LineExcursionIcon size="XL" />
      <Group direction="vertical" gap={4}>
        <Heading variant="heading5">{hasTheSameStartAndEndLocation ? 'Interested in an itinerary in reverse?' : `Interested in starting from ${startLocation} instead?`}</Heading>
        <BodyText variant="medium" colour="neutral-two">
          Experience an alternative route for this tour{!hasTheSameStartAndEndLocation ? `, travelling from ${startLocation} to ${endLocation}` : ''}.
        </BodyText>
      </Group>
      <TextButton kind="secondary" onClick={changeAlternativeVariation} >
        View alternate route
      </TextButton>
    </AlternativeRouteCTA>}
  </Group>
}

TourV2Itinerary.defaultProps = {
  changeAlternativeVariation: noop,
}

export default TourV2Itinerary
