import React from 'react'
import cn from 'clsx'
import { rem } from 'polished'
import { connect } from 'react-redux'
import styled from 'styled-components'
import getCheckoutSignUpOfferDetails from 'checkout/selectors/payment/getCheckoutSignUpOfferDetails'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Image from 'components/Common/Image'
import Group from 'components/utils/Group'
import { pluralizeToString } from 'lib/string/pluralize'
import { mediaQueryDown, mediaQueryUp } from 'components/utils/breakpoint'
import PriceRowPriceWithCaption from 'components/Luxkit/PricePoints/PriceRowPriceWithCaption'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import useLuxLoyaltyPointsCalculator from 'luxLoyalty/hooks/useLuxLoyaltyPointsCalculator'
import { generateLuxLoyaltyPointsCalculatorFlightOptions, generateLuxLoyaltyPointsCalculatorHotelOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'

const Container = styled.div`
  display: flex;
  border: 1px solid ${(props) => props.theme.palette.neutral.default.five};
  gap: ${rem(12)};
  padding: ${rem(12)};

  ${mediaQueryUp.tablet} {
    padding: 0;
  }
`

const TextBlock = styled(Group)`
  padding: 0;

  ${mediaQueryUp.tablet} {
    padding: ${rem(12)};
    padding-right: 0;
  }
`

const ImageBlock = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: ${rem(164)};
  height: ${rem(96)};

  ${mediaQueryDown.mobile} {
    width: ${rem(68)};
    height: ${rem(68)};
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &.image-padding {
    padding: ${rem(12)};
    padding-left: 0;

    ${mediaQueryDown.mobile} {
      padding: 0;
    }
  }
`

interface Props {
  hotelOfferDetails?: App.AccountModalOfferDetails;
  offerDetails?: App.AccountModalOfferDetails;
  isPerNightPricingTestEnabled: boolean;
}

function AccountAccessOfferPreview(props: Props) {
  const { hotelOfferDetails, offerDetails, isPerNightPricingTestEnabled } = props
  const signUpOfferDetails = hotelOfferDetails || offerDetails
  const offer = signUpOfferDetails?.offer
  const packageView = signUpOfferDetails?.packageView
  const showPerNightPricing = isPerNightPricingTestEnabled && !!signUpOfferDetails && signUpOfferDetails.duration
  const saleUnit = showPerNightPricing ? 'night' : 'total'
  const perNightTotal = showPerNightPricing ? Math.ceil(signUpOfferDetails.value / (signUpOfferDetails.duration || 1)) : 0
  const total = showPerNightPricing ? perNightTotal : (signUpOfferDetails?.value ?? 0)
  const hotelTotal = total + (packageView?.propertyFees ?? 0)

  const { totalPointsCalculation } = useLuxLoyaltyPointsCalculator([
    generateLuxLoyaltyPointsCalculatorHotelOptions(offer, { packageView }),
    generateLuxLoyaltyPointsCalculatorFlightOptions(offer, {
      packageView,
      skip: !signUpOfferDetails?.isBundledWithFlights,
    }),
  ])

  if (!signUpOfferDetails) return null

  let priceCaption = signUpOfferDetails.durationLabel ?? ''
  if (!signUpOfferDetails.durationLabel && signUpOfferDetails.duration) {
    priceCaption = `${pluralizeToString('night', signUpOfferDetails.duration)} ${signUpOfferDetails.isBundledWithFlights ? '+ flights from' : 'from'}`
  }

  return (
    <Container>
      <TextBlock direction="vertical" gap={4} fullWidth>
        <BodyTextBlock variant="medium" weight="bold" lineClamp={2}>{signUpOfferDetails.title}</BodyTextBlock>
        {signUpOfferDetails.value > 0 && (
          <PriceRowPriceWithCaption
            size="S"
            caption={priceCaption}
            price={hotelTotal}
            saleUnit={saleUnit}
            discountPercentage={signUpOfferDetails.discountPercentage}
          />
        )}
        <LuxLoyaltyPoints pointsEarnCalculation={totalPointsCalculation} calculationType="estimate" />
      </TextBlock>
      <ImageBlock className={cn({ 'image-padding': signUpOfferDetails.addImagePadding })}>
        <Image
          id={signUpOfferDetails.imageId}
          image={signUpOfferDetails.image}
          alt={signUpOfferDetails.imageTitle}
          dpr={2}
          fit={signUpOfferDetails.addImagePadding ? undefined : 'center'}
        />
      </ImageBlock>
    </Container>
  )
}

function mapStateToProps(state: App.State) {
  return {
    offerDetails: getCheckoutSignUpOfferDetails(state),
    isPerNightPricingTestEnabled: !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight),
  }
}

export default connect(mapStateToProps)(AccountAccessOfferPreview)
