import request from 'api/requestUtils'
import type { ClientToServerEvents, ServerToClientEvents, EventType, Message, Feedback } from '@luxuryescapes/contract-support'
import { eventTypes, TypeaheadResult, ChatActions, ContentCategories, ContentTypes, MessageMetaPayload, AgentType, ChatStatus, MessageSenders, Chat, Message as ChatMessage, MessageMeta, MessageMetaContent } from '@luxuryescapes/contract-support'
import { mapChat, mapChatMessage, mapChatMessages, mapChats, mapMessageFeedback } from './mappers/supportAssistant/supportAssistant'
import qs from 'qs'
import { globalSocket } from 'supportAssistant/hooks/useSocket'
export { ClientToServerEvents, ServerToClientEvents, EventType }
export const { CONNECTION, CHAT, SESSION, MESSAGE } = eventTypes
export { TypeaheadResult, ChatActions, ContentCategories, ContentTypes, MessageMetaPayload, AgentType, ChatStatus, MessageSenders, Chat, ChatMessage, MessageMeta, MessageMetaContent }

export function sendChatMessage(message: Partial<App.ChatMessage>) {
  globalSocket?.emit('message', message)
}

export function sendMessageFeedback(feedback: any) {
  globalSocket?.emit('message:feedback', feedback)
}

export function createChat(regionCode: string, customerId?: string, customerName?: string, isLuxPlusMember?: boolean, orderBookingNumbers?: Array<App.OrderBookingNumber>) {
  globalSocket?.emit('chat:create', { customerId, customerName, regionCode, isLuxPlusMember, orderBookingNumbers })
  return new Promise<[App.Chat, Array<App.ChatMessage>]>((resolve, reject) => {
    globalSocket?.on('chat:created', (response: Chat) => {
      const messages = response.messages.map(mapChatMessage)
      const chat = mapChat(response)
      resolve([chat, messages])
    })
    globalSocket?.on('chat:error', () => reject(undefined))
  })
}

export async function getChats() {
  const uri = '/api/support/v1/chats'

  const response = await request.get<App.ApiResponse<Array<Chat>>>(uri, { credentials: 'include' })
  return mapChats(response.result)
}

export async function createChatApi(payload: App.ChatApi) {
  const uri = '/api/support/v1/chats'
  await request.post<App.ApiResponse, App.ChatApi>(uri, payload, { credentials: 'include' })
}

export async function getChatWelcomeMessage() {
  const uri = '/api/support/v1/messages/welcome-message'

  const response = await request.get<App.ApiResponse<Message>>(uri, { credentials: 'include' })
  return mapChatMessage(response.result)
}

export async function getChatMessages(chatId: string) {
  const queryParams = qs.stringify({
    includeMessages: true,
    includeAgent: true,
  })

  const uri = `/api/support/v1/chats/${chatId}?${queryParams}`

  const response = await request.get<App.ApiResponse<Chat>>(uri, { credentials: 'include' })
  return mapChatMessages(response.result)
}

interface PutChatBody {
  id: string;
  customerId: string;
}

export async function putServerChat(chat: PutChatBody) {
  const uri = `/api/support/v1/chats/${chat.id}`

  const response = await request.put<App.ApiResponse<Chat>, PutChatBody>(uri, chat, { credentials: 'include' })
  return mapChat(response.result)
}

export async function postFeedback(feedback: App.ChatMessageFeedback) {
  const uri = '/api/support/v1/feedbacks'

  const response = await request.post<App.ApiResponse<Feedback>, App.ChatMessageFeedback>(uri, feedback, { credentials: 'include' })
  return mapMessageFeedback(response.result)
}

export function createGenesysConversation(payload: {
  chatId: string,
  customerId: string,
  customer: {
    firstName?: string,
    lastName?: string,
    email?: string,
    phone?: string,
    orderId?: string,
  },
}) {
  const uri = '/api/support/v1/genesys/conversations'

  return request.post<App.ApiResponse<Record<any, any>>, object>(
    uri,
    {
      chatId: payload.chatId,
      customerId: payload.customerId,
      customer: {
        firstName: payload.customer.firstName,
        lastName: payload.customer.lastName,
        email: payload.customer.email,
        phone: payload.customer.phone,
        orderId: payload.customer.orderId,
      },
    },
    { credentials: 'include' },
  )
    .then(data => data.result)
}

export function createGenesysAgentCallback(payload) {
  const uri = '/api/support/v1/genesys/callbacks'

  return request.post<App.ApiResponse<Record<any, any>>, App.ApiResponse<Record<any, any>>>(uri, payload, { credentials: 'include' })
}
