import React, { useCallback, useContext, useMemo, useRef } from 'react'
import { connect } from 'react-redux'

import {
  flightSearchQueryStringToBookmarkId,
  IS_SAVED_COPY,
  SaveItemsCallbackResult,
} from './utils'
import BaseBookmarkButton from '../Common/BaseBookmarkButton'
import SaveModal, { TripPlannerSaveModalResult } from '../Common/SaveModal'

import {
  saveToTripEvent,
  tripLoginModalSignUpDismiss,
  tripLoginModalSignUpView,
} from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import { FareClass, FlightsFareTypes } from 'constants/flight'
import AnalyticsPageContext from 'contexts/Analytics/analyticsPageContext'
import ModalContext from 'contexts/ModalContext'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import usePendingLoginHandler from 'hooks/usePendingLoginHandler'
import useSearchFlights, { FlightSearchParams } from 'hooks/useSearchFlights'
import { EmptyArray, nonNullable } from 'lib/array/arrayUtils'
import { parseSearchString } from 'lib/url/searchUrlUtils'
import {
  getFlightSearchParams,
  getFlightSearchValues,
} from 'selectors/flightsSelectors'
import {
  clearRecentlySavedTripId,
  getRecentlySavedTripId,
  setRecentlySavedTripId,
} from 'storage/recentSavedTrip'
import { FlightBookmarkPayload } from 'tripPlanner/api/bookmark/types'
import {
  DEFAULT_FLIGHT_IMAGE_ID,
  MAX_TRIP_NAME_CHARS,
} from 'tripPlanner/config'
import { useDeleteTripItem } from 'tripPlanner/hooks/api/tripItem'
import { useTrip, useEditableTrips } from 'tripPlanner/hooks/api/trip'
import { useProcessBookmarks } from 'tripPlanner/hooks/api/bookmark'
import useBookmarkDetails from 'tripPlanner/hooks/bookmarks/useBookmarkDetails'
import useBookmarkSnackbarHandlers from 'tripPlanner/hooks/bookmarks/useSavedItemSnackbarHandlers'
import {
  setCurrentSelectionId,
  setTripItemHasJustBeenAdded,
} from 'tripPlanner/reducers/actions'
import {
  getImmersiveTripId,
  selectTripPlannerTemplateId,
  selectTripPlannerTemplateItemId,
} from 'tripPlanner/selectors'
import { BasicTrip, FullTrip } from 'tripPlanner/types/common'
import {
  bookmarkableFareTypes,
  getMinJourneyPrice,
} from 'tripPlanner/utils/itemConversions/bookmarks/flight'
import { tripItemSelectionId } from 'tripPlanner/utils/itemSelection'
import { getUniqueTripName } from 'tripPlanner/utils/tripName'

interface Props {
  search: string
  fareType: FlightsFareTypes
  flightSearchParams: FlightSearchParams
  originAirport: App.Airport
  destinationAirport: App.Airport
  departDate: string
  returnDate: string
  bookmarkId: string
  passengers: App.Occupants
}

const trackTripLoginModalSignUpView = () => {
  fireInteractionEvent(tripLoginModalSignUpView('save'))
}
const trackTripLoginModalSignUpDismiss = () => {
  fireInteractionEvent(tripLoginModalSignUpDismiss('save'))
}

function FlightBookmarkButton({
  search,
  flightSearchParams,
  fareType,
  returnDate,
  departDate,
  destinationAirport,
  originAirport,
  bookmarkId,
  passengers,
}: Props) {
  const currentTripId = useAppSelector(getImmersiveTripId)
  const { data: currentTrip } = useTrip({ tripId: currentTripId })
  const { data } = useEditableTrips()
  const trips = data ?? EmptyArray
  const analyticsPage = useContext(AnalyticsPageContext)

  const dispatch = useAppDispatch()

  const didAutoSelectTrip = useRef(false)

  // A promise that can be awaited to make sure we only show snackbar messages once the modal is closed
  const modalPromise = useRef<Promise<any>>(Promise.resolve())

  const details = useBookmarkDetails(bookmarkId)
  const isSaved = !!details

  const {
    showSaveSuccessSnackbar,
    showSaveErrorSnackbar,
    showRemoveSuccessSnackbar,
    showRemoveErrorSnackbar,
  } = useBookmarkSnackbarHandlers()

  const { mutate: deleteBookmark, isLoading: isDeleting } = useDeleteTripItem({
    onSuccess: (_res, vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveSuccessSnackbar(trip.id, trip.name)
    },
    onError: (_res, _vars, context) => {
      // After item deletion, the basic trip in the RA cache will have enough valid information to provide to handlers
      const trip = context as BasicTrip
      showRemoveErrorSnackbar(trip.name)
    },
  })

  const [flights] = useSearchFlights(flightSearchParams)
  const { fareClass } = parseSearchString(search)

  const { mutateAsync: processBookmarks, isLoading: isCreating } =
    useProcessBookmarks({
      onError: (e, variables) => {
        console.error(e)
        showSaveErrorSnackbar(
          trips.find((t) => t.id === variables.tripIdsAdded[0])?.name,
        )

        // Clear the recently saved trip ID in case it errored because the trip was deleted
        clearRecentlySavedTripId()
      },
    })

  const templateId = useAppSelector(selectTripPlannerTemplateId)
  const templateItemId = useAppSelector(selectTripPlannerTemplateItemId)

  const createTripItems = useCallback(
    async(tripId: string): Promise<SaveItemsCallbackResult> => {
      let journeys: FlightBookmarkPayload['journeys'] = []
      if (fareType === FlightsFareTypes.ONE_WAY) {
        journeys = [
          {
            originAirportCode: originAirport.code,
            destinationAirportCode: destinationAirport.code,
            startDate: departDate,
            class: fareClass as FareClass,
          },
        ]
      } else if (fareType === FlightsFareTypes.RETURN) {
        journeys = [
          {
            originAirportCode: originAirport.code,
            destinationAirportCode: destinationAirport.code,
            startDate: departDate,
            class: fareClass as FareClass,
          },
          {
            originAirportCode: destinationAirport.code,
            destinationAirportCode: originAirport.code,
            startDate: returnDate,
            class: fareClass as FareClass,
          },
        ]
      }

      const minPrice = getMinJourneyPrice(nonNullable(flights))
      const payload: FlightBookmarkPayload = {
        type: 'flight',
        journeys,
        price: minPrice.totalPrice,
        adultPrice: minPrice.adultPrice!,
        occupancy: {
          adults: passengers.adults,
          childrenAge: passengers.childrenAge ?? [],
        },
        templateId,
        templateItemId,
      }

      const bookmarkResult = await processBookmarks({
        items: [payload],
        tripIdsAdded: [tripId],
        tripIdsRemoved: [],
      })

      return {
        savedItemIds: bookmarkResult.created.map((item) => item.id),
      }
    },
    [
      departDate,
      destinationAirport.code,
      fareClass,
      fareType,
      flights,
      originAirport.code,
      passengers,
      processBookmarks,
      returnDate,
      templateId,
      templateItemId,
    ],
  )

  const createTripItemsImmediate = useCallback(
    async(trip: FullTrip | BasicTrip) => {
      try {
        const res = await createTripItems(trip.id)
        const itemId = res.savedItemIds[0]
        dispatch(setCurrentSelectionId(tripItemSelectionId(itemId)))
        dispatch(setTripItemHasJustBeenAdded())
        modalPromise.current.then(() =>
          showSaveSuccessSnackbar(trip.id, trip.name, itemId, 'FLIGHT', true),
        )
        setRecentlySavedTripId(trip.id)
      } catch {
        // Do nothing - failure case is handled by the hook's onError handler
      }
    },
    [createTripItems, dispatch, showSaveSuccessSnackbar],
  )

  const hasFlights = !!flights?.length

  const newTripName = useMemo(() => {
    if (hasFlights) {
      const destinationName = destinationAirport.name.replace(
        / ?\(?all airports\)?/i,
        '',
      )
      const destination = getUniqueTripName(
        `${destinationName} Trip`,
        trips,
        MAX_TRIP_NAME_CHARS,
      )

      return destination.length <= MAX_TRIP_NAME_CHARS ? destination : ''
    }

    return ''
  }, [hasFlights, destinationAirport.name, trips])

  const showModal = useContext(ModalContext)
  const openSaveModal = useCallback(() => {
    return showModal<TripPlannerSaveModalResult>(
      <SaveModal
        offerImageId={DEFAULT_FLIGHT_IMAGE_ID}
        createTripItems={createTripItems}
        itemTypeLabel="flight"
        defaultTripName={newTripName}
        isCreatingItem={isCreating}
      />,
    )
  }, [createTripItems, isCreating, newTripName, showModal])

  const onSave = useCallback(async() => {
    didAutoSelectTrip.current = false
    const recentlySavedTripId = getRecentlySavedTripId()
    if (isSaved) {
      deleteBookmark({ tripId: details.trip.id, tripItemId: details.itemId })
    } else if (currentTrip) {
      fireInteractionEvent(
        saveToTripEvent('button', 'immersive', 'click', analyticsPage),
      )
      createTripItemsImmediate(currentTrip)
    } else {
      fireInteractionEvent(
        saveToTripEvent('button', 'global', 'click', analyticsPage),
      )
      const recentlySavedTrip = trips.find((t) => t.id === recentlySavedTripId)
      if (recentlySavedTrip) {
        didAutoSelectTrip.current = true
        createTripItemsImmediate(recentlySavedTrip)
      } else {
        modalPromise.current = openSaveModal()
        await modalPromise.current
        modalPromise.current = Promise.resolve()
      }
    }
  }, [
    currentTrip,
    createTripItemsImmediate,
    deleteBookmark,
    isSaved,
    openSaveModal,
    details,
    analyticsPage,
    trips,
  ])

  const onButtonClick = usePendingLoginHandler(
    onSave,
    'tripPlannerLogin',
    trackTripLoginModalSignUpView,
    trackTripLoginModalSignUpDismiss,
  )

  if (!hasFlights) {
    return null
  }

  if (!bookmarkableFareTypes.has(fareType as any)) {
    return null
  }

  return (
    <BaseBookmarkButton
      label={isSaved ? IS_SAVED_COPY : 'Save search'}
      isProcessing={isDeleting || isCreating}
      isSaved={isSaved}
      onClick={onButtonClick}
    />
  )
}

const mapStateToProps = (state: App.State) => {
  const flightSearchValues = getFlightSearchValues(state)

  return {
    originAirport: flightSearchValues.originAirport!,
    destinationAirport: flightSearchValues.destinationAirport!,
    departDate: flightSearchValues.departDate,
    returnDate: flightSearchValues.returnDate,
    fareType: flightSearchValues.fareType,
    passengers: flightSearchValues.occupants,
    flightSearchParams: getFlightSearchParams(state),
    search: state.router.location.search,
    bookmarkId: flightSearchQueryStringToBookmarkId(
      state.router.location.search,
    ),
  }
}

const connector = connect(mapStateToProps)

export default connector(FlightBookmarkButton)
