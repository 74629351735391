import moment from 'moment'
import { titleGenderMap } from 'checkout/lib/utils/cruises/cart'
import { RESERVATION_TYPE_INSTANT_BOOKING } from 'constants/reservation'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { createSelector } from 'reselect'
import generateDepartureRoomPriceBreakdown from 'lib/tours/generateDepartureRoomPriceBreakdown'
import { deduceGenderFromTitle, getPackageOptionType } from 'lib/tours/tourUtils'
import generateOccupancyStringByRoom from 'lib/offer/generateOccupancyStringByRoom'
import {
  findPostPurchaseCheckout,
  isBedbankItem,
  isBNBLLEHotelItem,
  isCarHireItem,
  isCruiseItem,
  isFlightItem,
  isInstantBookingLEHotelItem,
  isTourV1Item,
  isTourV2ExperienceItem,
  isTourV2Item,
} from 'lib/checkout/checkoutUtils'
import { arrayToObject, fillArray, groupBy, sum } from 'lib/array/arrayUtils'
import {
  CHECKOUT_ITEM_TYPE_FLIGHT,
  GIFT_RECIPIENT_FORM_ID, PRIMARY_TRAVELLER_FORM_ID,
} from 'constants/checkout'
import { getExperienceTimesKey } from 'lib/experiences/experienceUtils'
import { ITEM_TYPE_BEDBANK, ITEM_TYPE_EXPERIENCE, ITEM_TYPE_GIFT_CARD, ITEM_TYPE_INSURANCE, ITEM_TYPE_BOOKING_PROTECTION, ITEM_TYPE_BNBL_EXPERIENCE } from 'constants/cart'
import config from 'constants/config'
import uuidV4, { incrementUuidV4 } from 'lib/string/uuidV4Utils'
import { getPackageUniqueKey } from 'lib/offer/offerUtils'
import { computeBaggageCostForPaxList } from 'checkout/lib/utils/flights/extras'
import { OFFER_DYNAMIC_PRICING_TYPES } from 'constants/offer'
import { buildBaggagePayload, buildPassengerPayload } from 'checkout/lib/utils/flights/order'
import { buildTravellerInfo } from 'checkout/lib/utils/experiences/order'
import { getJourneyV2IdKey } from 'lib/flights/flightUtils'
import { excludeNullOrUndefined } from 'checkout/utils'
import { Order } from '@luxuryescapes/contract-svc-order'
import { generateScheduleDate } from 'lib/payment/gift'
import { ExperienceBookingType } from 'constants/experience'
import { getInstantBookingPriceAndSurcharge } from 'checkout/lib/utils/accommodation/price'
import { getExperienceItems, getTransferItems } from 'checkout/selectors/view/experience'
import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { getAge } from 'lib/datetime/dateUtils'
import { getCheckoutCruiseOfferView } from 'checkout/selectors/view/cruise'
import { TRANSPORTATION_CODE } from 'components/CarHire/CarHireHardcodedLists'
import {
  checkoutAccommodationOfferView,
  getLeInstanceBookingItems,
  getTourV2CheckoutItems,
} from 'checkout/selectors/view/accommodation'
import { getBundleAndSaveItemViews } from 'checkout/selectors/view/bundleAndSave'
import { getGiftCardItems } from 'checkout/selectors/view/giftCard'
import { getTravellerFormSchemaRequest, isTravellerFormNeeded } from 'checkout/selectors/request/travellerSchema'
import { baggageOptionsMap } from 'api/lib/convertJourneyV2'
import { getVillaViews } from '../view/villa'
import { getBookingProtectionItems } from '../view/bookingProtection'
import { getLuxPlusSubscriptionItems, checkoutWithMemberPrice, getSubscriptionJoinItems, shouldUseInsuranceMemberPrice } from '../view/luxPlusSubscription'
import { getFullOffers } from 'selectors/offerSelectors'
import getBookingProtectionDetails from 'checkout/selectors/request/getBookingProtectionDetails'
import { getFlightBreakdownView } from 'checkout/selectors/view/flights'
import { sumUpOccupancies } from 'lib/offer/occupancyUtils'
import { getBedbankChangeDatesTravellers } from '../bedbankChangeDatesTravellers'
import { getCoverGeniusAgeCategoryByDateOfBirth } from 'lib/insurance/insuranceHelpers'
import { getTourV2ExperienceItems } from '../view/toursv2'
import { isPostPurchaseTourChangeDates, isPostPurchaseTourOptionalExperience } from 'checkout/selectors/tourV2Selectors'
import { getCarHireSelectedRateOption } from 'checkout/lib/utils/carHire/view'
import { getOrderSubscriber } from 'luxPlus/selectors/checkout/order'
import { FlightViewTypes } from 'constants/flight'
import { floatify } from 'lib/maths/mathUtils'
import { getChannelMarkup } from 'selectors/channelMarkupSelector'
import { filterItemDiscounts, PromoItemDiscountsWithStatus } from 'lib/promo/promoMappers'
import { getAccommodationAndExperienceItemDiscounts, getAllPromoCodeItemDiscounts, getCruiseItemDiscounts, getExperienceItemDiscounts, getInsuranceItemDiscounts, GetPromoCodeItemDiscounts, getSubReoccuringItemDiscounts, getTransferItemDiscounts } from './checkout'
import getCheckoutTravellerSchema from 'checkout/selectors/getCheckoutTravellerSchema'
import { isAndroidAppUserAgent, isIOSAppUserAgent } from 'lib/web/deviceUtils'

function getTicketDate(date?: string, time?: string) {
  if (date && time) {
    const [hour, minute] = time.split(':')
    return moment.utc(date).minute(parseInt(minute)).hour(parseInt(hour)).format('YYYY-MM-DD[T]HH:mm:ss')
  } else {
    return moment.utc(date).startOf('day').format('YYYY-MM-DD[T]HH:mm:ss')
  }
}

/**
 * @see {@link Order.SourceApp}
 */
const getExperienceItemSourceApp = createSelector(
  (state: App.State) => isIOSAppUserAgent(state.system.rawUserAgentString) || isAndroidAppUserAgent(state.system.rawUserAgentString),
  (state: App.State) => state.interactionStudio.platform,
  (isMobileApp, platform): Order.SourceApp => {
    if (isMobileApp) {
      return 'mobile-app'
    } else if (platform === 'ios' || platform === 'android') {
      return 'mobile-web'
    }
    return 'web'
  },
)

export const getFormattedTravellers = createSelector(
  (state: App.State) => state.checkout.form.travellerForms,
  (state: App.State) => getCheckoutTravellerSchema(state),
  (travellerForms, schemaData) => {
    if (schemaData && travellerForms.length > 0) {
      return buildTravellerInfo(schemaData.accommodationFlightSchema, travellerForms)
    }
  },
)

const getFormattedTransferItems = createSelector(
  (state: App.State) => getTransferItems(state),
  (state: App.State) => state.experience.experiences,
  (state: App.State) => getTransferItemDiscounts(state),
  (state: App.State) => getExperienceItemSourceApp(state),
  (transferItems, experiences, itemDiscounts, sourceApp) => {
    return transferItems.map(item => {
      const experience = experiences[item.experienceId]
      const { transfer, transactionKey } = item
      if (experience && transfer.option && itemDiscounts.hasRequiredPromoCodeData) {
        const [hour, minute] = transfer.time?.split(':') ?? ['00', '00']
        const fullDateTime = moment.utc(transfer.date).minute(parseInt(minute)).hour(parseInt(hour)).format('YYYY-MM-DD[T]HH:mm:ss')

        return {
          type: ITEM_TYPE_EXPERIENCE,
          provider_offer_id: experience.id,
          brand: config.BRAND,
          transaction_key: transactionKey,
          total: transfer.option.price,
          taxes_and_fees: transfer.option.taxesAndFees,
          tickted: experience.ticketed,
          title: experience.name,
          categories: experience.categories,
          cancellation_policies: experience.cancellationPolicies.length > 0 ? {
            timezoneOffset: `${new Date().getTimezoneOffset()}`,
            refundPolicies: experience.cancellationPolicies.map(policy => ({
              id: policy.id,
              periodLabel: policy.periodLabel,
              periods: policy.periods,
              type: policy.type,
              value: policy.value,
            })),
          } : undefined,
          le_exclusive: experience.leExclusive,
          special_requests: transfer.specialRequests,
          pickup_point_name: transfer.type === 'HOTEL-TO-AIRPORT' ? transfer.hotel.name : experience.airport?.name,
          pickup_point_id: transfer.type === 'HOTEL-TO-AIRPORT' ? transfer.hotel.googlePlaceId : experience.airport?.code,
          dropoff_point_name: transfer.type === 'HOTEL-TO-AIRPORT' ? experience.airport?.name : transfer.hotel.name,
          dropoff_point_id: transfer.type === 'HOTEL-TO-AIRPORT' ? experience.airport?.code : transfer.hotel.googlePlaceId,
          deal_options: {
            adults: transfer.travellers.adults,
            children: transfer.travellers.children ?? 0,
            child_seats: transfer.travellers.childSeats ?? 0,
            booster_seats: transfer.travellers.boosterSeats ?? 0,
            option: transfer.option,
            flight_number: transfer.flightNumber,
            type: transfer.type,
            bags: transfer.baggage?.bags ?? 0,
            oversized_bags: transfer.baggage?.oversizedBags ?? 0,
          },
          ticket: {
            date: fullDateTime,
            // time is optional so we need separate fields to know whether it was set or not
            day: transfer.date,
            time: transfer.time,
            fareType: transfer.option.name,
            identifier: transfer.option.id,
            productId: transfer.option.id,
            type: transfer.type,
          },
          book_by_date: transfer.option?.bookByDate,
          ...((transfer.option?.discounts?.app.amount ?? 0) > 0 && {
            app_discount_amount: transfer.option.discounts?.app.amount,
            app_discount_percent: transfer.option.discounts?.app.percentage,
          }),
          item_discounts: itemDiscounts.itemDiscounts.map(formatItemDiscount),
          source_app: sourceApp,
        }
      }

      return null
    })
  },
)

const getFormattedBundleAndSaveItems = createSelector(
  (state: App.State) => getBundleAndSaveItemViews(state),
  (state: App.State) => state.checkout.form.travellerForms,
  (state: App.State) => state.auth.account,
  (state: App.State) => state.checkout.cart.specialRequests,
  (state: App.State) => getAllPromoCodeItemDiscounts(state),
  (views, travellerForms, account, specialRequests, promoCodeItemDiscounts) => {
    const data = views.data

    const primaryTraveller = travellerForms.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID) as App.Checkout.TravellerForm

    return data.flatMap((view) => {
      return view.hotels.map(hotelView => {
        const hotelViewPrice = hotelView.totals.price
        const newPrice = view.item.dates[hotelView.offer.id]?.newPrice
        const newSurcharge = view.item.dates[hotelView.offer.id]?.newSurcharge
        const newExtraGuestSurcharge = view.item.dates[hotelView.offer.id]?.newExtraGuestSurcharge

        const itemDiscounts = filterItemDiscounts({ itemDiscounts: promoCodeItemDiscounts.itemDiscounts, lookup: { specificType: 'cart-id-based', cartItemIds: [view.item.itemId] } })
        // TODO: We don't have an offical type for order item creation, create it
        const orderItem: any = {
          bundle_offer_id: view.bundleOffer.id,
          bundle_package_id: view.bundlePackage.id,
          reservation_type: RESERVATION_TYPE_INSTANT_BOOKING,
          check_in: moment.utc(hotelView.checkIn).format(ISO_DATE_FORMAT),
          check_out: moment.utc(hotelView.checkOut).format(ISO_DATE_FORMAT),
          number_of_nights: hotelView.duration,
          number_of_adults: view.occupancy.adults,
          number_of_children: view.occupancy.children,
          number_of_infants: view.occupancy.infants,
          children_ages: view.occupancy.childrenAge ?? [],
          rate_plan_id: hotelView.package.roomRate?.id,
          guest_first_name: primaryTraveller?.firstName,
          guest_last_name: primaryTraveller?.lastName,
          cart_item_id: view.item.itemId,
          offer_id: hotelView.offer.id,
          package_id: hotelView.package.id,
          room_rate_id: hotelView.package.roomRate?.id,
          transaction_key: uuidV4(),
          offer_booking_type: 'reservation',
          offer_type: hotelView.offer.type,
          offer_name: hotelView.offer.name,
          package_name: hotelView.package.name,
          offer_slug: view.bundleOffer.slug,
          price: newPrice || hotelViewPrice,
          value: hotelView.package.value,
          surcharge: newSurcharge ?? hotelView.totals.surcharge - hotelView.totals.extraGuestSurcharge,
          guest_special_requests: specialRequests[view.item.itemId],
          extra_guest_surcharge: newExtraGuestSurcharge ?? hotelView.totals.extraGuestSurcharge,
          property_fees: hotelView.totals.otherFees?.propertyFees,
          localizedPrices: hotelView.package.trackingPrice,
          customer_email: primaryTraveller?.email,
          customer_name: account?.fullName,
          room_rate_amount: undefined,
          item_discounts: itemDiscounts.map(formatItemDiscount),
        }

        if (OFFER_DYNAMIC_PRICING_TYPES.includes(hotelView.offer.type)) {
          orderItem.room_rate_amount = newPrice || hotelViewPrice
        }

        return orderItem
      })
    })
  },
)

const getFormattedTourV2Items = createSelector(
  (state: App.State) => getTourV2CheckoutItems(state),
  (state: App.State) => getTourV2ExperienceItems(state),
  (state: App.State) => state.checkout.cart.specialRequests,
  (state: App.State) => getFullOffers(state),
  (state: App.State) => state.checkout.form.travellerForms,
  (state: App.State) => getCheckoutTravellerSchema(state),
  (state: App.State) => getAllPromoCodeItemDiscounts(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => isPostPurchaseTourChangeDates(state),
  (
    tourV2Items,
    tourExperiencesItems,
    specialRequests,
    offers,
    travellerForms,
    schemaData,
    promoCodeItemDiscounts,
    checkoutWithMemberPrice,
    isTourDateChange,
  ): App.WithDataStatus<Array<any>> => {
    const groupedTourItems = groupBy(tourV2Items, (item: App.Checkout.TourV2Item) => item.purchasableOption.fkDepartureId)
    if (groupedTourItems.size === 0) {
      return {
        data: [],
        hasRequiredData: tourV2Items.filter(isTourV2Item).length === 0,
      }
    }
    if (!promoCodeItemDiscounts.hasRequiredPromoCodeData) {
      return { data: [], hasRequiredData: false }
    }

    if (isTourDateChange) {
      const formattedTourV2Items = Array.from(groupedTourItems.values())
        .map(itemGroup => {
          const offer = offers[itemGroup[0].offerId] as App.Tours.TourV2Offer
          return formatTourV2ItemGroupDateChanges(itemGroup, offer, checkoutWithMemberPrice)
        })
      return { data: formattedTourV2Items, hasRequiredData: true }
    }

    if (schemaData && travellerForms.length > 0) {
      const formattedTourV2Items = Array.from(groupedTourItems.values())
        .map(itemGroup => {
          const offer = offers[itemGroup[0].offerId] as App.Tours.TourV2Offer
          const primaryTraveller = travellerForms.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID) as App.Checkout.TravellerForm
          if (!offer || !primaryTraveller) {
            return { data: [], hasRequiredData: false }
          }
          return formatTourV2ItemGroup(
            itemGroup, tourExperiencesItems, specialRequests, travellerForms,
            primaryTraveller, offer, schemaData.accommodationFlightSchema, checkoutWithMemberPrice, promoCodeItemDiscounts.itemDiscounts)
        })
      return { data: formattedTourV2Items, hasRequiredData: true }
    }
    return {
      data: [],
      hasRequiredData: tourV2Items.filter(isTourV2Item).length === 0,
    }
  },
)

const getFormattedTourExperienceItems = createSelector(
  (state: App.State) => getTourV2ExperienceItems(state),
  (state: App.State) => isPostPurchaseTourOptionalExperience(state),
  (tourExperienceItems: Array<App.Checkout.TourV2ExperienceItem>, isPostPurchaseTourExperience: boolean): App.WithDataStatus<Array<any>> => {
    const data = [{
      totalprice: sum(tourExperienceItems.map(item => item.total)),
      optional_experiences: tourExperienceItems.map(formatTourExperienceItem),
      type: 'tour_optional_experience',
      transaction_key: uuidV4(),
    }]
    return {
      data: isPostPurchaseTourExperience ? data : [],
      hasRequiredData: isPostPurchaseTourExperience ? tourExperienceItems.filter(isTourV2ExperienceItem).length === 0 : true,
    }
  },
)

export const getFormattedCruiseItems = createSelector(
  (state: App.State) => state.checkout.cart.items,
  (state: App.State) => getCheckoutCruiseOfferView(state),
  (state: App.State) => state.checkout.form.travellerForms,
  (state: App.State) => getCruiseItemDiscounts(state),
  (items, offerViewsWithStatus, travellerForms, { itemDiscounts, hasRequiredPromoCodeData }): App.WithDataStatus<Array<any>> => {
    if (!travellerForms || !offerViewsWithStatus.hasRequiredData) {
      return { data: [], hasRequiredData: false }
    }

    const cruiseItems = items.filter(isCruiseItem)
    const offerViews = offerViewsWithStatus.data
    const result = offerViews.flatMap(offerView => {
      const { itemViews } = offerView
      return itemViews.map(itemView => {
        const { departure } = itemView
        const itemTravelers = travellerForms.filter(form => form.relatedItemId === itemView.item.itemId)

        if (!departure || !itemTravelers.length || !hasRequiredPromoCodeData) { return null }

        return formatCruiseItem(itemView, itemTravelers, itemDiscounts)
      })
    }).filter(excludeNullOrUndefined)

    return { data: result, hasRequiredData: cruiseItems.length === 0 }
  },
)

export const getFormattedExperienceItems = createSelector(
  (state: App.State) => getExperienceItems(state),
  (state: App.State) => state.experience.experiences,
  (state: App.State) => state.experience.experienceTimes,
  (state: App.State) => state.checkout.cart.currencyCode,
  (state: App.State) => state.checkout.cart.specialRequests,
  (state: App.State) => getFormattedTravellers(state),
  (state: App.State) => getExperienceItemDiscounts(state),
  (state: App.State) => getExperienceItemSourceApp(state),
  (experienceItems, experiences, experienceTimes, currencyCode, specialRequestsMap, travellers, itemDiscounts, sourceApp) => {
    if (!travellers || !itemDiscounts.hasRequiredPromoCodeData) { return [] }

    return experienceItems
      .map(item => {
        const experience = experiences[item.experienceId]

        // order service expects to send expiration date in ticket.date if open date item
        // if it's a BNBL or GIFT, value should be undefined
        let ticketDate: string | undefined
        if (!(item.isBuyNowBookLater || item.isGift)) {
          ticketDate = ['NO-CALENDAR-FIXED-END', 'NO-CALENDAR-FIXED-VALIDITY'].includes(experience.bookingType) ?
            experience.expirationDate :
            getTicketDate(item.date, item.time)
        }

        const pickupPoint = experience.pickupPoints.find((pickupPoint) => pickupPoint.id == item.pickupPointId)
        const redemptionLocation = experience.redemptionLocations.find((location) => location.id == item.redemptionLocationId)

        const key = getExperienceTimesKey(item.experienceId, item.date, {
          currency: currencyCode,
          pickupPointId: item.pickupPointId,
          redemptionLocationId: item.redemptionLocationId,
          isBuyNowBookLater: item.isBuyNowBookLater,
          isGift: item.isGift,
          ticketMode: item.ticketModeKey,
        })

        const timeSlots = experienceTimes[item.experienceId]?.[key]?.slots
        const tickets = timeSlots?.find(slot => slot.time === item.time)?.tickets ?? timeSlots?.flatMap(slot => slot.tickets)
        const ticketMap = arrayToObject(tickets, (ticket) => ticket.id)

        let nextTransactionKey = item.transactionKey
        let participantsCount = 0
        return item.tickets.map(ticket => {
          const ticketDetails = ticketMap[ticket.ticketId]

          // for each ticket item (count) group the corresponding participant
          const participants = travellers.participants?.slice(participantsCount, participantsCount + ticket.count)
          participantsCount += ticket.count

          return fillArray(ticket.count).map((index) => {
            // An experience item only has one transaction key,
            // but we need a separate key per item we send to svc-order.
            // So we'll increment the key to create a new one per ticket.
            const transactionKey = nextTransactionKey
            nextTransactionKey = incrementUuidV4(nextTransactionKey)

            // Store bookByDate from ticket if there's one
            const bookByDate = ticketDetails.bookByDate ?? experience.bookByDate
            return {
              type: item.isBookingBNBL ? ITEM_TYPE_BNBL_EXPERIENCE : ITEM_TYPE_EXPERIENCE,
              id_experience_items: ticket.orderItemIds?.[index],
              provider_offer_id: experience.id,
              brand: config.BRAND,
              transaction_key: transactionKey,
              total: ticketDetails.price,
              language: item.languageId,
              categories: experience.categories,
              cancellation_policies: experience.cancellationPolicies.length > 0 ? {
                isFree: experience.copy.cancellationInfo.isFree,
                timezoneOffset: `${new Date().getTimezoneOffset()}`,
                refundPolicies: experience.cancellationPolicies.map(policy => ({
                  id: policy.id,
                  periods: policy.periods,
                  periodLabel: policy.periodLabel,
                  type: policy.type,
                  value: policy.value,
                })),
                text: experience.copy.cancellationInfo.text,
              } : undefined,
              le_exclusive: experience.leExclusive,
              redemption_location_id: redemptionLocation?.id,
              redemption_location_name: redemptionLocation?.name,
              pickup_point_id: pickupPoint?.id,
              pickup_point_name: pickupPoint?.name,
              special_requests: specialRequestsMap[item.experienceId],
              traveller_info: {
                customer: travellers.customer,
                ...(participants?.length && {
                  participants: participants.map(participant => ({
                    [ticketDetails.productId]: participant,
                  })),
                }),
              },
              ticket: {
                fareType: ticketDetails.name,
                identifier: ticketDetails.id,
                productId: ticketDetails.productId,
                type: ticketDetails.type,
                date: ticketDate,
                rateStartDate: ticketDetails.rateStartDate,
                rateEndDate: ticketDetails.rateEndDate,
                bookByDate,
                isExtra: ticketDetails.isExtra,
              },
              title: experience.name,
              booking_type: (item.isBuyNowBookLater || item.isGift) ? ExperienceBookingType.BUY_NOW_BOOK_LATER :
                ExperienceBookingType.INSTANT_BOOKING,
              taxes_and_fees: ticketDetails.taxesAndFees,
              ticketed: experience.ticketed,
              ...(ticketDetails.discounts.app.amount > 0 && {
                app_discount_amount: ticketDetails.discounts.app.amount,
                app_discount_percent: ticketDetails.discounts.app.percentage,
              }),
              item_discounts: filterItemDiscounts({ itemDiscounts: itemDiscounts.itemDiscounts, lookup: { specificType: 'experience', transactionKeys: [transactionKey] } }).map(formatItemDiscount),
              source_app: sourceApp,
            }
          })
        })
      }).flat(2)
  },
)

const getFormattedInsuranceItems = createSelector(
  (state: App.State) => getInsuranceItemsView(state),
  (state: App.State) => state.checkout.form.travellerForms,
  (state: App.State) => getOrderSubscriber(state),
  (state: App.State) => getInsuranceItemDiscounts(state),
  (state: App.State) => shouldUseInsuranceMemberPrice(state),
  (
    itemView,
    travellers,
    subscriber,
    insuranceItemDiscounts,
    useMemberPrice,
  ) => {
    const primaryTraveller = travellers.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID)

    if (!primaryTraveller) {
      return []
    }

    if (!insuranceItemDiscounts.hasRequiredPromoCodeData) {
      return []
    }

    // views must have a quote associated with them -
    // those without a quote id are most likely post purchase updates and are handled via
    // the specific post purchase operation
    return itemView.data.filter(viewItem => viewItem.quoteId).map(viewItem => {
      const total = useMemberPrice ? viewItem.totals.memberPrice : viewItem.totals.price

      return {
        type: ITEM_TYPE_INSURANCE,
        product_id: viewItem.item.productId,
        quote_id: viewItem.quoteId,
        brand: config.BRAND,
        transaction_key: viewItem.item.transactionKey,
        policy_holder_firstname: primaryTraveller.firstName,
        policy_holder_surname: primaryTraveller.lastName,
        travellers_details: travellers.map(
          traveller => ({
            firstname: traveller.firstName,
            surname: traveller.lastName,
            age_range: traveller.dateOfBirth ? getCoverGeniusAgeCategoryByDateOfBirth(traveller.dateOfBirth) : traveller.id.split('-')[0],
          })),
        insurance_type: viewItem.item.insuranceType,
        total: floatify(total),
        subscriber,
        mobile_only_price: !!viewItem.mobileAppPrice,
        item_discounts: insuranceItemDiscounts.itemDiscounts.map(formatItemDiscount),
      }
    })
  },
)

const getFormattedBookingProtectionItems = createSelector(
  (state: App.State) => getBookingProtectionItems(state),
  (state: App.State) => getBookingProtectionDetails(state),
  (bookingProtectionItems, bookingProtectionDetails): Array<App.Checkout.BookingProtectionDetails> | [] => {
    if (!bookingProtectionDetails) return []

    return bookingProtectionItems.map(item => ({
      type: ITEM_TYPE_BOOKING_PROTECTION,
      transaction_key: item.transactionKey,
      ...bookingProtectionDetails,
    }))
  },
)

export const getFormattedGiftCardItems = createSelector(
  (state: App.State) => state.auth.account,
  (state: App.State) => getGiftCardItems(state),
  (account, items) => {
    return items.map(item => ({
      type: ITEM_TYPE_GIFT_CARD,
      brand: config.BRAND,
      transaction_key: item.transactionKey,
      gift_card_value: item.amount,
      customer_email: account.email,
      customer_name: account.fullName,
      personalised: {
        method: item.deliveryMethod,
        to: item.content.recipientName,
        from: item.content.fromName,
        message: item.content.message,
        email_of_recipient: item.content.recipientEmail,
        is_blank_message: !item.content.message,
      },
      product: item.product ? {
        name: item.product.name,
        items: item.product.items,
        imageId: item.product.image.id,
        imageUrl: item.product.image.url,
        type: item.product.type,
        url: item.product.url,
        location: item.product.location,
      } : undefined,
    }))
  },
)

const selectFormattedBusinessTravellerCreditItem = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getFlightBreakdownView(state),
  (
    accommodationOfferViews,
    flightBreakdownViewsWithStatus,
  ): App.BusinessTraveller.CreditOrderItem | undefined => {
    if (config.businessTraveller.currentAccountMode !== 'business') {
      return undefined
    }

    let total = 0
    for (const offerView of accommodationOfferViews.data) {
      offerView.itemViews.forEach((itemView: App.Checkout.AnyItemView) => {
        total += itemView.totals.businessTravellerCredits ?? 0
      })
    }

    for (const flightBreakdownView of flightBreakdownViewsWithStatus.data) {
      total += flightBreakdownView.businessTravellerCredits ?? 0
    }

    if (total === 0) {
      return undefined
    }

    return {
      type: 'business_credit',
      transaction_key: uuidV4(),
      total,
    }
  },
)

export function formatCartItems(
  cartState: App.CheckoutCartState,
  offers: Record<string, App.AnyOffer>,
  travellerForms: Array<App.Checkout.TravellerForm>,
  journeys: Record<string, App.AnyJourney>,
  account: App.AuthAccount,
  isTravellerFormNeeded: boolean,
  formSchemaRequest: { items: Array<any> },
  formattedInstantBookingHotelItems: Array<unknown>,
  formattedTourV2Items: App.WithDataStatus<Array<unknown>>,
  formattedExperienceItems: Array<unknown>,
  formattedTransferItems: Array<unknown>,
  formattedInsuranceItems: Array<unknown>,
  getFormattedBookingProtectionItems: Array<unknown>,
  formattedCruiseItems: App.WithDataStatus<Array<unknown>>,
  formattedBundleAndSaveItems: Array<unknown>,
  formattedGiftCardItems: Array<unknown>,
  formattedVillaItems: Array<unknown>,
  formattedBusinessTravellerCreditItem: App.BusinessTraveller.CreditOrderItem | undefined,
  formattedLuxPlusSubscriptionItems: Array<FormattedOrderSubscriptionItem>,
  formattedSubscriptionJoinFeeItems: Array<FormattedOrderSubscriptionItem>,
  checkoutWithMemberPrice: boolean,
  formattedTourExperienceItems: App.WithDataStatus<Array<unknown>>,
  existingOrderTraveller: App.ExistingOrderBedbankTraveller | undefined,
  promoCodeItemDiscounts: PromoItemDiscountsWithStatus,
  channelMarkup?: App.ChannelMarkup,
): App.WithDataStatus<Array<any>> {
  const { itemDiscounts: allItemDiscounts, hasRequiredPromoCodeData } = promoCodeItemDiscounts

  if (
    isTravellerFormNeeded &&
    formSchemaRequest.items.length > 0 &&
    !travellerForms.some(form => form.id === PRIMARY_TRAVELLER_FORM_ID) ||
    !promoCodeItemDiscounts.hasRequiredPromoCodeData
  ) {
    return { hasRequiredData: false, data: [] }
  }

  let hasRequiredData = true

  const { items, specialRequests, arrivalFlightNumber } = cartState

  const formattedBNBLHotelItems = items
    .filter(isBNBLLEHotelItem)
    .map(item => {
      const offer = offers[item.offerId] as App.Offer
      if (!offer) {
        hasRequiredData = false
        return null
      }

      return formatLeHotelItem(
        item,
        travellerForms,
        specialRequests[item.itemId],
        offer,
        undefined,
        account,
        checkoutWithMemberPrice,
        undefined,
        undefined,
        allItemDiscounts,
        channelMarkup,
      )
    })

  const formattedTourV1Items = items
    .filter(isTourV1Item)
    .map((item, idx) => {
      const offer = offers[item.offerId] as App.Offer
      const { itemDiscounts: allItemDiscounts } = promoCodeItemDiscounts

      if (!offer) {
        hasRequiredData = false
        return null
      }

      if (travellerForms[idx]) {
        const itemDiscounts = filterItemDiscounts({ itemDiscounts: allItemDiscounts, lookup: { specificType: 'cart-id-based', cartItemIds: [item.itemId] } })
        return formatLeTourV1Item(item, travellerForms[idx], specialRequests[item.itemId], offer, account, itemDiscounts)
      }
    })

  const formattedCarHireItems = items
    .filter(isCarHireItem)
    .map((item) => formatCarHireItem(item, travellerForms, arrivalFlightNumber, allItemDiscounts))

  // Bedbank cart room items need to be grouped together
  // Until the implementation changes on `svc-order`
  // As of now, there will only ever be 1 group
  const groupedBedbankItems = [...groupBy(items.filter(isBedbankItem), item => item.sessionId).values()]

  const formattedBedbankItems = groupedBedbankItems.map(group => {
    const firstItem = group[0]
    const primaryTraveller = travellerForms.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID) as App.Checkout.TravellerForm
    const offer = offers[firstItem.offerId] as App.BedbankOffer
    if (!offer) {
      hasRequiredData = false
      return null
    }
    const rooms = group.map(item => item.occupancy)
    const requests = group.map(item => item.itemId).map(id => specialRequests?.[id] ?? '').join(',')
    const itemDiscounts = filterItemDiscounts({
      itemDiscounts: allItemDiscounts,
      lookup: { specificType: 'accommodation' },
    })
    return formatBedbankItem(firstItem, requests, primaryTraveller, rooms, itemDiscounts, existingOrderTraveller)
  }).filter(Boolean)

  const formattedFlightItems = items
    .filter(isFlightItem)
    .reduce<Array<unknown>>((formattedItems, item) => {
      let flightJourneys: Array<App.JourneyV2> = []

      if (item.searchId) {
        const keys = item.flights.map(flight => getJourneyV2IdKey(flight.journeyId, item.searchId!, flight.fareFamily?.id))
        flightJourneys = keys.map(key => journeys[key] as App.JourneyV2)
      }

      if (!flightJourneys[0] || !item.hasFinalFare) {
        hasRequiredData = false
      }

      const data = flightJourneys[0] ? [
        ...formattedItems,
        ...formatFlightItem(item, travellerForms, flightJourneys, allItemDiscounts),
      ] : formattedItems

      return data
    }, [])

  // Check if data requirements satisfied
  hasRequiredData = hasRequiredData &&
    formattedTourV2Items.hasRequiredData &&
    formattedCruiseItems.hasRequiredData &&
    formattedTourExperienceItems.hasRequiredData &&
    hasRequiredPromoCodeData

  const formattedItems = [
    ...formattedInstantBookingHotelItems,
    ...formattedBNBLHotelItems,
    ...formattedTourV1Items,
    ...formattedTourV2Items.data,
    ...formattedBedbankItems,
    ...formattedFlightItems,
    ...formattedExperienceItems,
    ...formattedTransferItems,
    ...formattedInsuranceItems,
    ...getFormattedBookingProtectionItems,
    ...formattedCruiseItems.data,
    ...formattedCarHireItems,
    ...formattedBundleAndSaveItems,
    ...formattedGiftCardItems,
    ...formattedVillaItems,
    ...formattedLuxPlusSubscriptionItems,
    ...formattedSubscriptionJoinFeeItems,
    ...formattedTourExperienceItems.data,
  ]

  if (config.businessTraveller.currentAccountMode === 'business' && formattedBusinessTravellerCreditItem) {
    formattedItems.push(formattedBusinessTravellerCreditItem)
  }

  return {
    hasRequiredData,
    data: formattedItems,
  }
}

function formatLeHotelItem(
  item: App.Checkout.InstantBookingLEHotelItem | App.Checkout.BNBLLEHotelItem,
  travellerForms: Array<App.Checkout.TravellerForm>,
  specialRequest: string,
  offer: App.Offer,
  instantBookingPricing: (App.Checkout.ItemViewTotals & {
    extraGuestSurchargesPayableAtProperty?: boolean,
  }) | undefined,
  account: App.AuthAccount,
  checkoutWithMemberPrice: boolean,
  cartMode?:App.CheckoutCartMode,
  arrivalDetails?: App.ArrivalDetails,
  allItemDiscounts?: Array<App.Checkout.ItemDiscount>,
  channelMarkup?: App.ChannelMarkup,
) {
  const pkgUniqueKey = getPackageUniqueKey(item.packageId, item.duration, item.roomRateId)
  const pkg = offer.packages.find(pkg => pkg.uniqueKey === pkgUniqueKey)
  if (!pkg) {
    throw new Error(`Package with unique key ${pkgUniqueKey} not found in offer ${offer.id}`)
  }

  // primaryTraveller will be absent on package upgrading
  const primaryTraveller = travellerForms.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID)

  const priceFields = {
    price: instantBookingPricing?.price ?? pkg.price ?? 0,
    memberPrice: (instantBookingPricing?.memberPrice || pkg.memberPrice) ?? 0,
    taxesAndFees: instantBookingPricing?.taxesAndFees ?? pkg.taxesAndFees ?? 0,
    surcharge: instantBookingPricing?.surcharge ?? 0,
    extraGuestSurcharge: instantBookingPricing?.extraGuestSurcharge ?? 0,
    extraGuestSurchargesPayableAtProperty: instantBookingPricing?.extraGuestSurchargesPayableAtProperty ?? false,
  }
  const itemDiscounts = filterItemDiscounts({ itemDiscounts: allItemDiscounts ?? [], lookup: { specificType: 'accommodationAndExperiences', itemIds: [item.itemId] } })

  const newBookingFields = {
    offer_id: offer.id,
    offer_type: offer.type,
    offer_name: offer.name,
    offer_slug: offer.slug,
    offer_booking_type: 'reservation',
    package_id: pkg.id,
    room_rate_id: pkg.roomRate?.id,
    package_name: pkg.name,
    number_of_nights: pkg.duration,
    value: pkg.value,
    localizedPrices: pkg.trackingPrice,
    price: checkoutWithMemberPrice && priceFields.memberPrice > 0 ? priceFields.memberPrice : priceFields.price,
    surcharge: priceFields.surcharge,
    extra_guest_surcharge: priceFields.extraGuestSurchargesPayableAtProperty ? 0 : priceFields.extraGuestSurcharge,
    extra_guest_surcharge_payable_at_property: priceFields.extraGuestSurchargesPayableAtProperty ? priceFields.extraGuestSurcharge : 0,
    reservation_type: item.reservationType,
  }

  if (item.newPrice) {
    // accept a new price due to price change
    newBookingFields.price = item.newPrice
  }
  if (item.newSurcharge) {
    newBookingFields.surcharge = item.newSurcharge
  }
  if (item.newExtraGuestSurcharge) {
    newBookingFields.extra_guest_surcharge = item.newExtraGuestSurcharge
  }

  const selectDateFields = {
    ...(cartMode == 'select-date' && 'orderItemId' in item && {
      type: 'reservation',
      itemId: item.orderItemId,
      peak_period_surcharge: priceFields.surcharge,
      extra_guest_surcharge: priceFields.extraGuestSurcharge,
    }),
  }

  const arrivalDetailFields = {
    ...(arrivalDetails && {
      arrival_date: moment.utc(arrivalDetails.arrivalDate).format(ISO_DATE_FORMAT),
      arrival_time: arrivalDetails.arrivalTime,
      arrival_flight_number: arrivalDetails.arrivalFlightNumber,
    }),
  }

  const data: any = {
    // common fields
    cart_item_id: item.itemId,
    transaction_key: item.transactionKey,
    guest_special_requests: specialRequest,
    customer_email: primaryTraveller?.email || account.email,
    customer_name: account.fullName,
    arrival_details: arrivalDetailFields,
    item_discounts: itemDiscounts.map(formatItemDiscount),
    ...(cartMode === 'select-date' ? selectDateFields : newBookingFields),
  }

  if (item.reservationType === RESERVATION_TYPE_INSTANT_BOOKING) {
    data.check_in = moment.utc(item.checkIn).format(ISO_DATE_FORMAT)
    data.check_out = moment.utc(item.checkOut).format(ISO_DATE_FORMAT)
    data.number_of_adults = item.occupancy.adults
    data.number_of_children = item.occupancy.children
    data.number_of_infants = item.occupancy.infants
    data.children_ages = item.occupancy.childrenAge ?? []
    data.rate_plan_id = pkg.roomRate?.id

    if (primaryTraveller) {
      data.guest_first_name = primaryTraveller.firstName
      data.guest_last_name = primaryTraveller.lastName
      data.guest_phone = primaryTraveller.prefix && primaryTraveller.phone ? `${primaryTraveller.prefix}${primaryTraveller.phone}` : undefined
    }

    if (OFFER_DYNAMIC_PRICING_TYPES.includes(offer.type)) {
      const instantBookingPrice = instantBookingPricing?.price ?? 0
      data.room_rate_amount = item.newPrice || instantBookingPrice
    }
  }

  if (channelMarkup && !channelMarkup.channelMarkupBlacklist.includes(offer.id)) {
    data.channel_markup_id = channelMarkup.channelMarkupId
  }

  return data
}

function formatLeTourV1Item(
  item: App.Checkout.LETourV1Item,
  form: App.Checkout.TravellerForm,
  specialRequest: string,
  offer: App.Offer,
  account: App.AuthAccount,
  itemDiscounts: Array<App.Checkout.ItemDiscount>,
) {
  const pkg = offer.packages.find(pkg => pkg.id === item.packageId)
  if (!pkg) {
    throw new Error(`Package with id ${item.packageId} not found in offer ${offer.id}`)
  }
  const dob = form.dob || form.dateOfBirth
  const phone = form.prefix && form.phone ? `${form.prefix}${form.phone}` : undefined

  return {
    cart_item_id: item.itemId,
    transaction_key: item.transactionKey,

    offer_id: offer.id,
    offer_type: offer.type,
    offer_name: offer.name,
    offer_slug: offer.slug,

    package_id: pkg.id,
    room_rate_id: pkg.roomRate?.id,
    package_name: pkg.name,
    value: pkg.value,
    price: pkg.price,
    localizedPrices: pkg.trackingPrice,
    number_of_days: pkg.duration,

    offer_booking_type: 'reservation',
    reservation_type: RESERVATION_TYPE_INSTANT_BOOKING,
    guest_special_requests: specialRequest,

    customer_email: account.email,
    customer_name: account.fullName,

    start_date: moment.utc(item.startDate).format(ISO_DATE_FORMAT),
    end_date: moment.utc(item.endDate).format(ISO_DATE_FORMAT),

    guest_first_name: form.firstName,
    guest_middle_name: form.middleName,
    guest_last_name: form.lastName,
    guest_title: form.title,
    guest_phone: phone,

    dob: moment.utc(dob).format(ISO_DATE_FORMAT),
    postcode: form.postcode,
    item_discounts: itemDiscounts.map(formatItemDiscount) ?? [],
  }
}

function formatTourExperienceItem(experienceItem: App.Checkout.TourV2ExperienceItem) {
  const count = sumUpOccupancies(experienceItem.occupants)
  if (count === 0) {
    throw new Error(`Invalid Tour optional experience details count: ${count}`)
  }
  return {
    id: experienceItem.purchasableOption.fkExperienceId,
    itinerary_id: experienceItem.purchasableOption.fkItineraryId,
    day_number: experienceItem.purchasableOption.day,
    duration: experienceItem.purchasableOption.duration,
    name: experienceItem.purchasableOption.name,
    description: experienceItem.purchasableOption.description,
    location: experienceItem.purchasableOption.location,
    price: experienceItem.purchasableOption.price,
    time_slot: experienceItem.purchasableOption.timeSlot,
    count,
    date: experienceItem.date,
  }
}

/**
 *
 * @param itemDiscount The item discount to format
 * @returns the formatted item discount to be send to svc-order
 */
function formatItemDiscount(itemDiscount: App.Checkout.ItemDiscount): App.Checkout.FormattedItemDiscount {
  return {
    item_id: itemDiscount.itemId,
    offer_id: itemDiscount.offerId,
    category_bk: itemDiscount.categoryBK,
    sub_category_bk: itemDiscount.subCategoryBK,
    discount_value: itemDiscount.discountValue,
    discount_type: itemDiscount.discountType,
    discount_amount: itemDiscount.discountAmount,
    discountable_total: itemDiscount.discountableTotal,
    hide_discount_percentage: itemDiscount.hideDiscountPercentage,
    item_info_string: itemDiscount.itemInfoString,
  }
}

function formatTourV2ItemGroup(
  /** An array of items for a particular tour/option/departure */
  items: Array<App.Checkout.TourV2Item>,
  experienceItems: Array<App.Checkout.TourV2ExperienceItem>,
  specialRequests: { [itemId: string]: string; },
  travellerForms: Array<App.Checkout.TravellerForm>,
  primary: App.Checkout.TravellerForm,
  offer: App.Tours.TourV2Offer,
  schema: App.Checkout.FormSchema,
  checkoutWithMemberPrice: boolean,
  allItemDiscounts: Array<App.Checkout.ItemDiscount>,
) {
  const occupants = schema.properties
  const occupantsRoomMap: Record<string, { index: number, unit: string }> = Object.entries(occupants)
    .reduce((acc, [key, value]) => {
      const relatedItem = value.relatedItem
      if (!relatedItem) { return acc }
      return { ...acc, [key]: { index: relatedItem.index, type: relatedItem.unit } }
    }, {})
  const travellerDetails = travellerForms.map((form, i) => {
    const formId = form.id
    const roomType = items[occupantsRoomMap[formId].index]?.purchasableOption.roomType
    const roomNumber = occupantsRoomMap[formId].index + 1
    const res = {
      first_name: form.firstName,
      middle_name: form.middleName ? form.middleName : undefined,
      last_name: form.lastName,
      email_address: form.email,
      title: form.title,
      gender: deduceGenderFromTitle(form.title as App.Title),
      type: form.id.split('-')[0],
      date_of_birth: form.dateOfBirth,
      is_lead_passenger: i === 0,
      room_type: roomType,
      room_number: roomNumber,
      phone_prefix: form.prefix,
      phone: form.phone,
      departure_airport: form.departureAirportsList ?? undefined,
    }

    return res
  })

  const phone = `+${primary.prefix}${primary.phone}`

  // non-TTC tours don't need address
  const requiresAddress = offer.productType === 'connection_tour'

  const address = {
    city: primary.city,
    country_code: primary.countryOfResidence,
    line1: primary.address,
    postcode: primary.auPostcodeField ?? primary.usPostcodeField ?? primary.postcode,
    region: primary.auStateField ?? primary.usStateField ?? primary.state,
  }

  const contactDetails = {
    phone,
    email: primary.email,
    ...(requiresAddress ? { address } : {}),
  }

  const price = sum(
    items.map(
      item => generateDepartureRoomPriceBreakdown(
        item.occupancy,
        item.purchasableOption,
        offer,
      ).totalPrice,
    ),
  )

  const memberPrice = sum(
    items.map(
      item => generateDepartureRoomPriceBreakdown(
        item.occupancy,
        item.purchasableOption,
        offer,
      ).totalMemberPrice,
    ),
  )

  const tourPrice = checkoutWithMemberPrice && memberPrice > 0 ? memberPrice : price
  const totalPrice = sum([tourPrice, ...experienceItems.map(item => item.total!)])

  const tourOptionId = offer.variations[items[0].purchasableOption.fkVariationId].fkTourOptionId
  const package_option = getPackageOptionType(items[0].purchasableOption.roomType)

  const itemDiscounts = filterItemDiscounts({ itemDiscounts: allItemDiscounts, lookup: { specificType: 'tourAndCruiseItems' } })
  return {
    type: 'tour',
    product_type: offer.productType,
    // Since the items are grouped by departure, these IDs are the same for the entire group. Can take them from the first item
    tour_id: items[0].offerId,
    fine_print: offer.finePrint?.sections,
    tour_option_id: tourOptionId,
    departure_id: items[0].purchasableOption.fkDepartureId,
    transaction_key: items[0].transactionKey,
    price: totalPrice,
    rooms: items.map(item => ({
      room_type: item.purchasableOption.roomType,
      adult_count: item.occupancy.adults,
      child_count: item.occupancy.childrenAge?.length ?? 0,
      child_ages: item.occupancy.childrenAge ?? [],
      special_requests: specialRequests[item.itemId],
      room_type_pricing_id: item.purchasableOption.fkRoomTypePricingId,
    })),
    traveller_details: travellerDetails,
    contact_details: contactDetails,
    package_option,
    optional_experiences: experienceItems.map(formatTourExperienceItem),
    item_discounts: itemDiscounts.map(formatItemDiscount),
  }
}

function formatTourV2ItemGroupDateChanges(
  items: Array<App.Checkout.TourV2Item>,
  offer: App.Tours.TourV2Offer,
  checkoutWithMemberPrice: boolean,
) {
  const itemsPricing = items.map(
    item => generateDepartureRoomPriceBreakdown(
      item.occupancy,
      item.purchasableOption,
      offer,
    ),
  )
  const price = sum(itemsPricing, item => item.totalPrice)
  const memberPrice = sum(itemsPricing, item => item.totalMemberPrice)
  const tourPrice = checkoutWithMemberPrice && memberPrice > 0 ? memberPrice : price

  const originalPrice = items[0].originalTotal ?? 0
  const diffPrice = tourPrice - originalPrice

  const package_option = getPackageOptionType(items[0].purchasableOption.roomType)

  return {
    departure_id: items[0].purchasableOption.fkDepartureId,
    transaction_key: items[0].transactionKey,
    price: tourPrice,
    difference_to_pay: diffPrice > 0 ? diffPrice : 0,
    package_option,
    rooms: items.map(item => ({
      room_type: item.purchasableOption.roomType,
      adult_count: item.occupancy.adults,
      child_count: item.occupancy.childrenAge?.length ?? 0,
      child_ages: item.occupancy.childrenAge ?? [],
      room_type_pricing_id: item.purchasableOption.fkRoomTypePricingId,
    })),
  }
}

function formatCarHireItem(
  item: App.Checkout.CarHireItem,
  travellerForms: Array<App.Checkout.TravellerForm>,
  arrivalFlightNumber: string | null,
  allItemDiscounts: Array<App.Checkout.ItemDiscount>,
) {
  const primaryDriver = travellerForms.find((form) => form.id === PRIMARY_TRAVELLER_FORM_ID) as App.Checkout.TravellerForm
  const offer = item.offer

  const selectedRateOption = getCarHireSelectedRateOption(item)

  const totalPrice = selectedRateOption.payNowAmount + (item.selectedInsurance?.total || 0)
  const payOnPickUpAmount = selectedRateOption.payOnArrivalAmount + sum(item.selectedAddons, addon => addon.total ?? 0)

  const itemDiscounts = filterItemDiscounts({ itemDiscounts: allItemDiscounts, lookup: { specificType: 'car_hire' } })

  return {
    transaction_key: item.transactionKey,
    type: 'car_hire',
    offer,
    pickUpLocation: {
      code: offer.pickUp.id,
      date: moment.utc(`${item.pickUpDate}T${item.pickUpTime}`),
    },
    returnLocation: {
      code: offer.dropOff.id,
      date: moment.utc(`${item.dropOffDate}T${item.dropOffTime}`),
    },
    reference: {
      dateTime: selectedRateOption.dateTime,
      id: selectedRateOption.id,
      idContext: selectedRateOption.idContext,
      type: selectedRateOption.type,
      url: selectedRateOption.url,
    },
    vehicle: {
      code: offer.vehicle.code,
      model: offer.vehicle.model,
      image: offer.vehicle.image.url,
      transmissionType: offer.vehicle.transmission,
      baggageQuantity: offer.vehicle.luggage,
      passengerQuantity: offer.vehicle.seats,
      fuelType: offer.vehicle.fuelType,
      doorCount: offer.vehicle.doors,
      category: offer.vehicle.type,
      driveType: offer.vehicle.driveType,
      size: {
        code: offer.vehicle.sizeCode,
        description: offer.vehicle.type,
      },
      type: {
        code: offer.vehicle.typeCode,
        description: offer.vehicle.type,
      },
    },
    driver: {
      givenName: primaryDriver.firstName,
      surname: primaryDriver.lastName,
      email: primaryDriver.email,
      phoneNumber: primaryDriver.phone,
      phoneCode: primaryDriver.prefix,
      age: getAge(primaryDriver.dateOfBirth, new Date(item.pickUpDate)),
    },
    value: item.totalPrice,
    total: totalPrice,
    payOnPickUpAmount,
    qtyPassengers: 1, // TODO car hire remove mock
    availabilityUuid: offer.availabilityUuid,
    ...(arrivalFlightNumber && {
      arrivalDetails: {
        number: arrivalFlightNumber,
        transportationCode: TRANSPORTATION_CODE.AIRPLANE,
      },
    }),
    ...(item.selectedAddons && {
      specialEquipments: Object.values(item.selectedAddons),
    }),
    ...(item.selectedInsurance && item.selectedInsurance.type === 'enhanced' && {
      insuranceReference: {
        id: item.selectedInsurance.id,
        amount: item.selectedInsurance.total,
        currencyCode: item.selectedInsurance.currency,
        url: item.selectedInsurance.url,
      },
    }),
    item_discounts: itemDiscounts.map(formatItemDiscount),
  }
}

function formatCruiseItem(
  itemView: App.Checkout.CruiseAccommodationItemView,
  travellerForms: Array<App.Checkout.TravellerForm>,
  itemDiscounts: Array<App.Checkout.ItemDiscount>,
) {
  const item = itemView.item
  const travellerDetails = travellerForms.map((form, i) => ({
    first_name: form.firstName,
    last_name: form.lastName,
    title: form.title,
    gender: titleGenderMap[form.title],
    type: form.id.split('-')[0],
    date_of_birth: form.dateOfBirth,
    is_lead_passenger: i === 0,
  }))

  const total = itemView.totals.price

  return {
    type: 'cruise',
    departure_id: item.departureId,
    departure_date: item.startDate,
    arrival_date: item.endDate,
    session_id: item.sessionId,
    booking_id: item.bookingId,
    transaction_key: item.transactionKey,
    total,
    traveller_details: travellerDetails,
    offer_name: item.offerName,
    offer_id: item.offerId,
    item_discounts: itemDiscounts.map(formatItemDiscount),
    // cabins: items.map(item => ({
    //   cabin_type: item.cabinCategory,
    //   adult_count: item.occupancy.adults,
    //   child_count: item.occupancy.childrenAge?.length ?? 0,
    //   child_ages: item.occupancy.childrenAge ?? [],
    // })),
  }
}

function formatBedbankItem(
  item: App.Checkout.BedbankHotelItem,
  specialRequests: string,
  primaryForm: App.Checkout.TravellerForm,
  rooms: Array<App.Occupants>,
  itemDiscounts: Array<App.Checkout.ItemDiscount>,
  existingOrderTraveller?: App.ExistingOrderBedbankTraveller,
) {
  let travellerFormData = {
    bookingPhone: `+${primaryForm?.prefix}${primaryForm?.phone}`,
    bookingEmail: primaryForm?.email,
    guestFirstName: primaryForm?.firstName,
    guestLastName: primaryForm?.lastName,
  }

  if (existingOrderTraveller) {
    travellerFormData = {
      ...travellerFormData,
      ...existingOrderTraveller,
    }
  }

  return {
    brand: config.BRAND,
    type: ITEM_TYPE_BEDBANK,
    session_id: item.sessionId,
    bed_group_id: item.bedGroupId,
    property_id: item.offerId,
    room_type_id: item.roomId,
    room_rate_id: item.roomRateId,
    is_flight_bundle: item.isFlightBundle,
    transaction_key: item.transactionKey,
    booking_email: travellerFormData?.bookingEmail,
    booking_phone: travellerFormData.bookingPhone,
    rooms: rooms.map(roomOccupants => ({
      guest_first_name: travellerFormData.guestFirstName,
      guest_last_name: travellerFormData.guestLastName,
      guest_special_requests: specialRequests,
      occupancy: generateOccupancyStringByRoom(roomOccupants),
    })),
    item_discounts: itemDiscounts.map(formatItemDiscount),
  }
}

function formatPassengers(
  travellerForms: Array<App.Checkout.TravellerForm>,
  flightItem: App.Checkout.FlightItem,
) {
  if (travellerForms.length === 0 || !flightItem?.flights[0]) { return [] }
  const paxList = flightItem.passengers

  const paxBaggageMap = flightItem.flights.map(flight => flight.extras.baggage)
  const primaryForm = travellerForms.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID) as App.Checkout.TravellerForm

  const result = paxList.map((pax: App.Checkout.FlightPassenger) => {
    const paxFormData = travellerForms.find(form => pax.id === form.id)
    if (!paxFormData) { return null }

    const allBaggage = paxBaggageMap.map(baggage => baggage[pax.id])
    const baggage = buildBaggagePayload(allBaggage[0], allBaggage[1])
    return buildPassengerPayload(pax, paxFormData, primaryForm, baggage)
  }).filter(excludeNullOrUndefined)

  return result
}

function formatFlightItem(
  item: App.Checkout.FlightItem,
  travellerForms: Array<App.Checkout.TravellerForm>,
  journeys: Array<App.JourneyV2>,
  allItemDiscounts: Array<App.Checkout.ItemDiscount>,
): Array<unknown> {
  const isReturn = journeys.length === 2
  const isSameProvider = isReturn && new Set(journeys.map(journey => journey.provider)).size === 1

  if (item.viewType === FlightViewTypes.TWO_ONE_WAYS_AND_RETURN || item.viewType === FlightViewTypes.MULTI_CITY || isSameProvider) {
    return formatReturnFlightItemV2(item, travellerForms, journeys, allItemDiscounts)
  }

  return formatFlightItemV2(item, travellerForms, journeys, allItemDiscounts)
}

function formatFlightItemV2(
  item: App.Checkout.FlightItem,
  travellerForms: Array<App.Checkout.TravellerForm>,
  journeys: Array<App.JourneyV2>,
  allItemDiscounts: Array<App.Checkout.ItemDiscount>,
) {
  if (travellerForms.length === 0) { return [] }
  const primaryTraveller = travellerForms.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID) as App.Checkout.TravellerForm

  const result = item.flights.map((flight, index) => {
    const segmentJourney = journeys[index]

    if (segmentJourney) {
      const baggageOptions = baggageOptionsMap(segmentJourney.flightGroup.flights[0])?.baggage ?? []

      const cost = sum([
        segmentJourney.price.all.totalFare,
        sum(Object.values(item?.otherFees ?? [])),
        computeBaggageCostForPaxList(item.passengers, baggageOptions, flight.extras.baggage),
      ])

      const tax = segmentJourney.price.all.totalTax

      const itemDiscounts = filterItemDiscounts({ itemDiscounts: allItemDiscounts, lookup: { specificType: 'flight' } })

      return {
        provider: segmentJourney.provider,
        transaction_key: item.transactionKey,
        type: CHECKOUT_ITEM_TYPE_FLIGHT,
        offer_type: [(item.bundledItemIds?.length ?? 0) > 0 ? 'hotel' : null],
        contact_email_address: primaryTraveller.email,
        contact_phone_number: `${primaryTraveller.prefix}${primaryTraveller.phone}`,
        passengers: formatPassengers(travellerForms, item),
        journey: {
          id: flight.journeyId,
          cost,
          tax,
          searchId: item.searchId,
        },
        viewType: item.viewType,
        item_discounts: itemDiscounts.map(formatItemDiscount),
      }
    }
  }).filter(excludeNullOrUndefined)

  return result
}

function formatReturnFlightItemV2(
  item: App.Checkout.FlightItem,
  travellerForms: Array<App.Checkout.TravellerForm>,
  journeys: Array<App.JourneyV2>,
  allItemDiscounts: Array<App.Checkout.ItemDiscount>,
) {
  if (travellerForms.length === 0) { return [] }
  const primaryTraveller = travellerForms.find(form => form.id === PRIMARY_TRAVELLER_FORM_ID) as App.Checkout.TravellerForm

  const cost = sum([
    ...item.flights.map(flight => flight.cost),
    sum(Object.values(item?.otherFees ?? [])),
    ...journeys.map((journey, index) => {
      const baggageOptions = baggageOptionsMap(journey.flightGroup.flights[0])?.baggage ?? []
      return computeBaggageCostForPaxList(item.passengers, baggageOptions, item.flights[index]?.extras.baggage ?? {})
    }),
  ])

  const tax = sum(journeys.map(journey => journey.price.all.totalTax))

  const itemDiscounts = filterItemDiscounts({ itemDiscounts: allItemDiscounts, lookup: { specificType: 'flight' } })

  const result = {
    provider: journeys[0].provider,
    transaction_key: item.transactionKey,
    type: CHECKOUT_ITEM_TYPE_FLIGHT,
    offer_type: [(item.bundledItemIds?.length ?? 0) ? 'hotel' : null],
    contact_email_address: primaryTraveller.email,
    contact_phone_number: `${primaryTraveller.prefix}${primaryTraveller.phone}`,
    passengers: formatPassengers(travellerForms, item),
    journey: {
      id: journeys[0].id,
      cost,
      tax,
      searchId: item.searchId,
      selectedFareIds: item.flights.map(flight => flight.journeyId),
    },
    viewType: item.viewType === FlightViewTypes.MULTI_CITY ?
      FlightViewTypes.MULTI_CITY :
      FlightViewTypes.TWO_ONE_WAYS_AND_RETURN,
    item_discounts: itemDiscounts.map(formatItemDiscount),
  }

  return [result]
}

const getFormattedLeInstanceBookingHotelItems = createSelector(
  (state: App.State) => getLeInstanceBookingItems(state),
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.calendar.calendarsByOccupancy,
  (state: App.State) => state.offer.offerAvailableRatesByOccupancy,
  (state: App.State) => state.checkout.form.travellerForms,
  (state: App.State) => state.checkout.cart.specialRequests,
  (state: App.State) => state.auth.account,
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => getAccommodationAndExperienceItemDiscounts(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => getChannelMarkup(state),
  (
    items,
    offers,
    calendarsRatesByOccupancy,
    availableRatesByOccupancy,
    travellerForms,
    specialRequests,
    account,
    cartMode,
    promoCodeItemDiscounts,
    checkoutWithMemberPrice,
    channelMarkup,
  ) => {
    return items
      .filter(isInstantBookingLEHotelItem)
      .map((item) => {
        const offer = offers[item.offerId]
        if (!offer) {
          return null
        }

        const {
          data: instantBookingPricing,
          hasRequiredData,
        } = getInstantBookingPriceAndSurcharge(item, offer, calendarsRatesByOccupancy, availableRatesByOccupancy)

        const { itemDiscounts } = promoCodeItemDiscounts
        if (!hasRequiredData) {
          return null
        }
        const specificItemDiscounts = filterItemDiscounts({ itemDiscounts, lookup: { specificType: 'accommodation' } })

        return formatLeHotelItem(
          item,
          travellerForms,
          specialRequests[item.itemId],
          offer,
          instantBookingPricing,
          account,
          checkoutWithMemberPrice,
          cartMode,
          undefined,
          specificItemDiscounts,
          channelMarkup,
        )
      })
  },
)

export const getFormattedVillaItems = createSelector(
  (state: App.State) => getVillaViews(state),
  (state: App.State) => state.checkout.form.travellerForms,
  (state: App.State) => state.checkout.cart.specialRequests,
  (state: App.State) => state.auth.account,
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => state.checkout.cart.arrivalDetails,
  (state: App.State) => getAllPromoCodeItemDiscounts(state),
  (state: App.State) => getChannelMarkup(state),
  (
    views,
    travellerForms,
    specialRequests,
    account,
    checkoutWithMemberPrice,
    arrivalDetails,
    promoCodeItemDiscounts,
    channelMarkup,
  ) => {
    return views.data.map(view => {
      const { itemDiscounts: allItemDiscounts } = promoCodeItemDiscounts
      const itemDiscounts = filterItemDiscounts({ itemDiscounts: allItemDiscounts, lookup: { specificType: 'accommodation', cartItemIds: [view.item.itemId] } })
      return formatLeHotelItem(
        view.item,
        travellerForms,
        specialRequests[view.item.itemId],
        view.offer,
        view.totals,
        account,
        checkoutWithMemberPrice,
        undefined,
        arrivalDetails[view.item.itemId],
        itemDiscounts,
        channelMarkup,
      )
    })
  },
)

type FormattedOrderSubscriptionItem = Pick<Order.SubscriptionItem, 'subscription_offer_id' | 'type' | 'transaction_key' | 'total'>

export const getFormattedLuxPlusSubscriptionItems = createSelector(
  (state: App.State) => getLuxPlusSubscriptionItems(state),
  (state: App.State) => getSubReoccuringItemDiscounts(state),
  (items: Array<App.Checkout.LuxPlusSubscriptionItem>, subReoccuringItemDiscounts: GetPromoCodeItemDiscounts) => {
    if (!subReoccuringItemDiscounts.hasRequiredPromoCodeData) {
      return []
    }
    return items.map<FormattedOrderSubscriptionItem>(item => {
      return {
        subscription_offer_id: item.offerId,
        type: 'subscription',
        transaction_key: item.transactionKey,
        total: item.amount,
        sub_type: 'recurring_fee',
        item_discounts: subReoccuringItemDiscounts.itemDiscounts.map(formatItemDiscount),
      }
    })
  },
)

export const getFormattedSubscriptionJoinFeeItems = createSelector(
  (state: App.State) => getSubscriptionJoinItems(state),
  (items): Array<FormattedOrderSubscriptionItem> => {
    // We don't want to send the waived items to the backend
    return items.filter(item => !item.waived).map<FormattedOrderSubscriptionItem>(item => ({
      subscription_offer_id: item.subscriptionOfferId,
      type: 'subscription',
      transaction_key: item.transactionKey,
      total: item.amount,
      sub_type: 'joining_fee',
    }))
  },
)

export const getFormattedCartItems = createSelector(
  (state: App.State) => state.checkout.cart,
  (state: App.State) => getFullOffers(state),
  (state: App.State) => state.checkout.form.travellerForms,
  (state: App.State) => state.flights.journeysById,
  (state: App.State) => state.auth.account,
  (state: App.State) => isTravellerFormNeeded(state),
  (state: App.State) => getTravellerFormSchemaRequest(state),
  (state: App.State) => getFormattedLeInstanceBookingHotelItems(state),
  (state: App.State) => getFormattedTourV2Items(state),
  (state: App.State) => getFormattedExperienceItems(state),
  (state: App.State) => getFormattedTransferItems(state),
  (state: App.State) => getFormattedInsuranceItems(state),
  (state: App.State) => getFormattedBookingProtectionItems(state),
  (state: App.State) => getFormattedCruiseItems(state),
  (state: App.State) => getFormattedBundleAndSaveItems(state),
  (state: App.State) => getFormattedGiftCardItems(state),
  (state: App.State) => getFormattedVillaItems(state),
  (state: App.State) => selectFormattedBusinessTravellerCreditItem(state),
  (state: App.State) => getFormattedLuxPlusSubscriptionItems(state),
  (state: App.State) => getFormattedSubscriptionJoinFeeItems(state),
  (state: App.State) => checkoutWithMemberPrice(state),
  (state: App.State) => getFormattedTourExperienceItems(state),
  (state: App.State) => getBedbankChangeDatesTravellers(state),
  (state: App.State) => getAllPromoCodeItemDiscounts(state),
  (state: App.State) => getChannelMarkup(state),
  formatCartItems,
)

export const getOrderGift = createSelector(
  (state: App.State) => state.checkout.form.travellerForms,
  (travellers): Order.OrderGift | undefined => {
    if (travellers.length < 2) { return undefined }
    const giftRecipient = travellers.find(traveller => traveller.id === GIFT_RECIPIENT_FORM_ID)
    if (giftRecipient) {
      const giftGiver = travellers.find(traveller => traveller.id === PRIMARY_TRAVELLER_FORM_ID) as App.Checkout.TravellerForm
      const giftPayload: Order.OrderGift = {
        receiver_email: giftRecipient.email,
        receiver_name: `${giftRecipient.firstName} ${giftRecipient.lastName}`,
        giver_name: `${giftGiver.firstName} ${giftGiver.lastName}`,
        scheduled_date: generateScheduleDate(giftRecipient.scheduledDate),
      }
      if (giftRecipient.personalMessage) {
        giftPayload.gift_message = giftRecipient.personalMessage
      }
      return giftPayload
    }
  },
)
