import { addTotals } from 'checkout/lib/utils/payments/totals'
import { createSelector } from 'reselect'
import { getBookingProtectionTotals } from './bookingProtection'
import getTotalsWithoutInsurance from 'checkout/selectors/payment/getTotalsWithoutInsurance'
import { getInsuranceTotals } from './insurance'
import { getLuxPlusSubscriptionTotals, getSubscriptionJoinFeeTotals } from './luxPlusSubscription'
import { isLastInstalment } from 'lib/payment/payInInstalmentsUtils'
import { findPostPurchaseCheckout } from 'lib/checkout/checkoutUtils'

const getAncillaryPaymentTotals = createSelector(
  (state: App.State) => findPostPurchaseCheckout(state.checkout.cart.mode),
  (state: App.State) => state.checkout.cart.existingOrder,
  (postPurchaseMode, existingOrder): App.Checkout.ItemViewTotals | undefined => {
    if (existingOrder) {
      if (postPurchaseMode === 'deposit-balance' && existingOrder.depositDetails) {
        return {
          price: existingOrder.depositDetails.balance_amount,
          memberPrice: 0,
          taxesAndFees: 0,
          value: 0,
          memberValue: 0,
          surcharge: 0,
          extraGuestSurcharge: 0,
        }
      } else if (existingOrder.instalmentDetails && (postPurchaseMode === 'instalment-balance' || isLastInstalment(existingOrder.instalmentDetails))) {
        return {
          price: existingOrder.instalmentDetails.balance_amount,
          memberPrice: 0,
          taxesAndFees: 0,
          value: 0,
          memberValue: 0,
          surcharge: 0,
          extraGuestSurcharge: 0,
        }
      } else if (postPurchaseMode === 'instalment' && existingOrder.instalmentDetails) {
        return {
          price: existingOrder.instalmentDetails.per_instalment_amount,
          memberPrice: 0,
          taxesAndFees: 0,
          value: 0,
          memberValue: 0,
          surcharge: 0,
          extraGuestSurcharge: 0,
        }
      } else if (postPurchaseMode === 'reserve-balance' && existingOrder.reserveForZeroDetails) {
        return {
          price: existingOrder.reserveForZeroDetails.payableAmount,
          memberPrice: 0,
          taxesAndFees: 0,
          value: 0,
          memberValue: 0,
          surcharge: 0,
          extraGuestSurcharge: 0,
        }
      } else if (postPurchaseMode === 'custom-offer' && existingOrder.customOfferItems.length > 0) {
        return {
          price: parseFloat(existingOrder.customOfferItems[0].total),
          memberPrice: 0,
          taxesAndFees: 0,
          value: 0,
          memberValue: 0,
          surcharge: 0,
          extraGuestSurcharge: 0,
        }
      }
    }

    return
  },
)

const getCheckoutTotals = createSelector(
  (state: App.State) => getTotalsWithoutInsurance(state),
  (state: App.State) => getInsuranceTotals(state),
  (state: App.State) => getAncillaryPaymentTotals(state),
  (state: App.State) => getBookingProtectionTotals(state),
  (state: App.State) => getLuxPlusSubscriptionTotals(state),
  (state: App.State) => getSubscriptionJoinFeeTotals(state),
  (totals, insurance, ancillaryTotals, bookingProtection, luxPlusTotal, joinTotal): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    if (ancillaryTotals) {
      return { hasRequiredData: true, data: ancillaryTotals }
    } else {
      return addTotals([totals, insurance, bookingProtection, luxPlusTotal, joinTotal])
    }
  },
)

export default getCheckoutTotals
