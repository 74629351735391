import {
  OFFER_TYPE_ALWAYS_ON,
  OFFER_TYPE_BED_BANK,
  OFFER_TYPE_BUNDLE_AND_SAVE,
  OFFER_TYPE_CRUISE,
  OFFER_TYPE_HOTEL,
  PRODUCT_TYPE_FLIGHT,
  PRODUCT_TYPE_INSURANCE,
  PRODUCT_TYPE_LUX_PLUS,
  OFFER_TYPE_LAST_MINUTE,
  OFFER_TYPE_EXPERIENCE_ADDON,
  OFFER_TYPE_EXPERIENCE_ALWAYS_ON,
  OFFER_TYPE_EXPERIENCE_CHANNEL_MANAGER,
  OFFER_TYPE_EXPERIENCE_FLASH,
  OFFER_TYPE_EXPERIENCE_TRANSFER,
  OFFER_TYPE_VILLA,
  PRODUCT_TYPE_CAR_HIRE,
  PRODUCT_TYPE_CORPORATE,
  PRODUCT_TYPE_CRUISE_EXCLUSIVE,
  PRODUCT_TYPE_CRUISE_FLASH,
  PRODUCT_TYPE_CRUISE_TACTICAL,
  PRODUCT_TYPE_CRUISE_TOUR,
  PRODUCT_TYPE_EXPERIENCE,
  PRODUCT_TYPE_LIMITED_TIME_SPECIAL,
  PRODUCT_TYPE_TOUR,
  PRODUCT_TYPE_TOUR_V2,
  PRODUCT_TYPE_TOUR_V2_CONNECTION,
  PRODUCT_TYPE_TOUR_V2_PARTNER,
  PRODUCT_TYPE_ULTRALUX,
  PRODUCT_TYPE_ULTRALUX_CRUISE,
  PRODUCT_TYPE_TOUR_DELUXE,
  PRODUCT_TYPE_TOUR_CLASSIC,
  PRODUCT_TYPE_TOUR_SIGNATURE_SERIES,
} from 'constants/offer'

const luxLoyaltyProductTypeMap: Record<App.ProductType | App.OfferType, App.LuxLoyaltyProductType | undefined> = {
  [OFFER_TYPE_HOTEL]: 'hotel_flash',
  [OFFER_TYPE_ALWAYS_ON]: 'hotel_lpc',
  [OFFER_TYPE_BED_BANK]: 'hotel_lpp_eps',
  [OFFER_TYPE_CRUISE]: 'cruise',
  [PRODUCT_TYPE_EXPERIENCE]: 'experience',
  [PRODUCT_TYPE_LUX_PLUS]: 'lux_plus',
  [PRODUCT_TYPE_FLIGHT]: 'flight',
  [PRODUCT_TYPE_INSURANCE]: 'insurance',
  [PRODUCT_TYPE_ULTRALUX]: undefined,
  [PRODUCT_TYPE_ULTRALUX_CRUISE]: undefined,
  [PRODUCT_TYPE_TOUR]: undefined,
  [PRODUCT_TYPE_TOUR_V2]: undefined,
  [PRODUCT_TYPE_TOUR_V2_CONNECTION]: undefined,
  [PRODUCT_TYPE_TOUR_V2_PARTNER]: undefined,
  [PRODUCT_TYPE_TOUR_CLASSIC]: undefined,
  [PRODUCT_TYPE_TOUR_DELUXE]: undefined,
  [PRODUCT_TYPE_TOUR_SIGNATURE_SERIES]: undefined,
  [OFFER_TYPE_LAST_MINUTE]: undefined,
  [OFFER_TYPE_BUNDLE_AND_SAVE]: undefined,
  [OFFER_TYPE_VILLA]: undefined,
  [PRODUCT_TYPE_CAR_HIRE]: undefined,
  [PRODUCT_TYPE_CRUISE_TOUR]: undefined,
  [PRODUCT_TYPE_LIMITED_TIME_SPECIAL]: undefined,
  [PRODUCT_TYPE_CORPORATE]: undefined,
  [PRODUCT_TYPE_CRUISE_FLASH]: undefined,
  [PRODUCT_TYPE_CRUISE_TACTICAL]: undefined,
  [PRODUCT_TYPE_CRUISE_EXCLUSIVE]: undefined,
  [OFFER_TYPE_EXPERIENCE_CHANNEL_MANAGER]: undefined,
  [OFFER_TYPE_EXPERIENCE_ALWAYS_ON]: undefined,
  [OFFER_TYPE_EXPERIENCE_FLASH]: undefined,
  [OFFER_TYPE_EXPERIENCE_ADDON]: undefined,
  [OFFER_TYPE_EXPERIENCE_TRANSFER]: undefined,
}

function getLuxLoyaltyProductType(type: App.ProductType | App.OfferType | undefined): App.LuxLoyaltyProductType | undefined {
  return type ? luxLoyaltyProductTypeMap[type] : undefined
}

export default getLuxLoyaltyProductType
