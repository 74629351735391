import { reducerSwitch, createReducer } from 'lib/redux/reducerUtils'
import {
  API_CALL_REQUEST,
  API_CALL_FAILURE,
  API_CALL_SUCCESS,
  CLEAR_BOOKING_CALENDAR,
  CALENDAR_UPDATE_PRICING_DATA,
} from 'actions/actionConstants'
import { FETCH_DAY_CALENDAR, FETCH_CALENDARS, FETCH_CALENDARS_BY_OCCUPANCY, FETCH_TOUR_DATES } from 'actions/apiActionConstants'
import { getCalendarDateCoordinates } from 'lib/datetime/calendarUtils'
import { omitKeys } from 'lib/object/objectUtils'

const initialState: App.CalendarState = {
  calendarsByPackageKey: {},
  calendarsByOccupancy: {},
  calendarsByOccupancyLoading: {},
  calendarsLoading: {},
  calendarErrors: {},
  tourDates: {},
}

const apiRequests = reducerSwitch<App.CalendarState>({
  [FETCH_DAY_CALENDAR]: (state, action) => ({
    calendarsLoading: {
      ...state.calendarsLoading,
      [action.key]: true,
    },
    calendarErrors: {
      ...state.calendarErrors,
      [action.key]: undefined,
    },
  }),
  [FETCH_CALENDARS]: (state, action) => {
    const nextLoading: Record<string, boolean> = {
      ...state.calendarsLoading,
      [action.offerId]: true,
    }
    action.packageKeys.forEach((key: string) => {
      nextLoading[key] = true
    })
    const nextErrors = {
      ...state.calendarErrors,
    }
    delete nextErrors[action.offerId]

    return {
      calendarsLoading: nextLoading,
      calendarErrors: nextErrors,
    }
  },
  [FETCH_CALENDARS_BY_OCCUPANCY]: (state, action) => {
    const pkgKeys = action.pkgKeys as Array<string>
    const loadingState = Object.fromEntries(pkgKeys.map(key => [key, true]))
    return {
      calendarsByOccupancyLoading: {
        ...state.calendarsByOccupancyLoading,
        [action.occupancyKey]: {
          ...state.calendarsByOccupancyLoading[action.occupancyKey],
          ...loadingState,
        },
      },
      calendarErrors: omitKeys(Object.keys(loadingState), state.calendarErrors),
    }
  },
  [FETCH_TOUR_DATES]: (state, action) => {
    let nextErrors = state.calendarErrors
    if (state.calendarErrors[action.key]) {
      nextErrors = {
        ...state.calendarErrors,
        [action.key]: undefined,
      }
    }

    return {
      calendarsLoading: {
        ...state.calendarsLoading,
        [action.key]: true,
      },
      calendarErrors: nextErrors,
    }
  },
})

const apiSuccesses = reducerSwitch<App.CalendarState>({
  [FETCH_DAY_CALENDAR]: (state, action) => ({
    calendarsByPackageKey: {
      ...state.calendarsByPackageKey,
      [action.key]: {
        ...state.calendarsByPackageKey[action.key],
        ...action.data,
      },
    },
    calendarsLoading: {
      ...state.calendarsLoading,
      [action.key]: false,
    },
  }),
  [FETCH_CALENDARS]: (state, action) => {
    const nextLoading = {
      ...state.calendarsLoading,
      [action.offerId]: false,
    }
    action.packageKeys.forEach(key => nextLoading[key] = false)
    const nextCalendars = { ...state.calendarsByPackageKey }
    action.data.forEach(data => nextCalendars[data.uniqueKey] = data)

    return {
      calendarsLoading: nextLoading,
      calendarsByPackageKey: nextCalendars,
    }
  },
  [FETCH_CALENDARS_BY_OCCUPANCY]: (state, action) => {
    const pkgKeys = action.pkgKeys as Array<string>
    const occupancyKey = action.occupancyKey

    const newLoadingState = {
      ...state.calendarsByOccupancyLoading,
      [occupancyKey]: {
        ...state.calendarsByOccupancyLoading[occupancyKey],
        ...Object.fromEntries(pkgKeys.map(key => [key, false])),
      },
    }

    const newCalendarState = {
      ...state.calendarsByOccupancy,
      [occupancyKey]: {
        ...state.calendarsByOccupancy[occupancyKey],
        ...action.data,
      },
    }

    return {
      calendarsByOccupancyLoading: newLoadingState,
      calendarsByOccupancy: newCalendarState,
    }
  },
  [FETCH_TOUR_DATES]: (state, action) => ({
    calendarsLoading: {
      ...state.calendarsLoading,
      [action.key]: false,
    },
    tourDates: {
      ...state.tourDates,
      [action.key]: action.data,
    },
  }),
})

const apiFailures = reducerSwitch<App.CalendarState>({
  [FETCH_DAY_CALENDAR]: (state, action) => {
    const calendarsByPackageKey = { ...state.calendarsByPackageKey }
    delete calendarsByPackageKey[action.key]

    return {
      calendarsByPackageKey,
      calendarsLoading: {
        ...state.calendarsLoading,
        [action.key]: false,
      },
      calendarErrors: {
        ...state.calendarErrors,
        [action.key]: action.error,
      },
    }
  },
  [FETCH_CALENDARS]: (state, action) => {
    const nextLoading = {
      ...state.calendarsLoading,
      [action.offerId]: false,
    }
    const nextCalendars = { ...state.calendarsByPackageKey }
    const nextErrors = {
      ...state.calendarErrors,
      [action.offerId]: action.error,
    }

    action.packageKeys.forEach(key => {
      delete nextCalendars[key]
      nextErrors[key] = action.error
      nextLoading[key] = false
    })

    return {
      calendarsLoading: nextLoading,
      calendarsByPackageKey: nextCalendars,
      calendarErrors: nextErrors,
    }
  },
  [FETCH_CALENDARS_BY_OCCUPANCY]: (state, action) => {
    const occupancyKey = action.occupancyKey as string
    const pkgKeys = action.pkgKeys as Array<string>
    const loadingState = Object.fromEntries(pkgKeys.map(key => [key, false]))

    const newLoadingState = {
      ...state.calendarsByOccupancyLoading,
      [occupancyKey]: {
        ...state.calendarsByOccupancyLoading[occupancyKey],
        ...loadingState,
      },
    }

    const occupancyCalendarState = omitKeys(pkgKeys, state.calendarsByOccupancy[occupancyKey])
    const newCalendarState = {
      ...state.calendarsByOccupancy,
      [occupancyKey]: occupancyCalendarState,
    }

    const newErrorState = {
      ...state.calendarErrors,
      ...Object.fromEntries(pkgKeys.map(key => [key, action.error])),
    }

    return {
      calendarsByOccupancyLoading: newLoadingState,
      calendarsByOccupancy: newCalendarState,
      calendarErrors: newErrorState,
    }
  },
  [FETCH_TOUR_DATES]: (state, action) => ({
    calendarsLoading: {
      ...state.calendarsLoading,
      [action.key]: false,
    },
    calendarErrors: {
      ...state.calendarErrors,
      [action.key]: action.error,
    },
  }),
})

const calendarReducer = createReducer<App.CalendarState>(initialState, {
  [API_CALL_REQUEST]: (state, action) => apiRequests(action.api)(state, action),
  [API_CALL_FAILURE]: (state, action) => apiFailures(action.api)(state, action),
  [API_CALL_SUCCESS]: (state, action) => apiSuccesses(action.api)(state, action),
  [CLEAR_BOOKING_CALENDAR]: () => initialState,
  [CALENDAR_UPDATE_PRICING_DATA]: (state, action) => {
    const calendar = action.occupancy ?
      state.calendarsByOccupancy[action.occupancy][action.uniqueKey] :
      state.calendarsByPackageKey[action.uniqueKey]

    const { monthIdx, dayIdx } = getCalendarDateCoordinates(action.checkIn, calendar)

    const month = calendar.months[monthIdx]
    const day = month.days[dayIdx]

    const newDay = {
      ...day,
      ...action.data,
    }

    const newDayList = [...month.days.slice(0, dayIdx), newDay, ...month.days.slice(dayIdx + 1)]
    const newMonth = {
      ...month,
      days: newDayList,
    }

    const newMonthList = [...calendar.months.slice(0, monthIdx), newMonth, ...calendar.months.slice(monthIdx + 1)]
    const newCalendar = {
      ...calendar,
      months: newMonthList,
    }

    const newCalendarsByOccupancy = {
      ...state.calendarsByOccupancy[action.occupancy],
      [action.uniqueKey]: newCalendar,
    }

    return {
      ...state,
      calendarsByOccupancy: {
        [action.occupancy]: newCalendarsByOccupancy,
      },
    }
  },
})

export default calendarReducer
