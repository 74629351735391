import PageBanner from 'components/Luxkit/Banners/PageBanner'
import TextLink from 'components/Luxkit/TextLink'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import config from 'constants/config'
import GeoContext from 'contexts/geoContext'
import LuxPlusFreePreviewBanner from 'luxPlus/components/Banners/LuxPlusFreePreviewBanner'
import LuxPlusRenewalBanner from 'luxPlus/components/Banners/LuxPlusRenewalBanner'
import LuxPlusWarningMessageBanner from 'luxPlus/components/Banners/LuxPlusWarningMessageBanner'
import { isPendingRenewalLuxPlusMember, selectHasLuxPlusFreePreviewBanner } from 'luxPlus/selectors/featureToggle'
import React, { useContext, useRef } from 'react'
import { connect } from 'react-redux'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import AtolProtectedBanner from './HeaderBanners/AtolProtectedBanner'
import CookiesDisabledWarningBanner from './HeaderBanners/CookiesDisabledWarningBanner'
import { useAppHeaderObserver } from 'hooks/useAppLayoutObservers'
import useCurrentSiteTakeover from 'hooks/useCurrentSiteTakeover'
import SiteTakeoverPageBanner from 'components/Common/SiteTakeover/SiteTakeoverPageBanner'

const AGENTHUB_SUPPORT_EMAIL = 'agenthubsupport@luxuryescapes.com'

interface MappedStateProps {
  isPendingRenewalLuxPlusMember: boolean
  hasLuxPlusFreePreviewBanner: boolean
  subscription: App.MemberSubscription
}

/**
 * Contains all the banners that are meant to be displayed on top of the page.
 */
function HeaderTopBanners(props: MappedStateProps) {
  const {
    isPendingRenewalLuxPlusMember,
    hasLuxPlusFreePreviewBanner,
    subscription,
  } = props
  const { currentRegionCode } = useContext(GeoContext)
  const containerRef = useRef<HTMLDivElement>(null)
  useAppHeaderObserver(containerRef)

  const siteTakeover = useCurrentSiteTakeover()

  return <div ref={containerRef} className={HeaderTopBanners.name}>
    {subscription.error && <LuxPlusWarningMessageBanner />}
    {isPendingRenewalLuxPlusMember && <LuxPlusRenewalBanner />}
    {hasLuxPlusFreePreviewBanner && !siteTakeover && <LuxPlusFreePreviewBanner />}
    {currentRegionCode === 'GB' && <TripGuard>
      <CSSBreakpoint max="mobile">
        <AtolProtectedBanner />
      </CSSBreakpoint>
    </TripGuard>}
    {config.agentHub.isEnabled && <PageBanner
      kind="promotional-primary"
      description={<>Need help? Email <TextLink href={`mailto:${AGENTHUB_SUPPORT_EMAIL}`}>{AGENTHUB_SUPPORT_EMAIL}</TextLink></>}
    />}
    <CookiesDisabledWarningBanner />
    {!!siteTakeover && <SiteTakeoverPageBanner />}
  </div>
}

export default connect<MappedStateProps, {}, {}, App.State>((state) => {
  return {
    subscription: state.auth.account.luxPlus.member.subscription,
    isPendingRenewalLuxPlusMember: isPendingRenewalLuxPlusMember(state),
    hasLuxPlusFreePreviewBanner: selectHasLuxPlusFreePreviewBanner(state),
  }
})(HeaderTopBanners)
