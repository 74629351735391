import { definitions } from '@luxuryescapes/contract-svc-lux-loyalty'
import { arrayToObject, sortBy } from 'lib/array/arrayUtils'

type ServerLuxLoyaltyAccount = definitions['accountSummary']
type ServerLuxLoyaltyProgramConfiguration = definitions['programConfiguration']

export function luxLoyaltyAccountMapper(response: ServerLuxLoyaltyAccount | null): App.LuxLoyaltyAccount | undefined {
  if (!response) return undefined

  return {
    points: response.points,
    tier: response.tier,
    statusCredits: response.statusCredits,
    ibsMemberId: response.ibsMemberId,
    statusReviewDate: response.statusReviewDate ?? undefined,
    totalCreditsForTierRetention: response.totalCreditsForTierRetention ?? undefined,
    totalCreditsForTierUpgrade: response.totalCreditsForTierUpgrade ?? undefined,
  }
}

export function luxLoyaltyProgramConfigMapper(response: ServerLuxLoyaltyProgramConfiguration): App.LuxLoyaltyProgramConfig {
  return {
    programName: response.programName,
    currencyName: response.currencyName,
    currencyNameAbbreviation: response.currencyNameAbbreviation,
    statusCreditsName: response.statusCreditsName,
    tierLabel: response.tierLabel,
    tiersConfigs: arrayToObject(response.tiers, tier => tier.id),
    tiersOrder: sortBy(response.tiers, (tier) => tier.sortingOrder, 'asc').map((tier) => tier.id),
  }
}
