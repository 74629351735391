import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import { SearchMenuStates } from 'components/Search/type'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import React, { ChangeEventHandler, useCallback, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import BusinessTravellerSelectContent from './BusinessTravellerSelectContent'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
}

interface Props {
  className?: string;
  activeMenu: SearchMenuStates;
  onRoomChange: (rooms: Array<App.Occupants>) => void;
  openTravellerMenu: (e: React.MouseEvent<HTMLButtonElement>) => void
  closeMenu: () => void
}

function BusinessTravellerSelectHotelsDesktop(props: Props & MappedStateProps) {
  const {
    className,
    activeMenu,
    travellerEmployees,
    onRoomChange,
    openTravellerMenu,
    closeMenu,
  } = props

  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const handleTravellerSelect = useCallback((selectedTravellerEmployeeIds: Array<string>) => {
    if (selectedTravellerEmployeeIds.length > 0) {
      const occupants: Array<App.Occupants> = [{
        adults: selectedTravellerEmployeeIds.length ?? 1,
        children: 0,
        infants: 0,
        childrenAge: [],
      }]
      onRoomChange(occupants)
    }
    closeMenu()
  }, [closeMenu, onRoomChange])

  const fieldValue = useMemo(() => {
    if (travellerEmployees?.length) {
      const firstTraveller = travellerEmployees[0]
      return `${firstTraveller.firstName} ${firstTraveller.lastName}`
    }

    return undefined
  }, [travellerEmployees])

  const localInputRef = useRef<HTMLButtonElement>(null)

  return (
    <SearchFormFieldGroup className={className}>
      <SearchFormField
        label="Travellers"
        required
        requiredErrorMessage="Please select traveller"
        value={fieldValue}
        displayValue={fieldValue}
        placeholder="Select Traveller"
        onClick={openTravellerMenu}
        ref={localInputRef}
      />
      <DropdownList
        size="M"
        open={activeMenu === SearchMenuStates.Rooms}
        triggerRef={localInputRef}
        anchorRef={localInputRef}
        headerExtension={<TextInputOnly
          value={searchTerm}
          type="text"
          placeholder="Search traveller name"
          onChange={handleSearchTermChange}
          endIcon={<LineSearchIcon />}
          noValidationSpacing
        />}
      >
        <BusinessTravellerSelectContent
          searchTerm={searchTerm}
          onTravellerSelect={handleTravellerSelect}
        />
      </DropdownList>
    </SearchFormFieldGroup>
  )
}

export default connect<MappedStateProps, {}, Props, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectHotelsDesktop)
