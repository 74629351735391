import Modal from 'components/Luxkit/Modal/Modal'
import { mediaQueryUp } from 'components/utils/breakpoint'
import config from 'constants/config'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { rem } from 'polished'
import React, { ComponentProps, useCallback } from 'react'
import styled from 'styled-components'
import TourStyledNonSignatureProductTypeInfoSheet from './TourStyledNonSignatureProductTypeInfoSheet'

type NonSignatureStyledProductType = Exclude<App.Tours.V2ProductTypeStyled, App.Tours.V2SignatureSeriesProductType>

const NON_SIGNATURE_STYLES: Array<NonSignatureStyledProductType> = ['classic_tour', 'deluxe_tour', 'ultra_lux_tour']

const StylesContainer = styled.div`
  display: grid;
  grid-template: repeat(3, 1fr) / 1fr;
  padding-block: ${rem(12)};

  ${mediaQueryUp.tablet} {
    grid-template: 1fr / repeat(3, 1fr);
  }
`

type InheritedModalProps = Pick<ComponentProps<typeof Modal>, 'primaryActionText' | 'primaryActionTo'>

interface Props extends InheritedModalProps {
  productType: NonSignatureStyledProductType
}

export type TourStyledNonSignatureProductTypeInfoModalResult = 'primary-action' | undefined

function TourStyledNonSignatureProductTypeInfoModal({
  productType,
  primaryActionText,
  primaryActionTo,
}: Props) {
  const modalElement = useModalElementContext<TourStyledNonSignatureProductTypeInfoModalResult>()

  const handlePrimaryClick = useCallback(() => {
    modalElement.resolve('primary-action')
  }, [modalElement])

  return <Modal
    size="L"
    title={<>{config.brandConfig.name} Tour Styles</>}
    primaryActionText={primaryActionText}
    primaryActionTo={primaryActionTo}
    onPrimaryActionClick={!primaryActionTo ? handlePrimaryClick : undefined}
    // secondaryActionText="Learn more"
  >
    <StylesContainer>
      {NON_SIGNATURE_STYLES.map((knownTourStyle) => <TourStyledNonSignatureProductTypeInfoSheet
        key={knownTourStyle}
        productType={knownTourStyle}
        isCurrent={knownTourStyle === productType}
      />)}
    </StylesContainer>
  </Modal>
}

export default TourStyledNonSignatureProductTypeInfoModal
