import React, { useContext, useEffect, useMemo } from 'react'
import LayoutContainer from 'components/Common/LayoutContainer'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import Pane from 'components/Common/Pane'
import { ANYWHERE_SEARCH_ITEM } from 'constants/search'
import GeoContext from 'contexts/geoContext'
import { getHomeOfferListParams } from 'home/helpers'
import OfferListCarousel from 'components/OfferList/OfferListCarousel'
import config from 'constants/config'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchRegionRecommendations } from 'actions/RecommendationActions'
import useOfferList from 'hooks/Offers/useOfferList'
import { unique } from 'lib/array/arrayUtils'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import { encodeSearchParams } from 'lib/search/searchUtils'
import TextButton from 'components/Luxkit/Button/TextButton'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import OfferCarouselLookingForSomethingElse from '../LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import styled from 'styled-components'
import useCurrentSiteTakeover from 'hooks/useCurrentSiteTakeover'
import BodyText from 'components/Luxkit/Typography/BodyText'
import SiteTakeoverBackgroundImage from 'components/Common/SiteTakeover/SiteTakeoverBackgroundImage'
import { isAgentHubEnabled } from 'agentHub/selectors/agentHubSelectors'

const SiteTakeoverBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
`

const CarouselContainer = styled(Pane)`
  position: relative;
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  ${mediaQueryUp.desktop} {
    /**
     * This adds room for the search section on all homepages
     */
    padding-bottom: ${rem(100)};
  }
`

const Content = styled(VerticalSpacer)`
  position: relative;
  z-index: 1;
`

interface Props {
  className?: string;
}

function HeroOfferCarouselSection(props: Props) {
  const { className } = props
  const { currentRegionCode, geoStateName } = useContext(GeoContext)
  const dispatch = useAppDispatch()

  const filters = useMemo(() => {
    return getHomeOfferListParams({
      region: currentRegionCode,
      subregion: geoStateName,
    })
  }, [currentRegionCode, geoStateName])

  const siteTakeover = useCurrentSiteTakeover()

  // --------- START: LERE NZ/US/GB Region recommendations on homepage (AB TEST) ----------
  const filteredOfferList = useOfferList(filters)
  const regionRecommendations = useAppSelector(state => state.recommendations.regionRecommendations)
  const regionRecommendationsExperimentEnabled = false
  const overrideOfferList: App.OfferList = useMemo(() => {
    if (regionRecommendationsExperimentEnabled) {
      const offers = unique([...regionRecommendations.offers.map(o => o.offerId), ...filteredOfferList.offerIds])
      const fetching = regionRecommendations.state === 'loading' || filteredOfferList.fetching
      return {
        offerIds: offers,
        key: 'lere-region-recommendations',
        fetching,
      }
    }
    return filteredOfferList
  }, [regionRecommendations, filteredOfferList, regionRecommendationsExperimentEnabled])

  useEffect(() => {
    if (regionRecommendationsExperimentEnabled) {
      dispatch(fetchRegionRecommendations())
    }
  }, [regionRecommendationsExperimentEnabled, dispatch])
  // --------- END: LERE NZ/US/GB Region recommendations on homepage (AB TEST) ------------

  const searchUrl = useMemo(() => {
    const query = encodeSearchParams({
      searchItem: ANYWHERE_SEARCH_ITEM,
      rooms: [config.search.defaultOccupants],
    })
    return `/search?${query.toString()}`
  }, [])

  const carouselPalette = siteTakeover?.palette ?? 'inverse'
  const title = siteTakeover?.heroCarousel.title ??
    (isAgentHubEnabled ? <>Today's <i>featured</i> escapes</> : <>Today's top <i>exclusive</i> offers</>)
  const subtitle = siteTakeover?.heroCarousel.subtitle

  return (
    <CarouselContainer paletteType={carouselPalette} className={className}>
      {siteTakeover && <SiteTakeoverBackground>
        <SiteTakeoverBackgroundImage background="hero" />
      </SiteTakeoverBackground>}
      <Content gap={24}>
        <LayoutContainer>
          <Group
            direction="horizontal"
            horizontalAlign="space-between"
            verticalAlign="center"
            gap={16}
          >
            <VerticalSpacer gap={2}>
              <Heading variant="heading2">{title}</Heading>
              {!!subtitle && <BodyText variant="large">{subtitle}</BodyText>}
            </VerticalSpacer>
            <CSSBreakpoint min="tablet">
              <TextButton
                kind="tertiary"
                to={searchUrl}
              >
                View all
              </TextButton>
            </CSSBreakpoint>
          </Group>
        </LayoutContainer>
        <OfferListCarousel
          overrideOfferList={overrideOfferList}
          filters={filters}
          tileStyle="card-medium"
        >
          <OfferCarouselLookingForSomethingElse to={searchUrl} />
        </OfferListCarousel>
        <CSSBreakpoint max="mobile">
          <TextButton
            kind="tertiary"
            to={searchUrl}
            >
            View all
          </TextButton>
        </CSSBreakpoint>
      </Content>
    </CarouselContainer>
  )
}

export default React.memo(HeroOfferCarouselSection)
