import React, { useState, useRef, useEffect, useImperativeHandle, useMemo, useCallback } from 'react'
import styled, { css } from 'styled-components'
import config from 'constants/config'
import cn from 'clsx'
import { getImageUrl } from 'lib/image/imageUtils'
import { arrayToObject, nonNullable, unique } from 'lib/array/arrayUtils'
import { breakpointEntries } from 'components/utils/breakpoint'
import { useInView } from 'react-intersection-observer'

const reversedBreakpointEntries = [...breakpointEntries].reverse()

type SupportedFitPositions = 'bottom' | 'center' | 'left' | 'right' | 'top' | 'none'

const POSITIONS: Array<SupportedFitPositions> = ['bottom', 'center', 'left', 'right', 'top']
const PositionsCSS = css`
  ${POSITIONS.map(position => `
  &.fit-${position} {
    &:before {
      background-position: ${position};
    }

    img {
      object-position: ${position};
    }
  }
`)}
`

const PictureElement = styled.picture`
  position: relative;
  z-index: 0;

  &:not(.fit-none) {
    &.fit {
      height: 100%;
      width: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  ${PositionsCSS}

  &:before {
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: var(--placeholder-image);
    opacity: 0;
    transition: opacity 0s 0.2s;
  }

  &.placeholder {
    &:before {
      transition: none;
      opacity: 1;
    }
  }
`

const PlaceholderBlurrer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  backdrop-filter: blur(8px);
`

const ImgElement = styled.img`
  opacity: 1;
  transition: opacity 0.2s;
  position: relative;

  &.hide {
    opacity: 0;
  }
`

type Format = 'jpeg' | 'jpg' | 'png' | 'webp' | 'svg' | 'avif'
export interface ImageParams {
  // These define the aspect ratio for the breakpoint given
  mobileAspectRatio?: string;
  tabletAspectRatio?: string;
  desktopAspectRatio?: string;
  largeDesktopAspectRatio?: string;
  // These are the specified width of the image for the breakpoint given
  // and will appear in the 'sizes' setting
  mobileWidth?: string;
  tabletWidth?: string;
  desktopWidth?: string;
  largeDesktopWidth?: string;
  width?: number | string;
  height?: number | string;
  // These available cloundinary image options
  greyscale?: boolean;
  brightness?: number;
  gravity?: 'auto' | 'center' | 'east' | 'north' | 'northeast' | 'northwest' | 'south' | 'southeast' | 'southwest' | 'west' // auto is centre
  quality?: 'best' | 'good' | 'eco' | 'low';
  format?: Format;
  aspectRatio?: string;
  fit?: SupportedFitPositions;
  dpr?: 1 | 2 | 3;
}

interface Props extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'id' | 'loading'>, ImageParams {
  /**
   * The LE Image id, will be used to fetch the correct image
   */
  id: string | number;
  /**
   * How the image should be loaded
   * Eager: Load as soon as possible
   * Lazy: Load when it's in the current viewport + 200px
   * Lazy-managed: Lazy loaded, managed by parent
   */
  loading?: 'eager' | 'lazy' | 'lazy-managed';
  /**
   * Disable the placeholder being loaded
   */
  disablePlaceholder?: boolean;
  /**
   * When in `lazy-managed` mode, this value is used
   * to manage the image being shown or not
   */
  showImage?: boolean;
}

// The list of possible source set widths we'll support
const sourceImageWidths = [400, 600, 800, 1000, 1200, 1600, 1920]

const ResponsiveImage = React.forwardRef<HTMLImageElement, Props>((props, ref) => {
  const {
    id,
    quality = 'eco',
    gravity,
    greyscale,
    brightness,
    mobileAspectRatio,
    tabletAspectRatio,
    desktopAspectRatio,
    largeDesktopAspectRatio,
    mobileWidth,
    tabletWidth,
    desktopWidth,
    largeDesktopWidth,
    sizes = '100vw',
    aspectRatio,
    alt,
    format,
    className,
    fit,
    loading = 'eager',
    onLoad,
    dpr,
    width = '100%',
    showImage,
    disablePlaceholder,
    ...imageProps
  } = props

  // whether or not the image has fully loaded in
  const [loaded, setLoaded] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)
  // determines whether we think we should start showing the image
  // governed by whether it's lazy loaded and in view
  const [inViewRef, imageInView] = useInView({
    rootMargin: '200px 0px 200px 0px',
    threshold: 0.01,
    initialInView: loading === 'eager',
    // we don't need an intersection observer if it's managed by parent
    // or we're eagerly loading it
    skip: loading === 'lazy-managed' || loading === 'eager',
    triggerOnce: true,
  })

  const displayImage = loading === 'eager' || (loading === 'lazy-managed' ? showImage : imageInView)

  useImperativeHandle(ref, () => imgRef.current!)

  useEffect(() => {
    if (displayImage && imgRef.current?.complete) {
      // check on mount if the image is already complete and set the state accordingly
      setLoaded(true)
    }
    // eslint-disable-next-line
  }, [])

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    setLoaded(true)
    onLoad?.(e)
  }

  const buildImageUrl = useCallback((width?: string | number, aspectRatio?: string, format?: Format) => getImageUrl(id, {
    width,
    aspectRatio,
    gravity,
    greyscale,
    brightness,
    format,
    quality,
    dpr,
  }), [brightness, gravity, greyscale, id, quality, dpr])

  // All image formats this element will need to add sources for
  const imageFormats = useMemo((): Array<Format> => {
    const formats: Array<Format | undefined> = config.AVIF_ENABLED ? ['avif', 'webp', format] : ['webp', format]
    return unique(nonNullable(formats))
  }, [format])

  // Information about all the breakpoint based data
  const imageBreakpoints = useMemo(() => {
    return reversedBreakpointEntries.map(([bp, sizes]) => ({
      ...sizes,
      propKey: bp,
      ratio: props[`${bp}AspectRatio`],
      width: props[`${bp}Width`],
    })).filter(bp => bp.ratio || bp.width)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileAspectRatio, desktopAspectRatio, largeDesktopAspectRatio, largeDesktopAspectRatio, tabletAspectRatio, mobileWidth, desktopWidth, largeDesktopWidth, largeDesktopWidth, tabletWidth])

  const ratios = useMemo(() => {
    const allRatios = [aspectRatio, ...imageBreakpoints.map(bp => bp.ratio)].filter(Boolean)
    return unique(allRatios)
  }, [aspectRatio, imageBreakpoints])

  // A "layout change" is where the ratio changes at different breakpoints, causing the
  // layout of the image to change. This is used to optimise how many source elements we need
  const hasLayoutChanges = ratios.length > 1

  // The srcset without breakpoint aspect ratio information for each format
  const baseSrcSets = useMemo(() => {
    return arrayToObject(
      imageFormats,
      format => format,
      format => sourceImageWidths.map(width => `${buildImageUrl(width, ratios[0], format)} ${width}w`).join(', '),
    )
  }, [ratios, buildImageUrl, imageFormats])

  // we can be smart about how we create source elements, if there's no difference between resolutions
  // we can use 1 source item with 'sizes' instead of multiple
  const breakpointSrcSets = useMemo<Record<App.ScreenSize, any>>(() => {
    if (!hasLayoutChanges) {
      return {
        desktop: undefined,
        largeDesktop: undefined,
        mobile: undefined,
        tablet: undefined,
      }
    }

    return arrayToObject(imageBreakpoints,
      point => point.propKey,
      point => {
        return arrayToObject(imageFormats,
          format => format,
          format => sourceImageWidths.map(width => {
            const imageUrl = buildImageUrl(width, point.ratio ?? aspectRatio, format)
            return `${imageUrl} ${width}w`
          }).join(', '),
        )
      },
    )
  }, [hasLayoutChanges, imageBreakpoints, imageFormats, buildImageUrl, aspectRatio])

  // generates the "sizes" string. This tells picture which size image to load
  // We can't use for this for media query selecting if there are layout changes though
  const imgSizes = useMemo(() => {
    if (hasLayoutChanges) {
      return sizes
    } else {
      const breakpointSizes = imageBreakpoints.map(bp => {
        return `(min-width: ${bp.min}px) ${bp.width || '100vw'}`
      })

      return [...breakpointSizes, sizes].join(',\n')
    }
  }, [hasLayoutChanges, imageBreakpoints, sizes])

  const imageUrl = buildImageUrl(undefined, aspectRatio, format)
  const placeholderImage = useMemo<React.CSSProperties>(() => ({
    '--placeholder-image': `url(${buildImageUrl('64px', aspectRatio, imageFormats[0])})`,
  }),
  [aspectRatio, buildImageUrl, imageFormats])

  const showPlaceholder = !disablePlaceholder && !loaded

  return (
    <PictureElement
      className={cn(className, {
        fit,
        [`fit-${fit}`]: fit,
        placeholder: showPlaceholder,
      })}
      style={disablePlaceholder ? undefined : placeholderImage}
      ref={inViewRef}
    >
      {displayImage && <>
        {imageFormats.map(format => <React.Fragment key={format}>
          {hasLayoutChanges && imageBreakpoints.map(bp => <source
            key={bp.propKey}
            media={`(min-width: ${bp.min}px)`}
            type={`image/${format}`}
            sizes={props[`${bp.propKey}Width`] ?? sizes}
            srcSet={breakpointSrcSets[bp.propKey][format]}
          />)}
          <source
            type={`image/${format}`}
            sizes={imgSizes}
            srcSet={baseSrcSets[format]}
          />
        </React.Fragment>)}
      </>}
      <ImgElement
        {...imageProps}
        width={width}
        loading="eager"
        className={cn({ hide: !displayImage })}
        ref={imgRef}
        alt={alt}
        src={displayImage ? imageUrl : ''}
        onLoad={onImageLoad}
      />
      {showPlaceholder && <PlaceholderBlurrer />}
    </PictureElement>
  )
})

ResponsiveImage.displayName = 'ResponsiveImage'

export default React.memo(ResponsiveImage)
