import { getAccommodationDepositSettings } from 'checkout/lib/utils/accommodation/deposit'
import { createSelector } from 'reselect'
import { getDefaultDepositAmountPercentage } from '../featureConfig/deposit'
import { checkoutAccommodationOfferView } from '../view/accommodation'

const getDepositSettings = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getDefaultDepositAmountPercentage(state),
  (viewsWithStatus, defaultDepositAmountPercentage): App.Checkout.DepositSettings | undefined => {
    if (viewsWithStatus.hasRequiredData && viewsWithStatus.data.length > 0) {
      const offerView = viewsWithStatus.data[0]
      return getAccommodationDepositSettings(offerView, defaultDepositAmountPercentage)
    }
  },
)

export default getDepositSettings
