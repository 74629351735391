import React from 'react'
import LuxLoyaltyPoints from 'luxLoyalty/components/LuxLoyaltyPoints'
import useLuxLoyaltyPointsCalculator from 'luxLoyalty/hooks/useLuxLoyaltyPointsCalculator'
import { useSearchPrices } from 'hooks/Search/useSearchPrices'
import { getMinimumDurationFromFlexibleNights, isSearchStreamingSupported } from 'lib/search/searchUtils'
import { FLEXIBLE_DURATION_RANGE } from 'constants/search'
import useDefaultAirport from 'hooks/useDefaultAirport'
import { generateLuxLoyaltyPointsCalculatorFlightOptions, generateLuxLoyaltyPointsCalculatorHotelOptions } from 'luxLoyalty/lib/pointsCalculation/calculatorOptionsGenerators'

interface Props {
  offer: App.HotelOffer | App.HotelOfferSummary
  bestPackage?: App.HotelPackage
  bestAvailableRate?: App.OfferAvailableRate
  filters?: App.OfferListFilters
}

function HotelSearchTileEarnablePoints(props: Props) {
  const { offer, bestPackage, bestAvailableRate, filters } = props
  const isSearchStreamingEnabled = isSearchStreamingSupported(filters)
  const duration = filters?.flexibleMonths && filters.flexibleNights ? getMinimumDurationFromFlexibleNights(filters.flexibleNights as FLEXIBLE_DURATION_RANGE) : offer.lowestPricePackage?.duration
  const defaultAirport = useDefaultAirport()
  const flightPrice = (offer.flightPrices[defaultAirport?.code ?? ''] || 0)

  const {
    totalPrice = 0,
    totalMemberPrice = 0,
  } = useSearchPrices({
    offer,
    pkg: bestPackage,
    rate: bestAvailableRate,
    duration,
    filters,
    useRealSearchPrices: isSearchStreamingEnabled,
  })

  const { totalPointsCalculation } = useLuxLoyaltyPointsCalculator([
    generateLuxLoyaltyPointsCalculatorHotelOptions(offer, {
      pkg: bestPackage,
      price: totalPrice,
      memberPrice: totalMemberPrice,
    }),
    generateLuxLoyaltyPointsCalculatorFlightOptions(offer, {
      flightPrice,
      skip: !offer.bundledWithFlightsOnly,
    }),
  ])

  return <LuxLoyaltyPoints pointsEarnCalculation={totalPointsCalculation} calculationType="estimate" />
}

export default HotelSearchTileEarnablePoints
