import { shade, tint, transparentize } from 'polished'
import base from './base'
import { BLUE, BRONZE, CRIMSON, GOLD, LIME_GREEN, NAVY, ORANGE, PURPLE, RED, TEAL } from './primitiveColours'
import { baseThemeShadows } from './shadows'

const brandPalette: Record<App.Design.PaletteType, App.Design.BrandPalette<string>> = {
  default: {
    primary: {
      normal: NAVY[700],
      darkest: shade(0.24, '#363A45'),
      dark: shade(0.16, '#363A45'),
      light: tint(0.76, '#363A45'),
      lightest: tint(0.86, '#363A45'),
      hover: tint(0.16, '#363A45'),
      active: tint(0.24, '#363A45'),
    },
  },
  inverse: {
    primary: {
      normal: '#FFFFFF',
      darkest: shade(0.24, '#FFFFFF'),
      dark: shade(0.16, '#FFFFFF'),
      light: tint(0.76, '#FFFFFF'),
      lightest: tint(0.86, '#FFFFFF'),
      hover: tint(0.16, '#FFFFFF'),
      active: tint(0.24, '#FFFFFF'),
    },
  },
}

const neutralPalette: Record<App.Design.PaletteType, App.Design.NeutralPalette<string>> = {
  default: {
    default: {
      one: NAVY[700],
      one20: transparentize(0.2, NAVY[700]),
      two: NAVY[600],
      three: NAVY[500],
      four: NAVY[400],
      five: NAVY[300],
      five40: transparentize(0.4, NAVY[300]),
      six: NAVY[200],
      six25: transparentize(0.25, NAVY[200]),
      seven: NAVY[100],
      eight: NAVY[50],
      clear: transparentize(0.84, NAVY[50]),
    },
    control: {
      black100: '#000000',
      black25: transparentize(0.25, '#000000'),
      white16: transparentize(0.16, '#FFFFFF'),
      white24: transparentize(0.24, '#FFFFFF'),
    },
  },
  inverse: {
    default: {
      one: NAVY[50],
      one20: transparentize(0.2, NAVY[50]),
      two: NAVY[100],
      three: NAVY[200],
      four: NAVY[300],
      five: NAVY[400],
      five40: transparentize(0.4, NAVY[400]),
      six: NAVY[500],
      six25: transparentize(0.25, NAVY[500]),
      seven: NAVY[600],
      eight: NAVY[700],
      clear: transparentize(0.84, NAVY[50]),
    },
    control: {
      black100: '#000000',
      black25: transparentize(0.25, '#000000'),
      white16: transparentize(0.16, '#FFFFFF'),
      white24: transparentize(0.24, '#FFFFFF'),
    },
  },
}

const productPalette: Record<App.Design.PaletteType, App.Design.ProductPalette<string>> = {
  default: {
    exclusive: {
      foreground: GOLD[600],
      background: GOLD[500],
      contrast: NAVY[50],
    },
    lastMinute: {
      foreground: RED[500],
      background: RED[500],
      contrast: NAVY[50],
    },
    partner: {
      foreground: NAVY[500],
      background: NAVY[500],
      contrast: NAVY[50],
    },
    premium: {
      foreground: NAVY[600],
      background: NAVY[600],
      contrast: NAVY[50],
    },
    ultralux: {
      foreground: GOLD[600],
      background: GOLD[500],
      contrast: NAVY[50],
      active: shade(0.24, GOLD[500]),
      hover: shade(0.16, GOLD[500]),
    },
    luxPlus: {
      foreground: BLUE[700],
      altForeground: BLUE[700],
      background: BLUE[700],
      contrast: NAVY[50],
      active: shade(0.24, BLUE[700]),
      altActive: shade(0.24, BLUE[700]),
      hover: shade(0.16, BLUE[700]),
      altHover: shade(0.16, BLUE[700]),
    },
    luxLoyalty: {
      bronze: {
        gradient: `linear-gradient(45deg, ${BRONZE[300]}, ${BRONZE[50]}, ${BRONZE[300]})`,
        contrast: BRONZE[600],
      },
      silver: {
        gradient: 'linear-gradient(45deg, #DBDBDB, #F2F2F2, #DBDBDB)',
        contrast: NAVY[700],
      },
      gold: {
        gradient: `linear-gradient(45deg, ${GOLD[300]}, ${GOLD[100]}, ${GOLD[300]})`,
        contrast: GOLD[600],
      },
      platinum: {
        gradient: 'linear-gradient(45deg, #3D3B3B, #939393, #3D3B3B);',
        contrast: NAVY[50],
      },
    },
  },
  inverse: {
    exclusive: {
      foreground: GOLD[300],
      background: GOLD[500],
      contrast: NAVY[50],
    },
    lastMinute: {
      foreground: RED[200],
      background: RED[500],
      contrast: NAVY[50],
    },
    partner: {
      foreground: NAVY[300],
      background: NAVY[500],
      contrast: NAVY[50],
    },
    premium: {
      foreground: NAVY[400],
      background: NAVY[600],
      contrast: NAVY[50],
    },
    ultralux: {
      foreground: GOLD[300],
      background: GOLD[500],
      contrast: NAVY[50],
      active: shade(0.24, GOLD[500]),
      hover: shade(0.16, GOLD[500]),
    },
    luxPlus: {
      foreground: BLUE[300],
      altForeground: NAVY[50],
      background: BLUE[700],
      contrast: NAVY[50],
      active: shade(0.24, BLUE[700]),
      altActive: shade(0.24, NAVY[50]),
      hover: shade(0.16, BLUE[700]),
      altHover: shade(0.16, NAVY[50]),
    },
    luxLoyalty: {
      bronze: {
        gradient: `linear-gradient(45deg, ${BRONZE[300]} 0%, ${BRONZE[50]} 50%, ${BRONZE[300]} 100%)`,
        contrast: BRONZE[600],
      },
      silver: {
        gradient: 'linear-gradient(45deg, #DBDBDB 0%, #F2F2F2 50%, #DBDBDB 100%)',
        contrast: NAVY[700],
      },
      gold: {
        gradient: `linear-gradient(45deg, ${GOLD[300]} 0%, ${GOLD[100]} 50%, ${GOLD[300]} 100%)`,
        contrast: GOLD[600],
      },
      platinum: {
        gradient: 'linear-gradient(45deg, #3D3B3B 0%, #939393 50%, #3D3B3B 100%);',
        contrast: NAVY[50],
      },
    },
  },
}

const messagingPalette: Record<App.Design.PaletteType, App.Design.MessagingPalette<string>> = {
  default: {
    success: {
      normalForeground: LIME_GREEN[500],
      normalBackground: LIME_GREEN[500],
      normalContrast: NAVY[50],
      lightForeground: LIME_GREEN[50],
      lightBackground: LIME_GREEN[50],
      lightContrast: NAVY[700],
      darkForeground: LIME_GREEN[600],
      darkBackground: LIME_GREEN[600],
      darkContrast: NAVY[50],
    },
    warning: {
      normalForeground: ORANGE[500],
      normalBackground: ORANGE[500],
      normalContrast: NAVY[700],
      lightForeground: ORANGE[100],
      lightBackground: ORANGE[100],
      lightContrast: NAVY[700],
      darkForeground: ORANGE[600],
      darkBackground: ORANGE[600],
      darkContrast: NAVY[50],
    },
    urgency: {
      normalForeground: RED[500],
      normalBackground: RED[500],
      normalContrast: NAVY[50],
      lightForeground: RED[100],
      lightBackground: RED[100],
      lightContrast: NAVY[700],
      darkForeground: RED[600],
      darkBackground: RED[600],
      darkContrast: NAVY[50],
    },
    critical: {
      normalForeground: CRIMSON[500],
      normalBackground: CRIMSON[500],
      normalContrast: NAVY[50],
      lightForeground: CRIMSON[100],
      lightBackground: CRIMSON[100],
      lightContrast: NAVY[700],
      darkForeground: CRIMSON[600],
      darkBackground: CRIMSON[600],
      darkContrast: NAVY[50],
    },
    favourite: {
      normalForeground: CRIMSON[500],
      normalBackground: CRIMSON[500],
      normalContrast: NAVY[50],
      lightForeground: CRIMSON[100],
      lightBackground: CRIMSON[100],
      lightContrast: NAVY[700],
      darkForeground: CRIMSON[600],
      darkBackground: CRIMSON[600],
      darkContrast: NAVY[50],
    },
  },
  inverse: {
    success: {
      normalForeground: LIME_GREEN[500],
      normalBackground: LIME_GREEN[500],
      normalContrast: NAVY[50],
      lightForeground: LIME_GREEN[50],
      lightBackground: LIME_GREEN[50],
      lightContrast: NAVY[700],
      darkForeground: LIME_GREEN[600],
      darkBackground: LIME_GREEN[600],
      darkContrast: NAVY[50],
    },
    warning: {
      normalForeground: ORANGE[500],
      normalBackground: ORANGE[500],
      normalContrast: NAVY[700],
      lightForeground: ORANGE[300],
      lightBackground: ORANGE[100],
      lightContrast: NAVY[700],
      darkForeground: ORANGE[600],
      darkBackground: ORANGE[600],
      darkContrast: NAVY[700],
    },
    urgency: {
      normalForeground: RED[500],
      normalBackground: RED[500],
      normalContrast: NAVY[50],
      lightForeground: RED[100],
      lightBackground: RED[100],
      lightContrast: NAVY[700],
      darkForeground: RED[600],
      darkBackground: RED[600],
      darkContrast: NAVY[50],
    },
    critical: {
      normalForeground: CRIMSON[500],
      normalBackground: CRIMSON[500],
      normalContrast: NAVY[50],
      lightForeground: CRIMSON[100],
      lightBackground: CRIMSON[100],
      lightContrast: NAVY[700],
      darkForeground: CRIMSON[600],
      darkBackground: CRIMSON[600],
      darkContrast: NAVY[50],
    },
    favourite: {
      normalForeground: CRIMSON[500],
      normalBackground: CRIMSON[500],
      normalContrast: NAVY[50],
      lightForeground: CRIMSON[100],
      lightBackground: CRIMSON[100],
      lightContrast: NAVY[700],
      darkForeground: CRIMSON[600],
      darkBackground: CRIMSON[600],
      darkContrast: NAVY[50],
    },
  },
}

const highlightPalette: Record<App.Design.PaletteType, App.Design.HighlightPalette<string>> = {
  default: {
    primary: {
      normalForeground: TEAL[500],
      normalBackground: TEAL[500],
      normalContrast: NAVY[700],
      normalActive: shade(0.24, TEAL[500]),
      normalHover: shade(0.16, TEAL[500]),
      lightForeground: TEAL[50],
      lightBackground: TEAL[50],
      lightContrast: NAVY[700],
    },
    secondary: {
      normalForeground: TEAL[700],
      normalBackground: TEAL[700],
      normalContrast: NAVY[50],
      normalActive: shade(0.24, TEAL[700]),
      lightForeground: TEAL[50],
      lightBackground: TEAL[50],
      lightContrast: NAVY[700],
    },
    tertiary: {
      normalForeground: PURPLE[500],
      normalBackground: PURPLE[500],
      normalContrast: NAVY[50],
      lightForeground: PURPLE[50],
      lightBackground: PURPLE[50],
      lightContrast: NAVY[700],
    },
    luxPlus: {
      normalForeground: BLUE[400],
    },
  },
  inverse: {
    primary: {
      normalForeground: TEAL[500],
      normalBackground: TEAL[500],
      normalContrast: NAVY[700],
      normalActive: shade(0.24, TEAL[500]),
      normalHover: shade(0.16, TEAL[500]),
      lightForeground: TEAL[50],
      lightBackground: TEAL[50],
      lightContrast: NAVY[700],
    },
    secondary: {
      normalForeground: TEAL[600],
      normalBackground: TEAL[600],
      normalContrast: NAVY[50],
      normalActive: shade(0.24, TEAL[600]),
      lightForeground: TEAL[600],
      lightBackground: TEAL[50],
      lightContrast: NAVY[700],
    },
    tertiary: {
      normalForeground: PURPLE[500],
      normalBackground: PURPLE[500],
      normalContrast: NAVY[50],
      lightForeground: PURPLE[500],
      lightBackground: PURPLE[50],
      lightContrast: PURPLE[500],
    },
    luxPlus: {
      normalForeground: BLUE[400],
    },
  },
}

export const luxuryEscapesDefaultPalette: App.Design.Palette<string> = {
  brand: brandPalette.default,
  neutral: neutralPalette.default,
  product: productPalette.default,
  messaging: messagingPalette.default,
  highlight: highlightPalette.default,
}

export const luxuryEscapesInversePalette: App.Design.Palette<string> = {
  brand: brandPalette.inverse,
  neutral: neutralPalette.inverse,
  product: productPalette.inverse,
  messaging: messagingPalette.inverse,
  highlight: highlightPalette.inverse,
}

export const luxuryEscapesShadows: App.Design.Shadows<string> = { ...baseThemeShadows }
export const luxuryEscapesBorderRadii: App.Design.BorderRadii<string> = {
  XS: '0px',
  S: '2px',
  M: '4px',
  L: '8px',
  round: '9999px',
}

const luxuryEscapesTheme: App.Design.Theme = {
  ...base,
  typography: {
    heading: {
      one: {
        weight: 'semiBold',
      },
      oneAlt: {
        letterSpacing: '-0.5px',
        weight: 'semiBold',
      },
      two: {
        weight: 'semiBold',
      },
      twoAlt: {
        weight: 'semiBold',
      },
      three: {
        weight: 'semiBold',
      },
      threeAlt: {
        weight: 'semiBold',
        letterSpacing: '-0.5px',
      },
      four: {
        weight: 'semiBold',
      },
      five: {
        weight: 'superBold',
      },
      six: {
        weight: 'superBold',
      },
    },
  },
  font: {
    primary: {
      provider: 'local',
      localFileSrc: '/fonts/LE-fonts.css',
      name: 'Suisse Int\'l\'',
      family: '"Suisse Int\'l", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      weight: {
        regular: '400',
        bold: '500',
        semiBold: '500',
        superBold: '600',
      },
    },
    secondary: {
      provider: 'local',
      localFileSrc: '/fonts/LE-fonts.css',
      name: '"Suisse Int\'l\'"',
      family: '"Suisse Int\'l\", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      weight: {
        superBold: '600',
        bold: '500',
        semiBold: '450',
        regular: '400',
      },
    },
    secondaryAlternate: {
      provider: 'local',
      localFileSrc: '/fonts/LE-fonts.css',
      name: 'Suisse Works',
      family: '"Suisse Works", "Suisse Int\'l", "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontStyle: 'normal',
      weight: {
        // only semi-bold is offered by this alternate font
        bold: '450',
        semiBold: '450',
        regular: '450',
        superBold: '450',
      },
    },
  },
}

export default luxuryEscapesTheme
