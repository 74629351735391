import { useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchLuxLoyaltyPointsCalculation } from 'actions/LuxLoyaltyActions'
import buildPointsKey from 'luxLoyalty/lib/pointsCalculation/buildPointsKey'
import { getLuxLoyaltyDefaultTier } from 'luxLoyalty/selectors/utils'
import { nonNullable, sum } from 'lib/array/arrayUtils'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getPointsCalculationsByKeys, shouldUseInsuranceMemberPriceForCalculation } from 'luxLoyalty/selectors/pointsCalculation'

interface TotalPointsCalculation {
  totalPointsCalculation: App.StatefulData<App.LuxLoyaltyPointsEarnCalculation, boolean>;
  pointsCalculations: Array<App.StatefulData<App.LuxLoyaltyPointsEarnCalculation> | undefined>;
}

function useLuxLoyaltyPointsCalculator(
  calculatorOptions: Array<App.LuxLoyaltyPointsCalculatorOptions | undefined>,
): TotalPointsCalculation {
  const dispatch = useAppDispatch()
  const memberId = useAppSelector(state => state.auth.account.memberId)
  const accountDetails = useAppSelector(state => state.luxLoyalty.account.data)
  const defaultTier = useAppSelector(getLuxLoyaltyDefaultTier)
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const shouldUseInsuranceMemberPrice = useAppSelector(shouldUseInsuranceMemberPriceForCalculation)
  const optionsToCalculate = useMemo(() => {
    return nonNullable(calculatorOptions)
      .map(options => formatPointsCalculatorOptions(options, luxPlusEnabled, shouldUseInsuranceMemberPrice))
  }, [calculatorOptions, luxPlusEnabled, shouldUseInsuranceMemberPrice])

  type OptionsWithPointsKey = { pointsKey: string; calculatorOptions: App.LuxLoyaltyPointsCalculatorOptions }

  const optionsToCalculateWithPointsKey = useMemo<Array<OptionsWithPointsKey>>(() => {
    return optionsToCalculate.map((options) => {
      const pointsKey = buildPointsKey(options, accountDetails?.tier === defaultTier ? undefined : memberId)

      return {
        pointsKey,
        calculatorOptions: options,
      }
    })
  }, [optionsToCalculate, accountDetails?.tier, defaultTier, memberId])

  const pointsKeys = useMemo(() => optionsToCalculateWithPointsKey.map(({ pointsKey }) => pointsKey), [optionsToCalculateWithPointsKey])

  useEffect(() => {
    optionsToCalculateWithPointsKey.forEach(({ pointsKey, calculatorOptions }) => {
      dispatch(fetchLuxLoyaltyPointsCalculation(pointsKey, calculatorOptions))
    })
  }, [dispatch, optionsToCalculateWithPointsKey])

  const pointsCalculations = useAppSelector(state => getPointsCalculationsByKeys(state, pointsKeys))

  return useMemo<TotalPointsCalculation>(() => {
    const anyError = !!pointsCalculations.some(pointsCalculation => !!pointsCalculation?.error)
    const anyFetching = !!pointsCalculations.some(pointsCalculation => !!pointsCalculation?.fetching)

    if (anyFetching) {
      return {
        totalPointsCalculation: {
          fetching: true,
        },
        pointsCalculations,
      }
    }

    if (anyError) {
      return {
        totalPointsCalculation: {
          error: true,
          fetching: false,
        },
        pointsCalculations,
      }
    }

    return {
      totalPointsCalculation: {
        fetching: false,
        data: {
          points: sum(pointsCalculations, calculation => calculation?.data?.points ?? 0),
          statusCredits: sum(pointsCalculations, calculation => calculation?.data?.statusCredits ?? 0),
        },
      },
      pointsCalculations,
    }
  }, [pointsCalculations])
}

export default useLuxLoyaltyPointsCalculator

function formatPointsCalculatorOptions(
  options: App.LuxLoyaltyPointsCalculatorOptions,
  luxPlusEnabled: boolean,
  shouldUseInsuranceMemberPrice: boolean,
): App.LuxLoyaltyPointsCalculatorOptions {
  if (options.luxLoyaltyProductType === 'insurance') {
    const calculationPrice = shouldUseInsuranceMemberPrice && !!options.memberPrice ? options.memberPrice : options.price

    return {
      price: calculationPrice,
      luxLoyaltyProductType: options.luxLoyaltyProductType,
      mx: options.mx,
    }
  }

  const { memberPrice, ...restOptions } = options
  const calculationPrice = luxPlusEnabled && !!memberPrice ? memberPrice : restOptions.price

  return {
    ...restOptions,
    price: calculationPrice,
  }
}
