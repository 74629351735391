import { comluxgroup } from '@luxuryescapes/contract-data-event-schemas'
import getOccupantsFromCheckoutCart from 'analytics/snowplow/helpers/getOccupantsFromCheckoutCart'
import { getCategoryAndProductId, getHotelOrTourOffer } from 'analytics/snowplow/helpers/itemCategorisation'
import { checkoutAccommodationOfferView } from 'checkout/selectors/view/accommodation'
import { getVillaViews } from 'checkout/selectors/view/villa'
import { isMemberOrHasSubscriptionInTheCart } from 'checkout/selectors/view/luxPlusSubscription'
import { sum } from 'lib/array/arrayUtils'
import { isBedbankOffer } from 'lib/offer/offerTypes'
import { ItemContextMetadata } from 'analytics/snowplow/events'

function getHotelCheckoutItem(
  state: App.State,
  item: App.Checkout.BundleAndSaveItem | App.Checkout.BedbankHotelItem | App.Checkout.InstantBookingLEHotelItem | App.Checkout.BNBLLEHotelItem | App.Checkout.VillaItem,
  options?: Partial<comluxgroup.Item_1_1_0>,
  metadataOptions?: ItemContextMetadata,
) {
  const offerId = item.offerId
  const hotelOrTourOffer = getHotelOrTourOffer(state, offerId)
  const luxPlusMemberPriceApplies = isMemberOrHasSubscriptionInTheCart(state)
  const offer = hotelOrTourOffer as App.Offer
  const isFlashHotelOrVilla = item.itemType === 'hotel' || item.itemType === 'villa'
  const instantBooking = item.itemType === 'bedbankHotel' || (isFlashHotelOrVilla && item.reservationType === 'instant_booking')
  // bedbanks are instant booking only
  const reservationType = isFlashHotelOrVilla ? item.reservationType : 'instant_booking'
  const { categoryId, productId } = getCategoryAndProductId('hotel', offer)
  const offerLeadPrice = offer?.offerFlightsEnabled ? offer?.lowestPriceBundlePackage?.price : offer?.lowestPricePackage?.price
  const geo = offer?.property?.geoData
  let destinationCity = geo?.locality
  let destinationCountry = geo?.country
  let destinationRegion = geo?.administrativeAreaLevel1
  if (!geo && isBedbankOffer(hotelOrTourOffer)) {
    destinationCity = hotelOrTourOffer.property.address.city
    destinationCountry = hotelOrTourOffer.property.address.countryName
    destinationRegion = hotelOrTourOffer.property.address.stateProvinceName
  }

  let propertyFees: number | undefined
  let price: number | undefined
  let memberPrice: number | undefined
  let nonMemberPrice: number | undefined
  let surcharge: number | undefined
  let value: number | undefined
  if (item.itemType === 'villa') {
    const villasView = getVillaViews(state)
    const villaView = villasView.data.find(villaItem => villaItem.item.itemId === item.itemId)
    propertyFees = villaView?.totals.propertiesFees ? sum(villaView.totals.propertiesFees, f => f.propertyFees) : undefined
    price = villaView?.totals.price
    surcharge = villaView?.totals.surcharge
    value = villaView?.totals.value
  } else {
    const accommodationView = checkoutAccommodationOfferView(state)
    const accommodationViewItem = accommodationView.data[0].itemViews.find(v => v.item.itemId === item.itemId)
    propertyFees = accommodationViewItem?.totals.propertiesFees ? sum(accommodationViewItem.totals.propertiesFees, f => f.propertyFees) : undefined
    price = (luxPlusMemberPriceApplies && item.itemType === 'hotel') ? accommodationViewItem?.totals.memberPrice : accommodationViewItem?.totals.price
    surcharge = accommodationViewItem?.totals.surcharge
    value = accommodationViewItem?.totals.value
    nonMemberPrice = accommodationViewItem?.totals.price
    memberPrice = accommodationViewItem?.totals.memberPrice ? accommodationViewItem?.totals.memberPrice : accommodationViewItem?.totals.price
  }
  const isBundle = item.itemType === 'bundleAndSave'
  const duration = isBundle ? item?.dates[offerId].duration : item.duration
  const travelStart = isBundle ? item?.dates[offerId].checkIn : instantBooking ? item.checkIn : undefined
  const travelEnd = isBundle ? item?.dates[offerId].checkIn : instantBooking ? item.checkOut : undefined

  const travellers = getOccupantsFromCheckoutCart(state.checkout.cart.items)

  return comluxgroup.createItem_1_1_0(
    {
      offerId,
      categoryId,
      productId,
      typeId: item.itemType === 'bedbankHotel' ? item.roomId : undefined,
      rateId: item.itemType === 'bedbankHotel' ? item.roomRateId : undefined,
      travelStart,
      travelEnd,
      duration,
      price,
      value,
      numberOfAdults: travellers?.adults,
      numberOfChildren: travellers?.children,
      numberOfInfants: travellers?.infants,
      childrenAges: travellers?.childrenAge,
      offerLeadPrice,
      offerLeadDuration: offer?.lowestPricePackage?.duration ? String(offer?.lowestPricePackage?.duration) : undefined,
      offerLeadUnit: offer?.saleUnit,
      propertyFees,
      surcharge,
      destinationCity,
      destinationCountry,
      destinationRegion,
      reservationType,
      itemId: item.itemId,
      currency: state.geo.currentCurrency,
      ...options,
      metadata: memberPrice !== undefined ?
        JSON.stringify({
          memberPrice,
          nonMemberPrice,
          ...metadataOptions,
        }) :
        JSON.stringify(metadataOptions),
    },
  )
}

export default getHotelCheckoutItem
