import { syncUserCart } from 'actions/AuthActions'
import { LUX_BROADCAST_CHANNEL, canBroadcast } from 'lib/web/broadcastChannelUtils'
import watch from 'redux-watch'
import { AppStore } from 'src/client/store'

export function watchUserCart(store: AppStore) {
  store.subscribe(
    watch(store.getState, 'auth.userCart')(async(newState: App.UserCartState) => {
      if (newState.synced) return
      if (canBroadcast()) {
        const channel = new BroadcastChannel(LUX_BROADCAST_CHANNEL)
        // User cart changed sync it with other tabs
        channel.postMessage({ action: syncUserCart(newState) })
        channel.close()
      }
    }),
  )
}
