import React from 'react'
import styled, { css } from 'styled-components'
import Typography, { TYPOGRAPHY_ICON_SIZE_CSS_VAR, TYPOGRAPHY_LINE_HEIGHT_CSS_VAR } from './Typography'
import cn from 'clsx'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'

export const Heading1Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 34px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(40)};
  font-family: ${props => props.theme.font.secondary.family};
  letter-spacing: ${props => props.theme.typography.heading.one.letterSpacing};
  font-size: 32px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondary.weight[props.theme.typography.heading.one.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 48px;
    font-size: 40px;
  }
`

export const Heading1AltStyle = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 34px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(40)};
  font-family: ${props => props.theme.font.secondaryAlternate.family};
  letter-spacing: ${props => props.theme.typography.heading.oneAlt.letterSpacing};
  font-size: 32px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondaryAlternate.weight[props.theme.typography.heading.oneAlt.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 48px;
    font-size: 40px;
  }
`

export const Heading2Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 28px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(40)};
  font-family: ${props => props.theme.font.secondary.family};
  letter-spacing: ${props => props.theme.typography.heading.two.letterSpacing};
  font-size: 24px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondary.weight[props.theme.typography.heading.two.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 34px;
    font-size: 32px;
  }
`

export const Heading2AltStyle = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 28px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(40)};
  font-family: ${props => props.theme.font.secondaryAlternate.family};
  letter-spacing: ${props => props.theme.typography.heading.twoAlt.letterSpacing};
  font-style: ${props => props.theme.font.secondaryAlternate.fontStyle};
  font-size: 24px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondaryAlternate.weight[props.theme.typography.heading.twoAlt.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 34px;
    font-size: 32px;
  }
`

export const Heading3Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 24px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(24)};
  font-family: ${props => props.theme.font.secondary.family};
  letter-spacing: ${props => props.theme.typography.heading.three.letterSpacing};
  font-size: 20px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondary.weight[props.theme.typography.heading.three.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 28px;
    font-size: 24px;
  }
`

export const Heading3AltStyle = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 24px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(24)};
  font-family: ${props => props.theme.font.secondaryAlternate.family};
  letter-spacing: ${props => props.theme.typography.heading.threeAlt.letterSpacing};
  font-size: 20px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondaryAlternate.weight[props.theme.typography.heading.threeAlt.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 28px;
    font-size: 24px;
  }
`

export const Heading4Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 21px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(24)};
  font-family: ${props => props.theme.font.secondary.family};
  letter-spacing: ${props => props.theme.typography.heading.four.letterSpacing};
  font-size: 18px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondary.weight[props.theme.typography.heading.four.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 24px;
    font-size: 20px;
  }
`

export const Heading5Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 22px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(20)};
  font-family: ${props => props.theme.font.secondary.family};
  letter-spacing: ${props => props.theme.typography.heading.five.letterSpacing};
  font-size: 16px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondary.weight[props.theme.typography.heading.five.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 24px;
    font-size: 18px;
  }
`

export const Heading6Style = css`
  ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 19px;
  ${TYPOGRAPHY_ICON_SIZE_CSS_VAR}: ${rem(16)};
  font-family: ${props => props.theme.font.secondary.family};
  letter-spacing: ${props => props.theme.typography.heading.six.letterSpacing};
  font-size: 14px;

  &, &:is(b, strong), b, strong {
    font-weight: ${props => props.theme.font.secondary.weight[props.theme.typography.heading.six.weight]};
  }

  ${mediaQueryUp.tablet} {
    ${TYPOGRAPHY_LINE_HEIGHT_CSS_VAR}: 19px;
    font-size: 16px;
  }
`

/**
 * Old to new:
 * Grey medium -> neutral four
 * Black -> neutral one
 * Grey dark -> neutral two
 * white -> neutral eight
 */

/*
  Font sizes are express in px as they are not to be affected by the
  20% REM font decrease at mobile sizes. These font sizes are absolute sizes.
*/
const Element = styled(Typography)`
  /* reset font-style */
  &, &:is(i, em), i, em {
    font-style: normal;
  }

  &.colour-neutral-one {
    color: ${props => props.theme.palette.neutral.default.one};
  }

  &.colour-neutral-two {
    color: ${props => props.theme.palette.neutral.default.two};
  }

  &.colour-neutral-three {
    color: ${props => props.theme.palette.neutral.default.three};
  }

  &.colour-neutral-four {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &.colour-highlight-primary {
    color: ${props => props.theme.palette.highlight.primary.normalForeground};
  }

  &.colour-highlight-secondary {
    color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  }

  &.colour-neutral-eight {
    color: ${props => props.theme.palette.neutral.default.eight};
  }

  &.colour-exclusive {
    color: ${props => props.theme.palette.product.exclusive.foreground};
  }

  &.colour-success {
    color: ${props => props.theme.palette.messaging.success.normalForeground};
  }

  &.colour-urgent {
    color: ${props => props.theme.palette.messaging.urgency.normalForeground};
  }

  &.colour-lux-plus {
    color: ${props => props.theme.palette.product.luxPlus.foreground};
  }

  &.colour-exclusive-contrast {
    color: ${props => props.theme.palette.product.exclusive.contrast};
  }

  &.variant-heading1 {
    ${Heading1Style}
  }

  &.variant-heading1alt,
  &.variant-heading1alt:is(i, em),
  &.variant-heading1alt i,
  &.variant-heading1alt em,
  &.variant-heading1:is(i, em),
  &.variant-heading1 i,
  &.variant-heading1 em {
    ${Heading1AltStyle}
  }

  &.variant-heading2 {
    ${Heading2Style}
  }

  &.variant-heading2alt,
  &.variant-heading2alt:is(i, em),
  &.variant-heading2alt i,
  &.variant-heading2alt em,
  &.variant-heading2:is(i, em),
  &.variant-heading2 i,
  &.variant-heading2 em {
    ${Heading2AltStyle}
  }

  &.variant-heading3 {
    ${Heading3Style}
  }

  &.variant-heading3alt,
  &.variant-heading3alt:is(i, em),
  &.variant-heading3alt i,
  &.variant-heading3alt em,
  &.variant-heading3:is(i, em),
  &.variant-heading3 i,
  &.variant-heading3 em {
    ${Heading3AltStyle}
  }

  &.variant-heading4 {
    ${Heading4Style}
  }

  &.variant-heading5 {
    ${Heading5Style}
  }

  &.variant-heading6 {
    ${Heading6Style}
  }
`

interface Props extends Omit<React.ComponentProps<typeof Typography>, 'forwardedAs'> {
  variant: 'heading1' | 'heading1alt' | 'heading2' | 'heading2alt' | 'heading3' | 'heading3alt' | 'heading4' | 'heading5' | 'heading6';
  colour?: 'exclusive-contrast' | 'neutral-one' | 'neutral-two' | 'neutral-three' | 'neutral-eight' | 'neutral-four' | 'highlight-primary' | 'highlight-secondary' | 'exclusive' | 'success' | 'urgent' | 'lux-plus';
}

function Heading(props: Props) {
  const { variant, as, className, colour = 'neutral-one', ...rest } = props

  return <Element
    {...rest}
    forwardedAs={as}
    className={cn(
      className,
      `variant-${variant}`,
      `colour-${colour}`)
    }
  />
}

export default Heading
