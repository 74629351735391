import watch from 'redux-watch'
import { QueryClient } from '@tanstack/react-query'

import { AppStore } from 'src/client/store'
import { clearFormStateCheckout, clearFormStateSnapshot } from 'storage/checkout'
import { clearMemberSubscription } from 'actions/LuxPlusActions'
import { removeAuthedQueries } from 'tripPlanner/hooks/api/common'

export function watchAccount(store: AppStore, queryClient: QueryClient) {
  store.subscribe(watch(store.getState, 'auth.account')((account: App.AuthAccount) => {
    if (!account.memberId) {
      clearFormStateSnapshot()
      clearFormStateCheckout()
      removeAuthedQueries(queryClient)
      store.dispatch(clearMemberSubscription())
    }
  }))
}
