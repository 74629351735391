import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import moment from 'moment'
import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from '../view/accommodation'
import { getFlightItemsView } from '../view/flights'

const getInsuranceDatesFromCart = createSelector(
  (state: App.State) => checkoutAccommodationOfferView(state),
  (state: App.State) => getFlightItemsView(state),
  (accommodationViews, flightViews): { startDate: string, endDate: string } | undefined => {
    if (!accommodationViews.hasRequiredData || !flightViews.hasRequiredData) {
      return undefined
    }

    const accommodationDates = accommodationViews.data.map(item => ({
      startDate: item.startDate,
      endDate: item.endDate,
    }))

    const flights = flightViews.data.map(flightView => [
      ...(flightView.flights[0]?.journeyFlight.flights || []),
      ...(flightView.flights[1]?.journeyFlight.flights || []),
    ]).flat()

    const flightDates = flights.map(flight => ({
      startDate: flight.departingDate,
      endDate: flight.arrivalDate,
    }))

    const dates = [...accommodationDates, ...flightDates]

    /**
     * Typically, insurance quotes are generated as soon as customers land on the first checkout step.
     * However, in some cases, the dates are selected only during the checkout process, and initially, they may be undefined.
    */
    const invalidDates = dates.find((date) => !date.startDate || !date.endDate)
    if (invalidDates) return undefined

    if (dates.length) {
      return {
        startDate: moment.min(dates.map(date => moment(date.startDate, ISO_DATE_FORMAT))).format(ISO_DATE_FORMAT),
        endDate: moment.max(dates.map(date => moment(date.endDate, ISO_DATE_FORMAT))).format(ISO_DATE_FORMAT),
      }
    }
  },
)

export default getInsuranceDatesFromCart
