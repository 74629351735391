import { createSelector } from 'reselect'
import { arrayToObject } from 'lib/array/arrayUtils'
import { getFlightCalendarKey } from 'lib/flights/flightUtils'
import config from 'constants/config'
import { parseSearchString } from 'lib/url/searchUrlUtils'
import { FlightsClassTypes, FlightsFareTypes } from 'constants/flight'
import { FlightSearchParams } from 'hooks/useSearchFlights'
import { OfferPageState } from 'contexts/OfferPage/offerPageStateReducer'
import { getPromotionDefaultPeriod } from 'lib/hotels/bedbankUtils'
import findClosestAirport from 'lib/flights/findClosestAirport'
import { FlightSearchItinerary } from 'api/flights'
import { isEnabledForFeature } from 'lib/config/featureFlagUtils'

export const getClosestAirport = createSelector(
  (state: App.State) => state.geo.latitude,
  (state: App.State) => state.geo.longitude,
  (state: App.State) => state.geo.originAirports,
  (state: App.State) => state.geo.mainAirport,
  (state: App.State) => state.geo.currentRegionCode,
  (latitude, longitude, airports, fallbackAirport, regionCode) => {
    if (regionCode !== 'AU') {
      return fallbackAirport
    }

    const closest = findClosestAirport(airports, latitude, longitude)
    return closest ?? fallbackAirport
  },
)

export const flightsHasFiltersApplied = createSelector(
  (state: App.State) => state.flights.filters,
  (filters) => filters.some(f => f.options.some(o => o.active && !o.isDefault)),
)

export const getAirportsByCode = createSelector(
  (state: App.State) => state.geo.originAirports,
  airports => arrayToObject(airports, airport => airport.code),
)

export const getDefaultAirport = createSelector(
  (state: App.State) => getClosestAirport(state),
  (state: App.State) => state.auth.account.recentlyUsedAirportCode,
  (state: App.State) => getAirportsByCode(state),
  (closestAirport, recentlyUsedAirportCode, originAirports): App.Airport | undefined => {
    return originAirports[recentlyUsedAirportCode] ?? closestAirport
  },
)

export const getDefaultAirportCode = (state: App.State) => getDefaultAirport(state)?.code

export const selectIsBedbankFlightBookingEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionHasFlights,
  (currentRegionHasFlights) => !!(currentRegionHasFlights && config.BEDBANK_FLIGHT_BOOKING_ENABLED),
)

export const isStandaloneFlightsEnabled = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.geo.currentRegionHasFlights,
  (regionCode, regionHasFlights) => regionHasFlights && config.ENABLE_STANDALONE_FLIGHTS && (!config.STANDALONE_FLIGHT_REGIONS || isEnabledForFeature(config.STANDALONE_FLIGHT_REGIONS, regionCode)),
)

export const isFlightsCredit = createSelector(
  (state: App.State) => state.router.location.search,
  (search) => {
    const { flightsCredit } = parseSearchString(search)
    return flightsCredit === 'true' && config.ENABLE_FLIGHTS_CREDIT
  },
)

export const getFlightSearchValues = createSelector(
  (state: App.State) => state.router.location.search,
  (state: App.State) => state.geo.airports,
  (search, airports) => {
    const {
      departDate,
      returnDate,
      originAirportCode,
      originAirportName,
      destinationAirportCode,
      destinationAirportName,
      maxArrivalTime,
      minReturningDepartureTime,
      source,
      fareClass,
      adults,
      children,
      infants,
      fareType,
      childrenAge,
      carrier,
      filterAirlines,
      key,
      departingQuotedPrice,
      returningQuotedPrice,
    } = parseSearchString(search)

    const occupants: App.Occupants = {
      adults: parseInt(adults),
      children: parseInt(children),
      infants: parseInt(infants),
      childrenAge: childrenAge ? childrenAge.split(',').map((age) => parseInt(age)) : [],
    }

    return {
      originAirport: airports.find(a => a.code === originAirportCode),
      destinationAirport: airports.find(a => a.code === destinationAirportCode),
      departDate,
      returnDate,
      maxArrivalTime,
      source,
      minReturningDepartureTime,
      fareClass: fareClass as FlightsClassTypes,
      occupants,
      fareType: fareType as FlightsFareTypes,
      departureLocation: {
        airportCode: originAirportCode,
        airportName: originAirportName,
      } as App.AirportLocation,
      arrivalLocation: {
        airportCode: destinationAirportCode,
        airportName: destinationAirportName,
      } as App.AirportLocation,
      carrier,
      filterAirlines: filterAirlines?.split(','),
      departingQuotedPrice,
      returningQuotedPrice,
      key,
    }
  },
)

export const getFlightMultiCitySearchValues = createSelector(
  (state: App.State) => state.router.location.search,
  (state: App.State) => state.geo.airports,
  (search, airports) => {
    const {
      maxArrivalTime,
      minReturningDepartureTime,
      source,
      fareClass,
      adults,
      children,
      infants,
      fareType,
      childrenAge,
      carrier,
      filterAirlines,
      key,
      flights,
    } = parseSearchString(search)

    const occupants: App.Occupants = {
      adults: parseInt(adults),
      children: parseInt(children),
      infants: parseInt(infants),
      childrenAge: childrenAge ? childrenAge.split(',').map((age) => parseInt(age)) : [],
    }

    const flightsData: Array<{
      originAirportCode: string;
      originAirportName: string;
      destinationAirportCode: string;
      destinationAirportName: string;
      departDate: string;
    }> = flights as any

    return {
      flights: flightsData?.map(flight => ({
        originAirport: airports.find(a => a.code === flight.originAirportCode),
        destinationAirport: airports.find(a => a.code === flight.destinationAirportCode),
        departDate: flight.departDate,
      })) ?? [],
      maxArrivalTime,
      source,
      minReturningDepartureTime,
      fareClass: fareClass as FlightsClassTypes,
      occupants,
      fareType: fareType as FlightsFareTypes,
      carrier,
      filterAirlines: filterAirlines?.split(','),
      key,
    }
  },
)

/** For use with the `useSearchFlights` hook */
export const getFlightSearchParams = createSelector(
  (state: App.State) => getFlightSearchValues(state),
  (state: App.State) => isFlightsCredit(state),
  (values, flightsCredit): FlightSearchParams => {
    const itinerary: Array<FlightSearchItinerary> = [{
      departureDate: values.departDate,
      departureAirport: values.originAirport?.code!,
      arrivalAirport: values.destinationAirport?.code!,
    }]

    if ([FlightsFareTypes.RETURN, FlightsFareTypes.MULTI_CITY].includes(values.fareType)) {
      itinerary.push({
        departureDate: values.returnDate,
        arrivalAirport: values.originAirport?.code!,
        departureAirport: values.destinationAirport?.code!,
      })
    }

    return {
      itinerary,
      occupancies: [values.occupants],
      fareClass: values.fareClass,
      fareType: values.fareType,
      maxArrivalTime: values.maxArrivalTime,
      minReturningDepartureTime: values.minReturningDepartureTime,
      source: values.source,
      viewType: values.fareType === FlightsFareTypes.ONE_WAY ? 'OneWay' : undefined,
      carrier: values.carrier,
      filterAirlines: values.filterAirlines,
      key: values.key,
      flightsCredit,
    }
  },
)

/** For use with the `useSearchFlights` hook */
export const getMultiCityFlightSearchParams = createSelector(
  (state: App.State) => getFlightMultiCitySearchValues(state),
  (state: App.State) => isFlightsCredit(state),
  (values, flightsCredit): FlightSearchParams => {
    const itinerary: Array<FlightSearchItinerary> = values.flights?.map(flight => ({
      departureAirport: flight.originAirport?.code!,
      arrivalAirport: flight.destinationAirport?.code!,
      departureDate: flight.departDate,
    }))

    return {
      itinerary,
      occupancies: [values.occupants],
      fareClass: values.fareClass,
      fareType: values.fareType,
      maxArrivalTime: values.maxArrivalTime,
      minReturningDepartureTime: values.minReturningDepartureTime,
      source: values.source,
      viewType: values.fareType === FlightsFareTypes.ONE_WAY ? 'OneWay' : undefined,
      carrier: values.carrier,
      filterAirlines: values.filterAirlines,
      key: values.key,
      flightsCredit,
    }
  },
)

export const isFlightBedbankCalendarFetching = createSelector(
  (state: OfferPageState) => state.bedbankOffer,
  (_state: OfferPageState, appState: App.State) => appState.flights.flightCalendarLoading,
  (state: OfferPageState) => state.airport,
  (state: OfferPageState) => state.duration,
  (_state: OfferPageState, appState: App.State) => appState.geo.currentRegionCode,
  (_state: OfferPageState, appState: App.State) => appState.geo.currentCurrency,
  (offer, fetching, airport, duration, region, currency) => !!fetching[getFlightCalendarKey({
    ...getPromotionDefaultPeriod(),
    origin: airport?.code,
    destination: offer?.flights?.airportCode,
    nights: duration,
    currency,
    region,
  })],
)

export const getFlightCalendar = createSelector(
  (state: OfferPageState) => state.bedbankOffer,
  (_state: OfferPageState, appState: App.State) => appState.flights.flightCalendar,
  (state: OfferPageState) => state.airport,
  (state: OfferPageState) => state.duration,
  (_state: OfferPageState, appState: App.State) => appState.geo.currentRegionCode,
  (_state: OfferPageState, appState: App.State) => appState.geo.currentCurrency,
  (offer, flightCalendars, airport, duration, region, currency) => {
    const flightCalendar = flightCalendars[getFlightCalendarKey({
      ...getPromotionDefaultPeriod(),
      origin: airport?.code,
      destination: offer?.flights?.airportCode,
      nights: duration,
      currency,
      region,
    })]
    return flightCalendar && 'cachedPrices' in flightCalendar ? flightCalendar.cachedPrices : undefined
  },
)

export const isMultiCityEnabled = createSelector(
  (state: App.State) => state.auth.account.isSpoofed,
  (isSpoofed) => {
    return (config.FLIGHTS_MULTI_CITY_ENABLED_SPOOFED && isSpoofed) || config.FLIGHTS_MULTI_CITY_ENABLED
  },
)
