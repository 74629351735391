import { LERE_DEFAULT_USER_ID } from 'components/Recommendations/common/constant'
import getObjectKey from 'lib/object/getObjectKey'
import { createSelector } from 'reselect'
import { getHighIntentTours } from './highIntentSelectors'

export const selectHighIntentTourOfferIds = createSelector(
  (state: App.State) => state.recommendations.highIntent.state,
  (state: App.State) => getHighIntentTours(state),
  (
    highIntentState,
    highIntentTours,
  ): Array<string> | undefined => {
    if (highIntentState === 'done') {
      return highIntentTours.map(tour => tour.offerId)
    }
  },
)

export function constructPersonalisedTourKey(
  currentRegionCode: string,
  memberId: string = LERE_DEFAULT_USER_ID,
  tourOfferIds: Array<string>,
): string {
  return getObjectKey({
    currentRegionCode,
    memberId,
    tourOfferIds,
  })
}

export const selectPersonalisedTourKey = createSelector(
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.auth.account.memberId,
  (state: App.State) => selectHighIntentTourOfferIds(state),
  (
    currentRegionCode,
    memberId = LERE_DEFAULT_USER_ID,
    highIntentTourOfferIds,
  ): string | undefined => {
    if (highIntentTourOfferIds) {
      return constructPersonalisedTourKey(currentRegionCode, memberId, highIntentTourOfferIds)
    }
  },
)
