import {
  CHECKOUT_ITEM_TYPE_TOUR_V2,
  CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE,
} from 'constants/checkout'
import extractTourV2PurchasableOptionDetails from 'lib/tours/extractTourV2PurchasableOptionDetails'
import { getItemUniqueKey, getPackageId } from '../accommodation/cart'
import { OFFER_TYPE_TOUR_V2 } from 'constants/offer'
import getLuxLoyaltyProductType from 'luxLoyalty/lib/getLuxLoyaltyProductType'

export function isTourOfferViewWithPackageUpgrade(
  view: App.Checkout.AccommodationOfferView,
): view is App.Checkout.TourV2AccommodationOfferView {
  return view.offerType === OFFER_TYPE_TOUR_V2 && view.itemViews.some((item) => !!item?.packageOptionInclusion)
}

function dummyAccommodationItemView(
  item: App.Checkout.TourV2Item,
  kind: 'tourV2',
  offer?: App.Tours.TourV2Offer,
): App.Checkout.TourV2AccommodationItemView {
  return {
    kind,
    packageId: getPackageId(item),
    uniqueKey: getItemUniqueKey(item),
    item,
    luxLoyaltyProductType: getLuxLoyaltyProductType(offer?.productType),
    mainLabel: '',
    occupancy: { adults: 0, children: 0, infants: 0 },
    removeLabel: 'Remove',
    totals: {
      price: 0,
      memberPrice: 0,
      value: 0,
      surcharge: 0,
      taxesAndFees: 0,
      memberValue: 0,
      extraGuestSurcharge: 0,
    },
  }
}

export function getTourV2ItemView(
  item: App.Checkout.TourV2Item,
  index: number,
  offer?: App.Tours.TourV2Offer,
  postPurchase?: App.CheckoutCartMode,
  existingOrder?: App.Order,
): App.WithDataStatus<App.Checkout.TourV2AccommodationItemView> {
  if (!offer) {
    return {
      hasRequiredData: false,
      data: dummyAccommodationItemView(item, CHECKOUT_ITEM_TYPE_TOUR_V2, offer),
    }
  }

  const {
    roomTypeName,
    pricePerPerson,
    memberPricePerPerson,
    valuedAt,
  } = extractTourV2PurchasableOptionDetails(item.purchasableOption)

  const variationId = item.purchasableOption.fkVariationId
  const variation = offer.variations[variationId] ?? Object.values(offer?.variations ?? {}).find(variation => variation.fkTourOptionId === variationId)
  const packageOptionInclusion = variation?.availablePackageUpgrades?.find(
    packageUpgrade => packageUpgrade.packageOption.toLowerCase() === item.purchasableOption.packageType?.toLowerCase(),
  )

  const isTourDateChange = postPurchase === 'tour-change-dates'
  // only set paid price for the first item in the list on changes date because it's not splitable per room
  const paidPrice = isTourDateChange && index === 0 ? existingOrder?.tourItems?.[0].total : undefined
  return {
    hasRequiredData: true,
    data: {
      kind: CHECKOUT_ITEM_TYPE_TOUR_V2,
      packageId: item.purchasableOption.fkDepartureId,
      uniqueKey: item.purchasableOption.fkDepartureId,
      item,
      luxLoyaltyProductType: getLuxLoyaltyProductType(offer.productType),
      mainLabel: roomTypeName ?? '',
      occupancy: item.occupancy,
      removeLabel: 'Remove room',
      totals: {
        price: pricePerPerson,
        memberPrice: memberPricePerPerson,
        value: valuedAt?.price ?? 0,
        surcharge: 0,
        taxesAndFees: 0,
        paidPrice,
        memberValue: 0,
        extraGuestSurcharge: 0,
      },
      variation,
      packageOptionInclusion,
    },
  }
}

export function getTourExperienceV2ItemView(
  item: App.Checkout.TourV2ExperienceItem,
): App.Checkout.TourV2ExperienceItemView {
  return {
    kind: CHECKOUT_ITEM_TYPE_TOUR_V2_EXPERIENCE,
    packageId: item.purchasableOption.fkExperienceId!,
    uniqueKey: item.purchasableOption.fkExperienceId!,
    item,
    mainLabel: item.purchasableOption.name ?? '',
    removeLabel: 'Remove',
    totals: {
      price: item.total ?? 0,
      memberPrice: 0,
      value: 0,
      surcharge: 0,
      taxesAndFees: 0,
      memberValue: 0,
      extraGuestSurcharge: 0,
    },
  }
}
