import React, { useCallback, useRef, useImperativeHandle } from 'react'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import LineTimesIcon from 'components/Luxkit/Icons/line/LineTimesIcon'
import IconButton from 'components/Luxkit/Button/IconButton'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import styled from 'styled-components'
import { rem } from 'polished'
import noop from 'lib/function/noop'
import HiddenInput from 'components/Common/Form/Input/HiddenInput'

const Field = styled.div`
  position: relative;
`

const DisplayField = styled(SearchFormField)`
  pointer-events: none;
  transition: opacity 0.2s;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
  border: none;
  width: auto;

  &.hidden {
    opacity: 0;
  }
`

const ClearButton = styled(IconButton)`
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.2s, visibility 0s 0.2s;
`

const SearchInput = styled(TextInputOnly)`
  height: ${rem(50)};

  > input {
    padding-left: ${rem(16)};
    height: 100%;
  }

  &:focus-within + ${DisplayField} {
    opacity: 0;
  }

  &:focus-within > input:not(:placeholder-shown) + div ${ClearButton} {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transition: opacity 0.2s, visibility 0s;
  }
`

interface Props extends Omit<React.ComponentProps<typeof TextInputOnly>, 'noValidationSpacing' | 'noValidationMessage' > {
  label?: string;
  value?: string;
  defaultValue?: string;
  displayValue?: string;
  onClear?: () => void;
}

const SearchFormTextField = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    placeholder,
    className,
    label,
    value,
    displayValue,
    onClear = noop,
    required,
    ...inputProps
  } = props

  const localInputRef = useRef<HTMLInputElement>(null)
  useImperativeHandle(ref, () => localInputRef.current!)

  const onClearClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    e.preventDefault()
    if (localInputRef.current) {
      localInputRef.current.value = ''
      localInputRef.current.dispatchEvent(new Event('change', { bubbles: true }))
      onClear()
    }
  }, [onClear])

  return <Field className={className}>
    <HiddenInput
      required={required}
      value={displayValue || value}
    />
    <SearchInput
      {...inputProps}
      noValidationSpacing
      noValidationMessage
      ref={localInputRef}
      placeholder={placeholder}
      value={value}
      endIcon={<ClearButton
        kind="tertiary"
        // Safari does not focus buttons when they are clicked, this causes the ';focus-within' to
        // be removed and the button does not click (it'll have pointer events: none)
        // So we need to capture the 'click' earlier via mouse down
        onMouseDown={onClearClick}
      >
        <LineTimesIcon />
      </ClearButton>}
    />
    <DisplayField
      label={label}
      placeholder={placeholder}
      value={displayValue || value}
      className="display-field"
    />
  </Field>
})

SearchFormTextField.displayName = 'SearchFormSelectField'

export default SearchFormTextField
