import { definitions, Promotion } from '@luxuryescapes/contract-svc-promo'

import {
  CHECKOUT_ITEM_TYPE_TOUR_V2,
  CHECKOUT_ITEM_TYPE_CRUISE,
  CHECKOUT_ITEM_TYPE_CAR_HIRE,
  CHECKOUT_ITEM_TYPE_TOUR_V1,
  CHECKOUT_ITEM_TYPE_BEDBANK,
  CHECKOUT_ITEM_TYPE_LE_HOTEL,
  CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE,
  CHECKOUT_ITEM_TYPE_EXPERIENCE,
  CHECKOUT_ITEM_TYPE_TRANSFER,
  CHECKOUT_ITEM_TYPE_FLIGHT,
  CHECKOUT_ITEM_TYPE_INSURANCE,
  CHECKOUT_ITEM_TYPE_CARHIRE,
  CHECKOUT_ITEM_TYPE_GIFT_CARD,
} from 'constants/checkout'

type ReferralAllowedTypes = definitions['Config']['globalPromoConfig']['referralAllowedTypes'][number];

export type ReferralOfferTypes = ReferralAllowedTypes | 'DISALLOWED_UNMAPPED' | 'DISALLOWED_UNKNOWN';

export type PromoToggleKeys = definitions['PromoToggles']['key']

export type DiscountRequestOrder = definitions['Discount Request Order']

export type DiscountRequestItem = definitions['Discount Request Order']['items']['0']

export function itemTypeToReferralOfferType(itemType: string):ReferralOfferTypes {
  switch (itemType) {
    case CHECKOUT_ITEM_TYPE_LE_HOTEL:
    case CHECKOUT_ITEM_TYPE_EXPERIENCE:
    case CHECKOUT_ITEM_TYPE_FLIGHT:
      return itemType
    case CHECKOUT_ITEM_TYPE_BEDBANK:
      return 'bedbank_hotel'
    case CHECKOUT_ITEM_TYPE_TOUR_V1:
      return 'tour'
    case CHECKOUT_ITEM_TYPE_TOUR_V2:
      return 'tour_v2'
    case CHECKOUT_ITEM_TYPE_CRUISE:
      return 'cruises'
    case CHECKOUT_ITEM_TYPE_TRANSFER:
    case CHECKOUT_ITEM_TYPE_CARHIRE:
    case CHECKOUT_ITEM_TYPE_CAR_HIRE:
    case CHECKOUT_ITEM_TYPE_INSURANCE:
    case CHECKOUT_ITEM_TYPE_BUNDLE_AND_SAVE:
    case CHECKOUT_ITEM_TYPE_GIFT_CARD:
      return 'DISALLOWED_UNMAPPED'
    default:
      console.error(`(referral) Could not find map: "${itemType}"`)
      return 'DISALLOWED_UNKNOWN'
  }
}

/**
 * @@deprecated - see promotionMapV2
 */
export function promotionMap(promo: Promotion.Promotion): App.Promotion {
  return ({
    id: promo.id_promo_code,
    code: promo.code_name,
    discount: promo.promo_value,
    type: promo.promo_type,
    warning: promo.warning || '',
    maxDiscount: promo.max_discount || undefined,
    expiresAt: promo.expires_at,
    items: [],
    currency: promo.currency,
    minSpend: promo.min_spend || undefined,
    allowedPaymentMethods: promo.allowed_payment_methods,
    allowedAirlineCarriers: promo.allowed_airline_carriers,
    isCorporate: promo.is_corporate,
    isFriendsAndFamily: promo.promo_category === 'friends_and_family',
    hasBinNumbers: promo.has_bin_numbers,
    hideDiscountPercentage: false,
    promoToggles: promo.promo_toggles,
    popupMessage: promo.popup_message,
  })
}

export function itemPromotionMap(item: definitions['Discount Response']['promo']['items'][0]):App.Checkout.ItemDiscount {
  return ({
    offerId: item.offerId,
    itemId: item.itemId,
    categoryBK: item.categoryBK,
    subCategoryBK: item.subCategoryBK,
    productBK: item.productBK?.toString(),
    discountAmount: item.discountAmount,
    discountType: item.discountType,
    discountableTotal: item.discountableTotal,
    maxDiscount: item.maxDiscount,
    discountValue: item.discountValue,
    hideDiscountPercentage: item.discountHasCombinedWithLuxPlusPricing,
    itemInfoString: item.itemInfoString ?? 'no-promo-item-info-string?',
  })
}

export function promotionMapV2(promoV2: definitions['Discount Response']['promo']): App.Promotion {
  const items = promoV2.items.map((i) => itemPromotionMap(i))
  return ({
    id: promoV2.id_promo_code,
    code: promoV2.code_name,
    warning: promoV2.warning,
    discount: promoV2.discount,
    allowedPaymentMethods: promoV2.allowed_payment_methods ?? [],
    currency: promoV2.currency,
    maxDiscount: promoV2.max_discount,
    discountTotal: promoV2.discount_total,
    items,
    expiresAt: promoV2.expires_at,
    minSpend: promoV2.min_spend,
    type: promoV2.type,
    promoToggles: promoV2.promo_toggles,
    corporateName: promoV2.corporate_name,
    isCorporate: promoV2.is_corporate,
    isFriendsAndFamily: promoV2.promo_category === 'friends_and_family',
    hasBinNumbers: promoV2.has_bin_numbers,
    hideDiscountPercentage: items.some((i) => i.hideDiscountPercentage),
    popupMessage: promoV2.popup_message,
  })
}
