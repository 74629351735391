import React, { useCallback } from 'react'
import SwitchButton from 'components/Common/SwitchButton'
import { useAppDispatch } from 'hooks/reduxHooks'
import { connect } from 'react-redux'
import Group from '../../../../utils/Group'
import BodyText from '../../../../Luxkit/Typography/BodyText'
import { toggleShowMarginInSpoofMode } from 'actions/SystemActions'

interface MappedProps {
  showMarginInSpoofMode: boolean;
}

function ShowMarginSpoofModeToggle({
  showMarginInSpoofMode,
}: MappedProps) {
  const dispatch = useAppDispatch()

  const onToggleShowMarginWhenSpoof = useCallback(() => {
    dispatch(toggleShowMarginInSpoofMode())
  }, [dispatch])

  return (
    <Group direction="horizontal" gap={8} verticalAlign="center">
      <BodyText variant="medium" colour="neutral-one">Store mode</BodyText>
      <SwitchButton
        toggled={!showMarginInSpoofMode}
        onToggle={onToggleShowMarginWhenSpoof}
      />
    </Group>
  )
}

function mapStateToProps(state: App.State) {
  return {
    showMarginInSpoofMode: state.system.showMarginInSpoofMode,
  }
}

export default connect(mapStateToProps)(ShowMarginSpoofModeToggle)
