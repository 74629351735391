import { arrayToObject } from 'lib/array/arrayUtils'
import { reducerSwitch } from 'lib/redux/reducerUtils'
import { AnyAction } from 'redux'

export enum BUSINESS_TRAVELLER_API_ACTIONS {
  FETCH_ACCOUNT_BUSINESS = 'BUSINESS_TRAVELLER_FETCH_ACCOUNT_BUSINESS',
  FETCH_APPROVAL_REQUEST = 'BUSINESS_TRAVELLER_FETCH_APPROVAL_REQUEST',
  FETCH_OFFER_CREDITS = 'BUSINESS_TRAVELLER_FETCH_OFFER_CREDITS',
  FETCH_MULTIPLE_OFFERS_CREDITS = 'BUSINESS_TRAVELLER_FETCH_MULTIPLE_OFFERS_CREDITS',
  FETCH_BUSINESS_BUDGET = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_BUDGET',
  FETCH_BUSINESS_ACCOMMODATION_POLICY = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_ACCOMMODATION_POLICY',
  FETCH_BUSINESS_FLIGHT_POLICY = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_FLIGHT_POLICY',
  FETCH_BUSINESS_CAR_HIRE_POLICY = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_CAR_HIRE_POLICY',
  FETCH_BUSINESS_FLIGHT_BUDGET = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_FLIGHT_BUDGET',
  FETCH_BUSINESS_CAR_HIRE_BUDGET = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_CAR_HIRE_BUDGET',
  UPDATE_EMPLOYEE_ONBOARDING_STATUS = 'BUSINESS_TRAVELLER_UPDATE_EMPLOYEE_ONBOARDING_STATUS',
  UPDATE_EMPLOYEE_ONBOARDING_FLAGS = 'BUSINESS_TRAVELLER_UPDATE_EMPLOYEE_ONBOARDING_FLAGS',
  UPDATE_EMPLOYEE_DISPLAY_FLIGHT_BUDGET_STATUS = 'BUSINESS_TRAVELLER_UPDATE_EMPLOYEE_DISPLAY_FLIGHT_BUDGET_STATUS',
  UPDATE_EMPLOYEE_FREQUENT_FLYER = 'BUSINESS_TRAVELLER_UPDATE_EMPLOYEE_FREQUENT_FLYER',
  FETCH_BUSINESS_EMPLOYEES = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_EMPLOYEES',
  FETCH_BUSINESS_APPROVERS = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_APPROVERS',
  FETCH_BUSINESS_FLIGHT_BUDGET_SEARCH = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_FLIGHT_SEARCH',
  FETCH_BUSINESS_APPROVAL_POLICY = 'BUSINESS_TRAVELLER_FETCH_BUSINESS_APPROVAL_POLICY',
  FETCH_BUSINESS_EMPLOYEE_DETAILS = 'BUSINESS_TRAVELLER_FETCH_EMPLOYEE_DETAILS',
}

// REQUEST
export const businessTravellerApiRequestActions = reducerSwitch<App.BusinessTravellerState>({
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_ACCOUNT_BUSINESS]: () => {
    return {
      currentBusinessAccount: { status: 'pending' },
      onboarding: { status: 'pending' },
      employees: { status: 'pending' },
      businessCards: { status: 'pending', cards: [] },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_APPROVAL_REQUEST]: () => {
    return {
      approvalRequests: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.UPDATE_EMPLOYEE_ONBOARDING_STATUS]: () => {
    return {
      onboarding: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.UPDATE_EMPLOYEE_DISPLAY_FLIGHT_BUDGET_STATUS]: () => {
    return {
      flightBudgetNotification: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_OFFER_CREDITS]: (state, action) => {
    return {
      offersCredits: {
        ...state.offersCredits,
        [action.creditItemKey]: { status: 'pending' },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_MULTIPLE_OFFERS_CREDITS]: (state, action) => {
    const requestedOfferCredits = Object.fromEntries<App.BusinessTraveller.StatefulData<App.BusinessTraveller.OfferCredit>>(
      Object.keys(action.toBeCalculatedOfferCredits)
        .map((offerCreditKey) => ([offerCreditKey, { status: 'pending' }])),
    )
    return {
      offersCredits: {
        ...state.offersCredits,
        ...requestedOfferCredits,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_BUDGET]: (state, action) => {
    return {
      businessBudgets: {
        ...state.businessBudgets,
        [action.budgetKey]: { status: 'pending' },
      },
      businessMapBudget: undefined,
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_ACCOMMODATION_POLICY]: () => {
    return {
      businessAccommodationPolicy: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_POLICY]: () => {
    return {
      businessFlightPolicy: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_CAR_HIRE_POLICY]: () => {
    return {
      businessCarHirePolicy: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_BUDGET]: (state, action) => {
    return {
      businessFlightBudgets: {
        ...state.businessFlightBudgets,
        [action.budgetKey]: { status: 'pending' },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_CAR_HIRE_BUDGET]: (state, action) => {
    return {
      businessCarHireBudgets: {
        ...state.businessCarHireBudgets,
        [action.budgetKey]: { status: 'pending' },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_EMPLOYEES]: () => {
    return {
      employees: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_APPROVERS]: () => {
    return {
      approvers: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_BUDGET_SEARCH]: (state, action) => {
    return {
      flightSearchBudget: {
        ...state.flightSearchBudget,
        [action.flightSearchKey]: { status: 'pending' },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_APPROVAL_POLICY]: () => {
    return {
      businessApprovalPolicy: { status: 'pending' },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_EMPLOYEE_DETAILS]: () => {
    return {
      employeeDetails: { status: 'pending' },
    }
  },
})

// SUCCESS
export const businessTravellerApiSuccessActions = reducerSwitch<App.BusinessTravellerState>({
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_ACCOUNT_BUSINESS]: (state, action) => {
    const businessAccount = action.data.businessAccount
    const businessCards = action.data.businessCards

    return {
      currentBusinessAccount: {
        status: 'success',
        business: businessAccount.business,
        employee: businessAccount.employee,
      },
      onboarding: {
        status: 'success',
        employeeOnboardingStatus: businessAccount.employeeOnboardingStatus,
      },
      flightBudgetNotification: {
        status: 'success',
        displayFlightBudgetNotification: businessAccount.displayFlightBudgetNotification,
      },
      employees: {
        status: 'success',
        all: arrayToObject(businessAccount.employees, (employee: App.BusinessTraveller.EmployeeFromMeEndpoint) => employee.id),
      },
      businessCards: {
        status: 'success',
        cards: businessCards,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_APPROVAL_REQUEST]: (state, action) => {
    return {
      approvalRequests: {
        status: 'success',
        request: action.data,
      },
    }
  },

  [BUSINESS_TRAVELLER_API_ACTIONS.UPDATE_EMPLOYEE_ONBOARDING_STATUS]: (state, action) => {
    return {
      onboarding: {
        status: 'success',
        employeeOnboardingStatus: action.data.employeeOnboardingStatus,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.UPDATE_EMPLOYEE_ONBOARDING_FLAGS]: (state, action) => {
    const newFlags = action.data

    if (state.currentBusinessAccount && state.currentBusinessAccount.status === 'success' && state.currentBusinessAccount.employee) {
      const currentEmployee = state.currentBusinessAccount.employee
      const existingFlags = currentEmployee.onboardingFlags || []

      const updatedFlags = new Set([...existingFlags, ...newFlags])

      return {
        ...state,
        currentBusinessAccount: {
          ...state.currentBusinessAccount,
          employee: {
            ...currentEmployee,
            onboardingFlags: Array.from(updatedFlags),
          },
        },
      }
    }

    return state
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.UPDATE_EMPLOYEE_DISPLAY_FLIGHT_BUDGET_STATUS]: () => {
    return {
      flightBudgetNotification: {
        status: 'success',
        displayFlightBudgetNotification: false,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_OFFER_CREDITS]: (state, action) => {
    return {
      offersCredits: {
        ...state.offersCredits,
        [action.creditItemKey]: {
          status: 'success',
          creditValue: action.data.creditValue,
          budgetCompliance: action.data.budgetCompliance,
          budgetComparison: action.data.budgetComparison,
        },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_MULTIPLE_OFFERS_CREDITS]: (state, action) => {
    const fetchedOfferCredits = Object.fromEntries<App.BusinessTraveller.StatefulData<App.BusinessTraveller.OfferCredit>>(
      Object.entries<App.BusinessTraveller.OfferCredit>(action.data)
        .map(([offerCreditKey, calculatedCredit]) => ([
          offerCreditKey,
          calculatedCredit.budgetCompliance === 'under_budget' ? {
            status: 'success',
            budgetCompliance: 'under_budget',
            creditValue: calculatedCredit.creditValue,
            budgetComparison: calculatedCredit.budgetComparison,
          } : {
            status: 'success',
            budgetCompliance: calculatedCredit.budgetCompliance,
            creditValue: 0,
            budgetComparison: calculatedCredit.budgetComparison,
          },
        ])),
    )
    return {
      offersCredits: {
        ...state.offersCredits,
        ...fetchedOfferCredits,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_BUDGET]: (state, action) => {
    const currentBudget = action.data.budgets.length > 0 ? action.data.budgets[0] : undefined

    return {
      businessBudgets: {
        ...state.businessBudgets,
        [action.budgetKey]: {
          status: 'success',
          maxNightlyBudget: action.data.maxNightlyBudget,
          minNightlyBudget: action.data.minNightlyBudget,
          budgets: action.data.budgets,
        },
      },
      businessMapBudget: currentBudget,
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_ACCOMMODATION_POLICY]: (state, action) => {
    return {
      businessAccommodationPolicy: {
        status: 'success',
        policy: action.data,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_POLICY]: (state, action) => {
    return {
      businessFlightPolicy: {
        status: 'success',
        policy: action.data,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_CAR_HIRE_POLICY]: (state, action) => {
    return {
      businessCarHirePolicy: {
        status: 'success',
        policy: action.data,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_BUDGET]: (state, action) => {
    return {
      businessFlightBudgets: {
        ...state.businessFlightBudgets,
        [action.budgetKey]: {
          status: 'success',
          flightBudgets: action.data as Array<App.BusinessTraveller.BusinessBudget>,
        },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_CAR_HIRE_BUDGET]: (state, action) => {
    return {
      businessCarHireBudgets: {
        ...state.businessCarHireBudgets,
        [action.budgetKey]: {
          status: 'success',
          carHireBudgets: action.data,
        },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_APPROVAL_POLICY]: (state, action) => {
    return {
      businessApprovalPolicy: {
        status: 'success',
        policy: action.data,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_EMPLOYEES]: (state, action) => {
    return {
      employees: {
        status: 'success',
        all: action.data,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_APPROVERS]: (state, action) => {
    return {
      approvers: {
        status: 'success',
        all: action.data,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_BUDGET_SEARCH]: (state, action: AnyAction & { flightSearchKey: string, data: Array<App.BusinessTraveller.BusinessBudget>}) => {
    const budgets = action.data
    const budgetIds = budgets.map(budget => budget.id)
    const newBudgets = arrayToObject(budgets, budget => budget.id)
    return {
      budgets: { ...state.budgets, ...newBudgets },
      flightSearchBudget: {
        ...state.flightSearchBudget,
        [action.flightSearchKey]: { status: 'success', budgetIds },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_EMPLOYEE_DETAILS]: (state, action) => {
    return {
      employeeDetails: {
        status: 'success',
        all: action.data,
      },
    }
  },
})

// FAILURE
export const businessTravellerApiFailureActions = reducerSwitch<App.BusinessTravellerState>({
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_ACCOUNT_BUSINESS]: (state, action) => {
    return {
      currentBusinessAccount: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
      onboarding: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
      employees: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
      businessCards: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_APPROVAL_REQUEST]: (state, action) => {
    return {
      approvalRequests: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.UPDATE_EMPLOYEE_ONBOARDING_STATUS]: (state, action) => {
    return {
      onboarding: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.UPDATE_EMPLOYEE_DISPLAY_FLIGHT_BUDGET_STATUS]: (state, action) => {
    return {
      flightBudgetNotification: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_OFFER_CREDITS]: (state, action) => {
    return {
      offersCredits: {
        ...state.offersCredits,
        [action.creditItemKey]: { status: 'failed' },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_MULTIPLE_OFFERS_CREDITS]: (state, action) => {
    const failedOfferCredits = Object.fromEntries<App.BusinessTraveller.StatefulData<App.BusinessTraveller.OfferCredit>>(
      Object.keys(action.toBeCalculatedOfferCredits)
        .map((offerCreditKey) => ([offerCreditKey, { status: 'failed' }])),
    )
    return {
      offersCredits: {
        ...state.offersCredits,
        ...failedOfferCredits,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_BUDGET]: (state, action) => {
    return {
      businessBudgets: {
        ...state.businessBudgets,
        [action.budgetKey]: { status: 'failed' },
      },
      businessMapBudget: undefined,
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_ACCOMMODATION_POLICY]: (state, action) => {
    return {
      businessFlightPolicy: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_POLICY]: (state, action) => {
    return {
      businessFlightPolicy: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_CAR_HIRE_POLICY]: (state, action) => {
    return {
      businessCarHirePolicy: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_BUDGET]: (state, action) => {
    return {
      businessFlightBudgets: {
        ...state.businessFlightBudgets,
        [action.budgetKey]: { status: 'failed' },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_CAR_HIRE_BUDGET]: (state, action) => {
    return {
      businessCarHireBudgets: {
        ...state.businessCarHireBudgets,
        [action.budgetKey]: { status: 'failed' },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_APPROVAL_POLICY]: (state, action) => {
    return {
      businessApprovalPolicy: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_EMPLOYEES]: (state, action) => {
    return {
      employees: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_APPROVERS]: (state, action) => {
    return {
      approvers: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_FLIGHT_BUDGET_SEARCH]: (state, action) => {
    return {
      flightSearchBudget: {
        ...state.flightSearchBudget,
        [action.flightSearchKey]: { status: 'failed' },
      },
    }
  },
  [BUSINESS_TRAVELLER_API_ACTIONS.FETCH_BUSINESS_EMPLOYEE_DETAILS]: (state, action) => {
    return {
      employeeDetails: {
        status: 'failed',
        error: action.error.message ?? action.error,
      },
    }
  },
})
