import Clickable from 'components/Common/Clickable'
import LuxLoyaltyTierIcon from 'components/Luxkit/Icons/luxLoyalty/LuxLoyaltyTierIcon'
import SolidInfoCircleIcon from 'components/Luxkit/Icons/solid/SolidInfoCircleIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Plural from 'components/utils/Formatters/Plural'
import InlineIcon from 'components/utils/InlineIcon'
import React, { ComponentProps, MouseEventHandler, useCallback } from 'react'

interface Props {
  tier: 'silver' | 'gold' | 'platinum'
  earnablePoints: number
  statusCredits?: number
  size: 'S' | 'M'
  onInfoIconClick?: MouseEventHandler<HTMLButtonElement>
  calculationType: 'estimate' | 'exact'
}

export const SIZES_BODY_TEXT_VARIANTS: Record<Props['size'], ComponentProps<typeof BodyText>['variant']> = {
  S: 'medium',
  M: 'large',
}

function PriceRowLuxLoyaltyPoints({
  tier,
  earnablePoints,
  statusCredits,
  size,
  onInfoIconClick,
  calculationType,
}: Props) {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    event.stopPropagation()

    onInfoIconClick?.(event)
  }, [onInfoIconClick])

  return <BodyTextBlock
    variant={SIZES_BODY_TEXT_VARIANTS[size]}
    colour="neutral-one"
    weight="bold"
    startIcon={<LuxLoyaltyTierIcon size="S" tier={tier} />}
    wrap="no-wrap"
  >
    {calculationType === 'estimate' && <>Earn from <Plural singular="pt" count={earnablePoints} withCount /></>}
    {calculationType === 'exact' && <>Earn <Plural singular="pt" count={earnablePoints} withCount /></>}
    {!!statusCredits && <><wbr /> + <Plural singular="status credit" count={statusCredits} withCount /></>}
    {onInfoIconClick && <> <Clickable onClick={handleClick}><InlineIcon Icon={SolidInfoCircleIcon} /></Clickable></>}
  </BodyTextBlock>
}

export default PriceRowLuxLoyaltyPoints
