import { useMemo } from 'react'
import { isTourV2OfferSummary } from 'lib/offer/offerTypes'

function useTourV2Details(tourOffer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary) {
  const variationOptions = useMemo(() => {
    const variations = Object.values(tourOffer.variations)
    return {
      hasMoreVariations: isTourV2OfferSummary(tourOffer) ? tourOffer.allVariationsCount > 1 : variations.length > 1,
      hasAlternativeRoute: variations.some(variation => variation.isReverseItinerary),
      upgradesAvailable: variations[0]?.availablePackageUpgrades?.length > 1,
    }
  }, [tourOffer])

  return variationOptions
}

export default useTourV2Details
