import { computeCruiseDepositAmount, computeDepositAmount } from 'checkout/lib/utils/payments/deposit'
import { createSelector } from 'reselect'
import { checkoutAccommodationOfferView } from '../view/accommodation'
import { getAccommodationPayable } from './accommodation'
import getDepositSettings from './getDepositSettings'

const getAccommodationDepositAmount = createSelector(
  (state: App.State) => getAccommodationPayable(state),
  (state: App.State) => getDepositSettings(state),
  (state: App.State) => checkoutAccommodationOfferView(state),
  (accommodationPayable, depositSettings, viewWithStatus): number => {
    const isCruise = viewWithStatus?.data[0]?.offerType === 'cruise'
    return isCruise ?
      computeCruiseDepositAmount(accommodationPayable, depositSettings) :
      computeDepositAmount(accommodationPayable, depositSettings)
  },
)

export default getAccommodationDepositAmount
