import AddRoomButton from 'components/Common/AddRoomButton'
import config from 'constants/config'
import noop from 'lib/function/noop'
import { AdultLabel, generateRoomOccupants } from 'lib/offer/occupancyUtils'
import React, { useCallback, useMemo } from 'react'
import VerticalSpacer from '../Spacing/VerticalSpacer'
import OccupancyItem, { OccupancyChildVariant } from './OccupancyItem'
import styled from 'styled-components'

const Container = styled(VerticalSpacer)``

interface Props {
  occupancies: Array<App.Occupants | App.RoomOccupants>;
  maxRooms?: number;
  noServiceContact?: boolean;
  capacities?: Array<App.RoomCapacity>;
  maxChildAge?: number;
  maxInfantAge?: number;
  minInfantAge?: number;
  saleUnit?: string;
  onChange?: (occupancies: Array<App.Occupants | App.RoomOccupants>) => void;
  name?: string;
  'data-testid'?: string;
  className?: string;
  childrenAgeLabel?: string
  compact?: boolean;
  contactBoxStyle?: boolean;
  enableMultiRoom?: boolean
  adultLabelType?: AdultLabel;
  childVariant?: OccupancyChildVariant;
  bundleOfferId?: string;
  offerType?: App.OfferType;
  offerId?: string;
  disableSaleUnit?: boolean;
  departureDate?: string;
}

const OccupancyPicker = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    occupancies,
    capacities,
    maxChildAge,
    maxInfantAge,
    minInfantAge,
    saleUnit = 'room',
    onChange = noop,
    maxRooms,
    noServiceContact,
    name = 'occupants', // not user-visible - don't translate
    className,
    childrenAgeLabel = 'at time of check-in',
    compact = false,
    contactBoxStyle,
    enableMultiRoom,
    adultLabelType,
    childVariant: showChildren,
    bundleOfferId,
    offerType,
    offerId,
    disableSaleUnit,
    departureDate,
    'data-testid': dataTestId,
  } = props

  const isInstanceOfRoomOccupants = useMemo(() => occupancies.length && ('roomId' in occupancies[0]), [occupancies])

  const onAddAnotherRoom = useCallback(() => {
    const newRoom = isInstanceOfRoomOccupants ? generateRoomOccupants(config.search.defaultOccupants) : { ...config.search.defaultOccupants }
    const newOccupancies = [...occupancies, newRoom]
    onChange(newOccupancies)
  }, [occupancies, onChange, isInstanceOfRoomOccupants])

  const onRemove = useCallback((index: number) => {
    const newOccupancies = [...occupancies]
    newOccupancies.splice(index, 1)
    onChange(newOccupancies)
  }, [occupancies, onChange])

  const onReset = useCallback((index: number) => {
    const newOccupancies = [...occupancies]
    newOccupancies[index] = { ...newOccupancies[index], ...config.search.defaultOccupants }
    onChange(newOccupancies)
  }, [occupancies, onChange])

  const onItemChange = useCallback((index: number, newOccupancy: App.Occupants) => {
    const newOccupancies = [...occupancies]
    newOccupancies[index] = { ...newOccupancies[index], ...newOccupancy }
    onChange(newOccupancies)
  }, [occupancies, onChange])

  return <Container gap={12} className={className} data-testid={dataTestId} ref={ref}>
    {occupancies.map((occupants, index) => <OccupancyItem
      smallView={compact}
      key={index}
      itemId={index}
      occupancy={occupants}
      title={disableSaleUnit ? undefined : bundleOfferId ? 'Guests' : `${saleUnit} ${index + 1}`}
      showRemove={index > 0}
      onReset={onReset}
      onRemove={onRemove}
      maxChildAge={maxChildAge}
      maxInfantAge={maxInfantAge}
      minInfantAge={minInfantAge}
      onChange={onItemChange}
      capacities={capacities}
      childrenAgeLabel={childrenAgeLabel}
      name={name}
      adultLabelType={adultLabelType}
      childVariant={showChildren}
      offerType={offerType}
      offerId={offerId}
      departureDate={departureDate}
    />)}
    {enableMultiRoom && <div>
      <AddRoomButton
        maxRooms={maxRooms}
        saleUnit={saleUnit}
        roomsCount={occupancies.length}
        onClick={onAddAnotherRoom}
        noServiceContact={noServiceContact}
        contactBoxStyle={contactBoxStyle}
        offerId={offerId}
        departureDate={departureDate}
      />
    </div>}
  </Container>
})
OccupancyPicker.displayName = 'OccupancyPicker'

export default React.memo(OccupancyPicker)
