import { selectSelectedTravellerEmployees, selectTravellerCount } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import TextInputOnly from 'components/Common/Form/Input/TextInputOnly'
import { StandaloneFlightMenu } from 'components/Flights/FlightsSearch/SearchWidget/FlightSearchWidget'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import SearchFormFieldGroup from 'components/SearchV2/Components/SearchFormField/SearchFormFieldGroup'
import FlightSearchWidgetDispatchContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetDispatchContext'
import FlightSearchWidgetStateContext from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateContext'
import { FlightSearchWidgetActions } from 'contexts/Flights/FlightSearchWidget/flightSearchWidgetStateReducer'
import React, { ChangeEventHandler, RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import BusinessTravellerMultiSelectContent from './BusinessTravellerMultiSelectContent'
import { showSnackbar } from 'components/Luxkit/Snackbar/AppSnackbar'
import { resetCheckoutForm } from 'actions/CheckoutActions'
import { clearFormStateSnapshot } from 'storage/checkout'
import { saveBusinessTravellerSelectedTravellerState } from 'businessTraveller/storage/businessTravellerStorage'
import { useAppDispatch } from 'hooks/reduxHooks'
import setSelectedBusinessTravellersAndGuests from 'actions/businessTraveller/setSelectedBusinessTravellerEmployeesAndGuests'
import { getBusinessTravellerSelectEnabled } from 'businessTraveller/utils/getBusinessTravellerSelectEnabled'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import useBusinessTravellerEmployeeQuery from 'businessTraveller/hooks/useEmployeeQuery'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
  selectedTravellerEmployeeIds?: Array<string>
  selectedTravellerGuestCount?: number
  selectedTravellerCount?: number
  businessAccount?: App.BusinessTraveller.CurrentBusinessAccount
}

interface Props {
  dropdownBoundaryRef?: RefObject<HTMLElement>;
  className?: string;
}

function BusinessTravellerSelectFlightsDesktop(props: Props & MappedStateProps) {
  const { className, dropdownBoundaryRef, travellerEmployees, selectedTravellerGuestCount, selectedTravellerCount, businessAccount, selectedTravellerEmployeeIds } = props

  const [searchTerm, setSearchTerm] = useState<string>('')
  const handleSearchTermChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setSearchTerm(event.currentTarget.value)
  }, [])

  const state = useContext(FlightSearchWidgetStateContext)
  const flightsDispatch = useContext(FlightSearchWidgetDispatchContext)

  const { employees } = useBusinessTravellerEmployeeQuery()

  const appDispatch = useAppDispatch()

  const { activeMenu } = state

  const [travellerSelectState, setTravellerSelectState] = useState<{ selectedTravellers: Array<string>, guestCount: number }>({
    selectedTravellers: selectedTravellerEmployeeIds ?? [],
    guestCount: selectedTravellerGuestCount ?? 0,
  })

  const setActiveMenu = useCallback((activeMenu?: StandaloneFlightMenu) => {
    flightsDispatch({ type: FlightSearchWidgetActions.SET_ACTIVE_MENU, activeMenu })
  }, [flightsDispatch])

  const handleSelectTravellerClick = useCallback(() => {
    const menuToSet = activeMenu !== StandaloneFlightMenu.occupants ?
      StandaloneFlightMenu.occupants :
      undefined
    setActiveMenu(menuToSet)
  }, [activeMenu, setActiveMenu])

  const handleSelectTravellerClose = useCallback(() => {
    setActiveMenu(undefined)
  }, [setActiveMenu])

  const handleSelectTravellerApply = useCallback(() => {
    if (travellerSelectState.selectedTravellers.length === 0 && travellerSelectState.guestCount === 0) {
      showSnackbar('Please select at least one traveller or guest.', 'warning')
      return
    }
    // Because we are now saving the traveller form, we need to make sure its cleared when a new traveller is selected.
    appDispatch(resetCheckoutForm())
    clearFormStateSnapshot()
    // Update employee list and guest count in redux state
    appDispatch(setSelectedBusinessTravellersAndGuests(travellerSelectState.selectedTravellers, travellerSelectState.guestCount))
    // Update App.Occupants in GlobalSearchContext
    const occupants: App.Occupants = {
      adults: travellerSelectState.selectedTravellers.length + travellerSelectState.guestCount || 1,
      children: 0,
      infants: 0,
      childrenAge: [],
    }
    flightsDispatch({ type: FlightSearchWidgetActions.SET_OCCUPANTS, occupants })
    // Save to localstorage so persist on page refresh
    saveBusinessTravellerSelectedTravellerState(travellerSelectState.selectedTravellers, travellerSelectState.guestCount)
    setActiveMenu(undefined)
  }, [appDispatch, travellerSelectState, flightsDispatch, setActiveMenu])

  const fieldValue = useMemo(() => {
    const totalTravellers = (travellerEmployees?.length ?? 0) + (selectedTravellerGuestCount ?? 0)
    if (!totalTravellers) return undefined
    if (totalTravellers === 1) {
      const firstTraveller = travellerEmployees[0]

      return firstTraveller ? `${firstTraveller.firstName} ${firstTraveller.lastName}` : 'Guest traveller'
    }
    return `${totalTravellers} travellers`
  }, [travellerEmployees, selectedTravellerGuestCount])

  // Quick fix to keep occupants in sync with the selected travellers when the component mounts
  useEffect(() => {
    flightsDispatch({
      type: FlightSearchWidgetActions.SET_OCCUPANTS,
      occupants: { // use redux state passed in props, not the local state
        adults: selectedTravellerCount,
        children: 0,
        infants: 0,
        childrenAge: [],
      },
    })
  }, [flightsDispatch, selectedTravellerCount])

  useEffect(() => {
    // Set a default traveller, only for users that are travellers, not admins or managers (OPERX-263)
    const businessTravellerSelectEnabled = getBusinessTravellerSelectEnabled(businessAccount)
    // If user has business account and there are no selected travellers or guests, set the business account as the selected traveller
    if (!businessTravellerSelectEnabled && businessAccount?.employee?.id && !selectedTravellerEmployeeIds?.length && !selectedTravellerGuestCount) {
      saveBusinessTravellerSelectedTravellerState([businessAccount.employee.id])
      setTravellerSelectState({
        selectedTravellers: [businessAccount.employee.id],
        guestCount: 0,
      })
    }
  }, [selectedTravellerEmployeeIds, selectedTravellerGuestCount, businessAccount?.employee?.id, businessAccount])

  const localInputRef = useRef<HTMLButtonElement>(null)
  const hasEmployees = employees ? Object.values(employees).length > 1 : false

  return (
    <>
      <SearchFormFieldGroup className={className}>
        <SearchFormField
          label="Travellers"
          required
          requiredErrorMessage="Please select traveller"
          value={fieldValue}
          displayValue={fieldValue}
          placeholder="Select Traveller"
          onClick={handleSelectTravellerClick}
          ref={localInputRef}
        />
      </SearchFormFieldGroup>

      <DropdownList
        size="M"
        open={activeMenu === StandaloneFlightMenu.occupants}
        triggerRef={localInputRef}
        anchorRef={localInputRef}
        boundaryRef={dropdownBoundaryRef}
        onClose={handleSelectTravellerClose}
        headerExtension={hasEmployees &&
          <TextInputOnly
            value={searchTerm}
            type="text"
            placeholder="Search traveller name"
            onChange={handleSearchTermChange}
            endIcon={<LineSearchIcon />}
            noValidationSpacing
          />}
        primaryActionProps={{
          children: 'Apply',
          type: 'button',
          onClick: handleSelectTravellerApply,
        }}
      >
        <BusinessTravellerMultiSelectContent
          searchTerm={searchTerm}
          travellerSelectState={travellerSelectState}
          setTravellerSelectState={setTravellerSelectState}
        />
      </DropdownList>
    </>
  )
}

export default connect<MappedStateProps, {}, Props, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
    selectedTravellerEmployeeIds: state.businessTraveller.selectedTravellerEmployeeIds,
    selectedTravellerGuestCount: state.businessTraveller.selectedTravellerGuestCount,
    businessAccount: state.businessTraveller.currentBusinessAccount,
    selectedTravellerCount: selectTravellerCount(state),
  }
})(BusinessTravellerSelectFlightsDesktop)
