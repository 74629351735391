import { excludeNullOrUndefined } from 'checkout/utils'
import { sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { shouldUseInsuranceMemberPrice } from 'checkout/selectors/view/luxPlusSubscription'

export const getInsuranceTotals = createSelector(
  (state: App.State) => getInsuranceItemsView(state),
  (state: App.State) => shouldUseInsuranceMemberPrice(state),
  (viewWithStatus, checkoutWithDiscountedInsurance): App.WithDataStatus<App.Checkout.ItemViewTotals> => {
    const definedItems = viewWithStatus.data.filter(excludeNullOrUndefined)

    const itemsWithMobileAppDiscount = definedItems.filter((item) => item.totals.mobileAppDiscount?.amount)
    const mobileAppDiscountAmount = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, ({ totals }) => totals.mobileAppDiscount?.amount ?? 0) : 0
    const mobileAppDiscountPercentage = itemsWithMobileAppDiscount.length ? sum(itemsWithMobileAppDiscount, ({ totals }) => totals.mobileAppDiscount?.percentage ?? 0) / itemsWithMobileAppDiscount.length : 0

    const totals: App.Checkout.ItemViewTotals = {
      price: sum(definedItems, itemView => checkoutWithDiscountedInsurance ? (itemView.totals.memberPrice ?? 0) : (itemView.totals.price ?? 0),
      ),
      memberPrice: 0,
      value: sum(definedItems, itemView => (itemView.totals.value ?? 0)),
      surcharge: 0,
      taxesAndFees: 0,
      ...(mobileAppDiscountAmount > 0 && {
        mobileAppDiscount: {
          amount: mobileAppDiscountAmount,
          percentage: mobileAppDiscountPercentage,
        },
      }),
      memberValue: 0,
      extraGuestSurcharge: 0,
    }

    return {
      hasRequiredData: viewWithStatus.hasRequiredData,
      data: totals,
    }
  },
)
