import React, { useCallback, useContext, useMemo, useRef } from 'react'
import SearchFormField from 'components/SearchV2/Components/SearchFormField/SearchFormField'
import ModalContext from 'contexts/ModalContext'
import { connect } from 'react-redux'
import { selectSelectedTravellerEmployees } from 'businessTraveller/selectors/businessTravellerEmployeeSelectors'
import BusinessTravellerSelectModal from './BusinessTravellerSelectModal'
import { GlobalSearchDispatchContext, GlobalSearchStateContext } from 'contexts/GlobalSearch/GlobalSearchContexts'
import { SearchMenuStates } from 'components/Search/type'
import { GlobalSearchStateActions } from 'contexts/GlobalSearch/GlobalSearchState'

interface MappedStateProps {
  travellerEmployees: Array<App.BusinessTraveller.EmployeeFromMeEndpoint>
}

function BusinessTravellerSelectCarHireMobile(props: MappedStateProps) {
  const { travellerEmployees } = props

  const { activeMenu } = useContext(GlobalSearchStateContext)
  const dispatch = useContext(GlobalSearchDispatchContext)

  const showModal = useContext(ModalContext)

  const setActiveMenu = useCallback((menu: SearchMenuStates) => {
    dispatch({ type: GlobalSearchStateActions.SET_ACTIVE_MENU, menu })
  }, [dispatch])

  const handleTravellerSelect = useCallback(() => {
    setActiveMenu(SearchMenuStates.Closed)
  }, [setActiveMenu])

  const handleSelectTravellerClick = useCallback(async() => {
    if (activeMenu !== SearchMenuStates.CarHireBusinessTravellerSelect) {
      setActiveMenu(SearchMenuStates.CarHireBusinessTravellerSelect)

      await showModal(<BusinessTravellerSelectModal onTravellerSelect={handleTravellerSelect} />)

      setActiveMenu(SearchMenuStates.Closed)
    } else {
      setActiveMenu(SearchMenuStates.Closed)
    }
  }, [activeMenu, handleTravellerSelect, setActiveMenu, showModal])

  const fieldValue = useMemo(() => {
    if (travellerEmployees?.length) {
      const firstTraveller = travellerEmployees[0]
      return `${firstTraveller.firstName} ${firstTraveller.lastName}`
    }

    return undefined
  }, [travellerEmployees])

  const localInputRef = useRef<HTMLButtonElement>(null)

  return (
    <SearchFormField
      label="Travellers"
      required
      requiredErrorMessage="Please select traveller"
      value={fieldValue}
      displayValue={fieldValue}
      placeholder="Select Traveller"
      onClick={handleSelectTravellerClick}
      ref={localInputRef}
      />
  )
}

export default connect<MappedStateProps, {}, {}, App.State>((state) => {
  return {
    travellerEmployees: selectSelectedTravellerEmployees(state),
  }
})(BusinessTravellerSelectCarHireMobile)
