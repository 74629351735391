import Label from 'components/Luxkit/Label'
import { isAndroidAppUserAgent, isIOSAppUserAgent } from 'lib/web/deviceUtils'
import React from 'react'
import { connect } from 'react-redux'

interface Props {
  experienceOffer?: App.ExperienceOffer;
  isMobileApp: boolean;
  discountPercentage?: number;
  showOnlyOnMobile?: boolean;
}

function ExperienceAppDiscountTag(props: Props) {
  const { isMobileApp, experienceOffer, discountPercentage, showOnlyOnMobile = true } = props
  const percentage = discountPercentage ?? experienceOffer?.pricing.mobileAppDiscountPercentage

  if ((showOnlyOnMobile && !isMobileApp) || !percentage) return null

  return (
    <Label kind="primary" variant="highlight-secondary">
      {percentage}% app discount
    </Label>
  )
}

const mapStateToProps = (state: App.State) => ({
  isMobileApp: isAndroidAppUserAgent(state.system.rawUserAgentString) || isIOSAppUserAgent(state.system.rawUserAgentString),
})

export default connect(mapStateToProps)(ExperienceAppDiscountTag)
