import UrgencyLabel from 'components/Luxkit/Label/UrgencyLabel'
import useInterval from 'hooks/useInterval'
import { pluralizeToString } from 'lib/string/pluralize'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import useHasMounted from 'hooks/useHasMounted'

const FixedWidthCountdown = styled.div`
  display: inline-block;

  &.fixed-width {
    width: 7ch;
  }
`

const DEFAULT_DAYS_TO_BOOK = 4

interface Props {
  className?: string;
  daysToBook?: number
  endDate: string;
  showHourOnly?: boolean;
  isLuxPlusEarlyAccess?: boolean;
}

function OfferUrgencyCountdownLabel(props: Props) {
  const {
    endDate,
    daysToBook = DEFAULT_DAYS_TO_BOOK,
    showHourOnly = false,
    isLuxPlusEarlyAccess = false,
    ...rest
  } = props
  const [now, setNow] = useState<moment.Moment>(moment())
  const luxPlusEnabled = useAppSelector(state => isLuxPlusEnabled(state))

  const hasMounted = useHasMounted()

  const {
    message,
    timeLeft,
  } = useMemo(() => {
    const end = moment(endDate)

    const leftToTheEnd = moment.duration(end.diff(now))
    const roundedEndDays = Math.floor(leftToTheEnd.asDays())
    const endHours = leftToTheEnd.asHours()

    const message = isLuxPlusEarlyAccess && luxPlusEnabled ? 'Priority access ends in' : 'Ends in'
    if (endHours > 24) {
      if (isLuxPlusEarlyAccess && luxPlusEnabled) {
        return {
          message,
          timeLeft: pluralizeToString('day', roundedEndDays),
        }
      }

      if (roundedEndDays <= daysToBook) {
        return {
          message: 'Ends in',
          timeLeft: pluralizeToString('day', roundedEndDays),
        }
      }
    }

    if (endHours <= 24 && leftToTheEnd.asSeconds() > 0) {
      const timeLeft = moment.utc(leftToTheEnd.asMilliseconds())
      // round up the hour
      if (showHourOnly) timeLeft.add({ minutes: 59, seconds: 59 })
      const timeLeftString = showHourOnly ?
        timeLeft.format('HH') :
        timeLeft.format('HH:mm:ss')

      return {
        message,
        timeLeft: <FixedWidthCountdown className={cn({ 'fixed-width': !showHourOnly })}>
          {timeLeftString}
        </FixedWidthCountdown>,
      }
    }

    return {
      message: undefined,
      timeLeft: undefined,
    }
  }, [endDate, now, isLuxPlusEarlyAccess, luxPlusEnabled, daysToBook, showHourOnly])

  // only update per minute if we only show hr
  const updateInterval = showHourOnly ? 60000 : 1000

  useInterval(() => setNow(moment()), updateInterval)

  if (!message) {
    return null
  }

  return (
    <UrgencyLabel
      {...rest}
      className={cn(rest.className, { 'not-mounted': !hasMounted })}
      variant="critical2"
    >
      {message}{' '}{hasMounted && timeLeft}
    </UrgencyLabel>
  )
}

export default OfferUrgencyCountdownLabel
