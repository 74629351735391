import { definitions } from '@luxuryescapes/contract-trip'
import {
  QueryClient,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import debounce from 'lodash.debounce'

import { useTrips } from './trip'
import { invalidateTrip, cancelAndInvalidate, TRIP_STALE_TIME } from './common'
import * as TripKeys from '../reactQueryKeys/trips'

import { arrayToMap } from 'lib/array/arrayUtils'
import {
  createTripItemFromNLPOption,
  createTripItem,
  createTripItemBatch,
  deleteTripItem,
  deleteTripItemsByOrderId,
  getTripItems,
  moveItemToOtherTrip,
  nlpItemCreationMessage,
  patchTripItem,
  rankTripItem,
  updateTripItem,
} from 'tripPlanner/api/tripItem'
import {
  MoveItemToOtherTripError,
  PatchTripItemError,
  RankTripItemResponse,
  RankTripItemBadRequestError,
  RankTripItemNotFoundError,
  NLPItemCreationMessageResponse,
  UpdateTripItemError,
  DeleteTripItemError,
  DeleteTripItemsByOrderIdError,
} from 'tripPlanner/api/tripItem/types'
import * as Mapper from 'tripPlanner/mappers'
import { BasicTrip, CreateItemsBatchResponse, FullTrip } from 'tripPlanner/types/common'
import { TripItem } from 'tripPlanner/types/tripItem'
import { applyPatchFields } from '../utils'
import { useWebViewModal } from '../useWebViewModal'
import { useAppSelector } from 'hooks/reduxHooks'
import { isIOSSel } from 'selectors/configSelectors'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { mobileBatchSuccess, mobileDeleteSuccess, mobileSuccess, triggerMobileError } from '../mobileApi'
import * as Analytics from 'analytics/analytics'
import { isRejected } from 'lib/promise/promiseUtils'
import { getLowStockItemsForTrip } from 'tripPlanner/api'

export const TRIP_API_DEFAULT_TRIP_ITEMS: Array<definitions['tripItem']> = []

/**
 * Returns true if user has any trip items
 */
export function useHasTripItems(): boolean {
  const { data: trips, isFetched } = useTrips()
  return (
    (isFetched && trips?.some((trip) => trip.itemCount > 0)) ?? false
  )
}

export const useMoveItemToOtherTrip = (
  sourceTripId: string,
  tripItemId: string,
  options?: UseMutationOptions<
    { destinationTrip: FullTrip },
    MoveItemToOtherTripError,
    string
  >,
): UseMutationResult<
  { destinationTrip: FullTrip },
  MoveItemToOtherTripError,
  string
> => {
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (destinationTripId) =>
      moveItemToOtherTrip({ sourceTripId, destinationTripId, tripItemId }).then(
        (res) => ({
          destinationTrip: Mapper.fullTrip(res.destinationTrip),
        }),
      ),
    onSettled: (data, error, destinationTripId) => {
      cancelAndInvalidate(queryClient, TripKeys.lists)
      invalidateTrip(queryClient, sourceTripId)
      invalidateTrip(queryClient, destinationTripId)
      options?.onSettled?.(data, error, destinationTripId, undefined)
    },
  })
}

export const usePatchTripItem = (
  options?: UseMutationOptions<
    TripItem,
    PatchTripItemError,
    Parameters<typeof patchTripItem>[0]
  >,
): UseMutationResult<
  TripItem,
  PatchTripItemError,
  Parameters<typeof patchTripItem>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation(
    (params) =>
      patchTripItem(params).then((tripItem) =>
        Mapper.tripItem(tripItem, params.tripId),
      ),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
    },
  )
}

const debouncedInvalidateTrip = debounce(invalidateTrip, 10000)

export const useRankTripItem = (
  options?: UseMutationOptions<
    RankTripItemResponse,
    RankTripItemBadRequestError | RankTripItemNotFoundError,
    Parameters<typeof rankTripItem>[0]
  >,
): UseMutationResult<
  RankTripItemResponse,
  RankTripItemBadRequestError | RankTripItemNotFoundError,
  Parameters<typeof rankTripItem>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation((params) => rankTripItem(params), {
    retry: false,
    ...options,
    onSuccess: (data, variables, context) => {
      // Update local rank on success
      const updateMap = arrayToMap(
        data.updates,
        (itemUpdate) => itemUpdate.itemId,
      )
      const trip = queryClient.getQueryData<definitions['fullTrip']>(
        TripKeys.detail(variables.tripId),
      )
      if (trip) {
        const updatedItems = trip.items.map((item) => {
          const update = updateMap.get(item.id)
          return update ? applyPatchFields(item, update) : item
        })
        queryClient.setQueryData<definitions['fullTrip']>(
          TripKeys.detail(variables.tripId),
          { ...trip, items: updatedItems },
        )
      }
      // Don't want to immediately invalidate the trip, because if the user is doing a number
      // of drag and drop operations it would cause excessive re-fetching of the trip.
      // Instead, use a debounce to invalidate the trip after ten seconds.
      debouncedInvalidateTrip(queryClient, variables.tripId)
      options?.onSuccess?.(data, variables, context)
    },
    onError: (data, variables, error) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onError?.(data, variables, error)
    },
  })
}
export const getBasicTrip = (
  tripId: string,
  queryClient: QueryClient,
): BasicTrip | undefined => {
  const trip = queryClient
    .getQueryData<Array<definitions['basicTrip']>>(TripKeys.lists)
    ?.find((t) => t.id === tripId)

  return trip ? Mapper.basicTrip(trip) : undefined
}

export const useTripItems = (
  options?: UseQueryOptions<
    Array<definitions['tripItem']>, unknown, Array<TripItem>
  >,
) => {
  const loggedIn = useAppSelector(selectLoggedIn)

  return useQuery({
    queryKey: loggedIn ?
      TripKeys.tripItemsList :
      TripKeys.tripItemsListLoggedOut,
    queryFn: loggedIn ?
        () => getTripItems() :
        () => TRIP_API_DEFAULT_TRIP_ITEMS,
    select: Mapper.tripItems,
    initialData: loggedIn ? undefined : TRIP_API_DEFAULT_TRIP_ITEMS,
    placeholderData: TRIP_API_DEFAULT_TRIP_ITEMS,
    enabled: loggedIn,
    staleTime: TRIP_STALE_TIME,
    ...options,
  })
}

export const useCreateTripItemBatch = (
  options?: UseMutationOptions<
    CreateItemsBatchResponse, Error, Parameters<typeof createTripItemBatch>[0]
  >,
): UseMutationResult<
  definitions['createItemsBatchResponse'], Error, Parameters<typeof createTripItemBatch>[0]
> => {
  const queryClient = useQueryClient()
  const isWebViewModal = useWebViewModal()
  const isIOS = useAppSelector(isIOSSel)

  return useMutation((params) => createTripItemBatch(params), {
    retry: false,
    ...options,
    onSuccess: (response, variables, context) => {
      queryClient.setQueryData(TripKeys.detail(variables.tripId), response)
      isIOS && mobileBatchSuccess(isWebViewModal, variables.tripId, response)
      options?.onSuccess?.(
        Mapper.createItemsBatchResponse(response),
        variables,
        context,
      )
    },
    onError: (error, variables, context) => {
      cancelAndInvalidate(queryClient, TripKeys.detail(variables.tripId))
      isIOS && triggerMobileError(error.message)
      options?.onError?.(error, variables, context)
    },
    onSettled: (response, error, variables, context) => {
      if (response) {
        invalidateTrip(queryClient, response.id)
      }

      options?.onSettled?.(
        response && Mapper.createItemsBatchResponse(response),
        error,
        variables,
        context,
      )
    },
  })
}

export const useCreateTripItem = (
  options?: UseMutationOptions<
    TripItem, Error, Parameters<typeof createTripItem>[0], definitions['basicTrip']
  >,
): UseMutationResult<
  TripItem, Error, Parameters<typeof createTripItem>[0], definitions['basicTrip']
> => {
  const queryClient = useQueryClient()
  const isWebViewModal = useWebViewModal()
  const isIOS = useAppSelector(isIOSSel)

  return useMutation(
    (params) => createTripItem(params).then((tripItem) => Mapper.tripItem(tripItem, params.tripId),
    ),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
      onSuccess: (res, args) => {
        // If an item can be created, then the trip already exists in our basic trip list cache
        const trip = queryClient
          .getQueryData<Array<definitions['basicTrip']>>(TripKeys.lists)
          ?.find((t) => t.id === args.tripId)
        isIOS && mobileSuccess(isWebViewModal, res?.tripId, res?.id)
        options?.onSuccess?.(res, args, trip)
        if (args.tripItem.sourceType === 'NA' ||
          args.tripItem.sourceType === 'Explore') {
          Analytics.trackClientEvent({
            subject: 'add-custom-trip-item',
            action: 'clicked',
            category: 'logging',
            type: 'operational',
            optimizelyEventId: '28542940091',
            optimizelyEventKey: 'add-custom-trip-item',
          })
        }
      },
      onError: (error, args, trip) => {
        isIOS && triggerMobileError(error.message)
        options?.onError?.(error, args, trip)
      },
    },
  )
}

export const useUpdateTripItem = (
  options?: UseMutationOptions<
    TripItem, UpdateTripItemError, Parameters<typeof updateTripItem>[0]
  >,
): UseMutationResult<
  TripItem, UpdateTripItemError, Parameters<typeof updateTripItem>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation(
    (params) => updateTripItem(params).then((tripItem) => Mapper.tripItem(tripItem, params.tripId),
    ),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
    },
  )
}

export const useDeleteTripItem = (
  options?: UseMutationOptions<
    null, DeleteTripItemError, Parameters<typeof deleteTripItem>[0], BasicTrip
  >,
): UseMutationResult<
  null, DeleteTripItemError, Parameters<typeof deleteTripItem>[0], BasicTrip
> => {
  const queryClient = useQueryClient()
  const isIOS = useAppSelector(isIOSSel)

  return useMutation(deleteTripItem, {
    retry: false,
    ...options,
    onSettled: (data, error, variables, context) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onSettled?.(data, error, variables, context)
    },
    onSuccess: (res, args) => {
      isIOS && mobileDeleteSuccess(args.tripId, args.tripItemId)
      options?.onSuccess?.(res, args, getBasicTrip(args.tripId, queryClient))
    },
    onError: (res, args) => {
      isIOS && triggerMobileError(res.error)
      options?.onError?.(res, args, getBasicTrip(args.tripId, queryClient))
    },
  })
}

interface MultiItemDeleteArgs {
  tripId: string
  itemIds: Array<string>
}

export const useDeleteTripItems = (
  options?: UseMutationOptions<
    null, DeleteTripItemError, MultiItemDeleteArgs, BasicTrip
  >,
): UseMutationResult<
  null, DeleteTripItemError, MultiItemDeleteArgs, BasicTrip
> => {
  const queryClient = useQueryClient()

  // Reminder to make this a transaction on BE for bookmark api
  return useMutation(
    ({ tripId, itemIds }) => Promise.allSettled(
      itemIds.map((tripItemId) => deleteTripItem({ tripId, tripItemId })),
    ).then((results) => {
      const rejected = results.find(isRejected)
      if (rejected) {
        throw rejected.reason
      }
      return null
    }),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
      onSuccess: (data, vars) => {
        // If the trip can be saved to, or removed from, then it already exists in our basic trip list cache
        const trip = getBasicTrip(vars.tripId, queryClient)
        options?.onSuccess?.(data, vars, trip)
      },
    },
  )
}

export const useDeleteTripItemsBasedOnOrderId = (
  options?: UseMutationOptions<
    null, DeleteTripItemsByOrderIdError, Parameters<typeof deleteTripItemsByOrderId>[0]
  >,
): UseMutationResult<
  null, DeleteTripItemsByOrderIdError, Parameters<typeof deleteTripItemsByOrderId>[0]
> => {
  const queryClient = useQueryClient()

  return useMutation(deleteTripItemsByOrderId, {
    retry: false,
    ...options,
    onSettled: (data, error, variables, context) => {
      invalidateTrip(queryClient, variables.tripId)
      options?.onSettled?.(data, error, variables, context)
    },
  })
}

export const selectFirstTripItem = (
  lowStockItems: Array<definitions['lowStockItem']>,
): TripItem | undefined => lowStockItems && lowStockItems.length > 0 ?
  Mapper.tripItem(
    lowStockItems[0].tripItem,
    lowStockItems[0].tripItem.tripId,
  ) :
  undefined
export const useTripLowStockItems = ({
  tripId, ...options
}: {
  tripId: string
  options?: UseQueryOptions
}): UseQueryResult<TripItem | undefined> => {
  const loggedIn = useAppSelector(selectLoggedIn)

  return useQuery({
    queryKey: TripKeys.lowStockItems(tripId),
    queryFn: loggedIn ? () => getLowStockItemsForTrip({ tripId }) : () => [],
    select: selectFirstTripItem,
    ...options,
  })
}
export const useNLPItemCreationMessage = (
  args: {
    tripId: string
    locationBias?: string
    date?: string
  },
  options?: UseMutationOptions<{ response: NLPItemCreationMessageResponse, sentTimestamp: number}, any, { message: string; sentTimestamp: number} >,
) => {
  return useMutation({
    mutationFn: ({ message, sentTimestamp }) => nlpItemCreationMessage({ ...args, message, sentTimestamp }),
    ...options,
  })
}

export const useCreateTripItemFromNLPOption = (
  options?: UseMutationOptions<
    definitions['tripItem'],
    Error,
    Parameters<typeof createTripItemFromNLPOption>[0]
  >,
): UseMutationResult<
  definitions['tripItem'],
  Error,
  Parameters<typeof createTripItemFromNLPOption>[0]
> => {
  const queryClient = useQueryClient()
  const isWebViewModal = useWebViewModal()
  const isIOS = useAppSelector(isIOSSel)

  return useMutation(
    (params) =>
      createTripItemFromNLPOption(params),
    {
      retry: false,
      ...options,
      onSettled: (data, error, variables, context) => {
        invalidateTrip(queryClient, variables.tripId)
        options?.onSettled?.(data, error, variables, context)
      },
      onSuccess: (item, variables, context) => {
        const trip = queryClient.getQueryData<definitions['fullTrip']>(
          TripKeys.detail(variables.tripId),
        )
        if (trip) {
          queryClient.setQueryData<definitions['fullTrip']>(
            TripKeys.detail(variables.tripId),
            { ...trip, items: [...trip.items, item] },
          )
        }
        isIOS && mobileSuccess(isWebViewModal, item?.tripId, item?.id)
        options?.onSuccess?.(item, variables, context)
      },
      onError: (error, variables, context) => {
        isIOS && triggerMobileError(error.message)
        options?.onError?.(error, variables, context)
      },
    },
  )
}
