import { rotate } from 'components/utils/animations'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Spinner = styled.div`
  display: flex;
  align-items: end;
  justify-content: center;
  animation: ${rotate} 1s infinite reverse linear;
  transform-origin: center;
  width: var(--spinner-size);
  height: var(--spinner-size);
  border-radius: 100%;
  mask: radial-gradient(transparent calc(var(--spinner-size) * 0.4), black 1px);
  background: conic-gradient(from 180deg at 50% 50%, ${props => props.theme.palette.neutral.default.five} 0deg, rgba(217, 217, 217, 0) 360deg);

  &:before {
    content: '';
    height: calc(var(--spinner-size) * 0.1);
    width: calc(var(--spinner-size) * 0.1);
    background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
    border-radius: 100%;
  }
`

interface Props {
  size?: number;
}

function LoadingSpinner(props: Props) {
  const { size = 22 } = props

  return <Spinner style={{
    '--spinner-size': rem(size),
  }} />
}

export default LoadingSpinner
