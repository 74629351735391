import config from 'constants/config'
import { createSelector } from 'reselect'
import { isLastMinuteEnabled } from './lastMinute'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { NAVBAR_SPECIFIC_ORDER_REGIONS_LIST } from 'constants/config/region'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'

export const getNavItems = createSelector(
  (state: App.State) => state.auth.account,
  (state: App.State) => isLastMinuteEnabled(state),
  (state: App.State) => isLuxPlusEnabled(state),
  (state: App.State) => getIsLuxLoyaltyEnabled(state),
  (state: App.State) => state.geo,
  (state: App.State) => state.system.storeMode,
  (account, isLastMinuteEnabled, isLuxPlusEnabled, isLuxLoyaltyEnabled, geo, isStoreMode) => {
    const navEnabled = (nav: App.NavItem) => {
      if (nav.onlyOnRegions && !nav.onlyOnRegions.includes(geo.currentRegionCode)) {
        return false
      }

      if (nav.notOnRegions?.includes(geo.currentRegionCode)) {
        return false
      }

      if (nav.featureToggle && !config[nav.featureToggle]) {
        return false
      }

      if (nav.accountToggle && (!account[nav.accountToggle])) {
        return false
      }

      if (!!nav.lastMinuteToggle && !isLastMinuteEnabled) {
        return false
      }

      if (!!nav.luxPlusToggle && !isLuxPlusEnabled) {
        return false
      }

      if (!!nav.luxLoyaltyToggle && !isLuxLoyaltyEnabled) {
        return false
      }

      if (nav.hideInStoreMode && isStoreMode) {
        return false
      }

      return true
    }

    const navRegionConfig = NAVBAR_SPECIFIC_ORDER_REGIONS_LIST.has(geo.currentRegionCode) ? geo.currentRegionCode : 'DEFAULT'
    const navItems = Array.isArray(config.header.nav.items) ? config.header.nav.items : config.header.nav.items[navRegionConfig]
    return navItems.reduce<Array<App.NavItem>>((navList, nav) => {
      if (nav.newLabelOnlyOnRegions) nav.newLabel = nav.newLabelOnlyOnRegions.includes(geo.currentRegionCode)

      if (nav.hasDropdown) {
        // need to whittle down the dropdown items of this subnav
        // note: subnavs can only go 1 level deep at most

        const subNav = {
          ...nav,
          items: nav.items?.filter(navEnabled),
        }
        if (subNav.items && subNav.items.length > 0) {
          navList.push(subNav)
        }
      } else if (navEnabled(nav)) {
        // normal nav, see if we can push it in
        navList.push(nav)
      }
      return navList
    }, [])
  },
)
