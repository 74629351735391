import { actions, definitions } from '@luxuryescapes/contract-svc-agent'
import getAllItemViewsByType from 'checkout/selectors/view/getAllItemViewsByType'
import { OFFER_TYPE_BED_BANK, OFFER_TYPE_TOUR_V2, OFFER_TYPE_CRUISE, OFFER_TYPE_TOUR } from 'constants/offer'
import { sum, unique } from 'lib/array/arrayUtils'

type ServerAgentAccount = definitions['userSimpleSchema']
type ServerAgencyDetails = definitions['userAgencyResponse']
type ServerAgentEmailStatus = definitions['userStatusResponse']
type ServerAgentCommission = actions['calculateCartCommission']['responses']['200']['content']['application/json']['result']

interface CalculateCartCommissionBodyItem {
  offerId: string
  type: string
  price: number
  location: Array<string>
  vendor: string
  transactionKey: string
}

interface CalculateCartCommissionBody {
  cartId: string;
  items: Array<CalculateCartCommissionBodyItem>;
  total: number;
}

function sumViewItemTotal(itemView: App.Checkout.AnyItemView): number {
  const itemPrice = itemView.totals.price
  const itemTotalPrice = itemView.item.itemType === 'tourV2' ? itemView.item?.total ?? itemPrice : itemPrice
  return itemTotalPrice + (itemView.totals.surcharge ?? 0) + (itemView.totals.otherFees?.extraGuestSurcharge ?? 0)
}
function sumViewItemsTotal(items: Array<App.Checkout.AnyItemView>): number {
  return sum(items, (i) => sumViewItemTotal(i))
}

export function agentHubCheckEmailStatusMapper(response: ServerAgentEmailStatus): App.AgentHubAgentStatus {
  return {
    status: response.status ?? '',
    agentId: response.agent_id ?? '',
    action: response.action,
    ...(response.email && { email: response.email }),
    ...(response.firstName && { firstName: response.firstName }),
    ...(response.surname && { surname: response.surname }),
    ...(response.phone && { phone: response.phone }),
    ...(response.phone_prefix && { phonePrefix: response.phone_prefix }),
  }
}

export function agentHubAccountMapper(response: ServerAgentAccount): App.AgentHubAccount {
  return {
    agentId: response.id,
    name: response.name || '',
    phone: response.phone || '',
    email: response.email || '',
    affiliation: response.affiliation,
    agencyId: response.agencyId || '',
    agencyName: response.agencyName || '',
    status: response.status as any,
    agencyLogoId: response.agencyLogoId,
    agentTier: response.tier || '',
  }
}

export function agentHubAgencyMapper(response: ServerAgencyDetails): App.AgentHubAgency {
  return {
    id: response.id,
    name: response.name,
    agencyIdentifier: response.businessCodeValue,
    agencyIdentifierType: response.businessCodeType,
    address: response.address,
    city: response.city,
    state: response.state,
    postcode: response.postcode,
    country: response.country,
    affiliation: response.affiliation,
    brand: response.brand,
  }
}

const getCommissionItems = (state: App.State): Array<CalculateCartCommissionBodyItem> => {
  const resultItems: Array<CalculateCartCommissionBodyItem> = []
  const { accommodationItemsView, tourV2ExperienceItemsView, experienceItemsView, transferItemsView } = getAllItemViewsByType(state).data

  if (experienceItemsView.data.length > 0) {
    for (const item of experienceItemsView.data) {
      resultItems.push({
        offerId: item.id,
        type: 'experience',
        price: item.totals.price,
        location: [item.location || ''],
        vendor: '',
        transactionKey: item.item.transactionKey,
      })
    }
  }

  for (const itemView of transferItemsView.data || []) {
    resultItems.push({
      offerId: itemView.item.experienceId,
      type: 'transfer',
      price: itemView.item.transfer.option?.price || 0,
      location: [],
      vendor: '',
      transactionKey: itemView.item.transactionKey,
    })
  }

  if (accommodationItemsView.data.length > 0) {
    for (const item of accommodationItemsView.data) {
      if (item.offerType === OFFER_TYPE_TOUR) {
        const locations = unique((item?.offer?.locations || []).concat(item?.offer?.location || []))

        resultItems.push({
          offerId: item.offerId,
          type: item.offer?.productType || item.offerType,
          price: sumViewItemsTotal(item.itemViews),
          location: locations,
          vendor: item.offer?.vendorName || '',
          transactionKey: item.itemViews[0].item.transactionKey,
        })

        continue
      }

      if (item.offerType === OFFER_TYPE_BED_BANK) {
        const locations = [
          item?.offer?.property?.address.city || '',
          item?.offer?.property?.address.stateProvinceName || '',
          item.offer?.property?.address.countryName || '']
          .filter(Boolean)

        resultItems.push({
          type: item.offer?.productType || '',
          offerId: item.offerId,
          price: sumViewItemsTotal(item.itemViews),
          location: locations,
          vendor: '',
          transactionKey: item.itemViews[0].item.transactionKey,
        })

        continue
      }

      if (item.offerType === OFFER_TYPE_TOUR_V2) {
        const tourV2StartLocation = () => {
          const variations = item.itemViews.map((x) => x.variation)
          if (variations.length === 0) return ['']
          const variation = variations[0]
          return [variation?.startLocation || '']
        }

        const optionalExperiences = tourV2ExperienceItemsView?.data || []
        const optionalExperiencesTotalPrice = optionalExperiences.reduce((acc, curr) => acc + curr.item.purchasableOption.fullPrice, 0)

        let leahProductType = item.offer?.productType || ''

        const newTourProductTypes = ['classic_tour', 'deluxe_tour', 'ultra_lux_tour', 'signature_series_tour']
        if (newTourProductTypes.includes(leahProductType)) {
          leahProductType = 'direct_tour'
        }

        resultItems.push({
          type: leahProductType || '',
          offerId: item.offerId,
          price: optionalExperiencesTotalPrice + sumViewItemsTotal(item.itemViews),
          location: tourV2StartLocation(),
          vendor: '',
          transactionKey: item.itemViews[0].item.transactionKey,
        })

        continue
      }

      if (item.offerType === OFFER_TYPE_CRUISE) {
        resultItems.push({
          type: item.offer?.productType || '',
          offerId: item.offerId,
          price: sumViewItemsTotal(item.itemViews),
          location: [item.location],
          vendor: '',
          transactionKey: item.itemViews[0].item.transactionKey,
        })

        continue
      }

      const locations = unique((item?.offer?.locations || []).concat(item?.offer?.location || []))

      resultItems.push({
        offerId: item.offerId,
        type: item.offer?.productType || item.offerType,
        price: sumViewItemsTotal(item.itemViews),
        location: locations,
        vendor: item.offer?.vendorName || '',
        transactionKey: item.itemViews[0].item.transactionKey,
      })
    }
  }

  return resultItems
}

export const stateToCommissionOrder = (state: App.State): CalculateCartCommissionBody => {
  const items = getCommissionItems(state)
  return {
    items: getCommissionItems(state),
    cartId: state.checkout.cart.cartId,
    total: sum(items, (i) => i.price || 0),
  }
}

export const agentHubCommissionMap = (serverCommission: ServerAgentCommission): App.AgentHubCommission => {
  return {
    promoCode: serverCommission.promoCode,
    itemsCommission: Object.fromEntries(
      Object.entries(serverCommission.itemsCommission).map(([key, value]) => [
        key,
        {
          commissionPercentage: value.commissionPercentage,
          itemDiscount: value.itemDiscount,
        },
      ]),
    ),
  }
}
