import isInsuranceSupported from 'checkout/selectors/request/isInsuranceSupported'
import getCheckoutInsuranceQuotes from 'checkout/selectors/view/getCheckoutInsuranceQuotes'
import getInsuranceItemsView from 'checkout/selectors/view/getInsuranceItemsView'
import { max, sum } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import { isEnabledLuxPlusDiscountedInsurance } from 'luxPlus/selectors/featureToggle'
import getIsCheckoutInsuranceFetching from 'checkout/selectors/view/getIsCheckoutInsuranceFetching'

export const getAvailableInsuranceBenefits = createSelector(
  (state: App.State) => isEnabledLuxPlusDiscountedInsurance(state),
  (state: App.State) => isInsuranceSupported(state),
  (state: App.State) => getCheckoutInsuranceQuotes(state),
  (state: App.State) => getInsuranceItemsView(state),
  (state: App.State) => getIsCheckoutInsuranceFetching(state),
  (
    isEnabledLuxPlusDiscountedInsurance,
    insuranceSupported,
    quotes,
    insuranceItemsView,
    isFetchingInsurance,
  ): App.Checkout.LuxPlusInsuranceBenefits => {
    const maxSavings = max(quotes.map(quote => quote.luxPlusSavings)) ?? 0

    const totalPrice = sum(insuranceItemsView.data, item => item.totals.price)
    const totalMemberPrice = sum(insuranceItemsView.data, item => item.totals.memberPrice)
    const totalMemberPriceSavings = totalPrice - totalMemberPrice

    if (!isEnabledLuxPlusDiscountedInsurance) {
      return {
        isAvailable: false,
        fetching: false,
        luxPlusDiscountPercentage: 0,
        luxPlusDiscountValueDisplayThreshold: 0,
        totalMemberMaxSavings: 0,
        totalMemberPriceSavings: 0,
        totalPrice: 0,
        totalMemberPrice: 0,
      }
    }

    return {
      isAvailable: isEnabledLuxPlusDiscountedInsurance && insuranceSupported,
      fetching: insuranceSupported && isFetchingInsurance,
      // Todo: remove the default values (this affects the upsell tile in the order confirmation page)
      luxPlusDiscountPercentage: quotes[0]?.luxPlusDiscountPercentage || 40,
      // Todo: remove the default values (this affects the upsell tile in the order confirmation page)
      luxPlusDiscountValueDisplayThreshold: quotes[0]?.luxPlusDiscountValueDisplayThreshold || 30,
      totalMemberMaxSavings: maxSavings,
      totalMemberPriceSavings,
      totalPrice,
      totalMemberPrice,
    }
  },
)
