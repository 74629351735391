import PriceRowAgentHubCommission from 'agentHub/components/PriceRowAgentHubCommission'
import PriceRowCaption from 'components/Luxkit/PricePoints/PriceRowCaption'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import PriceRowSale from 'components/Luxkit/PricePoints/PriceRowSale'
import PriceRowTaxesAndFees from 'components/Luxkit/PricePoints/PriceRowTaxesAndFees'
import PriceRowValueDiscountWithCaption from 'components/Luxkit/PricePoints/Value/PriceRowValueDiscountWithCaption'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Plural from 'components/utils/Formatters/Plural'
import extractTourV2PurchasableOptionDetails from 'lib/tours/extractTourV2PurchasableOptionDetails'
import findCheapestTourV2PurchasableOption from 'lib/tours/findCheapestTourV2PurchasableOption'
import { getTourV2VariationDuration } from 'lib/tours/tourUtils'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import React, { useMemo } from 'react'

interface Props {
  offer: App.Tours.TourV2Offer | App.Tours.TourV2OfferSummary;
  variation?: App.Tours.TourV2OfferVariation;
  filters: App.OfferListFilters;
}

function TourV2SearchTilePriceStack(props: Props) {
  const { offer, variation } = props

  const option = useMemo(() => {
    if (variation) {
      return findCheapestTourV2PurchasableOption(offer, variation.id)
    }
  }, [offer, variation])
  const details = useMemo(() => {
    if (option) {
      return extractTourV2PurchasableOptionDetails(option)
    }
  }, [option])

  const duration = useMemo<number | undefined>(() => {
    if (variation) {
      return getTourV2VariationDuration(variation)
    }
  }, [variation])

  if (!details || !option) {
    return <BodyText variant="medium">Sold out</BodyText>
  }

  return <div>
    {!!duration && <PriceRowPriceCaption>
      <Plural singular="day" count={duration} withCount /> from
    </PriceRowPriceCaption>}
    <LuxPlusPriceStack
      size="L"
      price={details.pricePerPerson}
      saleUnit="person"
      memberPrice={details.memberPrice}
      isTour
    />
    {details.hasDiscount && <PriceRowSale value={details.fullPricePerPerson} />}
    {!!details.valuedAt && <PriceRowValueDiscountWithCaption
      size="M"
      originalValue={details.valuedAt.price}
      discountPercentage={details.valuedAt.savingPercentage}
    />}
    <PriceRowCaption>{details.roomTypeName}</PriceRowCaption>
    <PriceRowTaxesAndFees />
    <PriceRowAgentHubCommission
      size="L"
      productType={offer.productType}
      offerId={offer.id}
      offerLocations={variation ? [variation.startLocation!] : undefined}
    />
  </div>
}

export default TourV2SearchTilePriceStack
